import { enUS, es } from 'date-fns/locale';

const getLocale = (language?: string): Locale => {
  switch (language) {
    case 'en-US':
      return enUS;
    case 'es-ES':
      return es;
    default:
      return es;
  }
};

export { getLocale };
