import {
  ButtonIcon,
  DetailsFormContent,
  FilterButtons,
  FilterButtonsItem,
  PanelDock,
  PanelHeader,
  PanelTitle
} from '@app/shared/components';
import { DailyBooking } from '@domain/bookings';
import {
  FloorplanMetadata,
  FloorplansBookingDetailEnum
} from '@domain/floorplans';
import { WorkplaceFormModel } from '@domain/workplaces';
import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import { waveTools } from '@wave-engine/wave-viewer';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FloorplanBookingsDetailBookings } from './floorplan-bookings-details-bookings/FloorplanBookingsDetailBookings';
import { FloorplanBookingsDetailGeneral } from './floorplan-bookings-details-general/FloorplanBookingsDetailGeneral';

const DetailsContent = styled(DetailsFormContent)`
  margin-top: ${rem(pxToRem(24))};
  padding-right: ${rem(pxToRem(8))};
`;

interface FloorplanBookingsDetailsProps {
  payloadId: string;
  floorplanMetadata: FloorplanMetadata;
  dailyBookings: DailyBooking[];
  onClose: () => void;
  selectedDate?: Date;
}

const FloorplanBookingsDetails = ({
  payloadId,
  floorplanMetadata,
  dailyBookings,
  onClose,
  selectedDate
}: FloorplanBookingsDetailsProps): ReactElement => {
  const { t } = useTranslation('workplaces');
  const [activeTab, setActiveTab] = useState<number>(
    FloorplansBookingDetailEnum.GeneralInfo
  );

  const entity = floorplanMetadata?.entities.find((e) => e.id === payloadId);
  const workplace = waveTools.forms.buildFormFromEntityProperies<
    WorkplaceFormModel
  >({ ...entity?.properties });

  const tabs = [
    {
      label: t('bookingDetails.generalInfo.title'),
      active: activeTab === FloorplansBookingDetailEnum.GeneralInfo
    },
    {
      label: t('bookingDetails.bookings.title'),
      active: activeTab === FloorplansBookingDetailEnum.Bookings
    }
  ] as FilterButtonsItem[];

  return (
    <PanelDock>
      <PanelHeader>
        <PanelTitle>{t('bookingDetails.title')}</PanelTitle>
        <ButtonIcon
          className='closePanel'
          iconName='cross_small'
          type='button'
          title={t('shared:generics.close')}
          onClick={onClose}
        />
      </PanelHeader>

      <FilterButtons buttons={tabs} onSelectionChange={setActiveTab} />

      <DetailsContent>
        {activeTab === FloorplansBookingDetailEnum.GeneralInfo ? (
          <FloorplanBookingsDetailGeneral workplace={workplace} />
        ) : activeTab === FloorplansBookingDetailEnum.Bookings ? (
          <FloorplanBookingsDetailBookings
            dailyBookings={dailyBookings}
            payloadId={payloadId}
            onClose={onClose}
            workplace={workplace}
            selectedDate={selectedDate}
          />
        ) : null}
      </DetailsContent>
    </PanelDock>
  );
};

export { FloorplanBookingsDetails };
