import {
  UpdateUserProfile,
  UpdateUserProfileIsActive,
  UserProfile,
  UserResponse
} from '@domain/user-profile';
import { apiService } from '@services/api';
import {
  PatchIsActiveUserProfileModel,
  UpdateProfileModel
} from '@services/api/apiService';

const getUserProfile = async (): Promise<UserProfile> => {
  return await apiService.profile_Get();
};

const setUserProfile = async (
  userProfile: UpdateUserProfile
): Promise<UserProfile> => {
  return await apiService.profile_Update(
    UpdateProfileModel.fromJS(userProfile)
  );
};

const getSearchProfiles = async (name: string): Promise<UserResponse[]> => {
  return await apiService.profile_Search(name);
};

const getSearchActiveProfiles = async (
  name: string
): Promise<UserResponse[]> => {
  return await apiService.profile_SearchActives(name);
};

const updateUserIsActive = async (
  id: string,
  request: UpdateUserProfileIsActive
): Promise<void> => {
  await apiService.profile_PatchActive(
    id,
    PatchIsActiveUserProfileModel.fromJS(request)
  );
};

export {
  getUserProfile,
  setUserProfile,
  getSearchProfiles,
  updateUserIsActive,
  getSearchActiveProfiles
};
