import {
  getTraceId,
  trackException
} from '@services/logger/applicationInsightsService';
import React, { ReactElement } from 'react';
import {
  QueryErrorResetBoundary,
  useQueryErrorResetBoundary
} from 'react-query';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import {
  ErrorTypes,
  getErrorMessageFromEvent,
  pageErrorFactory
} from './pageErrorFactory';

interface ErrorBoundaryProps
  extends Pick<RouteComponentProps<Record<string, string>>, 'history'> {
  children?: React.ReactNode | React.FC;
  reset?: () => void;
  onError?: (error: Error, componentStack: string) => void;
}

interface ErrorBoundaryState {
  hasError?: boolean;
  error?: ErrorTypes;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  private unlisten: (() => void) | undefined;
  private routesWithErrors: string[] = [];

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error): void {
    this.routesWithErrors.push(this.props.history.location.pathname);
    trackException({ error });
  }

  componentDidMount(): void {
    this.unlisten = this.props.history?.listen((location) => {
      if (this.routesWithErrors.includes(location.pathname)) {
        this.props.reset?.();
        this.routesWithErrors = this.routesWithErrors.filter(
          (r) => r !== location.pathname
        );
      }

      if (this.state.hasError) {
        this.props.reset?.();
        this.setState({ hasError: false, error: undefined });
      }
    });

    addEventListener('unhandledrejection', this.myErrorHandler.bind(this));
  }

  componentWillUnmount(): void {
    this.unlisten?.();
    removeEventListener('unhandledrejection', this.myErrorHandler);
  }

  myErrorHandler = (
    event: PromiseRejectionEvent | ErrorEvent,
    _source?: string,
    _lineno?: number,
    _colno?: number,
    error?: Error
  ): void => {
    if (!this.state.hasError) {
      this.routesWithErrors.push(this.props.history.location.pathname);
      trackException({
        error: error ?? new Error(getErrorMessageFromEvent(event))
      });
      this.setState({ hasError: true, error: error ?? event });
    }
  };

  public render(): React.ReactNode | undefined {
    const { hasError, error } = this.state;
    const pageError = hasError && pageErrorFactory(error, getTraceId());
    return hasError ? (
      <>
        {pageError && pageError.component && (
          <pageError.component {...pageError.props} />
        )}
      </>
    ) : (
      this.props.children
    );
  }
}

const ErrorBoundaryWrapper = (
  props: Omit<ErrorBoundaryProps, keyof RouteComponentProps<{}>>
): ReactElement => {
  const history = useHistory();
  const { reset } = useQueryErrorResetBoundary();

  return <ErrorBoundary {...props} history={history} reset={reset} />;
};

const ResetableErrorBoundary = (
  props: Omit<ErrorBoundaryProps, keyof RouteComponentProps<{}>>
): ReactElement => (
  <QueryErrorResetBoundary>
    <ErrorBoundaryWrapper {...props} />
  </QueryErrorResetBoundary>
);

export { ErrorBoundaryWrapper as ErrorBoundary, ResetableErrorBoundary };
