import {
  FLOORPLAN_METADATA_QUERY_KEY,
  FloorplanMetadata
} from '@domain/floorplans';
import { getFloorplanMetadata } from '@services/floorplans/floorplansService';
import { useQuery, UseQueryResult } from 'react-query';

const useGetFloorplanMetadata = (
  venueId: string,
  buildingId: string,
  floorId: string
): UseQueryResult<FloorplanMetadata, Error> =>
  useQuery<FloorplanMetadata, Error>(
    FLOORPLAN_METADATA_QUERY_KEY(venueId, buildingId, floorId),
    () => getFloorplanMetadata(floorId)
  );

export { useGetFloorplanMetadata };
