import { Loader } from '@app/shared/components';
import { useAuthContextActions } from '@contexts/auth';
import React, { ReactElement, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export type LoginState = {
  previousPath: string;
};

const Login = (): ReactElement => {
  const { state } = useLocation<LoginState>();
  const history = useHistory();
  const { login } = useAuthContextActions();

  useEffect(() => {
    if (state) {
      const { previousPath } = state;
      login(previousPath);
    } else {
      history.push('/');
    }
  }, []);

  return <Loader isFullPage />;
};

export default Login;
