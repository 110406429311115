import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import {
  addAuthenticationInterceptor,
  addErrorInterceptor
} from './interceptors';

const createAxiosClient = (config: AxiosRequestConfig): AxiosInstance => {
  const instance = axios.create(config);
  instance?.interceptors.request.use(addAuthenticationInterceptor, undefined);
  instance?.interceptors.response.use(undefined, addErrorInterceptor);
  return instance;
};

const axiosClient = createAxiosClient({
  headers: {
    'Cache-Control': 'no-cache, no-store',
    Pragma: 'no-cache'
  }
});

export { axiosClient };
