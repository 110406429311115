import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React from 'react';
import { ReactElement } from 'react';

type UserAvatarType = 'table' | 'default';

const UserAvatarWrapper = styled.div`
  ${({
    theme: {
      base: {
        fonts: { getTextsConfig }
      }
    }
  }) => `
    align-items: center;
    display: flex;
    padding: ${rem(pxToRem(10))} 0;
    ${getTextsConfig('M')};
  `}
`;

const UserAvatarItem = styled.div`
  ${({
    theme: {
      base: { radius }
    }
  }) => `
    border-radius: ${radius.circle};
    height: ${rem(pxToRem(40))};
    margin-right: ${rem(pxToRem(12))};
    min-width: ${rem(pxToRem(40))};
    overflow: hidden;
    width: ${rem(pxToRem(40))};
  `}
`;

const UserAvatarImage = styled.div<{ userImageUrl?: string }>`
  ${({
    theme: {
      base: { colors }
    },
    userImageUrl
  }) => `
    background: url('${userImageUrl}') no-repeat center center ${
    colors.neutral.fullLight
  };
    background-size: cover;
    height: ${rem(pxToRem(40))};
    width: ${rem(pxToRem(40))};
  `}
`;

const UserAvatarInitials = styled.div`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig, boldFont }
      }
    }
  }) => `
    align-items: center;
    background-color: ${colors.primary.default};
    color: ${colors.neutral.fullLight};
    display: flex;
    ${getTextsConfig('M')};
    font-family: '${boldFont}';
    height: 100%;
    justify-content: center;
    width: 100%;
  `}
`;

const UserName = styled.p<{ type: UserAvatarType }>`
  ${({
    theme: {
      base: {
        fonts: { boldFont, regularFont, getTextsConfig }
      }
    },
    type
  }) => `
    font-family: ${type === 'table' ? `${regularFont}` : `${boldFont}`};
    ${type === 'table' && getTextsConfig('L')};
  `}
`;

const UserEmail = styled.a`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
    color: ${colors.primary.default};
    text-decoration: underline;
    word-break: break-word;
  `}
`;
interface UserAvatarProps {
  userImageUrl?: string;
  userName: string | undefined;
  userEmail?: string;
  type?: UserAvatarType;
}

const UserAvatar = ({
  userImageUrl,
  userName,
  userEmail,
  type = 'default'
}: UserAvatarProps): ReactElement => {
  return (
    <UserAvatarWrapper>
      <UserAvatarItem>
        {userImageUrl ? (
          <UserAvatarImage userImageUrl={userImageUrl} />
        ) : (
          <UserAvatarInitials>
            {userName?.substring(0, 2).toUpperCase()}
          </UserAvatarInitials>
        )}
      </UserAvatarItem>
      <ul>
        <li>
          <UserName type={type}>{userName}</UserName>
        </li>
        {userEmail && (
          <li>
            <UserEmail href={`mailto:${userEmail}`} title={userEmail}>
              {userEmail}
            </UserEmail>
          </li>
        )}
      </ul>
    </UserAvatarWrapper>
  );
};

export { UserAvatar };
