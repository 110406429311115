import styled from '@emotion/styled';
import { useClickOutsideAction } from '@hooks/useClickOutsideAction';
import { pxToRem, rem } from '@styles/utils/sizes';
import { isSameDate, isValidDate } from '@utils/helpers/dateHelper';
import parse from 'date-fns/parse';
import React, {
  ChangeEvent,
  FocusEvent,
  MutableRefObject,
  Ref,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { ReactElement } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import MaskedInput from 'react-text-mask';

import { ButtonIcon } from '../button-icon/ButtonIcon';
import { Calendar, CalendarProps } from '../calendar';
import { FormGroup, FormGroupProps } from '../form/FormGroup';
import { InputWrapper } from '../form/Input';

const DropdownInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const DropdownInputButtonIcon = styled(ButtonIcon)<{ hasError?: boolean }>`
  ${({
    theme: {
      base: { colors }
    },
    hasError
  }) => `
      padding: ${rem(pxToRem(12))};
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      ${hasError ? `color: ${colors.system.error}` : ''}
  `}
`;

const DropdownInput = styled(InputWrapper)`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
    padding-right: ${rem(pxToRem(34))};
    width: 100%;

    &:focus {
      ~ ${DropdownInputButtonIcon} {
        color: ${colors.primary.default};
      }
    }
  `}
`;

const DropdownCalendarWrapper = styled.div`
  ${({
    theme: {
      base: { zindex }
    }
  }) => `
    z-index: ${zindex.tooltip};
  `}
`;

type InputCalendarProps = {
  disabled?: boolean;
  innerRef?: Ref<HTMLInputElement>;
  className?: string;
} & FormGroupProps &
  CalendarProps;

const InputCalendar = ({
  disabled,
  innerRef,
  value,
  ...restProps
}: InputCalendarProps): ReactElement => {
  const {
    i18n: { language }
  } = useTranslation();
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);
  const inputElementRef = useRef<HTMLInputElement | null>(null);
  const tooltipElementRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setOpen] = useState(false);
  useClickOutsideAction(tooltipElementRef, () => setOpen(false));

  const { styles, attributes, update } = usePopper(inputRef, tooltipRef, {
    placement: 'bottom',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 20]
        }
      }
    ]
  });

  useLayoutEffect(() => {
    if (isOpen) {
      update?.();
    }
  }, [isOpen]);

  const dateString = useMemo(
    () =>
      isValidDate(value)
        ? new Intl.DateTimeFormat(language, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          }).format(value)
        : '',
    [value]
  );

  const [placeholder, mask, dateFormat] = useMemo(() => {
    const date = new Intl.DateTimeFormat(language, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).format(new Date(1970, 0, 2));

    return [
      date.replace('02', 'dd').replace('01', 'mm').replace('1970', 'yyyy'),
      date.split('').map((v) => (/\d/.test(v) ? /\d/ : v)),
      date.replace('02', 'dd').replace('01', 'MM').replace('1970', 'yyyy')
    ];
  }, []);

  const parseDate = (dateStr: string): Date | undefined =>
    parse(dateStr, dateFormat, new Date());

  const onInputChange = (ev: ChangeEvent<HTMLInputElement>): void => {
    const date = parseDate(ev.target.value);
    if (isValidDate(date)) {
      restProps.onChange?.(date!);
    }
  };

  const onInputBlur = (ev: FocusEvent<HTMLInputElement>): void => {
    const date = parseDate(ev.target.value);
    if (!isSameDate(date, value)) {
      restProps.onChange?.(isValidDate(date) ? date! : undefined);
    }
    if (!isValidDate(date)) {
      inputElementRef.current!.value = '';
    }
  };

  return (
    <FormGroup {...restProps}>
      <DropdownInputWrapper>
        <MaskedInput
          mask={mask}
          guide={true}
          value={dateString}
          onChange={onInputChange}
          onBlur={onInputBlur}
          render={(renderRef, props) => (
            <DropdownInput
              hasError={Boolean(restProps?.error?.message)}
              ref={(ref) => {
                if (innerRef) {
                  if (typeof innerRef === 'function') {
                    innerRef(ref);
                  } else {
                    (innerRef as MutableRefObject<HTMLInputElement | null>).current = ref;
                  }
                }
                inputElementRef.current = ref;
                setInputRef(ref);
                renderRef(ref!);
              }}
              disabled={disabled}
              placeholder={placeholder}
              {...props}
            />
          )}
        />
        <DropdownInputButtonIcon
          iconName='calendar'
          iconSize={24}
          hasError={Boolean(restProps?.error?.message)}
          disabled={disabled}
          onClick={() => setOpen((v) => !v)}
        />
      </DropdownInputWrapper>
      {createPortal(
        <DropdownCalendarWrapper
          ref={(ref) => {
            setTooltipRef(ref);
            tooltipElementRef.current = ref;
          }}
          style={styles.popper}
          {...attributes.popper}>
          {isOpen ? (
            <Calendar
              {...restProps}
              value={value}
              onChange={(date) => {
                setOpen(false);
                restProps.onChange?.(date);
              }}
            />
          ) : null}
        </DropdownCalendarWrapper>,
        document.querySelector('#root')!
      )}
    </FormGroup>
  );
};

export { InputCalendar, DropdownInput };
