import { PageContainer, PageHeader } from '@app/shared/components';
import React, { ReactElement, useState } from 'react';

import { BookingListTable } from './booking-list-table/BookingListTable';
import { BookingListToolbar } from './booking-list-toolbar/BookingToolbar';
import { BookingListContainer } from './shared';

const BookingList = (): ReactElement => {
  const [date, setDate] = useState<Date | undefined>(new Date());
  const [buildingName, setBuildingName] = useState<string | undefined>(
    undefined
  );

  return (
    <PageContainer>
      <PageHeader>
        <BookingListToolbar
          buildingName={buildingName}
          date={date}
          setBuildingName={setBuildingName}
          setDate={setDate}
        />
      </PageHeader>
      <BookingListContainer>
        {buildingName && date && (
          <BookingListTable buildingName={buildingName} bookingDate={date} />
        )}
      </BookingListContainer>
    </PageContainer>
  );
};

export default BookingList;
