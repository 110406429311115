import { BUILDING_QUERY_KEY } from '@domain/buildings';

const FLOORPLANS_BASE_KEY = 'floorplans';
const FLOORPLANS_METADATA_BASE_KEY = 'metadata';

const FLOORPLANS_KEY = (venueId: string, buildingId: string): string[] => [
  ...BUILDING_QUERY_KEY(venueId, buildingId),
  FLOORPLANS_BASE_KEY
];

const FLOORPLAN_QUERY_KEY = (
  venueId: string,
  buildingId: string,
  floorId: string
): string[] => [...FLOORPLANS_KEY(venueId, buildingId), floorId];

const FLOORPLAN_METADATA_QUERY_KEY = (
  venueId: string,
  buildingId: string,
  floorId: string
): string[] => [
  ...FLOORPLANS_KEY(venueId, buildingId),
  FLOORPLANS_METADATA_BASE_KEY,
  floorId
];

export { FLOORPLANS_KEY, FLOORPLAN_QUERY_KEY, FLOORPLAN_METADATA_QUERY_KEY };
