import { InputCalendar, Select } from '@app/shared/components';
import { PageTitle } from '@app/shared/components/page/page-title/PageTitle';
import {
  PageToolbar,
  PageToolbarActions
} from '@app/shared/components/page/page-toolbar/PageToolbar';
import styled from '@emotion/styled';
import { useGetBuildingsByVenueId } from '@hooks/buildings';
import { useGetAllVenues } from '@hooks/venues';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Filters = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: ${rem(pxToRem(180))} ${rem(pxToRem(180))} ${rem(
      pxToRem(160)
    )};
  column-gap: ${rem(pxToRem(24))};
`;

interface BookingsToolbarProps {
  buildingName: string | undefined;
  date: Date | undefined;
  setBuildingName: (buildingName: string | undefined) => void;
  setDate: (date: Date | undefined) => void;
}

const BookingListToolbar = ({
  buildingName,
  date,
  setBuildingName,
  setDate
}: BookingsToolbarProps): ReactElement => {
  const { t } = useTranslation('bookings');
  const [venueId, setVenueId] = useState<string | undefined>(undefined);

  const { data: venues, isLoading: isVenuesLoading } = useGetAllVenues({
    archived: true
  });
  const {
    data: buildings,
    isLoading: isBuildingsLoading
  } = useGetBuildingsByVenueId(venueId, {
    archived: true,
    closed: true,
    unpublished: true
  });

  useEffect(() => {
    setVenueId(venues?.first()?.id ?? '');
  }, [venues]);

  useEffect(() => {
    setBuildingName(buildings?.first()?.name ?? '');
  }, [buildings]);

  return (
    <PageToolbar>
      <PageTitle label={t(`shared:routes.bookingList`)} />
      <PageToolbarActions>
        <Filters>
          <Select
            label={t('list.form.venues')}
            items={venues ?? []}
            item={venues?.find((s) => s.id === venueId)}
            getItemLabel={(item) => item?.name ?? ''}
            getItemValue={(item) => item?.id}
            searchable={false}
            isLoading={isVenuesLoading}
            onChange={(item) => setVenueId(item?.id)}
          />
          <Select
            label={t('list.form.buildings')}
            items={buildings ?? []}
            item={
              buildings?.find((s) => s.name === buildingName) ||
              buildings?.first()
            }
            getItemLabel={(item) => item?.name ?? ''}
            getItemValue={(item) => item?.id}
            searchable={false}
            isLoading={isBuildingsLoading}
            onChange={(item) => setBuildingName(item?.name)}
          />
          <InputCalendar
            label={t('list.form.date')}
            value={date}
            onChange={setDate}
          />
        </Filters>
      </PageToolbarActions>
    </PageToolbar>
  );
};

export { BookingListToolbar };
