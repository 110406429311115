import { HttpStatusCodes } from '@services/http';

import { GenericError } from './pages-error/GenericError';
import { NotFound } from './pages-error/NotFound';
import { PageErrorProps } from './pages-error/shared/pageErrorProps';

export type ErrorTypes =
  | string
  | PromiseRejectionEvent
  | ErrorEvent
  | Error
  | undefined;

const getErrorMessageFromEvent = (
  event: string | PromiseRejectionEvent | ErrorEvent
): string => {
  let message = '';
  if (event instanceof PromiseRejectionEvent) {
    message = event.reason.message;
  } else if (event instanceof ErrorEvent) {
    message = event.message;
  } else {
    message = event;
  }
  return message;
};

const getErrorMessage = (error: ErrorTypes): string => {
  return error
    ? error instanceof Error
      ? error.message
      : getErrorMessageFromEvent(error)
    : '';
};

const getPageErrorComponentFromHttpStatus = (
  httpStatus: HttpStatusCodes
): React.ComponentType<PageErrorProps> => {
  const componentsDictionary = {
    [HttpStatusCodes.NotFound]: NotFound,
    [HttpStatusCodes.InternalServerError]: GenericError,
    [HttpStatusCodes.BadRequest]: GenericError,
    [HttpStatusCodes.Forbidden]: GenericError,
    [HttpStatusCodes.Unauthorized]: GenericError
  };
  return componentsDictionary[httpStatus] || GenericError;
};

const getPageErrorComponentFromPromise = (error: {
  status?: number;
  isApiException?: boolean;
  isAxiosError?: boolean;
}): React.ComponentType<PageErrorProps> => {
  return error?.isApiException && error?.status
    ? getPageErrorComponentFromHttpStatus(error.status)
    : GenericError;
};

const getPageErrorComponent = (
  error: ErrorTypes
): React.ComponentType<PageErrorProps> => {
  return error instanceof PromiseRejectionEvent
    ? getPageErrorComponentFromPromise(error.reason)
    : error instanceof ErrorEvent
    ? getPageErrorComponentFromPromise(error.error)
    : GenericError;
};

const pageErrorFactory = (
  error: ErrorTypes,
  traceId: string
): {
  component: React.ComponentType<PageErrorProps>;
  props: PageErrorProps;
} => {
  const message = getErrorMessage(error);
  const component = getPageErrorComponent(error);
  return { component, props: { traceId, message } };
};

export { pageErrorFactory, getErrorMessageFromEvent };
