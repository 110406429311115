import { FLOORPLAN_QUERY_KEY, FloorplanResponse } from '@domain/floorplans';
import { getFloorplan } from '@services/floorplans/floorplansService';
import { useQuery, UseQueryResult } from 'react-query';

const useGetFloorPlan = (
  venueId: string,
  buildingId: string,
  floorId: string
): UseQueryResult<FloorplanResponse, Error> =>
  useQuery<FloorplanResponse, Error>(
    FLOORPLAN_QUERY_KEY(venueId, buildingId, floorId),
    () => getFloorplan(floorId)
  );

export { useGetFloorPlan };
