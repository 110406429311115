import { ButtonPrimary } from '@app/shared/components';
import styled from '@emotion/styled';
import { useGetTermCondition } from '@hooks/term-condition/useGetTermCondition';
import { pxToRem, rem } from '@styles/utils/sizes';
import { downloadFile } from '@utils/files-utils';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, useHistory } from 'react-router-dom';

import { toLocaleDateString } from '../../../../utils/helpers/dateHelper';
import { TermConditionForm } from './term-condition-form';
import { CreateTermConditionFormLocation } from './term-condition-form/routes/TermConditionFormLocation';

const SectionContainer = styled.section`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig, boldFont }
      }
    }
  }) => `
    color: ${colors.primary.default};
    ${getTextsConfig('L')};
    font-family: '${boldFont}';
  `}
`;

const SectionActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rem(pxToRem(8))};
`;

const Text = styled.p`
  padding-bottom: ${rem(pxToRem(12))};
`;

export const TextContent = styled.span`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
    color: ${colors.neutral.grayDark};
  `}
`;

const TermCondition = (): ReactElement | null => {
  const {
    t,
    i18n: { language }
  } = useTranslation('term-condition');

  const { data: termCondition } = useGetTermCondition();

  const history = useHistory();

  const handleNewTermCondition = (): void =>
    history.push(CreateTermConditionFormLocation.toUrl());

  return (
    <SectionContainer>
      {termCondition ? (
        <>
          <Text>
            {t('main.date')}:{' '}
            <TextContent>
              {toLocaleDateString(termCondition.date, language)}
            </TextContent>
          </Text>
          <SectionActions>
            <ButtonPrimary
              label={t('update-term-condition')}
              onClick={handleNewTermCondition}
            />
            <ButtonPrimary
              label={t('main.download-button')}
              iconName='download'
              onClick={() =>
                termCondition?.url && downloadFile(termCondition?.url)
              }
            />
          </SectionActions>
        </>
      ) : (
        <ButtonPrimary
          label={t('new-term-condition')}
          onClick={handleNewTermCondition}
        />
      )}
      <Switch>
        {CreateTermConditionFormLocation.toRoute({
          component: TermConditionForm
        })}
      </Switch>
    </SectionContainer>
  );
};

export { TermCondition };
