/* eslint-disable @typescript-eslint/no-explicit-any */
import { NotFound } from '@app/errors/pages-error';
import ReactAppLocation, { YupParams } from '@jmserrano/react-app-location';
import { RouteComponentProps, StaticContext } from 'react-router';

interface LocationCtor {
  path: string;
  pathParamDefs?: YupParams;
  queryStringParamDefs?: YupParams;
}

export type ILocationComponentProps<
  TParams,
  TState = any
> = RouteComponentProps<TParams, StaticContext, TState>;
// TODO: Try to transform into a function
export class Location<
  TParams = unknown,
  TState = unknown
> extends ReactAppLocation<TParams, TState> {
  constructor(params: LocationCtor) {
    super({ ...params, invalid: NotFound as React.ComponentType });
  }
}
