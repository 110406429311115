import { TermCondition } from '@domain/term-condition';
import { TERM_CONDITION_QUERY_KEY } from '@domain/term-condition/termConditionKeys';
import { getTermCondition } from '@services/term-condition/termConditionService';
import { useQuery, UseQueryResult } from 'react-query';

const useGetTermCondition = (): UseQueryResult<
  TermCondition | undefined,
  Error
> =>
  useQuery<TermCondition | undefined, Error>(TERM_CONDITION_QUERY_KEY, () =>
    getTermCondition()
  );

export { useGetTermCondition };
