import { ButtonBase } from '@app/shared/components';
import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';

const VenuesWrapper = styled.div`
  ${() => `
    align-items: center;
    display: flex;
    flex-direction: row;

    ${ButtonBase} {
      margin-left: ${rem(pxToRem(32))};
    }
  `}
`;

export { VenuesWrapper };
