import { IconWrapper } from '@app/shared/components';
import styled from '@emotion/styled';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';

export const FileUploader = styled.div`
  ${({
    theme: {
      base: { colors, radius }
    }
  }) => `
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-bottom: ${rem(pxToRem(12))};

    ${IconWrapper} {
      align-items: center;
      background-color: ${getRgbaStrFromHexColor(colors.primary.default, 0.1)};
      border-radius: ${radius.circle};
      box-sizing: border-box;
      color: ${colors.primary.default};
      display: flex;
      font-size: ${rem(pxToRem(24))};
      height: ${rem(pxToRem(80))};
      justify-content: center;
      margin-right: ${rem(pxToRem(12))};
      padding: ${rem(pxToRem(15))};
      width: ${rem(pxToRem(80))};
    }
  `}
`;
