import { Modal } from '@app/shared/components';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { TermConditionLocation } from '../routes';
import { TermConditionCreateForm } from './term-condition-create-form/TermConditionCreateForm';

const TermConditionForm = (): ReactElement => {
  const { t } = useTranslation('term-condition');

  const history = useHistory();

  const onGoBack = (): void => {
    history.replace(TermConditionLocation.toUrl());
  };

  return (
    <Modal title={t('creation-title')} onClose={onGoBack}>
      <TermConditionCreateForm
        onCreateTermCondition={onGoBack}
        onCancel={onGoBack}
      />
    </Modal>
  );
};

export { TermConditionForm };
