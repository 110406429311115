import { VENUE_QUERY_KEY } from '@domain/venues';

const BUILDINGS_QUERY_KEY = (venueId: string): string[] => [
  ...VENUE_QUERY_KEY(venueId),
  'buildings'
];

const BUILDING_QUERY_KEY = (venueId: string, buildingId: string): string[] => [
  ...BUILDINGS_QUERY_KEY(venueId),
  buildingId
];

const SHIFT_QUERY_KEY = (buildingId: string): string[] => ['shifttypes', buildingId]

export { BUILDINGS_QUERY_KEY, BUILDING_QUERY_KEY, SHIFT_QUERY_KEY };
