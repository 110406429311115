import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement } from 'react';

import { Icon, IconWrapper } from '../icon';

const CardStatusIconWrapper = styled.div<{ color: string }>`
  ${({
    theme: {
      base: { colors, radius }
    },
    color
  }) => `
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    ${IconWrapper} {
      align-items: center;
      background-color: ${color};
      border-radius: ${radius.circle};
      color: ${colors.neutral.fullLight};
      display: flex;
      height: ${rem(pxToRem(72))};
      justify-content: center;
      width: ${rem(pxToRem(72))};
    }
  `}
`;

type CardStatusIconProps = {
  iconName: string;
  color: string;
};

const CardStatusIcon = ({
  iconName,
  color
}: CardStatusIconProps): ReactElement => {
  return (
    <CardStatusIconWrapper color={color}>
      <Icon iconName={iconName} iconSize={42} />
    </CardStatusIconWrapper>
  );
};

export { CardStatusIcon };
