import { Location } from '@app/shared/routes';
import { RouteHelper } from '@utils/helpers/routeHelper';

export interface FloorplanBookingsLocationParams {
  venueId: string;
  buildingId: string;
  floorplanId: string;
}

const FloorplanBookingsLocation = new Location<FloorplanBookingsLocationParams>(
  {
    path:
      '/facility-management/venues/:venueId/buildings/:buildingId/floorplans/:floorplanId/bookings',
    pathParamDefs: {
      venueId: RouteHelper.stringParamValidation(),
      buildingId: RouteHelper.stringParamValidation(),
      floorplanId: RouteHelper.stringParamValidation()
    }
  }
);

export { FloorplanBookingsLocation };
