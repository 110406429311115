import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';

const PageHeader = styled.header`
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${rem(pxToRem(36))};
  margin-top: ${rem(pxToRem(22))};
  padding: 0 ${rem(pxToRem(40))};
  width: 100%;
`;

export { PageHeader };
