import {
  FloorplanWorkplace,
  FloorplanWorkplaceEntity
} from '@app/facility-management/floorplans/floorplan-settings/shared/models/floorplanWorkplace';
import { MenuActionsItem } from '@app/shared/components';
import { WorkplaceFormModel } from '@domain/workplaces/models/create/workplaceFormModel';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type UseWorkplaceTypeActions = {
  bulkActionsItems: MenuActionsItem[];
  getItemActions: (
    entityId: string,
    workplace: FloorplanWorkplaceEntity
  ) => MenuActionsItem[];
};

type UseWorkplaceTypeActionsProps = {
  workplaces: FloorplanWorkplace;
  selectedWorkplacesIds: string[];

  onEdit: (entityId: string) => void;
  onDelete: (...entityId: string[]) => void;
  onEditEntitiesProperties: (
    properties: Partial<WorkplaceFormModel>,
    ...entityIds: string[]
  ) => void;
};

const useWorkplaceTypeActions = ({
  workplaces,
  selectedWorkplacesIds,
  onEdit,
  onDelete,
  onEditEntitiesProperties
}: UseWorkplaceTypeActionsProps): UseWorkplaceTypeActions => {
  const { t } = useTranslation('workplaces');

  const selectedWorkplaces = useMemo(
    () =>
      selectedWorkplacesIds.reduce((prevValue, w) => {
        if (workplaces[w]) {
          prevValue.push(workplaces[w]);
        }
        return prevValue;
      }, [] as FloorplanWorkplaceEntity[]),
    [workplaces, selectedWorkplacesIds]
  );

  const bulkActionsItems: MenuActionsItem[] = useMemo(() => {
    const publishAction = selectedWorkplaces.some(
      (w) => w.model.enabled !== '1'
    )
      ? {
          label: t('actions.publish'),
          iconName: 'eye',
          disabled: !selectedWorkplacesIds.length,
          onClick: () =>
            onEditEntitiesProperties(
              {
                enabled: '1'
              },
              ...selectedWorkplacesIds
            )
        }
      : null;

    const unpublishAction = selectedWorkplaces.some(
      (w) => w.model.enabled === '1'
    )
      ? {
          label: t('actions.unpublish'),
          iconName: 'eye-slash',
          disabled: !selectedWorkplacesIds.length,
          onClick: () =>
            onEditEntitiesProperties(
              {
                enabled: ''
              },
              ...selectedWorkplacesIds
            )
        }
      : null;

    const lockAction = selectedWorkplaces.some((w) => w.model.lockSite !== '1')
      ? {
          label: t('actions.lock'),
          iconName: 'lock-alt',
          disabled: !selectedWorkplacesIds.length,
          onClick: () =>
            onEditEntitiesProperties(
              {
                lockSite: '1'
              },
              ...selectedWorkplacesIds
            )
        }
      : null;

    const unlockAction = selectedWorkplaces.some(
      (w) => w.model.lockSite === '1'
    )
      ? {
          label: t('actions.unlock'),
          iconName: 'lock-slash',
          disabled: !selectedWorkplacesIds.length,
          onClick: () =>
            onEditEntitiesProperties(
              {
                lockSite: ''
              },
              ...selectedWorkplacesIds
            )
        }
      : null;

    const deleteAction = selectedWorkplaces.some(
      (w) => !w.infoEntity?.hasActiveBookings
    )
      ? {
          label: t('actions.remove'),
          iconName: 'trash',
          disabled: !selectedWorkplacesIds.length,
          onClick: onDelete
        }
      : null;

    return [
      publishAction,
      unpublishAction,
      lockAction,
      unlockAction,
      deleteAction
    ].filter((i) => i) as MenuActionsItem[];
  }, [selectedWorkplaces]);

  const getItemActions = (
    entityId: string,
    { model, infoEntity }: FloorplanWorkplaceEntity
  ): MenuActionsItem[] => [
    {
      label: t('actions.edit'),
      iconName: 'edit',
      onClick: () => onEdit(entityId)
    },
    {
      label:
        model.enabled === '1' ? t('actions.unpublish') : t('actions.publish'),
      iconName: model.enabled === '1' ? 'eye-slash' : 'eye',
      onClick: () =>
        onEditEntitiesProperties(
          {
            enabled: model.enabled === '1' ? '' : '1'
          },
          entityId
        )
    },
    {
      label: model.lockSite === '1' ? t('actions.unlock') : t('actions.lock'),
      iconName: model.lockSite === '1' ? 'lock-slash' : 'lock-alt',
      onClick: () =>
        onEditEntitiesProperties(
          {
            lockSite: model.lockSite === '1' ? '' : '1'
          },
          entityId
        )
    },
    ...(!infoEntity?.hasActiveBookings
      ? [
          {
            label: t('actions.remove'),
            iconName: 'trash',
            onClick: () => onDelete(entityId)
          }
        ]
      : [])
  ];

  return {
    bulkActionsItems,
    getItemActions
  };
};

export { useWorkplaceTypeActions };
