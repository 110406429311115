import { GridTemplate, Icon } from '@app/shared/components';
import {
  WorkplaceDefinition,
  WorkplaceEntity,
  WorkplaceFormModel,
  WorkplacePlaceType,
  workplacesDefinition
} from '@domain/workplaces';
import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
  IconItem,
  IconItemLabel,
  SectionTitle
} from '../shared/floorplan-bookings-details-layout';

interface FloorplanBookingsDetailGeneralProps {
  workplace: WorkplaceFormModel;
}

const WorkplaceWrapper = styled.div`
  margin-bottom: ${rem(pxToRem(24))};
`;

const WorkplaceTitle = styled.p`
  ${({
    theme: {
      base: {
        fonts: { getTextsConfig, boldFont }
      }
    }
  }) => `
      ${getTextsConfig('L')};
      font-family: ${boldFont};
      margin-top: ${rem(pxToRem(8))};
   `}
`;

const FloorplanBookingsDetailGeneral = ({
  workplace
}: FloorplanBookingsDetailGeneralProps): ReactElement => {
  const { t } = useTranslation('workplaces');

  const workplaceDefinition = workplacesDefinition[
    (+workplace.type as WorkplacePlaceType) || WorkplacePlaceType.Workstation
  ] as WorkplaceDefinition<WorkplaceEntity>;

  const type = workplaceDefinition.subTypes
    ? Object.values(workplaceDefinition.subTypes).find((i) =>
        i.filter(workplace)
      )
    : undefined;

  return (
    <>
      <WorkplaceWrapper>
        <SectionTitle>{t('bookingDetails.generalInfo.general')}</SectionTitle>
        <IconItem>
          <Icon iconName={type?.icon || workplaceDefinition.icon} />
          <IconItemLabel>
            {t(type?.title || workplaceDefinition.title)}
          </IconItemLabel>
        </IconItem>
        <WorkplaceTitle>{workplace.label}</WorkplaceTitle>
      </WorkplaceWrapper>

      <WorkplaceWrapper>
        <SectionTitle>
          {t('bookingDetails.generalInfo.facilities')}
        </SectionTitle>
        <GridTemplate columns={2} gap={12} align='center'>
          {workplaceDefinition.facilities
            ?.filter((f) => f.filter(workplace))
            .map((f) => (
              <IconItem key={f.fieldName}>
                <Icon iconName={f.icon} title={t(f.title)} />
                <IconItemLabel>{t(f.title)}</IconItemLabel>
              </IconItem>
            ))}
        </GridTemplate>
      </WorkplaceWrapper>
    </>
  );
};

export { FloorplanBookingsDetailGeneral };
