import {
  GridTemplate,
  Input,
  InputCheckBox,
  InputCheckItem
} from '@app/shared/components';
import { WorkplacePlaceType, workplacesDefinition } from '@domain/workplaces';
import { MeetingRoomFormModel } from '@domain/workplaces/models/create/workplaceFormModel';
import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PanelSubTitle } from '../../../../shared/components/page/page-header/PanelHeader';
import { LanSelector } from '../shared/LanSelector';
import { WorkplaceStatusOptions } from '../shared/WorkplaceStatusOptions';

type FormModel = MeetingRoomFormModel;

const MeetingRoomForm = (): ReactElement => {
  const { t } = useTranslation('workplaces');

  const { register, errors } = useFormContext<FormModel>();

  const facilities: InputCheckItem[] =
    workplacesDefinition[WorkplacePlaceType.MeetingRoom].facilities?.map(
      (f) => ({
        id: f.fieldName,
        label: t(f.title),
        name: f.fieldName,
        value: f.value
      })
    ) ?? [];

  const externalVisitors: InputCheckItem[] = [
    {
      id: 'externalVisitors',
      label: t('fieldNames.externalVisitors'),
      value: 1,
      name: 'externalVisitors'
    }
  ];

  return (
    <>
      <GridTemplate columns={2}>
        <Input
          label={t('fieldNames.totalOccupancy')}
          name='totalOccupancy'
          type='text'
          error={errors?.totalOccupancy}
          innerRef={register}
        />
        <Input
          label={t('fieldNames.allowedCapacity')}
          name='allowedCapacity'
          type='number'
          error={errors?.allowedCapacity}
          innerRef={register}
        />
      </GridTemplate>
      <div>
        <WorkplaceStatusOptions />
      </div>
      <div>
        <InputCheckBox
          innerRef={register}
          options={externalVisitors}
          columns={2}
        />
      </div>
      <div>
        <LanSelector />
      </div>

      <div>
        <PanelSubTitle>{t('fieldNames.facilities')}</PanelSubTitle>
        <GridTemplate>
          <InputCheckBox innerRef={register} options={facilities} columns={2} />
        </GridTemplate>
      </div>
    </>
  );
};

export { MeetingRoomForm };
