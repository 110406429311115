import { TableData } from '@app/shared/components';
import { UserCell } from '@app/shared/components/table/cells';
import { BookingSearch } from '@domain/bookings';
import i18n from '@services/i18n';

export const getBookingListTableColumns = (): TableData<BookingSearch> => [
  {
    id: 'start',
    Header: i18n.t('bookings:list.table.startHour') as string,
    accessor: 'start'
  },
  {
    id: 'end',
    Header: i18n.t('bookings:list.table.endHour') as string,
    accessor: 'end'
  },
  {
    id: 'userName',
    Header: i18n.t('bookings:list.table.userName') as string,
    accessor: ({ userName, userImageUrl }) => ({
      userName,
      userImageUrl
    }),
    width: 250,
    Cell: UserCell
  },
  {
    id: 'spaceTypeName',
    Header: i18n.t('bookings:list.table.type') as string,
    accessor: 'spaceTypeName'
  },
  {
    id: 'floorName',
    Header: i18n.t('bookings:list.table.floor') as string,
    accessor: 'floorName'
  },
  {
    id: 'spaceName',
    Header: i18n.t('bookings:list.table.nameSpace') as string,
    accessor: 'spaceName'
  }
];
