import i18n from '@services/i18n';
import * as yup from 'yup';

import { TermConditionModel } from '../create/createTermCondition';
import {
  MAX_SIZE_BYTES,
  PDF_ALLOWED_EXTENSIONS
} from './termConditionDetailFormConstraints';

export interface TermConditionDetailFormModel {
  pdfFile: TermConditionModel['data'];
}

const validateMaxSizeError = (value: FileList): value is FileList => {
  return !value || !value.length || value[0].size < MAX_SIZE_BYTES;
};

const maxSizeErrorMessage = () => (
  params?: Partial<yup.TestMessageParams>
): string =>
  i18n.t('fieldValidations.custom.fileMaxSize', {
    ...params,
    label: i18n.t('term-condition:fieldNames.pdf'),
    maxKb: MAX_SIZE_BYTES / 1024
  });

const validateFileTypeError = (value: FileList): value is FileList => {
  return (
    !value ||
    !value.length ||
    PDF_ALLOWED_EXTENSIONS.some((t) => t === value[0].type)
  );
};

const fileTypeErrorMessage = () => (
  params?: Partial<yup.TestMessageParams>
): string =>
  i18n.t('fieldValidations.mixed.notType', {
    ...params,
    label: i18n.t('term-condition:fieldNames.pdf')
  });

const termConditionDetailFormSchema = yup
  .object<TermConditionDetailFormModel>()
  .shape({
    pdfFile: yup
      .mixed()
      .test('validSize', maxSizeErrorMessage(), validateMaxSizeError)
      .test('validType', fileTypeErrorMessage(), validateFileTypeError)
      .required()
  });

export { termConditionDetailFormSchema };
