import {
  INCIDENT_QUERY_KEY,
  IncidentResolveModel,
  IncidentResolveResponse
} from '@domain/care-management';
import { UseMutation, useMutationWrapper } from '@hooks/useMutationWrapper';
import { careManagementResolveIncident } from '@services/care-management/incidents/careManagementIncidentsService';
import { useQueryClient } from 'react-query';

const useResolveIncident = (): UseMutation<
  IncidentResolveResponse,
  Error,
  IncidentResolveModel,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutationWrapper<
    IncidentResolveResponse,
    Error,
    IncidentResolveModel
  >((model) => careManagementResolveIncident(model), {
    onSuccess: () => {
      queryClient.invalidateQueries(INCIDENT_QUERY_KEY);
    }
  });
};

export { useResolveIncident };
