import { AuthUser } from '@services/auth';
import React, { useContext } from 'react';

export interface AuthContextState {
  isLoading: boolean;
  user?: AuthUser;
}

interface AuthContextActionsProps {
  goLoginPage: () => Promise<void>;
  login: (redirectUri?: string) => Promise<void>;
  goLogoutPage: () => void;
  logout: () => Promise<void>;
  completeLogin: () => Promise<void>;
  completeSilentRenewToken: () => Promise<void>;
  signinSilent: () => Promise<void>;
  initialize: () => void;
}

const AuthContext = React.createContext<Partial<AuthContextState>>(
  {}
) as React.Context<AuthContextState>;

const AuthConsumer: React.ExoticComponent<React.ConsumerProps<
  AuthContextState
>> = AuthContext.Consumer;

const AuthContextActions = React.createContext<
  Partial<AuthContextActionsProps>
>({}) as React.Context<AuthContextActionsProps>;

const useAuthContext = (): AuthContextState => useContext(AuthContext);
const useAuthContextActions = (): AuthContextActionsProps =>
  useContext(AuthContextActions);

export {
  AuthContext,
  AuthConsumer,
  AuthContextActions,
  useAuthContext,
  useAuthContextActions
};
