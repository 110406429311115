import { BuildingsLocation } from '@app/facility-management/buildings';
import { FloorplansLocation } from '@app/facility-management/floorplans';
import { FacilityManagementLocation } from '@app/facility-management/routes/facilityManagementLocation';
import {
  ButtonIconSecondary,
  InputCalendar,
  Select
} from '@app/shared/components';
import {
  Breadcrumb,
  BreadcrumbItems,
  BreadcrumbWrapper
} from '@app/shared/components/breadcrumb/Breadcrumb';
import {
  PageToolbar,
  PageToolbarActions,
  PageToolbarHeader
} from '@app/shared/components/page/page-toolbar/PageToolbar';
import { Floorplan } from '@domain/floorplans';
import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { FloorplanBookingsLocation } from '../routes/floorplanBookingsLocation';

const ToolbarHeader = styled(PageToolbarHeader)(`
  ${BreadcrumbWrapper} {
    margin-right: ${rem(pxToRem(16))};
  }
`);

const Filters = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: ${rem(pxToRem(140))} ${rem(pxToRem(160))};
  column-gap: ${rem(pxToRem(24))};
`;

interface FloorplanBookingsToolbarProps {
  venueId: string;
  venueName: string;
  buildingId: string;
  buildingName: string;
  floorId: string;
  floorplans: Floorplan[];
  date: Date | undefined;
  onToggleLegend: () => void;
  onDateChange: (date: Date | undefined) => void;
}

const FloorplanBookingsToolbar = ({
  venueId,
  venueName,
  buildingId,
  buildingName,
  floorId,
  floorplans,
  date,
  onToggleLegend,
  onDateChange
}: FloorplanBookingsToolbarProps): ReactElement => {
  const { t } = useTranslation('workplaces');
  const history = useHistory();

  const brItems: BreadcrumbItems[] = [
    {
      label: t(`shared:routes.facilities`),
      linkTo: FacilityManagementLocation.toUrl()
    },
    {
      label: venueName,
      linkTo: BuildingsLocation.toUrl({ venueId })
    },
    {
      label: buildingName,
      linkTo: FloorplansLocation.toUrl({ venueId, buildingId })
    },
    {
      label: t('floor')
    }
  ];

  return (
    <PageToolbar>
      <ToolbarHeader>
        <Breadcrumb items={brItems} />
        <Filters>
          <Select
            searchable={false}
            items={floorplans}
            item={floorplans?.find((s) => s.floorId === floorId)}
            getItemLabel={(item) => item?.name ?? ''}
            getItemValue={(item) => item?.floorId}
            onChange={(item) => {
              history.push(
                FloorplanBookingsLocation.toUrl({
                  venueId: venueId,
                  buildingId: buildingId,
                  floorplanId: item?.floorId ?? ''
                })
              );
            }}
          />
          <InputCalendar value={date} onChange={onDateChange} />
        </Filters>
        <PageToolbarActions>
          <ButtonIconSecondary
            iconName='help'
            iconSize={28}
            onClick={onToggleLegend}
          />
        </PageToolbarActions>
      </ToolbarHeader>
    </PageToolbar>
  );
};

export default FloorplanBookingsToolbar;
