import {
  MutateOptions,
  MutationFunction,
  UseMutateAsyncFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult
} from 'react-query';

export type UseMutation<TData, TError, TVariables, TContext> = {
  throwableMutateAsync: UseMutateAsyncFunction<
    TData,
    TError,
    TVariables,
    TContext
  >;
  mutateAsync: (
    variables: TVariables,
    options?: MutateOptions<TData, TError, TVariables, TContext>
  ) => Promise<TData | undefined>;
} & Omit<UseMutationResult<TData, TError, TVariables, TContext>, 'mutateAsync'>;

const useMutationWrapper = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: UseMutationOptions<TData, TError, TVariables, TContext>
): UseMutation<TData, TError, TVariables, TContext> => {
  const mutation = useMutation(mutationFn, options);

  const mutateAsync = async (
    variables: TVariables,
    options?: MutateOptions<TData, TError, TVariables, TContext>
  ): Promise<TData | undefined> => {
    try {
      return await mutation.mutateAsync(variables, options);
    } catch (err) {
      return undefined;
    }
  };

  return {
    ...mutation,
    throwableMutateAsync: mutation.mutateAsync,
    mutateAsync
  };
};

export { useMutationWrapper };
