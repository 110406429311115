import { TableDataResult, TableRequestModel } from '@app/shared/components';
import { BOOKINGS_LIST_QUERY_KEY, BookingSearch } from '@domain/bookings';
import { getBookings } from '@services/bookings/bookingsService';
import { useQuery, UseQueryResult } from 'react-query';

const useGetAllBookings = (
  model: TableRequestModel<BookingSearch> | undefined
): UseQueryResult<TableDataResult<BookingSearch>, Error> =>
  useQuery<TableDataResult<BookingSearch>, Error>(
    [BOOKINGS_LIST_QUERY_KEY, model],
    () => getBookings(model ?? { pageIndex: 0 }),
    { enabled: !!model }
  );

export { useGetAllBookings };
