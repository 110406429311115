import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { Ref } from 'react';

import { FormGroupList, FormGroupListProps, Icon, IconWrapper } from '../..';
import { OnChangeTypeWith } from '../shared/types';

interface EventChangeValue {
  isChecked: boolean;
}

export type InputCheckItem = {
  id: string;
  name?: string;
  label?: string;
  disabled?: boolean;
  iconName?: string;
  iconColor?: string;
  value?: string | number;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: OnChangeTypeWith<EventChangeValue>;
};

type InputListProps = {
  columns?: number;
};

type InputRadioProps = {
  radioStyle?: 'radio' | 'radioicon';
};

type InputCheckFormGroupListProps = {
  innerRef?: Ref<HTMLInputElement>;
  options: InputCheckItem[];
  checkType?: 'checkbox' | 'radio';
} & FormGroupListProps &
  InputRadioProps &
  InputListProps &
  JSX.IntrinsicElements['input'];

const InputCheckElement = styled.li`
  align-items: center;
  display: flex;
  position: relative;
`;

const InputCheckLabel = styled.label`
  ${({
    theme: {
      base: {
        fonts: { getTextsConfig }
      }
    }
  }) => `
        cursor: pointer;
        padding-left: ${rem(pxToRem(12))};
        ${getTextsConfig('M')};
    `}
`;

const InputCheckBoxStyle = styled.span`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
        background-color: ${colors.neutral.fullLight};
        border-radius: ${rem(pxToRem(2))};
        border: 2px solid ${colors.neutral.grayDark};
        box-sizing: content-box;
        cursor: pointer;
        height: ${rem(pxToRem(14))};
        position: relative;
        width: ${rem(pxToRem(14))};
    `}
`;

const InputRadioBoxStyle = styled(InputCheckBoxStyle)`
  ${() => `
    border: 0 none;
    flex-direction: column;
    height: auto;
    line-height: 0;
    width: auto;

    ~ ${InputCheckLabel} {
      padding-left: 0;
    }

    ${IconWrapper} {
      opacity: 0.7;
      font-size: ${rem(pxToRem(24))};
      margin-bottom: ${rem(pxToRem(8))};
    }
  `}
`;

const CheckIconWrapper = styled.div`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
        align-items: center;
        display: none;
        height: 100%;
        justify-content: center;
        width: 100%;

        ${IconWrapper} {
            color: ${colors.neutral.fullLight};
            font-size: ${rem(pxToRem(22))};
        }
    `}
`;

const RadioBall = styled.div`
  ${({
    theme: {
      base: { colors, radius }
    }
  }) => `
        width: ${rem(pxToRem(10))};
        height: ${rem(pxToRem(10))};
        border-radius: ${radius.circle};
        background-color: ${colors.primary.default};
    `}
`;

const InputCheck = styled.input<{ hasError: boolean }>`
  ${({
    theme: {
      base: { colors, radius }
    },
    hasError
  }) => `
        opacity: 0;
        height: ${rem(pxToRem(18))};
        margin: 0;
        padding: 0;
        position: absolute;
        width: ${rem(pxToRem(18))};
        z-index: 1;

        &:hover {
            cursor: pointer;

            ~ ${InputCheckBoxStyle} {
                border-color: ${colors.primary.dark};
            }
        }

        &:focus,
        &:active {
            ~ ${InputCheckBoxStyle} {
                border-color: ${colors.primary.default};
            }
        }

        &:checked {
            ~ ${InputCheckBoxStyle} {
                background-color: ${colors.primary.default};
                border-color: transparent;

                > ${CheckIconWrapper} {
                    display: flex;
                }
            }

            ~ ${InputRadioBoxStyle} {
                ${IconWrapper} {
                    opacity: 1;
                }
            }

            &:hover {
                ~ ${InputCheckBoxStyle} {
                    background-color: ${colors.primary.dark};
                }
            }

            &:active {
                ~ ${InputCheckBoxStyle} {
                    background-color: ${colors.primary.default};
                }
            }
        }

        &:disabled {
            &:hover {
                cursor: default;
            }

            ~ ${InputCheckBoxStyle} {
              border-color: ${colors.neutral.grayDark};
              opacity: 0.3;
              cursor: default;
            }

            &:checked {
              ~ ${InputCheckBoxStyle} {
                background-color: ${colors.neutral.grayDark};
              }
            }

            ~ ${InputCheckLabel} {
                cursor: default;
            }

            ~ ${InputRadioBoxStyle} {
                ${IconWrapper} {
                    color: ${colors.neutral.gray};
                }

                ~ ${InputCheckLabel} {
                    color: ${colors.neutral.gray};
                }
            }
        }

        &[type="radio"] {
            ~ ${InputCheckBoxStyle} {
                border-radius: ${radius.circle};
                display: flex;
                align-items: center;
                justify-content: center;

                ${RadioBall} {
                    display: none;
                }
            }

            &:checked {
                ~ ${InputCheckBoxStyle} {
                    background-color: ${colors.neutral.fullLight};
                    border-color: ${colors.primary.default};

                    > ${RadioBall} {
                        display: block;
                    }
                }
            }

            &:hover {
                ~ ${InputCheckBoxStyle} {
                    border-color: ${colors.primary.dark};

                    > ${RadioBall} {
                        background-color: ${colors.primary.dark};
                    }
                }
            }

            &:disabled {
                &:hover {
                  ~ ${InputCheckBoxStyle} {
                    border-color: ${colors.neutral.grayDark};
                  }
                }

                &:checked {
                    ~ ${InputCheckBoxStyle} {
                        border-color: ${colors.primary.default};

                        > ${RadioBall} {
                          background-color: ${colors.primary.default};
                        }
                    }
                }
            }

            &:active {
                ~ ${InputCheckBoxStyle} {
                    border-color: ${colors.primary.default};

                    > ${RadioBall} {
                        background-color: ${colors.primary.default};
                    }
                }
            }
        }

        ${
          hasError
            ? `
              ~ ${InputCheckBoxStyle} {
                  border-color: ${colors.system.error};
              }

              ~ ${InputCheckLabel} {
                  color: ${colors.system.error};
              }
            `
            : ``
        }
    `}
`;

const InputCheckItemList = styled.ul<InputListProps>`
  ${({ columns }) => `
        ${
          columns
            ? `
          display: grid;
          grid-template-columns: repeat(${columns}, 1fr);
        `
            : `
          display: flex;
          flex-wrap: wrap;
        `
        }
        
        gap: ${rem(pxToRem(12))} ${rem(pxToRem(32))};
    `}
`;

const InputCheckFormGroupList: React.FC<InputCheckFormGroupListProps> = ({
  label,
  labelKey,
  name,
  error,
  options,
  checkType,
  radioStyle,
  columns,
  innerRef,
  disabled,
  ...props
}) => {
  return (
    <FormGroupList label={label} labelKey={labelKey} error={error}>
      <InputCheckItemList columns={columns}>
        {options.map((item) => (
          <InputCheckElement key={item.id}>
            <InputCheck
              name={item.name || name}
              id={item.id}
              type={checkType}
              hasError={Boolean(error?.message)}
              disabled={disabled || item.disabled}
              ref={innerRef}
              value={item.value}
              checked={item.checked}
              defaultChecked={item.defaultChecked}
              onChange={(e) => {
                item.onChange?.(e, { isChecked: e.target.checked });
              }}
              {...props}
            />
            {checkType === 'checkbox' ? (
              <InputCheckBoxStyle>
                <CheckIconWrapper data-testid='icon'>
                  <Icon iconName='tick' />
                </CheckIconWrapper>
              </InputCheckBoxStyle>
            ) : radioStyle === 'radio' ? (
              <InputCheckBoxStyle>
                <RadioBall />
              </InputCheckBoxStyle>
            ) : item.iconName ? (
              <InputRadioBoxStyle>
                <Icon iconName={item.iconName} iconColor={item.iconColor} />
              </InputRadioBoxStyle>
            ) : null}
            <InputCheckLabel htmlFor={item.id}>{item.label}</InputCheckLabel>
          </InputCheckElement>
        ))}
      </InputCheckItemList>
    </FormGroupList>
  );
};

const InputCheckBox: React.FC<Omit<
  InputCheckFormGroupListProps,
  'checkType' | 'radioStyle'
>> = ({ ...restProps }) => {
  return <InputCheckFormGroupList checkType='checkbox' {...restProps} />;
};

const InputRadio: React.FC<Omit<
  InputCheckFormGroupListProps,
  'checkType' | 'radioStyle'
>> = ({ ...restProps }) => (
  <InputCheckFormGroupList
    checkType='radio'
    radioStyle='radio'
    {...restProps}
  />
);

const InputRadioIcon: React.FC<Omit<
  InputCheckFormGroupListProps,
  'checkType' | 'radioStyle'
>> = ({ ...restProps }) => (
  <InputCheckFormGroupList
    checkType='radio'
    radioStyle='radioicon'
    {...restProps}
  />
);

export { InputCheckBox, InputRadio, InputRadioIcon };
