import * as Yup from 'yup';

const defaultParamValidation = (): Yup.Schema<number> =>
  Yup.number().integer().min(0).required();

const defaultOptionalParamValidation = (): Yup.Schema<number | undefined> =>
  Yup.number().integer().min(0).notRequired();

const stringParamValidation = (): Yup.Schema<string> => Yup.string().required();

const dateParamValidation = (): Yup.Schema<Date> => Yup.date().required();

export {
  defaultParamValidation,
  defaultOptionalParamValidation,
  stringParamValidation,
  dateParamValidation
};
