import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { PropsWithChildren, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, IconWrapper } from '../icon';

type CardEmptyImageProps = {
  size?: 'large' | 'default' | 'mini';
};

const CardEmptyImageWrapper = styled.div<CardEmptyImageProps>`
  ${({
    theme: {
      base: { colors, radius }
    }
  }) => `
        align-items: center;
        background-color: ${colors.neutral.gray};
        border-radius: ${radius.circle};
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        &.mini {
            height: ${rem(pxToRem(50))};
            width: ${rem(pxToRem(50))};
        }

        &.default {
          background-color: transparent;

          ${IconWrapper} {
              font-size: ${rem(pxToRem(24))};
          }
        }

        &.large {
            border-radius: ${rem(pxToRem(6))};
            height: ${rem(pxToRem(152))};
            width: ${rem(pxToRem(254))};

            ${IconWrapper} {
                font-size: ${rem(pxToRem(24))};
            }
        }

        ${IconWrapper} {
            color: ${colors.neutral.gray};
        }
    `}
`;

const CardEmptyImageText = styled.span`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig }
      }
    }
  }) => `
        color: ${colors.neutral.gray};
        ${getTextsConfig('M')};
    `}
`;

const CardEmptyImage = ({
  size = 'mini',
  children
}: PropsWithChildren<CardEmptyImageProps>): ReactElement => {
  const { t } = useTranslation();

  return (
    <CardEmptyImageWrapper className={size}>
      {children ? (
        children
      ) : (
        <>
          <Icon iconName='picture' />
          {size === 'default' ? (
            <CardEmptyImageText>{t('images.noImage')}</CardEmptyImageText>
          ) : null}
        </>
      )}
    </CardEmptyImageWrapper>
  );
};

export { CardEmptyImage };
