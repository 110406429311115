import i18n from '@services/i18n';
import React, { ReactElement } from 'react';

import { CellProps } from '../../models';

const DateCell = <T extends object>({
  value
}: CellProps<T, Date>): ReactElement => (
  <>{value?.localeDateString(i18n.language)}</>
);

export { DateCell };
