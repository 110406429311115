import { NotFoundRoute } from '@app/errors';
import {
  FilterButtons,
  FilterButtonsItem,
  PageContainer,
  PageHeader,
  PageTitle,
  PageToolbar
} from '@app/shared/components';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom';

import { CareManagementContainer, CareManagementLocation } from '.';
// import Covid from './covid/Covid';
// import { CareManagementCovidLocation } from './covid/routes/careManagementCovidLocation';
import Incident from './incident/Incident';
import { incidentLocation } from './incident/routes/incidentLocation';

const CareManagement = (): ReactElement => {
  const { t } = useTranslation('care');
  const history = useHistory();
  const location = useLocation();

  const tabs = [
    // {
    //   label: t('careManagement.tabs.covid'),
    //   active: location.pathname.includes(CareManagementCovidLocation.path),
    //   onClick: () => history.push(CareManagementCovidLocation.toUrl())
    // },
    {
      label: t('careManagement.tabs.incidents'),
      active: location.pathname.includes(incidentLocation.path),
      onClick: () => history.push(incidentLocation.toUrl())
    }
  ] as FilterButtonsItem[];

  return (
    <PageContainer>
      <PageHeader>
        <PageToolbar>
          <PageTitle label={t(`shared:routes.careManagement`)} />
          <FilterButtons buttons={tabs} noBorder />
        </PageToolbar>
      </PageHeader>
      <CareManagementContainer>
        <Switch>
          {CareManagementLocation.toRoute({
            exact: true,
            render: () => <Redirect to={incidentLocation.path} />
          })}
          {/* {CareManagementCovidLocation.toRoute({
            component: Covid
          })} */}
          {incidentLocation.toRoute({
            component: Incident
          })}
          <NotFoundRoute />
        </Switch>
      </CareManagementContainer>
    </PageContainer>
  );
};

export default CareManagement;
