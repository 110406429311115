import { Grid, NoResults } from '@app/shared/components';
import { Floorplan } from '@domain/floorplans';
import { useLoadImage } from '@hooks/useLoadImage';
import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import FloorplansCard from './floorplans-card/FloorplansCard';

interface FloorplansListProps {
  venueId: string;
  buildingId: string;
  floorplans: Floorplan[];
}

const FloorplansList = ({
  venueId,
  buildingId,
  floorplans
}: FloorplansListProps): ReactElement => {
  const { t } = useTranslation('floorplans');
  const { getImage } = useLoadImage();

  const itemTemplate = useCallback(
    (floorplan: Floorplan) => (
      <FloorplansCard
        venueId={venueId}
        buildingId={buildingId}
        floorplan={floorplan}
      />
    ),
    [venueId, buildingId]
  );

  return (
    <>
      {floorplans && floorplans.length ? (
        <Grid items={floorplans ?? []} itemTemplate={itemTemplate} />
      ) : (
        <NoResults
          title={t('noResults.title')}
          description={t('noResults.description')}
          image={getImage('NoFloorplans')}
        />
      )}
    </>
  );
};

export default FloorplansList;
