import create from 'zustand';

type FloorplansStoreActions = {
  setUnpublish: (isPublish: boolean) => void;
  setArchived: (isArchived: boolean) => void;
  setClosed: (isClosed: boolean) => void;
};

type Filters = {
  unpublish: boolean;
  archived: boolean;
  closed: boolean;
};

type FloorplansStore = {
  filter: Filters;
} & FloorplansStoreActions;

const useFloorplans = create<FloorplansStore>((set) => ({
  filter: {
    unpublish: true,
    archived: false,
    closed: false
  },
  setUnpublish: (isChecked: boolean) =>
    set(({ filter }) => ({
      filter: {
        ...filter,
        unpublish: isChecked
      }
    })),
  setArchived: (isArchived: boolean) =>
    set(({ filter }) => ({
      filter: {
        ...filter,
        archived: isArchived
      }
    })),
  setClosed: (isClosed: boolean) =>
    set(({ filter }) => ({
      filter: {
        ...filter,
        closed: isClosed
      }
    }))
}));

export { useFloorplans };
