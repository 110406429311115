import i18n from '@services/i18n';
import * as yup from 'yup';

const translateWithParams = (key: string) => (
  params?: Partial<yup.TestMessageParams>
): string =>
  i18n.t(key, {
    ...params,
    label: params?.label ? i18n.t(params.label) : undefined
  });

yup.setLocale({
  mixed: {
    default: translateWithParams('fieldValidations.mixed.default'),
    required: translateWithParams('fieldValidations.mixed.required'),
    oneOf: translateWithParams('fieldValidations.mixed.oneOf'),
    notOneOf: translateWithParams('fieldValidations.mixed.notOneOf'),
    notType: translateWithParams('fieldValidations.mixed.notType')
  },
  string: {
    length: translateWithParams('fieldValidations.string.length'),
    min: translateWithParams('fieldValidations.string.min'),
    max: translateWithParams('fieldValidations.string.max'),
    matches: translateWithParams('fieldValidations.string.matches'),
    email: translateWithParams('fieldValidations.string.email'),
    url: translateWithParams('fieldValidations.string.url'),
    trim: translateWithParams('fieldValidations.string.trim'),
    lowercase: translateWithParams('fieldValidations.string.lowercase'),
    uppercase: translateWithParams('fieldValidations.string.uppercase')
  },
  number: {
    min: translateWithParams('fieldValidations.number.min'),
    max: translateWithParams('fieldValidations.number.max'),
    lessThan: translateWithParams('fieldValidations.number.lessThan'),
    moreThan: translateWithParams('fieldValidations.number.moreThan'),
    positive: translateWithParams('fieldValidations.number.positive'),
    negative: translateWithParams('fieldValidations.number.negative'),
    integer: translateWithParams('fieldValidations.number.integer')
  },
  date: {
    min: translateWithParams('fieldValidations.date.min'),
    max: translateWithParams('fieldValidations.date.max')
  },
  array: {
    min: translateWithParams('fieldValidations.array.min'),
    max: translateWithParams('fieldValidations.array.max')
  }
});
