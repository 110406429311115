import { configuration } from '@configuration';

import { EDGEImages } from './load-images/EDGEImages';
import { ImageName } from './load-images/load-images.model';
import { SCRMImages } from './load-images/SCRMimages';
import { SMOImages } from './load-images/SMOimages';

type UseLoadImageReturn = {
  getImage: (name: ImageName) => string;
};

const IMAGES = {
  SMO: SMOImages,
  EDGE: EDGEImages,
  SCRM: SCRMImages
};

const useLoadImage = (): UseLoadImageReturn => {
  const { theme } = configuration;

  const getImage = (name: ImageName): string => IMAGES[theme][name];

  return { getImage };
};

export { useLoadImage };
