import { TermConditionDetailFormModel } from '@domain/term-condition/models/shared/termConditionDetailForm';
import { useCreateTermCondition } from '@hooks/term-condition/useCreateTermCondition';
import React, { ReactElement, useEffect } from 'react';

import { TermConditionDetailForm } from './shared/TermConditionDetailForm';

interface TermConditionCreateFormProps {
  onCreateTermCondition: () => void;
  onCancel: () => void;
}

const TermConditionCreateForm = ({
  onCreateTermCondition,
  onCancel
}: TermConditionCreateFormProps): ReactElement => {
  const {
    mutateAsync: createTermCondition,
    isSuccess
  } = useCreateTermCondition();

  useEffect(() => {
    if (isSuccess) {
      onCreateTermCondition();
    }
  }, [isSuccess]);

  const onSubmit = (file: TermConditionDetailFormModel): Promise<void> => {
    return createTermCondition({
      data: file.pdfFile[0],
      fileName: file.pdfFile[0].name
    });
  };

  return <TermConditionDetailForm onSubmit={onSubmit} onCancel={onCancel} />;
};

export { TermConditionCreateForm };
