import { PageContainer, PageHeader } from '@app/shared/components';
import { ILocationComponentProps } from '@app/shared/routes';
import { useGetBuildingsByVenueId } from '@hooks/buildings';
import { useGetAllVenues } from '@hooks/venues';
import { useBuildings } from '@stores/buildingsStore';
import React, { ReactElement } from 'react';
import { Switch } from 'react-router-dom';

import BuildingsForm from './buildings-form/BuildingsForm';
import {
  CreateBuildingFormLocation,
  EditBuildingFormLocation
} from './buildings-form/routes/buildingFormLocation';
import BuildingsList from './buildings-list/BuildingsList';
import BuildingsToolbar from './buildings-toolbar/BuildingsToolbar';
import { VenuesListLocation } from './buildings-toolbar/venues/venues-list/routes/venuesListLocation';
import VenuesList from './buildings-toolbar/venues/venues-list/VenuesList';
import { BuildingsLocationParams } from './routes/buildingsLocation';

interface BuildingsProps
  extends ILocationComponentProps<BuildingsLocationParams> {}

const Buildings = ({
  match: {
    params: { venueId }
  }
}: BuildingsProps): ReactElement => {
  const filter = useBuildings((s) => s.filter);
  const {
    data: buildings = [],
    isFetching: isFetchingBuildings
  } = useGetBuildingsByVenueId(venueId, {
    archived: filter.archived,
    closed: filter.closed,
    unpublished: filter.unpublish
  });
  const { data: venues = [], isFetching: isFetchingVenues } = useGetAllVenues({
    archived: false
  });

  return (
    <PageContainer isFetching={isFetchingVenues || isFetchingBuildings}>
      <PageHeader>
        <BuildingsToolbar
          venues={venues.filter((v) => !v.isArchived)}
          venueId={venueId}
        />
      </PageHeader>
      <BuildingsList buildings={buildings} />
      <Switch>
        {VenuesListLocation.toRoute({ component: VenuesList })}
        {CreateBuildingFormLocation.toRoute({
          render: (props) => (
            <BuildingsForm {...props} venues={venues} mode='create' />
          )
        })}
        {EditBuildingFormLocation.toRoute({
          render: (props) => (
            <BuildingsForm {...props} venues={venues} mode='edit' />
          )
        })}
      </Switch>
    </PageContainer>
  );
};

export default Buildings;
