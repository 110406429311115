import { BaleaApplicationsLocation, BaleaLocation } from '@app/balea';
import { NotFoundRoute, ResetableErrorBoundary } from '@app/errors';
import { FacilityManagementLocation } from '@app/facility-management';
import { Suspense } from '@app/shared/components';
import React, { ReactElement } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { renderRoutes } from './renderRoutes';
import { RootLocation } from './rootLocation';
import { sectionRoutes } from './sectionRoutes';

const AppRoutes = (): ReactElement => {
  return (
    <ResetableErrorBoundary>
      <Suspense>
        <Switch>
          <Redirect
            exact
            from={RootLocation.toUrl()}
            to={FacilityManagementLocation.toUrl()}
          />
          <Redirect
            exact
            from={BaleaLocation.toUrl()}
            to={BaleaApplicationsLocation.toUrl()}
          />
          {renderRoutes(sectionRoutes)}
          <NotFoundRoute />
        </Switch>
      </Suspense>
    </ResetableErrorBoundary>
  );
};

export { AppRoutes };
