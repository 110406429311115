import { BuildingsLocation } from '@app/facility-management/buildings';
import {
  ButtonBase,
  ButtonPrimary,
  ButtonsContainer,
  ButtonSecondary,
  DetailsFormContent,
  FormFooter,
  GridTemplate,
  Modal
} from '@app/shared/components';
import { notifySuccess } from '@app/shared/notifications';
import { ILocationComponentProps } from '@app/shared/routes';
import { Venue, VenueDetailFormModel } from '@domain/venues';
import styled from '@emotion/styled';
import { useGetAllVenues } from '@hooks/venues';
import { useArchiveVenue } from '@hooks/venues/useArchiveVenue';
import { useCreateVenue } from '@hooks/venues/useCreateVenue';
import { useDeleteVenue } from '@hooks/venues/useDeleteVenue';
import { useUnarchiveVenue } from '@hooks/venues/useUnarchiveVenue';
import { useUpdateVenue } from '@hooks/venues/useUpdateVenue';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { VenuesListLocationParams } from './routes/venuesListLocation';
import VenueListItem from './shared/VenueListItem';

const VenuesForm = styled.div`
  ${() => `
    ${ButtonBase} {
      align-self: flex-start;
      margin-bottom: ${rem(pxToRem(32))};
    }
  `}
`;

const VenuesListWrapper = styled.div``;

const ArchivedVenues = styled.div`
  margin-top: ${rem(pxToRem(24))};
`;

const ArchivedVenuesTitle = styled.h2`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getHeadlinesConfig, boldFont }
      }
    }
  }) => `
  color: ${colors.primary.default};
  ${getHeadlinesConfig('M')};
  font-family: '${boldFont}';
`}
`;

interface VenuesListProps
  extends ILocationComponentProps<VenuesListLocationParams> {}

const VenuesList = ({
  match: {
    params: { venueId }
  }
}: VenuesListProps): ReactElement => {
  const history = useHistory();
  const { t } = useTranslation('venues');

  const { data: venues = [] } = useGetAllVenues({
    archived: true
  });

  const {
    mutateAsync: createVenue,
    isSuccess: isCreateVenueSuccess,
    data
  } = useCreateVenue();
  const {
    mutateAsync: updateVenue,
    isSuccess: isUpdateVenueSuccess
  } = useUpdateVenue();
  const { mutateAsync: deleteVenue } = useDeleteVenue();
  const { mutateAsync: archiveVenue } = useArchiveVenue();
  const { mutateAsync: unarchiveVenue } = useUnarchiveVenue();

  const [createdVenuesIds, setCreatedVenuesIds] = useState<string[]>([]);
  const [creatingVenue, setCreatingVenue] = useState<VenueDetailFormModel>();
  const [editingVenue, setEditingVenue] = useState<Venue>();

  const onGoBack = (): void =>
    history.replace(BuildingsLocation.toUrl({ venueId }));

  const createNewVenue = (): void => {
    if (!creatingVenue) {
      setCreatingVenue({ name: '' });
    }
  };

  const onCancelCreation = (): void => {
    setCreatingVenue(undefined);
  };

  useEffect(() => {
    if (isCreateVenueSuccess && data) {
      notifySuccess(t('shared:toast.operation-success'));
      setCreatingVenue(undefined);
      setCreatedVenuesIds((values) => [...values, data.id]);
    }
  }, [isCreateVenueSuccess]);

  useEffect(() => {
    if (isUpdateVenueSuccess) {
      setEditingVenue(undefined);
    }
  }, [isUpdateVenueSuccess]);

  return (
    <Modal onClose={onGoBack} title={t('venues-settings')}>
      <VenuesForm>
        <DetailsFormContent>
          <ButtonSecondary
            label={t('creation-title')}
            iconName='plus'
            onClick={createNewVenue}
          />
          <GridTemplate>
            <VenuesListWrapper>
              {creatingVenue ? (
                <VenueListItem
                  mode='create'
                  onCreateVenue={createVenue}
                  onDeleteVenue={onCancelCreation}
                />
              ) : null}

              {venues
                .filter((v) => !v.isArchived)
                .map((venue) => (
                  <VenueListItem
                    key={`${venue.id}_${venue.name}`}
                    isNew={createdVenuesIds.includes(venue.id)}
                    venue={venue}
                    mode={editingVenue?.id === venue.id ? 'edit' : 'view'}
                    editVenue={(venue) => setEditingVenue(venue)}
                    onEditVenue={updateVenue}
                    onDeleteVenue={({ id }) => deleteVenue(id)}
                    onArchiveVenue={({ id }) => archiveVenue(id)}
                  />
                ))}

              {venues.some((v) => v.isArchived) && (
                <ArchivedVenues>
                  <ArchivedVenuesTitle>
                    {t('archived-venues')}
                  </ArchivedVenuesTitle>

                  {venues
                    .filter((v) => v.isArchived)
                    .map((venue) => (
                      <VenueListItem
                        key={`${venue.id}_${venue.name}`}
                        isNew={createdVenuesIds.includes(venue.id)}
                        venue={venue}
                        mode={editingVenue?.id === venue.id ? 'edit' : 'view'}
                        editVenue={(venue) => setEditingVenue(venue)}
                        onEditVenue={updateVenue}
                        onDeleteVenue={({ id }) => deleteVenue(id)}
                        onUnarchiveVenue={({ id }) => unarchiveVenue(id)}
                      />
                    ))}
                </ArchivedVenues>
              )}
            </VenuesListWrapper>
          </GridTemplate>
        </DetailsFormContent>
        <FormFooter>
          <ButtonsContainer>
            <ButtonPrimary
              onClick={onGoBack}
              label={t('shared:generics.accept')}
            />
          </ButtonsContainer>
        </FormFooter>
      </VenuesForm>
    </Modal>
  );
};

export default VenuesList;
