import styled from '@emotion/styled';
import React from 'react';

const PageTitleElement = styled.h2`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getHeadlinesConfig }
      }
    }
  }) => `
    color: ${colors.primary.default};
    ${getHeadlinesConfig('L')};
  `}
`;

interface PageContainerProps {
  label: string;
}

const PageTitle: React.FC<PageContainerProps> = ({ label }) => {
  return <PageTitleElement>{label}</PageTitleElement>;
};

export { PageTitle, PageTitleElement };
