import { Venue, VENUES_QUERY_KEY } from '@domain/venues';
import { getAllVenues } from '@services/venues/venuesService';
import { useQuery, UseQueryResult } from 'react-query';

type UseGetAllVenuesParams = {
  archived: boolean;
};

const useGetAllVenues = ({
  archived = false
}: UseGetAllVenuesParams): UseQueryResult<Venue[], Error> =>
  useQuery<Venue[], Error>([VENUES_QUERY_KEY, { archived }], () =>
    getAllVenues(archived)
  );

export { useGetAllVenues };
