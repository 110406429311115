import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useResizeObserver } from '@pbr1111/use-resize-observer';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement, ReactNode, useCallback } from 'react';
import { FixedSizeGrid, GridChildComponentProps } from 'react-window';

import { GridItem } from './grid-item/GridItem';

const Container = styled.div`
  height: 100%;
  max-height: calc(100vh - ${rem(pxToRem(218))} - ${rem(pxToRem(72))});
  overflow: hidden;
  padding: 0 ${rem(pxToRem(30))} ${rem(pxToRem(20))};

  .FixedSizeGrid {
    overflow-x: hidden !important;
    overflow-y: auto !important;

    > ul {
      min-width: 100%;
    }
  }
`;

interface GridProps<T> {
  items: T[];
  itemTemplate: (item: T) => ReactNode;
}

const Grid = <T,>({ items, itemTemplate }: GridProps<T>): ReactElement => {
  const theme = useTheme();
  const { ref, width = 0, height = 0 } = useResizeObserver<HTMLDivElement>();

  const columnCount = Math.floor(width / theme.base.grid.card.width);
  const rowCount = Math.ceil((items.length ?? 0) / columnCount);

  const gridItem = useCallback(
    (props: GridChildComponentProps) => (
      <GridItem
        {...props}
        columnCount={columnCount}
        itemTemplate={itemTemplate as (item: unknown) => ReactNode}
      />
    ),
    [itemTemplate, columnCount]
  );

  return (
    <Container ref={ref}>
      {width && height && items && items.length > 0 ? (
        <FixedSizeGrid
          className='FixedSizeGrid'
          columnCount={columnCount}
          columnWidth={theme.base.grid.card.width}
          height={height}
          rowCount={rowCount}
          rowHeight={theme.base.grid.card.height}
          width={width}
          itemData={items}
          innerElementType={'ul'}>
          {gridItem}
        </FixedSizeGrid>
      ) : null}
    </Container>
  );
};

export { Grid };
