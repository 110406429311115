import { UserAvatar } from '@app/shared/components/user-avatar/UserAvatar';
import React, { ReactElement } from 'react';

import { CellProps } from '../../models';

type UserCellType = {
  userName: string;
  userImageUrl?: string;
};

const UserCell = <T extends object>({
  value
}: CellProps<T, UserCellType>): ReactElement => (
  <UserAvatar
    userName={value.userName}
    userImageUrl={value.userImageUrl}
    type='table'
  />
);

export { UserCell };
