import styled from '@emotion/styled';
import React, { MutableRefObject, PropsWithChildren, Ref, useRef } from 'react';

type FileBoxProps = {
  className?: string;
  onUploadFiles?: (files: File[]) => void;
  innerRef?: Ref<HTMLInputElement>;
} & Omit<JSX.IntrinsicElements['input'], 'onChange' | 'type' | 'style'>;

const FileBoxContainer = styled.div`
  cursor: pointer;
`;

const FileBox: React.FC<PropsWithChildren<FileBoxProps>> = ({
  children,
  onUploadFiles,
  className,
  innerRef,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleBoxClick = (): void => {
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.click();
    }
  };

  const fileInputChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    if (onUploadFiles && evt.target.files) {
      const files = Array.from(evt.target.files);
      onUploadFiles?.(files);
    }
  };

  return (
    <FileBoxContainer onClick={handleBoxClick} className={className}>
      {children}
      <input
        {...props}
        ref={(ref) => {
          if (innerRef) {
            if (typeof innerRef === 'function') {
              innerRef(ref);
            } else {
              (innerRef as MutableRefObject<HTMLInputElement | null>).current = ref;
            }
          }
          (inputRef as MutableRefObject<HTMLInputElement | null>).current = ref;
        }}
        type='file'
        onChange={fileInputChange}
        style={{ display: 'none' }}
      />
    </FileBoxContainer>
  );
};

export { FileBox, FileBoxContainer };
