import { Form } from '@app/shared/components';
import { CreateBuildingModel } from '@domain/buildings';
import { Venue } from '@domain/venues';
import { useCreateBuilding } from '@hooks/buildings';
import React, { ReactElement, useEffect } from 'react';

import BuildingsDetailForm from '../shared/buildings-detail-form/BuildingsDetailForm';

interface BuildingsCreateFormProps {
  venueId: string;
  venues: Venue[];
  onCreateBuilding: () => void;
  onCancel: () => void;
}

const BuildingsCreateForm = ({
  venueId,
  venues,
  onCreateBuilding,
  onCancel
}: BuildingsCreateFormProps): ReactElement => {
  const { mutateAsync: createBuilding, isSuccess } = useCreateBuilding(venueId);

  useEffect(() => {
    if (isSuccess) {
      onCreateBuilding();
    }
  }, [isSuccess]);

  const onSubmit = (building: CreateBuildingModel): Promise<void> =>
    createBuilding(building);

  return (
    <Form>
      <BuildingsDetailForm
        onSubmit={onSubmit}
        onCancel={onCancel}
        venues={venues ?? []}
        initialValues={{ venueId: venueId ?? '' }}
      />
    </Form>
  );
};

export default BuildingsCreateForm;
