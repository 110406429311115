import DefaultBuilding from '@assets/img/SMO/default-building.svg';
import Error404 from '@assets/img/SMO/error-404.svg';
import Error500 from '@assets/img/SMO/error-500.svg';
import Isotipo from '@assets/img/SMO/isotipo.svg';
import LogoImage from '@assets/img/SMO/logo.svg';
import NoBuildings from '@assets/img/SMO/no-buildings.svg';
import NoFloorplans from '@assets/img/SMO/no-floorplans.svg';

import { ImageType } from './load-images.model';

const SMOImages = {
  DefaultBuilding: DefaultBuilding,
  Error404: Error404,
  Error500: Error500,
  Isotipo: Isotipo,
  Logo: LogoImage,
  NoBuildings: NoBuildings,
  NoFloorplans: NoFloorplans
} as ImageType;

export { SMOImages };
