import { PrivacyPolicyDetailFormModel } from '@domain/privacy-policy/models/shared/privacyPolicyDetailForm';
import { useCreatePrivacyPolicy } from '@hooks/privacy-policy/useCreatePrivacyPolicy';
import React, { ReactElement, useEffect } from 'react';

import { PrivacyPolicyDetailForm } from '../shared/PrivacyPolicyDetailForm';

interface PrivacyPolicyCreateFormProps {
  onCreatePrivacyPolicy: () => void;
  onCancel: () => void;
}

const PrivacyPolicyCreateForm = ({
  onCreatePrivacyPolicy,
  onCancel
}: PrivacyPolicyCreateFormProps): ReactElement => {
  const {
    mutateAsync: createPrivacyPolicy,
    isSuccess
  } = useCreatePrivacyPolicy();

  useEffect(() => {
    if (isSuccess) {
      onCreatePrivacyPolicy();
    }
  }, [isSuccess]);

  const onSubmit = (file: PrivacyPolicyDetailFormModel): Promise<void> => {
    return createPrivacyPolicy({
      data: file.pdfFile[0],
      fileName: file.pdfFile[0].name
    });
  };

  return <PrivacyPolicyDetailForm onSubmit={onSubmit} onCancel={onCancel} />;
};

export { PrivacyPolicyCreateForm };
