import { StatusTag } from '@app/shared/components/status-tag/StatusTag';
import React, { ReactElement } from 'react';

import { CellProps } from '../../models';

const StatusCell = <T extends object>({
  value
}: CellProps<T>): ReactElement => (
  <StatusTag status={value ? 'resolved' : 'unsolved'} />
);

export { StatusCell };
