import styled from '@emotion/styled';

export const FileUploadErrorMessage = styled.p`
  ${({
    theme: {
      base: {
        fonts: { getTextsConfig },
        colors
      }
    }
  }) => `
        color: ${colors.system.error};
        ${getTextsConfig('S')};
    `}
`;
