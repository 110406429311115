import { Input } from '@app/shared/components';
import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterProps, useAsyncDebounce } from 'react-table';

import { DEBOUNCE_TIME_FILTER_IN_MILLISECONDS } from '../../table.service';

const InputFilter = styled(Input)`
  width: 100%;
  height: ${rem(pxToRem(24))};
`;

const TextFilter = <T extends object>({
  column: { filterValue, setFilter, preFilteredRows }
}: FilterProps<T>): ReactElement => {
  const { t } = useTranslation();
  const [value, setValue] = useState(filterValue);

  const onChange = useAsyncDebounce((value: string | undefined) => {
    setFilter(value || undefined);
  }, DEBOUNCE_TIME_FILTER_IN_MILLISECONDS);

  const count = preFilteredRows?.length ?? 0;

  return (
    <InputFilter
      placeholder={t('shared:table.filters.placeHolder', { count })}
      type='text'
      value={value || ''}
      onChange={(e) => (setValue(e.target.value), onChange(e.target.value))}
    />
  );
};

export { TextFilter };
