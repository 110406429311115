import React, { ReactElement } from 'react';

import { Loader } from '../..';

interface TableOverlayProps {
  show: boolean;
}

const TableOverlay = ({ show }: TableOverlayProps): ReactElement | null => {
  return show ? <Loader /> : null;
};

export { TableOverlay };
