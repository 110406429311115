import { InputCheckBox, InputCheckItem } from '@app/shared/components';
import { useFloorplans } from '@stores/floorplansStore';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

const Filters = (): ReactElement => {
  const { t } = useTranslation('floorplans');

  const { unpublish, archived, closed } = useFloorplans((s) => s.filter);
  const setUnpublish = useFloorplans((s) => s.setUnpublish);
  const setArchived = useFloorplans((s) => s.setArchived);
  const setClosed = useFloorplans((s) => s.setClosed);

  const menuCheckbox: InputCheckItem[] = [
    {
      id: 'unPublish',
      label: t('unpublished-floorplans'),
      checked: unpublish,
      onChange: (_event, { isChecked }) => setUnpublish(isChecked)
    },
    {
      id: 'archived',
      label: t('archived-floorplans'),
      checked: archived,
      onChange: (_, { isChecked }) => setArchived(isChecked)
    },
    {
      id: 'clsed',
      label: t('closed-floorplans'),
      checked: closed,
      onChange: (_event, { isChecked }) => setClosed(isChecked)
    }
  ];

  return <InputCheckBox options={menuCheckbox} />;
};

export default Filters;
