import { Icon, IconWrapper } from '@app/shared/components';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

const BreadcrumbItemLink = styled.span`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
    color: ${colors.primary.default};
    margin-right: ${rem(pxToRem(8))};

    &:hover {
      color: ${colors.primary.dark};
    }
  `}
`;

const BreadcrumbWrapper = styled.div`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig }
      }
    }
  }) => `
    display: flex;
    align-items: center;
    min-height: ${rem(pxToRem(36))};

    .BreadcrumbItem {
      align-items: center;
      display: inline-flex;
      ${getTextsConfig('M')};
      justify-content: flex-start;

      &:last-of-type {
        cursor: default;

        ${BreadcrumbItemLink} {
          color: ${colors.neutral.dark};
          margin: 0;

          &:hover {
            cursor: default;
          }
        }

        ${IconWrapper} {
          display: none;
        }
      }
    }

    ${IconWrapper} {
      margin-right: ${rem(pxToRem(8))};
    }
  `}
`;

export interface BreadcrumbItems {
  linkTo?: string;
  label: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItems[];
}

const Breadcrumb = ({ items }: BreadcrumbProps): ReactElement => {
  const {
    base: { colors }
  } = useTheme();

  return (
    <BreadcrumbWrapper>
      {items.map((item, index) => (
        <NavLink
          key={index}
          to={item.linkTo ? item.linkTo : '#'}
          className='BreadcrumbItem'>
          <BreadcrumbItemLink>{item.label}</BreadcrumbItemLink>
          <Icon
            iconName='chevron-right'
            iconSize={24}
            iconColor={colors.neutral.grayDark}
          />
        </NavLink>
      ))}
    </BreadcrumbWrapper>
  );
};

export { Breadcrumb, BreadcrumbWrapper };
