import { Form } from '@app/shared/components';
import { FloorplanDetailFormModel } from '@domain/floorplans';
import { useGetFloorPlan } from '@hooks/floorplans/useGetFloorplan';
import { useUpdateFloorplan } from '@hooks/floorplans/useUpdateFloorplan';
import React, { ReactElement, useEffect } from 'react';

import FloorplansDetailForm from '../shared/floorplans-detail-form/FloorplansDetailForm';

interface FloorplansEditFormProps {
  venueId: string;
  buildingId: string;
  floorplanId: string;
  onEditFloorplan: () => void;
  onCancel: () => void;
}

const FloorplansEditForm = ({
  venueId,
  buildingId,
  floorplanId,
  onEditFloorplan,
  onCancel
}: FloorplansEditFormProps): ReactElement => {
  const { data: floorplan } = useGetFloorPlan(venueId, buildingId, floorplanId);
  const { mutateAsync: updateFloorplan, isSuccess } = useUpdateFloorplan(
    venueId,
    buildingId,
    floorplanId
  );

  useEffect(() => {
    if (isSuccess) {
      onEditFloorplan();
    }
  }, [isSuccess]);

  const onSubmit = async (
    floorplant: FloorplanDetailFormModel
  ): Promise<void> =>
    updateFloorplan({
      ...floorplant,
      planModelFile: floorplant.dxfFile[0]
    });

  return (
    <Form>
      <FloorplansDetailForm
        initialValues={floorplan}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </Form>
  );
};

export default FloorplansEditForm;
