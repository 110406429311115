import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';

const PanelDock = styled.div`
  ${({
    theme: {
      base: { colors, zindex, shadow }
    }
  }) => `
    animation-duration: 0.5s;
    animation-name: translateX;
    background-color: ${colors.neutral.fullLight};
    bottom: 0;
    box-shadow: ${shadow.base(colors)};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding: ${rem(pxToRem(40))};
    position: absolute;
    right: 0;
    top: 0;
    width: ${rem(pxToRem(650))};
    z-index: ${zindex.above};

    @keyframes translateX {
      from {
        transform: translateX(100%);
      }
      to {
        transform: translateX(0);
      }
    }
  `}
`;

export { PanelDock };
