import { Location } from '@app/shared/routes';
import { RouteHelper } from '@utils/helpers/routeHelper';

export interface BuildingsLocationParams {
  venueId: string;
}

const BuildingsLocation = new Location<BuildingsLocationParams>({
  path: '/facility-management/venues/:venueId/buildings',
  pathParamDefs: {
    venueId: RouteHelper.stringParamValidation()
  }
});

export { BuildingsLocation };
