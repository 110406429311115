const BOOKINGS_QUERY_KEY = 'bookings';
const BOOKINGS_LIST_QUERY_KEY = 'booking-list';

const BOOKING_QUERY_KEY = (id: string): string[] => [BOOKINGS_QUERY_KEY, id];

const DAILY_BOOKING_QUERY_KEY = 'daily-booking';

const DAILY_BOOKINGS_QUERY_KEY = (
  id: string,
  startDate: Date,
  endDate?: Date
): string[] => [
  DAILY_BOOKING_QUERY_KEY,
  id,
  startDate.withoutTime().getTime().toString(),
  endDate?.withoutTime().getTime().toString() ?? ''
];

export {
  BOOKING_QUERY_KEY,
  BOOKINGS_QUERY_KEY,
  BOOKINGS_LIST_QUERY_KEY,
  DAILY_BOOKINGS_QUERY_KEY,
  DAILY_BOOKING_QUERY_KEY
};
