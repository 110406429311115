import styled from '@emotion/styled';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement } from 'react';

interface LoaderProps {
  isFullPage?: boolean;
}

const LoaderOverlay = styled.div<LoaderProps>`
  ${({
    theme: {
      base: { colors, zindex }
    },
    isFullPage
  }) => `
    align-items: center;
    background-color: ${getRgbaStrFromHexColor(colors.neutral.fullLight, 0.8)};
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: ${isFullPage ? 'fixed' : 'absolute'};
    top: 0;
    width: 100%;
    z-index: ${zindex.loader}
  `}
`;

const LoaderWrapper = styled.div`
  display: inline-block;
  height: ${rem(pxToRem(80))};
  position: relative;
  width: ${rem(pxToRem(80))};
`;

const LoaderItem = styled.div`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    background: ${colors.spinner};
    border-radius: 50%;
    height: ${rem(pxToRem(13))};
    position: absolute;
    top: ${rem(pxToRem(33))};
    width: ${rem(pxToRem(13))};

    &:nth-of-type(1) {
      animation: lds-ellipsis1 0.6s infinite;
      left: ${rem(pxToRem(8))};
    }
    &:nth-of-type(2) {
      animation: lds-ellipsis2 0.6s infinite;
      left: ${rem(pxToRem(8))};
    }
    &:nth-of-type(3) {
      animation: lds-ellipsis2 0.6s infinite;
      left: ${rem(pxToRem(32))};
    }
    &:nth-of-type(4) {
      animation: lds-ellipsis3 0.6s infinite;
      left: ${rem(pxToRem(56))};
    }

    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(${rem(pxToRem(24))}, 0);
      }
    }
  `}
`;

const Loader = ({ isFullPage }: LoaderProps): ReactElement => {
  return (
    <LoaderOverlay isFullPage={isFullPage}>
      <LoaderWrapper>
        <LoaderItem></LoaderItem>
        <LoaderItem></LoaderItem>
        <LoaderItem></LoaderItem>
        <LoaderItem></LoaderItem>
      </LoaderWrapper>
    </LoaderOverlay>
  );
};

export { Loader };
