/* eslint-disable @typescript-eslint/no-non-null-assertion */
type ResizeImageParams = {
  dataImage: string;
  maxSize: number;
};

type CreateImageParams = {
  blob: Blob;
  name: string;
  type: ImageType;
};

type ImageType = 'png' | 'jpeg' | 'svg';

const IMAGE_INFO: {
  [key: string]: { extension: string; contentType: string };
} = {
  ['png']: { extension: '.png', contentType: 'image/png' },
  ['jpeg']: { extension: '.jpeg', contentType: 'image/jpeg' },
  ['svg']: { extension: '.svg', contentType: 'image/svg+xml' }
};

const createImage = ({ blob, name, type }: CreateImageParams): File => {
  const { extension, contentType } = IMAGE_INFO[type];

  const filename = `${name}${extension}`;
  const imageOptions: FilePropertyBag = { type: contentType };

  return new File([blob], filename, imageOptions);
};

const resizeImage = ({
  dataImage,
  maxSize
}: ResizeImageParams): Promise<Blob | null> => {
  return new Promise((resolve) => {
    const image = new Image();

    image.onload = () => {
      const canvas = document.createElement('canvas');
      let width = image.width;
      let height = image.height;

      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }
      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d')!.drawImage(image, 0, 0, width, height);

      canvas.toBlob(resolve);
    };

    image.src = dataImage;
  });
};

export { createImage, resizeImage };
