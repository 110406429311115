import {
  PageContainer,
  PageHeader,
  PageTitle,
  PageToolbar,
  PageToolbarHeader,
  Select
} from '@app/shared/components';
import {
  useGetAllReports,
  useGetReportParams
} from '@hooks/reports/useGetAllReports';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Dashboard = (): ReactElement => {
  const { t } = useTranslation();
  const { data: reports = [] } = useGetAllReports();

  const [reportId, setReport] = useState<string | undefined>(undefined);

  const { data: reportParams } = useGetReportParams(
    reportId || reports.first()?.id
  );

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle label={t(`routes.dashboard`)} />
        <PageToolbar>
          <PageToolbarHeader>
            <Select
              searchable={false}
              items={reports}
              item={reports?.find(
                (s) => s.reportId == reportId || reports.first()
              )}
              getItemLabel={(item) => item?.name ?? ''}
              getItemValue={(item) => item?.reportId}
              onChange={(item) => () => {
                setReport(item?.id);
              }}
            />
          </PageToolbarHeader>
        </PageToolbar>
        {reportParams ? (
          <PowerBIEmbed
            embedConfig={{
              type: reportParams?.type.toLowerCase() ?? 'report',
              id: reportParams?.embedReports.first()?.reportId ?? '',
              embedUrl: reportParams?.embedReports.first()?.embedUrl ?? '',
              accessToken: reportParams?.embedToken ?? '',
              tokenType: models.TokenType.Embed,
              settings: {
                panes: {
                  filters: {
                    expanded: false,
                    visible: false
                  }
                },
                background: models.BackgroundType.Transparent
              }
            }}
            cssClassName={'report-style-class'}            
          />
        ) : null}
      </PageHeader>
    </PageContainer>
  );
};

export default Dashboard;
