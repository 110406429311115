import { useAuthContextActions } from '@contexts/auth';
import React, { useEffect } from 'react';

const SilentRenew: React.FC = () => {
  const { completeSilentRenewToken } = useAuthContextActions();

  useEffect(() => {
    completeSilentRenewToken();
  }, []);

  return null;
};

export default SilentRenew;
