import '@services/i18n';

import { defineCustomElements } from '@balea/ui/dist/loader';
import { configuration } from '@configuration';
import App from '@modules/App';
import { initializeWaveEngine } from '@wave-engine/wave-viewer';
import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

defineCustomElements(window);

initializeWaveEngine(configuration.wave.resourcesPath!);

serviceWorker.unregister();
