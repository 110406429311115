import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';

import { ButtonBase } from '../Button';

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${ButtonBase} {
    &:not(:last-child) {
      margin-right: ${rem(pxToRem(24))};
    }
  }
`;

export { ButtonsContainer };
