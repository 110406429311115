import FloorplanSettingsToolbar from '@app/facility-management/floorplans/floorplan-settings/floorplan-settings-toolbar/FloorplanSettingsToolbar';
import { WorkplaceModal } from '@app/facility-management/workplaces/workplaces-form/WorkplaceModal';
import { WorkplacesList } from '@app/facility-management/workplaces/workplaces-list/WorkplacesList';
import {
  Loader,
  PageContainer,
  PageHeader,
  Prompt
} from '@app/shared/components';
import { useDialog } from '@app/shared/components/dialog';
import { notifySuccess } from '@app/shared/notifications';
import { WorkplaceFormModel } from '@domain/workplaces/models/create/workplaceFormModel';
import { useTheme } from '@emotion/react';
import { useGetFloorplanMetadata } from '@hooks/floorplans/useGetFloorplanMetadata';
import { useUpdateFloorplanMetadata } from '@hooks/floorplans/useUpdateFloorplanMetadata';
import { useDebounceResizeObserver } from '@pbr1111/use-resize-observer';
import {
  EntityAbsolutePosition,
  SceneState,
  useWaveEngineStore,
  WaveCanvas,
  WaveClickEventPayload,
  waveCommands,
  WaveSelectionChangedEventPayload,
  waveTools
} from '@wave-engine/wave-viewer';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  getEntityColor,
  getEntityPushpin,
  getEntityScale,
  hasEntityActiveBookings,
  updateEntity
} from '../../../../../domain/workplaces/models/shared/workplaceEntity';
import { ILocationComponentProps } from '../../../shared/routes/location';
import { WaveCameraActionsPanel } from '../../../wave/WaveCameraActionsPanel';
import { WaveStateActionsPanel } from '../../../wave/WaveStateActionsPanel';
import { FloorplanViewerLegend } from '../shared/FloorplanViewerLegend';
import { WaveContainer, WaveWrapper } from '../shared/WaveWrapper';
import { FloorplanSettingsLocationParams } from './routes/floorplanSettingsLocation';
import { FloorplanWorkplace } from './shared/models/floorplanWorkplace';

export interface FloorplanSettingsProps
  extends ILocationComponentProps<FloorplanSettingsLocationParams> {}

const FloorplanSettings = ({
  match: {
    params: { venueId, buildingId, floorplanId: floorId }
  }
}: FloorplanSettingsProps): ReactElement => {
  const { data: floorplanMetadata } = useGetFloorplanMetadata(
    venueId,
    buildingId,
    floorId
  );

  const {
    mutateAsync: updateFloorplanMetadata,
    isSuccess: isUpdateSuccess,
    isLoading: isLoading
  } = useUpdateFloorplanMetadata(venueId, buildingId, floorId);

  const [showLegend, setShowLegend] = useState<boolean>(false);

  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const [workplaceEditor, setWorkplaceEditor] = useState<{
    visible: boolean;
    position?: EntityAbsolutePosition;
    model?: WorkplaceFormModel;
    entityId?: string;
  }>({
    visible: false
  });

  const [workplaceListVisible, setWorkplaceListVisible] = useState<boolean>(
    true
  );
  const [workplaces, setWorkplaces] = useState<FloorplanWorkplace>({});
  const [selectedWorkplacesIds, setSelectedWorkplacesIds] = useState<string[]>(
    []
  );

  const waveCanvasRef = useRef<HTMLCanvasElement>(null);
  const { ref, width, height } = useDebounceResizeObserver<HTMLDivElement>(500);
  const isDXFLoaded = useWaveEngineStore((s) => s.isDXFLoaded);
  const { t } = useTranslation('workplaces');
  const {
    base: { colors }
  } = useTheme();
  const { showDialog } = useDialog();

  const editEntity = useCallback((entityId: string): void => {
    const position = waveTools.screen.getEntityAbsolutePosition(
      waveCanvasRef,
      entityId
    );
    const entity = waveCommands.state.getEntity(entityId);
    const workplaceModel = waveTools.forms.buildFormFromEntityProperies<
      WorkplaceFormModel
    >(entity!.properties);

    setWorkplaceEditor({
      visible: true,
      position,
      model: workplaceModel,
      entityId
    });
  }, []);

  const updateWorkplaces = useCallback((): void => {
    const state = waveCommands.state.getState();
    const places = state.entities.reduce((prevValue, e) => {
      prevValue[e.id] = {
        model: waveTools.forms.buildFormFromEntityProperies<WorkplaceFormModel>(
          e!.properties
        ),
        infoEntity: floorplanMetadata?.infoEntities.find(
          (i) => i.waveId === e.id
        )
      };
      return prevValue;
    }, {} as FloorplanWorkplace);
    setWorkplaces(places);    
  }, [floorplanMetadata?.infoEntities]);

  useEffect(() => {
    if (floorplanMetadata && isDXFLoaded && workplaceListVisible) {
      updateWorkplaces();
    }
  }, [floorplanMetadata, isDXFLoaded, workplaceListVisible, updateWorkplaces]);

  useEffect(() => {
    if (isUpdateSuccess) {
      notifySuccess(t('shared:toast.operation-success'));
    }
  }, [isUpdateSuccess]);

  const onClick = useCallback(
    (payload: WaveClickEventPayload): void => {
      if (waveCanvasRef.current && payload) {
        const selectedEntities = waveCommands.state.getSelectedEntities();
        if (selectedEntities.length === 1) {
          editEntity(payload.id);
        }
      }
    },
    [editEntity]
  );

  const onSelectionChanged = useCallback(
    (payload: WaveSelectionChangedEventPayload): void => {
      if (payload.length !== 1) {
        setWorkplaceEditor({ visible: false });
      } else {
        const entity = waveCommands.state.getEntity(payload[0]);
        if (
          entity &&
          (!entity.properties || Object.keys(entity.properties).length <= 1)
        ) {
          updateWorkplaces();
          editEntity(entity.id);
        }
      }

      setSelectedWorkplacesIds(payload);
    },
    [updateWorkplaces, editEntity]
  );

  const onSave = (entityId: string, form: WorkplaceFormModel): void => {
    const properties = waveTools.forms.buildEntityPropertiesFromForm(form);
    updateEntity(
      entityId,
      properties,
      colors,
      hasEntityActiveBookings(entityId, floorplanMetadata?.infoEntities)
    );
    setWorkplaceEditor({ visible: false });
    updateWorkplaces();
    setHasChanges(true);
  };

  const saveWorkplaceMetadata = (): void => {
    const state = waveCommands.state.getState();
    updateFloorplanMetadata(state);
    setHasChanges(false);
  };

  const onDeleteWorkplace = (entityId?: string): void => {
    const entitiesToDelete = entityId
      ? [entityId]
      : waveCommands.state.getSelectedEntities().map((e) => e.id);
    if (entitiesToDelete.length > 0) {
      const hasActiveBookings = entitiesToDelete.find((e) =>
        floorplanMetadata?.infoEntities.some(
          (i) => i.waveId === e && i.hasActiveBookings
        )
      );
      if (hasActiveBookings) {
        showDialog({
          open: true,
          title: t('delete-with-bookings-prompt.title'),
          subtitle: t('delete-with-bookings-prompt.subtitle'),
          message: t('delete-with-bookings-prompt.body'),
          headerIcon: 'warning',
          headerColor: colors.system.error,
          btnPrimaryText: t('shared:generics.cancel'),
          btnSecondaryText: t('shared:generics.continue'),
          onCancel: {
            callback: () => {
              if (entityId) {
                waveCommands.state.removeEntity(entityId);
              } else {
                waveCommands.state.removeSelectedEntities();
              }
              updateWorkplaces();
              setHasChanges(true);
              setWorkplaceEditor({ visible: false });
            }
          }
        });
      } else {
        showDialog({
          open: true,
          title: t('delete-prompt.title', { count: entitiesToDelete.length }),
          subtitle: t('delete-prompt.subtitle', {
            count: entitiesToDelete.length
          }),
          message: t('delete-prompt.body'),
          headerIcon: 'trash',
          headerColor: colors.system.error,
          btnPrimaryText: t('delete-prompt.confirmButtonText'),
          onConfirm: {
            callback: () => {
              if (entityId) {
                waveCommands.state.removeEntity(entityId);
              } else {
                waveCommands.state.removeSelectedEntities();
              }
              updateWorkplaces();
              setHasChanges(true);
              setWorkplaceEditor({ visible: false });
            }
          }
        });
      }
    }
  };

  const selectEntity = (entityId: string, isSelected: boolean): void => {
    const selectedEntitiesIds = waveCommands.state
      .getSelectedEntities()
      .map((i) => i.id);
    let entitiesToSelect: string[] = [];
    if (isSelected) {
      entitiesToSelect = [...selectedEntitiesIds, entityId];
    } else {
      entitiesToSelect = selectedEntitiesIds.filter((i) => i !== entityId);
    }
    waveCommands.state.setSelection(...entitiesToSelect);
  };

  const onEditEntity = (entityId: string): void => {
    editEntity(entityId);
  };

  const onEditEntitiesProperties = (
    properties: Partial<WorkplaceFormModel>,
    ...entityIds: string[]
  ): void => {
    for (const entityId of entityIds) {
      updateEntity(
        entityId,
        properties,
        colors,
        hasEntityActiveBookings(entityId, floorplanMetadata?.infoEntities)
      );
    }
    updateWorkplaces();
  };

  const metadataModel = useMemo(
    () =>
      (floorplanMetadata?.entities?.length ?? 0) > 0
        ? ({
            camera: floorplanMetadata?.camera,
            entities: floorplanMetadata?.entities.map((e) => ({
              ...e,
              color: getEntityColor(
                e,
                colors,
                hasEntityActiveBookings(e.id, floorplanMetadata.infoEntities)
              ),
              scale: getEntityScale(e),
              isPushpinVisible: true,
              pushpinId: getEntityPushpin(e)
            }))
          } as SceneState)
        : null,
    [floorplanMetadata]
  );

  return (
    <Prompt hasChanges={hasChanges}>
      <PageContainer>
        <PageHeader>
          <FloorplanSettingsToolbar
            onToggleSettings={() => setWorkplaceListVisible((value) => !value)}
            onToggleLegend={() => setShowLegend((value) => !value)}
            buildingId={floorplanMetadata?.buildingId || ''}
            buildingName={floorplanMetadata?.buildingName || ''}
            venueId={floorplanMetadata?.venueId || ''}
            venueName={floorplanMetadata?.venueName || ''}
            floorplanName={floorplanMetadata?.floorName || ''}
          />
        </PageHeader>
        <WaveWrapper>
          <WaveContainer ref={ref}>
            {height != undefined && width != undefined ? (
              <WaveCanvas
                canvasRef={waveCanvasRef}
                onClick={onClick}
                onSelectionChanged={onSelectionChanged}
                height={height!}
                width={width!}
                metadataModel={metadataModel}
                dxfModel={floorplanMetadata?.modelUrl}
                manipulators={{
                  enableElevation: false
                }}
                style={{
                  backgroundColor: colors.neutral.transparent,
                  manipulatorColor: colors.primary.default,
                  secondaryManipulatorColor: colors.primary.dark,
                  selectionOverlayColor: colors.primary.default,
                  overridedMapColor: colors.neutral.grayDark
                }}
              />
            ) : null}
          </WaveContainer>
          {workplaceEditor.visible ? (
            <WorkplaceModal
              left={workplaceEditor.position!.x}
              top={workplaceEditor.position!.y}
              radius={workplaceEditor.position!.radius}
              initialValues={workplaceEditor.model!}
              onSave={(form) => onSave(workplaceEditor.entityId!, form)}
              onClose={() => setWorkplaceEditor({ visible: false })}
              onDelete={onDeleteWorkplace}
            />
          ) : null}
          <WaveStateActionsPanel onDelete={onDeleteWorkplace} />
          <WaveCameraActionsPanel />
          {!isDXFLoaded ? <Loader isFullPage /> : null}
        </WaveWrapper>
        {workplaceListVisible ? (
          <WorkplacesList
            workplaces={workplaces}
            selectedWorkplacesIds={selectedWorkplacesIds}
            onSelectionChange={selectEntity}
            onDelete={onDeleteWorkplace}
            onEdit={onEditEntity}
            onClose={() => setWorkplaceListVisible(false)}
            onSave={saveWorkplaceMetadata}
            onEditEntitiesProperties={onEditEntitiesProperties}
            isUpdating={isLoading}
          />
        ) : null}
        {showLegend ? (
          <FloorplanViewerLegend onClose={() => setShowLegend(false)} />
        ) : null}
      </PageContainer>
    </Prompt>
  );
};

export default FloorplanSettings;
