import { FloorplanWorkplace } from '@app/facility-management/floorplans/floorplan-settings/shared/models/floorplanWorkplace';
import {
  MeetingRoomFormModel,
  WorkplaceFormModel
} from '@domain/workplaces/models/create/workplaceFormModel';
import {
  WorkplacePlaceType,
  workplacesDefinition
} from '@domain/workplaces/models/workplace';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FacilitiesIconList } from '../shared/FacilitiesIconList';
import { Column, WorkplaceTypeList } from '../shared/WorkplaceTypeList';

interface MeetingRoomsListProps {
  meetingRooms: FloorplanWorkplace<WorkplaceFormModel>;
  selectedWorkplacesIds: string[];
  onSelectionChange: (entityId: string, isSelected: boolean) => void;
  onEdit: (entityId: string) => void;
  onDelete: (...entityId: string[]) => void;
  onEditEntitiesProperties: (
    properties: Partial<WorkplaceFormModel>,
    ...entityIds: string[]
  ) => void;
}

const MeetingRoomsList = ({
  meetingRooms,
  selectedWorkplacesIds,
  onSelectionChange,
  onEdit,
  onDelete,
  onEditEntitiesProperties
}: MeetingRoomsListProps): ReactElement => {
  const {
    t,
    i18n: { language }
  } = useTranslation('workplaces');

  const columns = useMemo(
    () =>
      [
        {
          header: t('fieldNames.label'),
          value: (item) => item.label
        },
        {
          header: t('fieldNames.capacity'),
          value: (item: MeetingRoomFormModel) => item.allowedCapacity
        },
        {
          header: t('fieldNames.lan'),
          value: (item) =>
            item.lan && item.lan.toString() === '1'
              ? t('shared:confirmation.yes')
              : t('shared:confirmation.no')
        },
        {
          header: t('fieldNames.facilities'),
          value: (item: MeetingRoomFormModel) => (
            <FacilitiesIconList
              facilities={
                workplacesDefinition[
                  WorkplacePlaceType.MeetingRoom
                ].facilities?.filter((f) => f.filter(item)) ?? []
              }
            />
          )
        }
      ] as Column[],
    [language]
  );

  return (
    <WorkplaceTypeList
      title={t('workplaceSites.meetingRoom_plural')}
      placeType={WorkplacePlaceType.MeetingRoom}
      workplaces={meetingRooms}
      selectedWorkplacesIds={selectedWorkplacesIds}
      columns={columns}
      onSelectionChange={onSelectionChange}
      onEdit={onEdit}
      onDelete={onDelete}
      onEditEntitiesProperties={onEditEntitiesProperties}
    />
  );
};

export { MeetingRoomsList };
