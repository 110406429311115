import { Loader, PageContainer, PageHeader } from '@app/shared/components';
import { ILocationComponentProps } from '@app/shared/routes';
import { WaveCameraActionsPanel } from '@app/wave/WaveCameraActionsPanel';
import { BookingSpace } from '@domain/bookings';
import { WorkplaceFormModel } from '@domain/workplaces';
import {
  getEntityColor,
  getEntityPushpin,
  getEntityScale,
  hasEntityDailyBookings
} from '@domain/workplaces/models/shared/workplaceEntity';
import { useTheme } from '@emotion/react';
import { useGetAllDailyBookings } from '@hooks/bookings';
import { useGetAllFloorplans } from '@hooks/floorplans/useGetAllFloorplans';
import { useGetFloorplanMetadata } from '@hooks/floorplans/useGetFloorplanMetadata';
import { useDebounceResizeObserver } from '@pbr1111/use-resize-observer';
import { isValidDate } from '@utils/helpers/dateHelper';
import {
  SceneState,
  useWaveEngineStore,
  WaveCanvas,
  WaveClickEventPayload,
  waveCommands,
  WaveSelectionChangedEventPayload
} from '@wave-engine/wave-viewer';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ReactElement } from 'react';

import { FloorplanViewerLegend } from '../shared/FloorplanViewerLegend';
import { WaveContainer, WaveWrapper } from '../shared/WaveWrapper';
import { FloorplanBookingsDetails } from './floorplan-bookings-details/FloorplanBookingsDetails';
import FloorplanBookingsToolbar from './floorplan-bookings-toolbar/FloorplanBookingsToolbar';
import { FloorplanBookingsLocationParams } from './routes/floorplanBookingsLocation';

interface FloorplanBookingsProps
  extends ILocationComponentProps<FloorplanBookingsLocationParams> {}

const FloorplanBookings = ({
  match: {
    params: { venueId, buildingId, floorplanId: floorId }
  }
}: FloorplanBookingsProps): ReactElement => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    new Date()
  );
  const [showLegend, setShowLegend] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<{
    show: boolean;
    workplace?: WorkplaceFormModel;
    bookingsSpace?: BookingSpace[];
    payloadId?: string;
  }>({ show: false });

  const { data: floorplanMetadata } = useGetFloorplanMetadata(
    venueId,
    buildingId,
    floorId
  );
  const { data: floorplans } = useGetAllFloorplans(venueId, buildingId, {
    archived: true,
    unpublished: true,
    closed: true
  });
  const { data: dailyBookings = [] } = useGetAllDailyBookings(
    floorId || '',
    selectedDate?.withoutTime()
  );

  const isDXFLoaded = useWaveEngineStore((s) => s.isDXFLoaded);
  const waveCanvasRef = useRef<HTMLCanvasElement>(null);
  const { ref, width, height } = useDebounceResizeObserver<HTMLDivElement>(500);
  const {
    base: { colors }
  } = useTheme();

  const metadataModel = useMemo(
    () =>
      floorplanMetadata?.entities?.length ?? 0 > 0
        ? ({
            camera: floorplanMetadata?.camera,
            entities: floorplanMetadata?.entities.map((e) => ({
              ...e,
              color: getEntityColor(
                e,
                colors,
                hasEntityDailyBookings(e.id, dailyBookings)
              ),
              scale: getEntityScale(e),
              isPushpinVisible: true,
              pushpinId: getEntityPushpin(e)
            }))
          } as SceneState)
        : null,
    [floorplanMetadata, dailyBookings]
  );

  const onClick = useCallback(
    (payload: WaveClickEventPayload): void => {
      if (waveCanvasRef.current && payload) {
        waveCommands.state.setSelection(payload.id);
        const entity = floorplanMetadata?.entities.find(
          (e) => e.id === payload.id
        );

        if (entity) {
          const newShowDetails = {
            show: true,
            payloadId: payload.id
          };
          setShowDetails(newShowDetails);
        }
      }
    },
    [floorplanMetadata?.entities]
  );

  const onSelectionChange = useCallback(
    (payload: WaveSelectionChangedEventPayload): void => {
      if (waveCanvasRef.current && payload.length === 0) {
        setShowDetails({ show: false });
      }
    },
    []
  );

  return (
    <PageContainer>
      <PageHeader>
        <FloorplanBookingsToolbar
          buildingId={floorplanMetadata?.buildingId || ''}
          buildingName={floorplanMetadata?.buildingName || ''}
          venueId={floorplanMetadata?.venueId || ''}
          venueName={floorplanMetadata?.venueName || ''}
          floorId={floorplanMetadata?.floorId || ''}
          floorplans={floorplans ?? []}
          date={selectedDate}
          onDateChange={(date) => isValidDate(date) && setSelectedDate(date)}
          onToggleLegend={() => setShowLegend((value) => !value)}
        />
      </PageHeader>
      <WaveWrapper>
        <WaveContainer ref={ref}>
          {height != undefined && width != undefined ? (
            <WaveCanvas
              canvasRef={waveCanvasRef}
              height={height!}
              width={width!}
              metadataModel={metadataModel}
              dxfModel={floorplanMetadata?.modelUrl}
              manipulators={{
                enableElevation: false,
                enableMove: false,
                enableRotate: false,
                enableScale: false
              }}
              style={{
                backgroundColor: colors.neutral.transparent,
                manipulatorColor: colors.primary.default,
                secondaryManipulatorColor: colors.primary.dark,
                selectionOverlayColor: colors.primary.default,
                overridedMapColor: colors.neutral.grayDark
              }}
              onClick={onClick}
              onSelectionChanged={onSelectionChange}
            />
          ) : null}
        </WaveContainer>
        <WaveCameraActionsPanel />
        {!isDXFLoaded ? <Loader isFullPage /> : null}
      </WaveWrapper>
      {showDetails.show ? (
        <FloorplanBookingsDetails
          payloadId={showDetails.payloadId!}
          dailyBookings={dailyBookings}
          floorplanMetadata={floorplanMetadata!}
          onClose={() => setShowDetails({ show: false })}
          selectedDate={selectedDate}
        />
      ) : null}
      {showLegend ? (
        <FloorplanViewerLegend onClose={() => setShowLegend(false)} />
      ) : null}
    </PageContainer>
  );
};

export default FloorplanBookings;
