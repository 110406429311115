import { Floorplan, FLOORPLANS_KEY } from '@domain/floorplans';
import { getAllFloorplans } from '@services/floorplans/floorplansService';
import { useQuery, UseQueryResult } from 'react-query';

type UseGetAllFloorplansParams = {
  archived: boolean;
  unpublished: boolean;
  closed: boolean;
};

const useGetAllFloorplans = (
  venueId: string,
  buildingId: string,
  searchParams: UseGetAllFloorplansParams = {
    archived: false,
    closed: false,
    unpublished: false
  }
): UseQueryResult<Floorplan[], Error> =>
  useQuery<Floorplan[], Error>(
    [...FLOORPLANS_KEY(venueId, buildingId), searchParams],
    () =>
      getAllFloorplans(
        buildingId,
        searchParams.archived,
        searchParams.unpublished,
        searchParams.closed
      )
  );

export { useGetAllFloorplans };
