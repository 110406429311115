import { NoResults } from '@app/shared/components';
import { useLoadImage } from '@hooks/useLoadImage';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { PageErrorProps } from './shared/pageErrorProps';

const GenericError = ({ traceId }: PageErrorProps): ReactElement => {
  const { t } = useTranslation();
  const { getImage } = useLoadImage();

  return (
    <NoResults
      errorStyle
      title={t('genericError.title')}
      description={
        traceId
          ? t('genericError.descriptionWithSession', {
              sessionLogId: traceId
            })
          : t('genericError.description')
      }
      image={getImage('Error500')}
    />
  );
};

export { GenericError };
