import { ThemeType } from '@styles/themeFactory';

declare global {
  interface Window {
    REACT_APP_IS_DEBUG: boolean;
    REACT_APP_API_URL: string;
    REACT_APP_BALEA_API_URL: string;
    REACT_APP_OIDC_AUTHORITY: string;
    REACT_APP_OIDC_CLIENT_ID: string;
    REACT_APP_OIDC_CLIENT_SCOPES: string;
    REACT_APP_APPINSIGHTS: string;
    REACT_APP_VERSION: string;
    REACT_APP_WAVE_RESOURCES_PATH_URL: string;
    REACT_APP_THEME: ThemeType;
    REACT_APP_LOCALE_OVERRIDE: boolean;
    REACT_APP_LOCALE_NAMESPACE: string;
    REACT_APP_ORGANIZATION_ID: number;
  }
}

export default {
  apiUrl: window.REACT_APP_API_URL || process.env.REACT_APP_API_URL,
  oidc: {
    type: 'bearer',
    authority:
      window.REACT_APP_OIDC_AUTHORITY || process.env.REACT_APP_OIDC_AUTHORITY,
    clientId:
      window.REACT_APP_OIDC_CLIENT_ID || process.env.REACT_APP_OIDC_CLIENT_ID,
    clientRoot: window.location.protocol + '//' + window.location.host,
    clientScopes:
      window.REACT_APP_OIDC_CLIENT_SCOPES ||
      process.env.REACT_APP_OIDC_CLIENT_SCOPES
  },
  wave: {
    resourcesPath:
      window.REACT_APP_WAVE_RESOURCES_PATH_URL ||
      process.env.REACT_APP_WAVE_RESOURCES_PATH_URL
  },
  balea: {
    apiUrl:
      window.REACT_APP_BALEA_API_URL || process.env.REACT_APP_BALEA_API_URL!,
    entrypoint: '/'
  },
  applicationInsights: {
    instrumentationKey: window.REACT_APP_APPINSIGHTS || ''
  },
  i18n: {
    languages: ['en-US', 'es-ES'],
    defaultLanguage: 'en-US',
    namespaces: ['shared'],
    defaultNamespace: 'shared'
  },
  // TODO: Define app version management. Phase.Sprint.BuildId?
  appVersion:
    window.REACT_APP_VERSION ||
    process.env.REACT_APP_VERSION ||
    'DevelopmentFrontVersion',
  theme: window.REACT_APP_THEME || process.env.REACT_APP_THEME,
  locales: {
    override:
      window.REACT_APP_LOCALE_OVERRIDE ||
      process.env.REACT_APP_LOCALE_OVERRIDE ||
      true,
    namespace:
      window.REACT_APP_LOCALE_NAMESPACE ||
      process.env.REACT_APP_LOCALE_NAMESPACE ||
      'SCRM'
  },
  debug: window.REACT_APP_IS_DEBUG,
  organizationId:
    window.REACT_APP_ORGANIZATION_ID || process.env.REACT_APP_ORGANIZATION_ID
};
