import { BUILDINGS_QUERY_KEY } from '@domain/buildings';
import { CreateFloorplanModel, FLOORPLANS_KEY } from '@domain/floorplans';
import { UseMutation, useMutationWrapper } from '@hooks/useMutationWrapper';
import { createFloorplan } from '@services/floorplans/floorplansService';
import { useQueryClient } from 'react-query';

const useCreateFloorplan = (
  venueId: string,
  buildingId: string
): UseMutation<void, Error, CreateFloorplanModel, unknown> => {
  const queryClient = useQueryClient();

  return useMutationWrapper<void, Error, CreateFloorplanModel>(
    (floorplan) => createFloorplan(floorplan),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FLOORPLANS_KEY(venueId, buildingId));
        queryClient.invalidateQueries(BUILDINGS_QUERY_KEY(venueId));
      }
    }
  );
};

export { useCreateFloorplan };
