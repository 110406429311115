import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { getLocale } from '@services/i18n/date';
import { pxToRem, rem } from '@styles/utils/sizes';
import { getDayLabelsInWeek, getMonthsLabels } from '@utils/helpers/dateHelper';
import { useDayzed } from 'dayzed';
import React, { useMemo } from 'react';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonIcon } from '../button-icon';

const CalendarWrapper = styled.div(
  ({
    theme: {
      base: { colors, shadow }
    }
  }) => `
  display: flex;
  flex-direction: column;
  background-color: ${colors.primary.default};
  box-shadow: ${shadow.default(colors)};
  border-radius: ${rem(pxToRem(6))} ${rem(pxToRem(6))} 0 0;
  width: ${rem(pxToRem(336))};
`
);

const Week = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

const CalendarHeader = styled.div`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${rem(pxToRem(16))};

    ~ ${Week} {
      border-bottom: 1px solid ${colors.primary.dark};
    }
   `}
`;

const CalendarMonth = styled.h5`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getHeadlinesConfig }
      }
    }
  }) => `
    color: ${colors.neutral.fullLight};
    ${getHeadlinesConfig('XS')};
    text-align: center;
    text-transform: capitalize;
   `}
`;

const WeekDayLabel = styled.span(
  ({
    theme: {
      base: { colors, fonts }
    }
  }) => `
    ${fonts.getTextsConfig('S')};
    text-align: center;
    color: ${colors.neutral.fullLight};
    text-transform: capitalize;
    padding-bottom: ${rem(pxToRem(12))};
`
);

const Month = styled.div(
  ({
    theme: {
      base: { colors }
    }
  }) => `
  background-color: ${colors.neutral.fullLight};
`
);

const Day = styled.div<{
  selected: boolean;
  disabled: boolean;
  today: boolean;
}>(
  ({
    theme: {
      base: { colors, fonts }
    },
    selected,
    disabled,
    today
  }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  ${fonts.getTextsConfig('L')};
  color:  ${
    selected
      ? colors.neutral.fullLight
      : today
      ? colors.primary.default
      : colors.primary.darkest
  };
  opacity: ${disabled ? 0.3 : 1};
  border: 1px solid ${
    selected ? colors.primary.default : colors.neutral.grayMedium
  };
  height: ${rem(pxToRem(48))};
  box-sizing: border-box;
  background-color: ${
    selected ? colors.primary.default : colors.neutral.fullLight
  };
  cursor: ${disabled ? 'default' : 'pointer'};
  pointer-events: ${disabled ? 'none' : 'auto'};
  user-select: none;

  &:hover {
     ${
       !disabled && !selected
         ? `background-color: ${colors.neutral.grayLight};`
         : ''
     }
  }
`
);

export type CalendarProps = {
  value?: Date | undefined;
  showOutsideDays?: boolean;
  minDate?: Date;
  maxDate?: Date;
  onChange?: (date: Date | undefined) => void;
};

const Calendar = ({
  value = new Date(),
  showOutsideDays = true,
  minDate,
  maxDate,
  onChange
}: CalendarProps): ReactElement => {
  const {
    i18n: { language }
  } = useTranslation();
  const locale = getLocale(language);
  const {
    base: { colors }
  } = useTheme();

  const { calendars, getBackProps, getForwardProps, getDateProps } = useDayzed({
    date: value,
    selected: value,
    firstDayOfWeek: locale.options?.weekStartsOn,
    monthsToDisplay: 1,
    showOutsideDays,
    minDate,
    maxDate,
    onDateSelected: ({ date }) => onChange?.(date)
  });

  const dayLabelsInWeek = useMemo(() => getDayLabelsInWeek(value, locale), [
    value,
    language
  ]);

  const monthLabels = useMemo(() => getMonthsLabels(locale), [locale]);

  const calendar = calendars[0];

  return (
    <>
      <CalendarWrapper>
        <CalendarHeader>
          <ButtonIcon
            iconName='chevron-left'
            iconSize={12}
            iconColor={colors.neutral.fullLight}
            {...getBackProps({ calendars })}
          />

          <CalendarMonth>
            {monthLabels[calendar.month]} {calendar.year}
          </CalendarMonth>

          <ButtonIcon
            iconName='chevron-right'
            iconSize={12}
            iconColor={colors.neutral.fullLight}
            {...getForwardProps({ calendars })}
          />
        </CalendarHeader>
        <Week>
          {dayLabelsInWeek.map((label, index) => (
            <WeekDayLabel key={index}>{label}</WeekDayLabel>
          ))}
        </Week>

        <Month>
          {calendar.weeks.map((week, weekIndex) => (
            <Week key={weekIndex}>
              {week.map((dateObj, index) => {
                if (!dateObj) {
                  return (
                    <Day
                      key={index}
                      selected={false}
                      disabled={true}
                      today={false}
                    />
                  );
                }
                const {
                  date,
                  selected,
                  selectable,
                  today,
                  nextMonth,
                  prevMonth
                } = dateObj;
                return (
                  <Day
                    key={index}
                    {...getDateProps({ dateObj })}
                    selected={selected}
                    disabled={!selectable || nextMonth || prevMonth}
                    today={today}>
                    <span>{date.getDate()}</span>
                  </Day>
                );
              })}
            </Week>
          ))}
        </Month>
      </CalendarWrapper>
    </>
  );
};

export { Calendar };
