import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement } from 'react';

import { ButtonBase, ButtonPrimary } from '../button';

interface NoResultsProps {
  errorStyle?: boolean;
  title: string;
  description: string;
  image: string;
  btnLabel?: string;
  btnClick?: () => void;
}

const NoResultsContent = styled.div`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig }
      }
    }
  }) => `
      color: ${colors.primary.default};
      flex: 1 1 auto;
      ${getTextsConfig('L')}
      margin-right: ${rem(pxToRem(64))};
      width: 100%;

      ${ButtonBase} {
        margin-top: ${rem(pxToRem(24))};
      }
  `}
`;

const NoResultsTitle = styled.h3`
  margin-bottom: ${rem(pxToRem(8))};
`;

const NoResultsImage = styled.img`
  flex-basis: ${rem(pxToRem(290))};
`;

const NoResultsWrapper = styled.div<{ errorStyle?: boolean }>`
  ${({
    theme: {
      base: {
        fonts: { getHeadlinesConfig },
        layout
      }
    },
    errorStyle
  }) => `
      align-items: center;
      align-self: flex-start;
      animation-duration: 0.5s;
      animation-name: showWrapper;
      display: flex;
      margin: 0 auto;
      width: ${rem(pxToRem(674))};

      ${errorStyle && `height: calc(100vh - ${layout.header.height});`}

      @keyframes showWrapper {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      ${NoResultsTitle} {
        ${errorStyle ? `margin-bottom: ${rem(pxToRem(24))};` : ''};
        ${getHeadlinesConfig('L')};
      }
  `}
`;

const NoResults = ({
  title,
  description,
  image,
  errorStyle,
  btnLabel,
  btnClick
}: NoResultsProps): ReactElement => {
  return (
    <NoResultsWrapper errorStyle={errorStyle}>
      <NoResultsContent>
        <NoResultsTitle>{title}</NoResultsTitle>
        <p>{description}</p>
        {btnLabel && <ButtonPrimary label={btnLabel} onClick={btnClick} />}
      </NoResultsContent>
      <NoResultsImage src={image} />
    </NoResultsWrapper>
  );
};

export { NoResults };
