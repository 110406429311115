import { User } from 'oidc-client';

export type AuthUser = {
  username: string;
  token: string;
};

const getAuthorizationHeaderValue = (user?: User): string =>
  user && user.token_type && user.access_token
    ? `${user.token_type} ${user.access_token}`
    : '';

const mapUser = (user?: User): AuthUser | undefined =>
  user
    ? {
        username: user?.profile?.name || '',
        token: user?.access_token
      }
    : undefined;

export { getAuthorizationHeaderValue, mapUser };
