import { SigninCallbackLocation } from '@app/auth/routes/signinCallbackLocation';
import { SilentRenewLocation } from '@app/auth/routes/silentRenewLocation';
import { configuration } from '@configuration';
import { UserManagerSettings, WebStorageStateStore } from 'oidc-client';

const {
  oidc: { authority, clientId, clientRoot, clientScopes }
} = configuration;

const authConfiguration: UserManagerSettings = {
  authority,
  client_id: clientId,
  redirect_uri: `${clientRoot}${SigninCallbackLocation.toUrl()}`,
  silent_redirect_uri: `${clientRoot}${SilentRenewLocation.toUrl()}`,
  post_logout_redirect_uri: `${clientRoot}`,
  response_type: 'code',
  scope: clientScopes,
  automaticSilentRenew: true,
  userStore: new WebStorageStateStore({ store: window.localStorage })
};

export { authConfiguration };
