import { Icon, IconWrapper } from '@app/shared/components';
import styled from '@emotion/styled';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import React from 'react';
import { NavLink } from 'react-router-dom';

const NavbarItemWrapper = styled.div`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig, semiBoldFont }
      }
    }
  }) => `
    align-items: center;
    display: flex;
    height: ${rem(pxToRem(56))};
    justify-content: flex-start;
    overflow: hidden;

    &:not(:last-of-type) {
      margin-bottom: ${rem(pxToRem(8))};
    }

    > a {
      align-items: center;
      display: flex;
      ${getTextsConfig('M')};
      font-family: ${semiBoldFont};
      height: 100%;
      justify-content: flex-start;
      width: 100%;

      &:hover,
      &.NavbarItemActive {
        &,
        ${IconWrapper} {
          color: ${colors.primary.selectable};
        }
      }

      &:active {
        &,
        ${IconWrapper} {
          color: ${colors.primary.dark};
        }
      }
    }

    ${IconWrapper} {
      color: ${getRgbaStrFromHexColor(colors.primary.darkest, 0.4)};
      flex: 0 0 auto;
      justify-content: center;
      margin-right: ${rem(pxToRem(12))};
      width: ${rem(pxToRem(48))};
    }
  `}
`;

const NavbarItemLink = styled.span`
  max-width: 0;
  overflow: hidden;
  transition: max-width 0.3s ease-in-out;
  white-space: nowrap;
`;

export type NavLinkProps = {
  linkTo: string;
  iconName: string;
  label: string;
};

const NavbarItem: React.FC<NavLinkProps> = ({
  linkTo,
  iconName,
  label
}: NavLinkProps) => {
  return (
    <NavbarItemWrapper>
      <NavLink to={linkTo} activeClassName='NavbarItemActive'>
        <Icon iconName={iconName} iconSize={32} />
        <NavbarItemLink>{label}</NavbarItemLink>
      </NavLink>
    </NavbarItemWrapper>
  );
};

export { NavbarItem, NavbarItemLink };
