import { Loader } from '@app/shared/components';
import { useAuthContext } from '@contexts/auth';
import { useUserProfile } from '@stores/userProfileStore';
import React, { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const UserProfile: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { i18n } = useTranslation();
  const { userProfile, loadUserProfile } = useUserProfile();
  const { user } = useAuthContext();

  const setUserProfile = async (): Promise<void> => {
    const { language } = await loadUserProfile();
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    if (user) {
      setUserProfile();
    }
  }, [user]);

  return userProfile.email || !user ? <>{children}</> : <Loader isFullPage />;
};

export default UserProfile;
