import { ImageUpload } from '@app/shared/components/image-upload';
import { BuildingDetailFormModel } from '@domain/buildings';
import React from 'react';
import { Control, useWatch } from 'react-hook-form';

type BuildingFileUploadProps = {
  control: Control<BuildingDetailFormModel>;
  error?: string;
};

const BuildingFileUpload: React.FC<BuildingFileUploadProps> = ({
  control,
  error
}: BuildingFileUploadProps) => {
  const image = useWatch<FileList>({
    control: control as Control,
    name: 'image'
  });

  const imageUrl = useWatch<string>({
    control: control as Control,
    name: 'imageUrl'
  });

  const onRemoveImage = (): void => {
    control.setValue('image', undefined, {
      shouldDirty: true,
      shouldValidate: true
    });
    control.setValue('imageUrl', undefined, {
      shouldDirty: true,
      shouldValidate: true
    });
  };

  return (
    <ImageUpload
      image={image?.[0]}
      imageUrl={imageUrl}
      error={error}
      onRemoveImage={onRemoveImage}
    />
  );
};

export { BuildingFileUpload };
