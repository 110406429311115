import { Report, ReportParams } from '@domain/reports';
import { apiService } from '@services/api';

const getAllReports = async (): Promise<Report[]> => {
  return await apiService.report_Get();
};

const getParametersReports = async (
  reportId: string
): Promise<ReportParams> => {
  return await apiService.report_GetReportParams(reportId);
};

export { getAllReports, getParametersReports };
