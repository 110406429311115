import {
  ButtonIcon,
  ButtonIconBase,
  ButtonsContainer,
  Input,
  InputWrapper
} from '@app/shared/components';
import { useDialog } from '@app/shared/components/dialog';
import {
  Venue,
  VenueDetailFormModel,
  venueDetailFormSchema,
  VenuesActionsEnum
} from '@domain/venues';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type VenueListItemProps = {
  venue?: Venue;
  mode: 'create' | 'edit' | 'view';
  isNew?: boolean;
  onCreateVenue?: (venue: VenueDetailFormModel) => void;
  editVenue?: (venue: Venue) => void;
  onEditVenue?: (venue: Venue) => void;
  onDeleteVenue: (venue: Venue) => void;
  onArchiveVenue?: (venue: Venue) => void;
  onUnarchiveVenue?: (venue: Venue) => void;
};

const VenueListItemWrapper = styled.div`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig }
      }
    }
  }) => `
    align-items: center;
    border-bottom: 1px solid ${getRgbaStrFromHexColor(
      colors.primary.darkest,
      0.2
    )};
    display: flex;
    ${getTextsConfig('M')};
    justify-content: flex-start;
    min-height: ${rem(pxToRem(42))};

    ${ButtonsContainer} {
      ${ButtonIconBase} {
        height: ${rem(pxToRem(40))};
        width: ${rem(pxToRem(40))};
      }
    }

    ${InputWrapper} {
      border: 0 none;
      height: auto;
      padding: ${rem(pxToRem(4))} ${rem(pxToRem(8))};
    }

    > form {
      margin-right: auto;
    }
  `}
`;

const VenueName = styled.p`
  ${({
    theme: {
      base: {
        fonts: { semiBoldFont }
      }
    }
  }) => `
    cursor: default;
    font-family: ${semiBoldFont};
    margin-left: ${rem(pxToRem(8))};
    margin-right: auto;
  `}
`;

const VenueBuildings = styled.p`
  cursor: default;
  margin-left: auto;
`;

const NewVenue = styled.div`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig },
        radius
      }
    }
  }) => `
    background-color: ${colors.neutral.grayAccent};
    color: ${colors.primary.default};
    ${getTextsConfig('M')};
    margin-left: auto;
    text-transform: uppercase;
    padding: 0 ${rem(pxToRem(10))};
    border-radius: ${radius.rounded};
  `}
`;

const VenueListItem = ({
  venue,
  mode,
  isNew = false,
  onCreateVenue,
  editVenue,
  onEditVenue,
  onDeleteVenue,
  onArchiveVenue,
  onUnarchiveVenue
}: VenueListItemProps): ReactElement => {
  const { t } = useTranslation('venues');
  const {
    base: { colors }
  } = useTheme();
  const { showDialog } = useDialog();

  const { register, handleSubmit, errors } = useForm<VenueDetailFormModel>({
    resolver: yupResolver(venueDetailFormSchema),
    mode: 'all',
    defaultValues: {
      name: venue?.name ?? ''
    }
  });

  const onSubmit = (model: VenueDetailFormModel): void => {
    if (mode === 'create') {
      onCreateVenue?.(model);
    } else if (mode === 'edit') {
      onEditVenue?.({
        ...(venue as Venue),
        ...model
      });
    }
  };

  const deleteVenue = (): void => {
    showDialog({
      open: true,
      title: t('delete-prompt.title'),
      subtitle: t('delete-prompt.subtitle'),
      message: t('delete-prompt.body'),
      headerIcon: 'trash',
      headerColor: colors.system.error,
      btnPrimaryText: t('delete-prompt.confirmButtonText'),
      onConfirm: {
        callback: () => onDeleteVenue?.(venue as Venue)
      }
    });
  };

  const archiveVenue = (): void => {
    showDialog({
      open: true,
      title: t('archive-prompt.title'),
      subtitle: t('archive-prompt.subtitle'),
      message: t('archive-prompt.body'),
      headerIcon: 'archive',
      headerColor: colors.system.error,
      btnPrimaryText: t('archive-prompt.confirmButtonText'),
      onConfirm: {
        callback: () => onArchiveVenue?.(venue as Venue)
      }
    });
  };

  const unarchiveVenue = (): void => {
    showDialog({
      open: true,
      title: t('unarchive-prompt.title'),
      subtitle: t('unarchive-prompt.subtitle'),
      message: t('unarchive-prompt.body'),
      headerIcon: 'unarchive',
      headerColor: colors.system.error,
      btnPrimaryText: t('unarchive-prompt.confirmButtonText'),
      onConfirm: {
        callback: () => onUnarchiveVenue?.(venue as Venue)
      }
    });
  };

  const onInputBlur = (ev: React.FocusEvent<HTMLInputElement>): void => {
    if (mode === 'create' && !ev.target.value) {
      onDeleteVenue?.(venue as Venue);
    } else {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <VenueListItemWrapper>
      {mode === 'view' ? (
        <VenueName>{venue?.name}</VenueName>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            type='text'
            name='name'
            innerRef={register}
            error={errors.name}
            onBlur={onInputBlur}
            placeholder={t('fieldNames.namePlaceholder')}
            autoFocus
          />
        </form>
      )}

      {isNew ? (
        <NewVenue>{t('shared:generics.new')}</NewVenue>
      ) : mode !== 'create' ? (
        <VenueBuildings>
          {t('buildings', { count: venue?.totalBuilding })}
        </VenueBuildings>
      ) : null}
      <ButtonsContainer>
        {mode !== 'create' &&
        venue?.activeActions.some(
          (a) => a.identifier === VenuesActionsEnum.Edit
        ) ? (
          <ButtonIcon
            iconName='edit'
            iconSize={24}
            onClick={() => editVenue?.(venue as Venue)}
          />
        ) : null}
        {venue?.activeActions.some(
          (a) => a.identifier === VenuesActionsEnum.Delete
        ) ? (
          <ButtonIcon iconName='trash' iconSize={24} onClick={deleteVenue} />
        ) : null}
        {venue?.activeActions.some(
          (a) => a.identifier === VenuesActionsEnum.Archive
        ) ? (
          <ButtonIcon iconName='archive' iconSize={24} onClick={archiveVenue} />
        ) : null}
        {venue?.activeActions.some(
          (a) => a.identifier === VenuesActionsEnum.Unarchive
        ) ? (
          <ButtonIcon
            iconName='unarchive'
            iconSize={24}
            onClick={unarchiveVenue}
          />
        ) : null}
      </ButtonsContainer>
    </VenueListItemWrapper>
  );
};

export default VenueListItem;
