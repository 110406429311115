import { FloorplansLocation } from '@app/facility-management/floorplans';
import { CardBase, CardTitle } from '@app/shared/components';
import {
  CardEmptyImage,
  CardFooter,
  CardInfoWrapper
} from '@app/shared/components/card';
import { CardListData } from '@app/shared/components/card/CardListData';
import {
  CardImage,
  CardPicture
} from '@app/shared/components/card/CardPicture';
import { CardStatusIcon } from '@app/shared/components/card/CardStatusIcon';
import { CardTag } from '@app/shared/components/card/CardTag';
import { Building } from '@domain/buildings';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useLoadImage } from '@hooks/useLoadImage';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import BuildingsCardMenu from './buildings-card-menu/BuildingsCardMenu';

const CardLocation = styled.ul`
  ${({
    theme: {
      base: {
        fonts: { getTextsConfig }
      }
    }
  }) => `
        display: flex;
        ${getTextsConfig('M')};
        flex-direction: column;
        margin-bottom: ${rem(pxToRem(8))};
    `}
`;

const CardData = styled.div`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig, mediumFont }
      }
    }
  }) => `
      align-items: center;
      colors: ${colors.neutral.grayDark};
      display: flex;
      ${getTextsConfig('M')};
      font-family: '${mediumFont}';
      justify-content: flex-start;
      margin-bottom: ${rem(pxToRem(16))};
      width: 100%;
  `}
`;

const DefaultImageWrapper = styled.picture`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
    background-color: ${getRgbaStrFromHexColor(colors.primary.default, 0.08)};
    display: flex;
    justify-content: center;
    padding-top: ${rem(pxToRem(10))};
    width: 100%;
`}
`;

const CardFloorplans = styled.p`
  margin-right: ${rem(pxToRem(24))};
`;

const DefaultBuildingImage = styled.img``;

interface BuildingsCardProps {
  building: Building;
}

const BuildingsCard = memo(({ building }: BuildingsCardProps) => {
  const { t } = useTranslation('buildings');
  const history = useHistory();

  const theme = useTheme();
  const { getImage } = useLoadImage();

  return (
    <CardBase
      onClick={() =>
        history.push(
          FloorplansLocation.toUrl({
            venueId: building.venueId,
            buildingId: building.id
          })
        )
      }
      clickable={true}>
      <CardInfoWrapper
        className={
          building.isClosed || building.isArchived ? 'grayscale' : 'default'
        }>
        <CardTitle label={building.name} iconName='building' />
        <CardPicture>
          {building.imageUrl ? (
            <CardImage loading='lazy' src={building.imageUrl} />
          ) : (
            <CardEmptyImage size='default'>
              <DefaultImageWrapper>
                <DefaultBuildingImage src={getImage('DefaultBuilding')} />
              </DefaultImageWrapper>
            </CardEmptyImage>
          )}
          {building.isClosed && (
            <CardStatusIcon
              iconName='lock-alt'
              color={theme.base.colors.secondary.first}
            />
          )}
          {building.isArchived && (
            <CardStatusIcon
              iconName='archive'
              color={theme.base.colors.primary.default}
            />
          )}
        </CardPicture>
        <CardLocation>
          <li>{building.address}</li>
          <li>{building.city}</li>
        </CardLocation>
        <CardData>
          <CardFloorplans>
            {t('floorplans', { count: building.floorCount })}
          </CardFloorplans>
          <CardListData iconName='users' label={building.availableCapacity} />
        </CardData>
      </CardInfoWrapper>
      <CardFooter>
        <CardTag className={building.isPublished ? 'default' : 'inverse'}>
          {building.isPublished ? t('published') : t('unpublished')}
        </CardTag>
        <BuildingsCardMenu building={building} />
      </CardFooter>
    </CardBase>
  );
});

export default BuildingsCard;
