import { notifySuccess } from '@app/shared/notifications';
import { TermConditionModel } from '@domain/term-condition/models/create/createTermCondition';
import { TERM_CONDITION_QUERY_KEY } from '@domain/term-condition/termConditionKeys';
import { UseMutation, useMutationWrapper } from '@hooks/useMutationWrapper';
import { createTermCondition } from '@services/term-condition/termConditionService';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

const useCreateTermCondition = (): UseMutation<
  void,
  Error,
  TermConditionModel,
  unknown
> => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutationWrapper<void, Error, TermConditionModel>(
    (data) => createTermCondition(data),
    {
      onSuccess: () => {
        notifySuccess(t('shared:toast.operation-success'));

        queryClient.invalidateQueries(TERM_CONDITION_QUERY_KEY);
      }
    }
  );
};

export { useCreateTermCondition };
