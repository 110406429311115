import { DAILY_BOOKINGS_QUERY_KEY } from '@domain/bookings';
import { CreateBookingModel } from '@domain/bookings/models/shared/BookingFormModel';
import { FLOORPLANS_KEY } from '@domain/floorplans';
import { UseMutation, useMutationWrapper } from '@hooks/useMutationWrapper';
import { createBooking } from '@services/bookings/bookingsService';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { notifySuccess } from '../../modules/app/shared/notifications';

const useCreateBooking = (
  venueId: string,
  buildingId: string,
  floorId: string,
  selectedDate: Date
): UseMutation<void, Error, CreateBookingModel, unknown> => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutationWrapper<void, Error, CreateBookingModel>(
    (model) => createBooking(model),
    {
      onSuccess: () => {
        notifySuccess(t('shared:toast.operation-success'));

        queryClient.invalidateQueries(
          DAILY_BOOKINGS_QUERY_KEY(floorId, selectedDate)
        );
        queryClient.invalidateQueries(FLOORPLANS_KEY(venueId, buildingId));
      }
    }
  );
};

export { useCreateBooking };
