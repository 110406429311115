import { css } from '@emotion/react';

export const DashboardStyles = css`
.report-style-class {
	height: 75vh;
	margin: 1% auto;
	width: 100%;
}

iframe {
	border: none;
}
`;

export default { DashboardStyles };