import styled from '@emotion/styled/macro';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

const NoDataContainerRow = styled.tr`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
  border-bottom: 1px solid ${getRgbaStrFromHexColor(
    colors.primary.darkest,
    0.2
  )};
    min-width: 100%;
    height: ${rem(pxToRem(60))};
  `}
`;

const NoDataContainerColumn = styled.td`
  vertical-align: middle;
  padding: 0 ${rem(pxToRem(8))};
  width: 100%;
  text-align: center;
`;

interface TableNoDataProps {
  show: boolean;
  columns: number;
  message?: string;
}

const TableNoData = ({
  show,
  columns,
  message
}: TableNoDataProps): ReactElement | null => {
  const { t } = useTranslation();

  return show ? (
    <NoDataContainerRow>
      <NoDataContainerColumn colSpan={columns}>
        {message || t('table.noData')}
      </NoDataContainerColumn>
    </NoDataContainerRow>
  ) : null;
};

export { TableNoData };
