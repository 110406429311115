import i18n from '@services/i18n';
//import { differenceSecondsInterval } from '@utils/helpers/dateHelper';
import * as yup from 'yup';

//import { OfficeTimeInterval } from '../building';
import {
  ACCEPTED_IMAGE_TYPES,
  BUILDING_CONSTRAINTS,
  MAX_IMAGE_SIZE_BYTES
} from './buildingConstraints';

export interface BuildingDetailFormModel {
  name: string;
  address: string;
  city: string;
  capacity: number;
  limitedCapacity: number;
  venueId: string;
  image?: FileList;
  imageUrl?: string;
  // officeHours?: OfficeTimeInterval[];
  // hasFreeSchedule?: boolean;
}

const translateImageError = () => (
  params?: Partial<yup.TestMessageParams>
): string =>
  i18n.t('fieldValidations.custom.fileMaxSize', {
    ...params,
    label: i18n.t('buildings:fieldNames.image'),
    maxKb: MAX_IMAGE_SIZE_BYTES / 1024
  });

const translateImageTypeError = () => (
  params?: Partial<yup.TestMessageParams>
): string =>
  i18n.t('fieldValidations.mixed.notType', {
    ...params,
    label: i18n.t('buildings:fieldNames.image')
  });

const buildingDetailFormSchema = yup.object().shape({
  name: yup
    .string()
    .label('buildings:fieldNames.name')
    .required()
    .min(BUILDING_CONSTRAINTS.NAME.MIN_LENGTH)
    .max(BUILDING_CONSTRAINTS.NAME.MAX_LENGTH),
  address: yup
    .string()
    .label('buildings:fieldNames.address')
    .required()
    .min(BUILDING_CONSTRAINTS.ADDRESS.MIN_LENGTH)
    .max(BUILDING_CONSTRAINTS.ADDRESS.MAX_LENGTH),
  city: yup
    .string()
    .label('buildings:fieldNames.city')
    .required()
    .min(BUILDING_CONSTRAINTS.CITY.MIN_LENGTH)
    .max(BUILDING_CONSTRAINTS.CITY.MAX_LENGTH),
  capacity: yup
    .number()
    .transform((value) => value || undefined)
    .label('buildings:fieldNames.capacity')
    .required(),
  limitedCapacity: yup
    .number()
    .transform((value) => value || undefined)
    .label('buildings:fieldNames.limitedCapacity')
    .required()
    .min(BUILDING_CONSTRAINTS.LIMITED_CAPACITY.MIN_VALUE)
    .max(BUILDING_CONSTRAINTS.LIMITED_CAPACITY.MAX_VALUE),
  venueId: yup.string().label('buildings:fieldNames.venue').required(),
  image: yup
    .mixed()
    .nullable()
    .test(
      'validSize',
      translateImageError(),
      (value: FileList): value is FileList =>
        !value || !value.length || value[0].size < MAX_IMAGE_SIZE_BYTES
    )
    .test(
      'validType',
      translateImageTypeError(),
      (value: FileList): value is FileList =>
        !value ||
        !value.length ||
        ACCEPTED_IMAGE_TYPES.some((t) => t === value[0].type)
    ),
  imageUrl: yup.string().nullable()//,
  // hasFreeSchedule: yup.boolean().nullable(),
  // officeHours: yup
  //   .array<OfficeTimeInterval>()
  //   .nullable()
  //   .label('buildings:fieldNames.officeHours')
  //   .min(1)
  //   .required()
  //   .of(
  //     yup.object<OfficeTimeInterval>().shape({
  //       title: yup
  //         .object<{ [key: string]: string }>()
  //         .nullable()
  //         .label('buildings:fieldNames.name')
  //         .test(
  //           'all-with-value',
  //           () => i18n.t('fieldValidations.custom.allLanguagesRequired'),
  //           (value): boolean => {
  //             if (!value) {
  //               return false;
  //             }
  //             return Object.values(value).every((v) => v);
  //           }
  //         )
  //         .required(),
  //       start: yup
  //         .string()
  //         .nullable()
  //         .required()
  //         .label('buildings:fieldNames.start')
  //         .matches(/\d\d:\d\d/, ({ label }) =>
  //           i18n.t('fieldValidations.mixed.default', { label: i18n.t(label!) })
  //         )
  //         .test(
  //           'is-less',
  //           ({ label }) =>
  //             i18n.t('fieldValidations.number.lessThan', {
  //               label: i18n.t(label!),
  //               less: i18n.t('buildings:fieldNames.end')
  //             }),
  //           function (value) {
  //             const { end } = this.parent;
  //             if (!value || !end) {
  //               return true;
  //             }
  //             return (differenceSecondsInterval(value, end) ?? 1) > 0;
  //           }
  //         ),
  //       end: yup
  //         .string()
  //         .nullable()
  //         .required()
  //         .label('buildings:fieldNames.end')
  //         .matches(/\d\d:\d\d/, ({ label }) =>
  //           i18n.t('fieldValidations.mixed.default', { label: i18n.t(label!) })
  //         )
  //         .test(
  //           'is-greater',
  //           ({ label }) =>
  //             i18n.t('fieldValidations.number.moreThan', {
  //               label: i18n.t(label!),
  //               more: i18n.t('buildings:fieldNames.start')
  //             }),
  //           function (value) {
  //             const { start } = this.parent;
  //             if (!value || !start) {
  //               return true;
  //             }
  //             return (differenceSecondsInterval(start, value) ?? 1) > 0;
  //           }
  //         )
  //     })
  //   )
});

export { buildingDetailFormSchema };
