import { Location } from '@app/shared/routes';
import { RouteHelper } from '@utils/helpers/routeHelper';

export interface FloorplanSettingsLocationParams {
  venueId: string;
  buildingId: string;
  floorplanId: string;
}

const FloorplanSettingsLocation = new Location<FloorplanSettingsLocationParams>(
  {
    path:
      '/facility-management/venues/:venueId/buildings/:buildingId/floorplans/:floorplanId/settings',
    pathParamDefs: {
      venueId: RouteHelper.stringParamValidation(),
      buildingId: RouteHelper.stringParamValidation(),
      floorplanId: RouteHelper.stringParamValidation()
    }
  }
);

export { FloorplanSettingsLocation };
