import { Table } from '@app/shared/components';
import { UserResponse } from '@domain/user-profile';
import { useSearchProfiles } from '@hooks/user-profile/useSearchUsers';
import { useUpdateUserProfileIsActive } from '@hooks/user-profile/useUpdateUserProfileIsActive';
import React, { ReactElement } from 'react';

import { useGetUserListTableColumns } from './use-get-user-list-table-columns/UserListTableColumns';

const UserListTable = (): ReactElement => {
  const {
    data: users,
    isLoading: isLoadingUsers,
    isFetching,
    isSuccess: isGetUsersSuccess
  } = useSearchProfiles('', false, true);
  const { mutateAsync: updateUserIsActive } = useUpdateUserProfileIsActive();

  const columns = useGetUserListTableColumns(updateUserIsActive);

  const isLoading = !isGetUsersSuccess || isLoadingUsers || isFetching;

  return (
    <Table<UserResponse>
      isPaginable
      isSortable
      isFilterable
      data={users}
      columns={columns}
      isLoading={isLoading}
      count={users?.length}
    />
  );
};

export { UserListTable };
