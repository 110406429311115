import { Modal } from '@app/shared/components';
import { ILocationComponentProps } from '@app/shared/routes';
import { Venue } from '@domain/venues';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BuildingsLocation } from '../routes/buildingsLocation';
import BuildingsCreateForm from './buildings-create-form/BuildingsCreateForm';
import BuildingsEditForm from './buildings-edit-form/BuildingsEditForm';
import { EditBuildingFormLocationParams } from './routes/buildingFormLocation';

interface BuildingFormProps
  extends ILocationComponentProps<EditBuildingFormLocationParams> {
  mode: 'create' | 'edit';
  venues: Venue[];
}

const BuildingsForm = ({
  match: {
    params: { buildingId, venueId }
  },
  mode,
  venues
}: BuildingFormProps): ReactElement => {
  const { t } = useTranslation('buildings');

  const history = useHistory();

  const onGoBack = (): void =>
    history.replace(BuildingsLocation.toUrl({ venueId }));

  return (
    <Modal
      onClose={onGoBack}
      title={mode === 'create' ? t('creation-title') : t('edition-title')}>
      {mode === 'create' ? (
        <BuildingsCreateForm
          venueId={venueId}
          venues={venues}
          onCreateBuilding={onGoBack}
          onCancel={onGoBack}
        />
      ) : (
        <BuildingsEditForm
          venueId={venueId}
          venues={venues}
          onEditBuilding={onGoBack}
          onCancel={onGoBack}
          buildingId={buildingId || ''}
        />
      )}
    </Modal>
  );
};

export default BuildingsForm;
