import {
  FloorplanWorkplace,
  FloorplanWorkplaceEntity
} from '@app/facility-management/floorplans/floorplan-settings/shared/models/floorplanWorkplace';
import {
  ButtonBase,
  CardDropdown,
  InputCheckBox,
  MenuActionsItem,
  SelectorGridColumn,
  TableGrid,
  TableGridBody,
  TableGridBodyRow,
  TableGridHeader,
  TableGridHeaderItem,
  TableGridItem,
  TableGridRow
} from '@app/shared/components';
import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Column } from './WorkplaceTypeList';

const WorkplacesListWrapper = styled.div`
  ${() => `
    display: flex;
    flex-direction: column;

    ${ButtonBase} {
      align-self: flex-start;
      margin-bottom: ${rem(pxToRem(32))};
    }
  `}
`;

interface WorkplaceTableProps {
  columns: Column[];
  workplaces: FloorplanWorkplace;
  selectedWorkplacesIds: string[];
  getItemActions: (
    entityId: string,
    workplace: FloorplanWorkplaceEntity
  ) => MenuActionsItem[];
  onSelectionChange: (entityId: string, isSelected: boolean) => void;
}

const WorkplaceTable = ({
  columns,
  workplaces,
  selectedWorkplacesIds,
  getItemActions,
  onSelectionChange
}: WorkplaceTableProps): ReactElement => {
  const { t } = useTranslation('workplaces');

  const visibleColumns = useMemo(
    () =>
      columns.filter(
        ({ show }) => !show || show(Object.values(workplaces).first()!.model)
      ),
    [columns, workplaces]
  );

  return (
    <WorkplacesListWrapper>
      <TableGrid>
        <TableGridHeader>
          <TableGridRow>
            <TableGridHeaderItem>{t('fieldNames.number')}</TableGridHeaderItem>
            {visibleColumns.map(({ header }) => (
              <TableGridHeaderItem key={header}>{header}</TableGridHeaderItem>
            ))}
            <TableGridHeaderItem></TableGridHeaderItem>
          </TableGridRow>
        </TableGridHeader>
        <TableGridBody>
          {Object.entries(workplaces).map(([entityId, workplace], index) => {
            const isSelected = selectedWorkplacesIds.includes(entityId);
            return (
              <TableGridBodyRow
                key={entityId}
                isSelected={isSelected}
                onClick={() => onSelectionChange(entityId, !isSelected)}>
                <TableGridItem>
                  <SelectorGridColumn>
                    <InputCheckBox
                      options={[{ id: entityId, checked: isSelected }]}
                    />
                    {(index + 1).toString()}
                  </SelectorGridColumn>
                </TableGridItem>
                {visibleColumns.map(({ value }, index) => (
                  <TableGridItem key={`${entityId}_${index}`}>
                    {value?.(workplace.model)}
                  </TableGridItem>
                ))}
                <TableGridItem>
                  <CardDropdown
                    dropdownOptions={getItemActions(entityId, workplace)}
                  />
                </TableGridItem>
              </TableGridBodyRow>
            );
          })}
        </TableGridBody>
      </TableGrid>
    </WorkplacesListWrapper>
  );
};

export { WorkplaceTable, TableGridItem };
