import { EmployeeTypeEnum } from '@domain/employees';
import * as Services from '@services/api/apiService';
import * as yup from 'yup';

export type BookingFormModel = {
  shiftTypeId: string;
  employeeType: EmployeeTypeEnum;
  bookedUser?: {
    id: string;
    name: string;
    email: string;
  };
  externalUserMail?: string;
};

export interface CreateBookingModel
  extends Services.ICreateBookingForGivenUserModel {}

export interface CreateBookingModelForExternalUser
  extends Services.ICreateBookingForExternalUserModel {}

export const bookingFormModelSchema = yup.object<BookingFormModel>().shape({
  shiftTypeId: yup.string().label('workplaces:fieldNames.shift').required(),
  employeeType: yup
    .number()
    .label('workplaces:fieldNames.employeeTypeLabel')
    .required(),
  bookedUser: yup.object().when('employeeType', {
    is: (val) => val === EmployeeTypeEnum.Internal,
    then: yup.object().shape({
      id: yup.string().required(),
      name: yup.string().required(),
      email: yup.string().required()
    }),
    otherwise: yup.object().nullable()
  }),
  externalUserMail: yup.string().when('employeeType', {
    is: (val) => val === EmployeeTypeEnum.External,
    then: yup.string().required().email(),
    otherwise: yup.string().nullable()
  })
});
