import {
  CreateFloorplanModel,
  Floorplan,
  FloorplanMetadata as DomainFloorplanMetadata,
  FloorplanResponse,
  UpdateFloorplanModel
} from '@domain/floorplans';
import { apiService } from '@services/api';
import { SceneState } from '@wave-engine/wave-viewer';

import {
  CreateFloorModel,
  FloorplanMetadata,
  UpdateFloorModel
} from '../api/apiService';

const getAllFloorplans = async (
  buildingId: string,
  getArchived: boolean,
  getUnpublished: boolean,
  getClosed: boolean
): Promise<Floorplan[]> => {
  return apiService.floor_GetAll(
    buildingId,
    getArchived,
    getUnpublished,
    getClosed
  );
};

const getFloorplan = async (
  floorPlanId: string
): Promise<FloorplanResponse> => {
  return apiService.floor_Get(floorPlanId);
};

const createFloorplan = async (
  floorplan: CreateFloorplanModel
): Promise<void> => {
  const { id } = await apiService.floor_Create(
    CreateFloorModel.fromJS(floorplan)
  );

  await apiService.floor_SetFloorPlanModel(
    id,
    { data: floorplan.planModelFile, fileName: floorplan.planModelFile.name },
    {
      data: floorplan.imageModelFile,
      fileName: floorplan.imageModelFile.name
    }
  );

  return apiService.floor_PublishDraft(id);
};

const updateFloorplan = async (
  id: string,
  floorplan: UpdateFloorplanModel
): Promise<void> => {
  await apiService.floor_Update(id, UpdateFloorModel.fromJS(floorplan));

  if (floorplan.planModelFile && floorplan.imageModelFile) {
    await apiService.floor_SetFloorPlanModel(
      id,
      {
        data: floorplan.planModelFile,
        fileName: floorplan.planModelFile.name
      },
      {
        data: floorplan.imageModelFile,
        fileName: floorplan.imageModelFile.name
      }
    );
  }

  return apiService.floor_PublishDraft(id);
};

const deleteFloorplan = async (floorplanId: string): Promise<void> => {
  return await apiService.floor_Delete(floorplanId);
};

const openFloorplan = async (floorplanId: string): Promise<void> => {
  return await apiService.floor_Open(floorplanId);
};

const closeFloorplan = async (floorplanId: string): Promise<void> => {
  return await apiService.floor_Close(floorplanId);
};

const publishFloorplan = async (floorplanId: string): Promise<void> => {
  return await apiService.floor_Publish(floorplanId);
};

const unpublishFloorplan = async (floorplanId: string): Promise<void> => {
  return await apiService.floor_UnPublish(floorplanId);
};

const archiveFloorplan = async (floorplanId: string): Promise<void> => {
  return await apiService.floor_Archive(floorplanId);
};

const unarchiveFloorplan = async (floorplanId: string): Promise<void> => {
  return await apiService.floor_UnArchive(floorplanId);
};

const getFloorplanMetadata = async (
  floorPlanId: string
): Promise<DomainFloorplanMetadata> => {
  return apiService.floor_GetFloorPlanMetadata(floorPlanId);
};

const setFloorplanMetadata = async (
  floorId: string,
  sceneState: SceneState
): Promise<void> => {
  const metadata = FloorplanMetadata.fromJS(sceneState);
  return apiService.floor_SetFloorPlanMetadata(floorId, metadata);
};

export {
  getAllFloorplans,
  getFloorplan,
  createFloorplan,
  updateFloorplan,
  deleteFloorplan,
  openFloorplan,
  closeFloorplan,
  publishFloorplan,
  unpublishFloorplan,
  archiveFloorplan,
  unarchiveFloorplan,
  getFloorplanMetadata,
  setFloorplanMetadata
};
