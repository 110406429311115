import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { Select } from '../..';
import { ROWS_PER_PAGE } from '../table.service';

const TableToolbarWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${rem(pxToRem(12))};
`;

const ResultsNumber = styled.div`
  ${({
    theme: {
      base: {
        fonts: { getTextsConfig, semiBoldFont }
      }
    }
  }) => `
    ${getTextsConfig('M')};
    font-family: ${semiBoldFont};
    margin-left: auto;
    margin-right: ${rem(pxToRem(24))};
  `}
`;

type TableToolbarProps<T extends object> = {
  total: number | undefined;
  instance: TableInstance<T>;
};

const TableToolbar = <T extends object>({
  total = 0,
  instance
}: TableToolbarProps<T>): ReactElement => {
  const { t } = useTranslation();

  const {
    page,
    setPageSize,
    state: { pageSize }
  } = instance;

  return (
    <TableToolbarWrapper>
      <ResultsNumber>
        {t('table.showingResults', {
          count: page.length,
          total
        })}
      </ResultsNumber>
      <Select
        direction='row'
        dropdownSize={80}
        label={t('table.show')}
        items={ROWS_PER_PAGE.map((x) => x.toString())}
        item={ROWS_PER_PAGE.find((x) => x === pageSize)?.toString()}
        onChange={(item) => setPageSize(Number(item))}
        searchable={false}
      />
    </TableToolbarWrapper>
  );
};

export { TableToolbar };
