import {
  INCIDENT_TYPES_QUERY_KEY,
  IncidentType
} from '@domain/care-management';
import { getCareManagementIncidentTypes } from '@services/care-management/incidents/careManagementIncidentsService';
import { useQuery, UseQueryResult } from 'react-query';

const useGetIncidentTypes = (): UseQueryResult<IncidentType[], Error> =>
  useQuery<IncidentType[], Error>([INCIDENT_TYPES_QUERY_KEY], () =>
    getCareManagementIncidentTypes()
  );

export { useGetIncidentTypes };
