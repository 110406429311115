import '@balea/ui/dist/balea/balea.css';
import '@assets/balea-theme.css';

import { configuration } from '@configuration';
import { useAuthContext } from '@contexts/auth';
import React, { ReactElement } from 'react';

const {
  oidc: { type },
  balea: { entrypoint, apiUrl }
} = configuration;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'balea-admin': BaleaAdminProps;
    }
  }
}

interface BaleaAdminProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  url: string;
  token: string;
  type: string;
  entrypoint?: string;
  useHash?: boolean;
}

const Balea = (): ReactElement => {
  const { user } = useAuthContext();

  return (
    <balea-admin
      entrypoint={entrypoint}
      url={apiUrl}
      token={user ? user.token : ''}
      type={type}
      useHash={false}
    />
  );
};

export default Balea;
