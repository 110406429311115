import axios from 'axios';

type DownloadDxfParams = {
  url: string;
};

const getFilenameExtension = (filename: string): string => {
  const lastDot = filename.lastIndexOf('.');
  return filename.substring(lastDot);
};

const getFilenameFromUrl = (url: string): string => {
  const fileUrl = new URL(url);
  const pathnameArray = fileUrl.pathname.split('/');

  return pathnameArray[pathnameArray.length - 1];
};

const downloadDxf = async ({ url }: DownloadDxfParams): Promise<File> => {
  const resp = await axios.get<Blob>(url, { responseType: 'blob' });
  const name = getFilenameFromUrl(url);

  return new File([resp.data], name || '');
};

const downloadFile = (url: string): void => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export { getFilenameExtension, getFilenameFromUrl, downloadDxf, downloadFile };
