import { CellProps, TableData } from '@app/shared/components';
import {
  ButtonCell,
  TextCell,
  UserCell
} from '@app/shared/components/table/cells';
import { UserResponse } from '@domain/user-profile';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useGetUserListTableColumns = (
  updateUserIsActive: Function
): TableData<UserResponse> => {
  const { t } = useTranslation('users');

  const columns: TableData<UserResponse> = useMemo(
    () => [
      {
        id: 'name',
        Header: t('list.table.name') as string,
        accessor: ({ name }) => ({
          userName: name
        }),
        width: 250,
        Cell: UserCell
      },
      {
        id: 'email',
        Header: t('list.table.email') as string,
        accessor: 'email'
      },
      {
        id: 'isActive',
        Header: t('list.table.user-state') as string,
        accessor: ({ isActive }) =>
          isActive === false
            ? t('list.table.inactive')
            : t('list.table.active'),
        width: 250,
        Cell: TextCell
      },
      {
        id: 'deactivate-users',
        accessor: ({ isActive, id }) => ({ isActive, id }),
        disableFilters: true,
        width: 25,
        Cell: ({
          value: { isActive, id }
        }: CellProps<UserResponse, Pick<UserResponse, 'isActive' | 'id'>>) => (
          <ButtonCell
            label={isActive ? t('list.table.remove') : t('list.table.activate')}
            onClick={() => {
              updateUserIsActive({ id, isActive: !isActive });
            }}
          />
        )
      }
    ],
    []
  );

  return columns;
};
