import { TableDataResult, TableRequestModel } from '@app/shared/components';
import {
  IncidentResolveModel,
  IncidentResolveResponse,
  IncidentSearch,
  IncidentType
} from '@domain/care-management';
import { apiService } from '@services/api';
import { SearchIncidentModel } from '@services/api/apiService';

const getCareManagementIncidentTypes = async (): Promise<IncidentType[]> => {
  return apiService.incident_GetTypes();
};

const getCareManagementIncidents = async (
  model: TableRequestModel<IncidentSearch>
): Promise<TableDataResult<IncidentSearch>> => {
  return await apiService.incident_Search(SearchIncidentModel.fromJS(model));
};

const careManagementResolveIncident = async ({
  incidentId,
  productId
}: IncidentResolveModel): Promise<IncidentResolveResponse> => {
  return apiService.incident_ResolveIncidentByProduct(incidentId, productId);
};

const careManagementUnresolveIncident = async ({
  incidentId,
  productId
}: IncidentResolveModel): Promise<IncidentResolveResponse> => {
  return apiService.incident_UnResolveIncidentByProduct(incidentId, productId);
};

export {
  getCareManagementIncidentTypes,
  getCareManagementIncidents,
  careManagementResolveIncident,
  careManagementUnresolveIncident
};
