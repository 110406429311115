import {
  CreateVenueModel,
  CreateVenueResponse,
  VENUES_QUERY_KEY
} from '@domain/venues';
import { UseMutation, useMutationWrapper } from '@hooks/useMutationWrapper';
import { createVenue } from '@services/venues/venuesService';
import { useQueryClient } from 'react-query';

const useCreateVenue = (): UseMutation<
  CreateVenueResponse,
  Error,
  CreateVenueModel,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutationWrapper<CreateVenueResponse, Error, CreateVenueModel>(
    (venue) => createVenue(venue),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(VENUES_QUERY_KEY);
      }
    }
  );
};

export { useCreateVenue };
