import { Icon, IconWrapper } from '@app/shared/components';
import { configuration } from '@configuration';
import styled from '@emotion/styled';
import { useUserProfile } from '@stores/userProfileStore';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

const {
  i18n: { languages }
} = configuration;

const LanguageSelectorWrapper = styled.div`
  ${({
    theme: {
      base: {
        fonts: { getTextsConfig }
      }
    }
  }) => `
    ${getTextsConfig('S')};
    padding: 0;
    transition: background 0.3s ease;
    width: 100%;
  `}
`;

const LanguageSelectorContainer = styled.ul`
  width: 100%;
`;

const LanguageSelectorItemWrapper = styled.ul`
  animation-duration: 0.3s;
  animation-name: showList;
  height: ${rem(pxToRem(76))};
  overflow: hidden;

  @keyframes showList {
    from {
      height: 0;
    }
    to {
      height: ${rem(pxToRem(76))};
    }
  }
`;

const LanguageSelectorItemTitle = styled.li`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
    align-items: center;
    border: 1px solid ${getRgbaStrFromHexColor(colors.primary.darkest, 0.3)};
    border-radius: ${rem(pxToRem(2))};
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: ${rem(pxToRem(3))} ${rem(pxToRem(8))};
    transition: background 0.3s ease;
    width: 100%;
  `}
`;

interface LanguageSelectorItemTitleIconProps {
  isOpen?: boolean;
}

const LanguageSelectorItemTitleIcon = styled.span<
  LanguageSelectorItemTitleIconProps
>`
  line-height: 0;

  ${({ isOpen }) => `
    transform: ${isOpen ? 'rotateX(-180deg)' : 'rotateX(0)'};
    transition: all 0.3s ease;
  `}
`;

const LanguageSelectorItemOption = styled.li`
  ${({
    theme: {
      base: { colors, zindex }
    }
  }) => `
        align-items: center;
        display: flex;
        height: ${rem(pxToRem(38))};
        padding-left: ${rem(pxToRem(10))};
        padding-right: ${rem(pxToRem(10))};
        position: relative;
        justify-content: space-between;

        > span {
          text-transform: capitalize;
          z-index: ${zindex.S};
        }

        ${IconWrapper} {
            display: none;
        }

        > input {
          cursor: pointer;
          height: 100%;
          left: 0;
          margin: 0;
          opacity: 0;
          position: absolute;
          width: 100%;
          z-index: ${zindex.S};

          &:checked {
            ~ ${IconWrapper} {
                display: block;
            }
          }
        }

        &:hover {
          background: ${colors.neutral.grayLight};
        }
    `}
`;

const LanguageSelector = (): ReactElement => {
  const { t, i18n } = useTranslation();

  const [isOpenOptionList, setOpenOptionList] = useState<boolean>();
  const setUserProfile = useUserProfile((s) => s.setUserProfile);

  const handleChangeLanguage = (
    value: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const language = value.target.value;
    i18n.changeLanguage(language);
    setUserProfile({ language });
  };

  return (
    <LanguageSelectorWrapper>
      <LanguageSelectorContainer>
        <LanguageSelectorItemTitle
          onClick={() => setOpenOptionList(!isOpenOptionList)}>
          <span>{t('profile.changeLanguage')}</span>
          <LanguageSelectorItemTitleIcon isOpen={isOpenOptionList}>
            <Icon iconName='chevron-down' iconSize={24} />
          </LanguageSelectorItemTitleIcon>
        </LanguageSelectorItemTitle>
        {isOpenOptionList && (
          <LanguageSelectorItemWrapper>
            {languages.map((lang) => (
              <LanguageSelectorItemOption key={lang}>
                <span>{t(`languages.${lang}`)}</span>
                <input
                  type='radio'
                  name={lang}
                  value={lang}
                  checked={i18n.language === lang}
                  onChange={handleChangeLanguage}
                />
                <Icon iconName='tick' iconSize={22} />
              </LanguageSelectorItemOption>
            ))}
          </LanguageSelectorItemWrapper>
        )}
      </LanguageSelectorContainer>
    </LanguageSelectorWrapper>
  );
};

export { LanguageSelector };
