import { createImage, resizeImage } from '@utils/image-utils';
import { WAVE_CANVAS_ID } from '@wave-engine/wave-viewer';

type GetScreenshotProps = {
  imageName: string;
};

type UseScreenshot = {
  getScreenshot: (props: GetScreenshotProps) => Promise<File | undefined>;
};

const useScreenshot = (): UseScreenshot => {
  const getScreenshot = async ({
    imageName
  }: GetScreenshotProps): Promise<File | undefined> => {
    const canvas = document.getElementById(WAVE_CANVAS_ID) as HTMLCanvasElement;
    if (canvas) {
      const imageResized = await resizeImage({
        dataImage: canvas.toDataURL(),
        maxSize: 12000
      });

      if (imageResized) {
        const image = createImage({
          blob: imageResized,
          name: imageName,
          type: 'png'
        });
        return image;
      }
    }
  };

  return { getScreenshot };
};

export { useScreenshot };
