import {
  FilterButtons,
  FilterButtonsItem,
  PageContainer,
  PageHeader,
  PageTitle
} from '@app/shared/components';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom';

import { PrivacyPolicy } from './privacy-policy/PrivacyPolicy';
import { PrivacyPolicyLocation } from './privacy-policy/routes';
import { LegalLocation } from './routes/legalLocation';
import { LegalContainer } from './shared';
import { TermConditionLocation } from './term-condition/routes';
import { TermCondition } from './term-condition/TermCondition';

const Legal = (): ReactElement => {
  const { t } = useTranslation(['privacy-policy', 'term-condition']);
  const history = useHistory();
  const location = useLocation();

  const tabs = [
    {
      label: t('privacy-policy:title'),
      active: location.pathname.includes(PrivacyPolicyLocation.path),
      onClick: () => history.push(PrivacyPolicyLocation.toUrl())
    },
    {
      label: t('term-condition:title'),
      active: location.pathname.includes(TermConditionLocation.path),
      onClick: () => history.push(TermConditionLocation.toUrl())
    }
  ] as FilterButtonsItem[];

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle label={t('shared:routes.legal')} />
        <FilterButtons buttons={tabs} noBorder />
      </PageHeader>
      <LegalContainer>
        <Switch>
          {LegalLocation.toRoute({
            exact: true,
            render: () => <Redirect to={PrivacyPolicyLocation.path} />
          })}
          {PrivacyPolicyLocation.toRoute({
            component: PrivacyPolicy
          })}
          {TermConditionLocation.toRoute({
            component: TermCondition
          })}
        </Switch>
      </LegalContainer>
    </PageContainer>
  );
};

export default Legal;
