import { TableDataResult, TableRequestModel } from '@app/shared/components';
import { INCIDENT_QUERY_KEY, IncidentSearch } from '@domain/care-management';
import { getCareManagementIncidents } from '@services/care-management/incidents/careManagementIncidentsService';
import { useQuery, UseQueryResult } from 'react-query';

const useGetIncidents = (
  model: TableRequestModel<IncidentSearch> | undefined
): UseQueryResult<TableDataResult<IncidentSearch>, Error> =>
  useQuery<TableDataResult<IncidentSearch>, Error>(
    [INCIDENT_QUERY_KEY, model],
    () => getCareManagementIncidents(model ?? { pageIndex: 0 }),
    { enabled: !!model }
  );

export { useGetIncidents };
