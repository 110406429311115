import { Icon, IconWrapper, Switch } from '@app/shared/components';
import styled from '@emotion/styled';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import React from 'react';
import { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const SwitchWrapper = styled.div`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig }
      }
    }
  }) => `
  align-items: center;
  border-bottom: 1px solid ${getRgbaStrFromHexColor(
    colors.primary.darkest,
    0.2
  )};
  color: ${colors.primary.darkest};
  display: flex;
  ${getTextsConfig('M')};
  justify-content: space-between;
  padding: ${rem(pxToRem(6))} ${rem(pxToRem(12))} ${rem(pxToRem(6))} ${rem(
    pxToRem(6)
  )};

  ${IconWrapper} {
    color: ${colors.neutral.grayDark};
    font-size: ${rem(pxToRem(24))};
    margin-right: ${rem(pxToRem(12))};
  }
`}
`;

const SwitchData = styled.div`
  align-items: center;
  display: flex;
`;

const WorkplaceStatusOptions = (): ReactElement => {
  const { t } = useTranslation('workplaces');
  const { register } = useFormContext<{ enabled: string; lockSite: string }>();

  return (
    <>
      <SwitchWrapper>
        <SwitchData>
          <Icon iconName='eye' />
          {t('fieldNames.publishSite')}
        </SwitchData>
        <Switch name='enabled' innerRef={register} />
      </SwitchWrapper>
      <SwitchWrapper>
        <SwitchData>
          <Icon iconName='lock-alt' />
          {t('fieldNames.lockSite')}
        </SwitchData>
        <Switch name='lockSite' innerRef={register} />
      </SwitchWrapper>
    </>
  );
};

export { WorkplaceStatusOptions };
