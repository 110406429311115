import { Icon, IconWrapper } from '@app/shared/components';
import {
  WorkplaceEntity,
  WorkplaceFacilityDefinition
} from '@domain/workplaces';
import styled from '@emotion/styled';
import { useClickOutsideAction } from '@hooks/useClickOutsideAction';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';

const FacilitiesMoreItemsWrapper = styled.div`
  position: relative;
`;

const ListIconsWrapper = styled.div`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-right: auto;

    ${IconWrapper} {
        align-items: center;
        color: ${colors.neutral.grayDark};
        display: flex;
        font-size: ${rem(pxToRem(24))};
        height: ${rem(pxToRem(40))};
        justify-content: center;
        min-width: ${rem(pxToRem(40))};
        width: ${rem(pxToRem(40))};
    }
  `}
`;

const FacilitiesItemsTooltip = styled(ListIconsWrapper)`
  ${({
    theme: {
      base: { colors, shadow }
    }
  }) => `
        background-color: ${colors.neutral.fullLight};
        border-radius: ${rem(pxToRem(4))};
        box-shadow: ${shadow.default(colors)};
        cursor: default;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        overflow: hidden;
        position: relative;

        &:after {
            background-color: ${colors.neutral.fullLight};
            bottom: 0;
            content: '';
            height: ${rem(pxToRem(1))};
            position: absolute;
            width: 100%;
        }

        ${IconWrapper} {
            border-right: 1px solid ${getRgbaStrFromHexColor(
              colors.primary.darkest,
              0.2
            )};
            border-bottom: 1px solid ${getRgbaStrFromHexColor(
              colors.primary.darkest,
              0.2
            )};
            box-sizing: content-box;
            padding: ${rem(pxToRem(3))};

            &:nth-child(3n) {
                border-right: 1px solid transparent;
            }
        }
    `}
`;

const FacilitiesBubble = styled.div`
  ${({
    theme: {
      base: {
        colors,
        radius,
        fonts: { getTextsConfig, semiBoldFont }
      }
    }
  }) => `
        align-items: center;
        background-color: ${colors.primary.default};
        border-radius: ${radius.circle};
        color: ${colors.neutral.fullLight};
        display: flex;
        ${getTextsConfig('S')};
        font-family: ${semiBoldFont};
        height: ${rem(pxToRem(32))};
        justify-content: center;
        margin: ${rem(pxToRem(4))};
        min-width: ${rem(pxToRem(32))};
        position: relative;
        width: ${rem(pxToRem(32))};

        &:hover {
            cursor: pointer;
        }
   `}
`;

const PopperWrapper = styled.div`
  z-index: 1000;
`;

const MAX_VISIBLE_FACILITIES = 3;

type FacilitiesIconListProps<T extends WorkplaceEntity> = {
  facilities: WorkplaceFacilityDefinition<T>[];
};

const FacilitiesIconList = <T extends WorkplaceEntity>({
  facilities: filteredFacilities
}: FacilitiesIconListProps<T>): ReactElement => {
  const { t } = useTranslation('workplaces');

  const hasOverflow = filteredFacilities.length > MAX_VISIBLE_FACILITIES;
  const facilities = hasOverflow
    ? filteredFacilities.slice(0, MAX_VISIBLE_FACILITIES)
    : filteredFacilities;

  const [buttonRef, setButtonRef] = useState<HTMLDivElement | null>(null);
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);
  const tooltipElementRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setOpen] = useState(false);
  useClickOutsideAction(tooltipElementRef, () => setOpen(false));

  const { styles, attributes, update } = usePopper(buttonRef, tooltipRef, {
    placement: 'top',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8]
        }
      }
    ]
  });

  useLayoutEffect(() => {
    if (isOpen) {
      update?.();
    }
  }, [isOpen]);

  return (
    <ListIconsWrapper>
      {facilities.map((f, index) => (
        <Icon key={index} iconName={f.icon} title={t(f.title)} />
      ))}
      {hasOverflow ? (
        <FacilitiesMoreItemsWrapper
          ref={(ref) => {
            setButtonRef(ref);
          }}
          onClick={(ev) => {
            setOpen((value) => !value);
            ev.preventDefault();
            ev.stopPropagation();
          }}>
          <FacilitiesBubble>
            +{filteredFacilities.length - facilities.length}
          </FacilitiesBubble>
          {isOpen &&
            createPortal(
              <PopperWrapper
                ref={(ref) => {
                  setTooltipRef(ref);
                  tooltipElementRef.current = ref;
                }}
                style={styles.popper}
                {...attributes.popper}>
                <FacilitiesItemsTooltip>
                  {filteredFacilities.map((f, index) => (
                    <Icon key={index} iconName={f.icon} title={t(f.title)} />
                  ))}
                </FacilitiesItemsTooltip>
              </PopperWrapper>,
              document.querySelector('#root')!
            )}
        </FacilitiesMoreItemsWrapper>
      ) : null}
    </ListIconsWrapper>
  );
};

export { FacilitiesIconList };
