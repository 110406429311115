import {
  Building,
  CreateBuildingModel,
  UpdateBuilding
} from '@domain/buildings';
import { apiService } from '@services/api';
import {
  CreateBuildingModel as CreateBuildingModelServices,
  ShiftTypeResponse,
  UpdateBuildingModel as UpdateBuildingModelServices
} from '@services/api/apiService';

const getBuildingsByVenueId = async (
  venueId: string,
  getArchived: boolean,
  getUnpublished: boolean,
  getClosed: boolean
): Promise<Building[]> => {
  return await apiService.venue_GetByVenue(
    venueId,
    getArchived,
    getUnpublished,
    getClosed
  );
};

const getBuilding = async (id: string): Promise<Building> => {
  const data = await apiService.building_Get(id);
  return data;
};

const getShift = async (id: string): Promise<ShiftTypeResponse[]> => {
  const data = await apiService.shiftType_GetAll(id);
  return data;
};

const createBuilding = async (building: CreateBuildingModel): Promise<void> => {
  const { id } = await apiService.building_Create(
    CreateBuildingModelServices.fromJS(building)
  );

  return building.image && building.image.length > 0
    ? await apiService.building_UploadImage(id, {
        fileName: building.image[0].name,
        data: building.image[0]
      })
    : Promise.resolve();
};

const updateBuilding = async (
  id: string,
  building: UpdateBuilding
): Promise<void> => {
  await apiService.building_Update(
    id,
    UpdateBuildingModelServices.fromJS(building)
  );

  return building.image && building.image.length > 0
    ? await apiService.building_UploadImage(id, {
        fileName: building.image[0].name,
        data: building.image[0]
      })
    : Promise.resolve();
};

const deleteBuilding = async (buildingId: string): Promise<void> => {
  return await apiService.building_Delete(buildingId);
};

const archiveBuilding = async (buildingId: string): Promise<void> => {
  return await apiService.building_Archive(buildingId);
};

const unarchiveBuilding = async (buildingId: string): Promise<void> => {
  return await apiService.building_Unarchive(buildingId);
};

const openBuilding = async (buildingId: string): Promise<void> => {
  return await apiService.building_Open(buildingId);
};

const closeBuilding = async (buildingId: string): Promise<void> => {
  return await apiService.building_Close(buildingId);
};

const publishBuilding = async (buildingId: string): Promise<void> => {
  return await apiService.building_Publish(buildingId);
};

const unpublishBuilding = async (buildingId: string): Promise<void> => {
  return await apiService.building_Unpublish(buildingId);
};

export {
  getBuildingsByVenueId,
  getBuilding,
  getShift,
  createBuilding,
  updateBuilding,
  deleteBuilding,
  archiveBuilding,
  unarchiveBuilding,
  openBuilding,
  closeBuilding,
  publishBuilding,
  unpublishBuilding
};
