import { Loader } from '@app/shared/components';
import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { PropsWithChildren } from 'react';

const PageContainerContent = styled.section`
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  margin: auto;
  min-height: calc(100% - ${rem(pxToRem(72))});
  width: 100%;
`;

interface PageContainerProps {
  isFetching?: boolean;
}

const PageContainer: React.FC<PropsWithChildren<PageContainerProps>> = ({
  isFetching = false,
  children
}) => {
  return (
    <PageContainerContent>
      {isFetching && <Loader />}
      {children}
    </PageContainerContent>
  );
};

export { PageContainer };
