import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { Ref } from 'react';
import { ReactElement } from 'react';

type SwitchProps = {
  label?: string;
  name?: string;
  disabled?: boolean;
  innerRef?: Ref<HTMLInputElement>;
} & JSX.IntrinsicElements['input'];

const SwitchLabel = styled.label`
  ${({
    theme: {
      base: {
        fonts: { getTextsConfig }
      }
    }
  }) => `
        ${getTextsConfig('M')};
        padding-left: ${rem(pxToRem(8))};

        &:hover {
            cursor: pointer;
        }
    `}
`;

const SwitchWrapper = styled.div<SwitchProps>`
  ${({ disabled }) => `
        align-items: center;
        display: flex;

        ${
          disabled &&
          `
                opacity: 0.3;

                > ${SwitchLabel} {
                    cursor: default;
                }
            `
        }
    `}
`;

const SwitchContainer = styled.div`
  height: ${rem(pxToRem(16))};
  position: relative;
  width: ${rem(pxToRem(28))};
`;

const SwitchControl = styled.div`
  ${({
    theme: {
      base: { colors, radius }
    }
  }) => `
        background-color: ${colors.neutral.grayDark};
        border-radius: ${radius.rounded};
        border: 0 none;
        display: block;
        height: 100%;
        position: relative;
        transition: all 0.3s ease;
        width: 100%;
    `}
`;

const SwitchBullet = styled.span`
  ${({
    theme: {
      base: { colors, radius }
    }
  }) => `
        background-color: ${colors.neutral.fullLight};
        border-radius: ${radius.circle};
        display: block;
        height: ${rem(pxToRem(10))};
        left: ${rem(pxToRem(4))};
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: left 0.2s ease;
        width: ${rem(pxToRem(10))};
    `}
`;

const SwitchInput = styled.input<SwitchProps>`
  ${({
    theme: {
      base: { colors, zindex }
    }
  }) => `
        appearance: none;
        height: 100%;
        left: 0;
        margin: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: ${zindex.XS};

        &:hover:not([disabled]) {
            cursor: pointer;

            ~ ${SwitchControl} {
                background-color: ${colors.primary.dark};
            }
        }

        &:checked {
            ~ ${SwitchControl} {
                background-color: ${colors.primary.default};

                ${SwitchBullet} {
                    left: calc(100% - 50%);
                }
            }

            &:hover {
                ~ ${SwitchControl} {
                    background-color: ${colors.primary.dark};
                }
            }
        }

        &[disabled] {
            &:checked {
                &,
                &:hover {
                    ~ ${SwitchControl} {
                        background-color: ${colors.neutral.grayDark};
                    }
                }
            }
        }
    `}
`;

const Switch = ({
  label,
  name,
  disabled,
  innerRef,
  ...restProps
}: SwitchProps): ReactElement => {
  return (
    <SwitchWrapper disabled={disabled}>
      <SwitchContainer>
        <SwitchInput
          ref={innerRef}
          name={name}
          id={name}
          type='checkbox'
          disabled={disabled}
          {...restProps}></SwitchInput>
        <SwitchControl>
          <SwitchBullet />
        </SwitchControl>
      </SwitchContainer>
      {label ? <SwitchLabel htmlFor={name}>{label}</SwitchLabel> : null}
    </SwitchWrapper>
  );
};

export { Switch };
