import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { ButtonIconBase, ButtonIconSecondary } from '../../button-icon';
import { Input } from '../../form';

interface TablePaginationProps<T extends object> {
  instance: TableInstance<T>;
}

const PaginationWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: ${rem(pxToRem(16))};
`;

const ButtonsActionsWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-right: ${rem(pxToRem(16))};

  ${ButtonIconBase} {
    &:not(:last-of-type) {
      margin-right: ${rem(pxToRem(8))};
    }
  }
`;

const TotalPages = styled.span`
  ${({
    theme: {
      base: {
        fonts: { semiBoldFont }
      }
    }
  }) => `
    font-family: ${semiBoldFont};
    margin-right: ${rem(pxToRem(16))};
  `}
`;

const CustomInput = styled(Input)`
  ${() => `
    height: ${rem(pxToRem(32))};
    padding: 0 ${rem(pxToRem(4))} 0 ${rem(pxToRem(16))};
    text-align: center;
    width: ${rem(pxToRem(70))};
  `}
`;

const TablePagination = <T extends object>({
  instance
}: TablePaginationProps<T>): ReactElement => {
  const { t } = useTranslation();

  const {
    state: { pageIndex },
    gotoPage,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageOptions
  } = instance;

  return (
    <PaginationWrapper>
      <ButtonsActionsWrapper>
        <ButtonIconSecondary
          iconName='arrow-left'
          onClick={() => gotoPage(0)}
          iconSize={24}
          disabled={!canPreviousPage}
        />
        <ButtonIconSecondary
          iconName='chevron-left'
          onClick={() => previousPage()}
          iconSize={24}
          disabled={!canPreviousPage}
        />
        <ButtonIconSecondary
          iconName='chevron-right'
          onClick={() => nextPage()}
          iconSize={24}
          disabled={!canNextPage}
        />
        <ButtonIconSecondary
          iconName='arrow-right'
          onClick={() => gotoPage(pageCount - 1)}
          iconSize={24}
          disabled={!canNextPage}
        />
      </ButtonsActionsWrapper>
      <TotalPages>
        {pageIndex + 1} - {pageOptions.length}
      </TotalPages>
      <CustomInput
        label={`${t('table.pagination.goToPage')}:`}
        type='number'
        direction='row'
        defaultValue={pageIndex + 1}
        onChange={(e) => {
          const page = Number(e.target.value) - 1;
          gotoPage(page);
        }}
      />
    </PaginationWrapper>
  );
};

export { TablePagination };
