import edgeTheme from './theme/EDGE/theme';
import { Theme } from './theme/models';
import scrmTheme from './theme/SCRM/theme';
import smoTheme from './theme/SMO/theme';

export type ThemeType = 'SMO' | 'EDGE' | 'SCRM';

const getTheme = (name: ThemeType): Theme => {
  const themesDictionary = {
    SMO: smoTheme,
    EDGE: edgeTheme,
    SCRM: scrmTheme
  } as Record<ThemeType, Theme>;

  return themesDictionary[name];
};

export { getTheme };
