import { UserResponse } from '@domain/user-profile';
import { USER_QUERY_KEY } from '@domain/user-profile/userProfileKeys';
import {
  getSearchActiveProfiles,
  getSearchProfiles
} from '@services/user-profile/userProfileService';
import { useQuery, UseQueryResult } from 'react-query';

const useSearchProfiles = (
  name: string,
  suspense = true,
  enabled = false
): UseQueryResult<UserResponse[], Error> =>
  useQuery<UserResponse[], Error>(
    [...USER_QUERY_KEY(name)],
    () => getSearchProfiles(name),
    {
      suspense,
      enabled
    }
  );

const useSearchActiveProfiles = (
  name: string,
  suspense = true,
  enabled = false
): UseQueryResult<UserResponse[], Error> =>
  useQuery<UserResponse[], Error>(
    [...USER_QUERY_KEY(name)],
    () => getSearchActiveProfiles(name),
    {
      suspense,
      enabled
    }
  );

export { useSearchProfiles, useSearchActiveProfiles };
