import { Icon } from '@app/shared/components';
import {
  FileUploader,
  FileUploadErrorMessage,
  FileUploaderSummaryChooseImage,
  FileUploaderSummaryConditions,
  FileUploaderSummaryContainer
} from '@app/shared/components/file-uploader';
import React from 'react';
import { useTranslation } from 'react-i18next';

type PrivacyPolicyFileUploadProps = {
  fileName?: string;
  error?: Error;
};

export const PrivacyPolicyFileUpload: React.FC<PrivacyPolicyFileUploadProps> = ({
  fileName,
  error
}: PrivacyPolicyFileUploadProps) => {
  const { t } = useTranslation('privacy-policy');

  return (
    <FileUploader role='button'>
      <Icon iconName={fileName ? 'edit' : 'archive'} iconSize={18} />
      <FileUploaderSummaryContainer>
        <FileUploaderSummaryChooseImage>
          {fileName
            ? t('fieldNames.pdfFileSelected', { name: fileName })
            : t('fieldNames.pdfFile')}
        </FileUploaderSummaryChooseImage>
        {!fileName && (
          <FileUploaderSummaryConditions>
            {t('fieldNames.pdfFileConditions')}
          </FileUploaderSummaryConditions>
        )}
        {error?.message && (
          <FileUploadErrorMessage>{error?.message}</FileUploadErrorMessage>
        )}
      </FileUploaderSummaryContainer>
    </FileUploader>
  );
};
