import { pxToRem, rem } from '@styles/utils/sizes';

export type SizeHeadline = 'XXXL' | 'XXL' | 'XL' | 'L' | 'M' | 'S' | 'XS';
export type SizeText = 'L' | 'M' | 'S' | 'XS';

type FontTypeHeadlineSize = {
  XXXL: number;
  XXL: number;
  XL: number;
  L: number;
  M: number;
  S: number;
  XS: number;
};

type FontTypeTextSize = {
  L: number;
  M: number;
  S: number;
  XS: number;
};

type FontType = {
  headline: FontTypeHeadlineSize;
  lineHeightHeadline: FontTypeHeadlineSize;
  text: FontTypeTextSize;
  lineHeightText: FontTypeTextSize;
};

export type Font = {
  regularFont: string;
  mediumFont: string;
  semiBoldFont: string;
  boldFont: string;
  getHeadlinesConfig: (size: SizeHeadline) => string;
  getTextsConfig: (size: SizeText) => string;
};

const createFont = (
  regularFont: string,
  mediumFont: string,
  semiBoldFont: string,
  boldFont: string,
  fontSize: FontType
): Font => ({
  regularFont,
  mediumFont,
  semiBoldFont,
  boldFont,
  getHeadlinesConfig: (size: SizeHeadline): string => `
            font-family: '${semiBoldFont}';
            font-size: ${rem(pxToRem(fontSize.headline[size]))};
            line-height: ${rem(pxToRem(fontSize.lineHeightHeadline[size]))};
        `,
  getTextsConfig: (size: SizeText): string => `
            font-family: '${regularFont}';
            font-size: ${rem(pxToRem(fontSize.text[size]))};
            line-height: ${rem(pxToRem(fontSize.lineHeightText[size]))};
        `
});

export { createFont };
