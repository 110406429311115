import { FloorplanWorkplace } from '@app/facility-management/floorplans/floorplan-settings/shared/models/floorplanWorkplace';
import {
  WorkplaceFormModel,
  WorkstationFormModel
} from '@domain/workplaces/models/create/workplaceFormModel';
import {
  WorkplacePlaceType,
  workplacesDefinition,
  WorkStationType
} from '@domain/workplaces/models/workplace';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FacilitiesIconList } from '../shared/FacilitiesIconList';
import {
  Column,
  Section,
  WorkplaceTypeList
} from '../shared/WorkplaceTypeList';

interface WorkstationsListProps {
  workstations: FloorplanWorkplace<WorkstationFormModel>;
  selectedWorkplacesIds: string[];
  onSelectionChange: (entityId: string, isSelected: boolean) => void;
  onEdit: (entityId: string) => void;
  onDelete: (...entityId: string[]) => void;
  onEditEntitiesProperties: (
    properties: Partial<WorkplaceFormModel>,
    ...entityIds: string[]
  ) => void;
}

const WorkstationsList = ({
  workstations,
  selectedWorkplacesIds,
  onSelectionChange,
  onEdit,
  onDelete,
  onEditEntitiesProperties
}: WorkstationsListProps): ReactElement => {
  const {
    t,
    i18n: { language }
  } = useTranslation('workplaces');

  const sections = useMemo(
    () =>
      [
        {
          title: t('station.flexDesk_plural'),
          filter: (item: WorkstationFormModel) =>
            item.workstationtype === WorkStationType.FlexDesk.toString()
        },
        {
          title: t('station.fixedDesk_plural'),
          filter: (item: WorkstationFormModel) =>
            item.workstationtype === WorkStationType.FixedDesk.toString()
        }
      ] as Section[],
    [language]
  );

  const columns = useMemo(
    () =>
      [
        {
          header: t('fieldNames.label'),
          value: (item) => item.label
        },
        {
          header: t('fieldNames.bookedUser'),
          show: (item: WorkstationFormModel) =>
            item.workstationtype === WorkStationType.FixedDesk.toString(),
          value: (item: WorkstationFormModel) => item.bookedUserName
        },
        {
          header: t('fieldNames.facilities'),
          value: (item: WorkstationFormModel) => (
            <FacilitiesIconList
              facilities={
                workplacesDefinition[
                  WorkplacePlaceType.Workstation
                ].facilities?.filter((f) => f.filter(item)) ?? []
              }
            />
          )
        }
      ] as Column[],
    [language]
  );

  return (
    <WorkplaceTypeList
      title={t('workplaceSites.workstation_plural')}
      placeType={WorkplacePlaceType.Workstation}
      workplaces={workstations}
      selectedWorkplacesIds={selectedWorkplacesIds}
      columns={columns}
      sections={sections}
      onSelectionChange={onSelectionChange}
      onEdit={onEdit}
      onDelete={onDelete}
      onEditEntitiesProperties={onEditEntitiesProperties}
    />
  );
};

export { WorkstationsList };
