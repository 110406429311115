import {
  PageToolbar,
  PageToolbarActions,
  Select
} from '@app/shared/components';
import { useGetIncidentTypes } from '@hooks/care-management';
import React, { ReactElement, useEffect } from 'react';

interface IncidentToolbarProps {
  incidentTypeId: string | undefined;
  onChangeIncidentType: (incidentTypeId: string | undefined) => void;
}

const IncidentToolbar = ({
  incidentTypeId,
  onChangeIncidentType
}: IncidentToolbarProps): ReactElement => {
  const { data: incidentTypes = [], isLoading } = useGetIncidentTypes();

  useEffect(() => {
    onChangeIncidentType(incidentTypes.first()?.id);
  }, [incidentTypes]);

  return (
    <PageToolbar>
      <PageToolbarActions>
        <Select
          items={incidentTypes ?? []}
          item={incidentTypes?.find((s) => s.id === incidentTypeId)}
          getItemLabel={(item) => item?.name ?? ''}
          getItemValue={(item) => item?.name}
          searchable={false}
          isLoading={isLoading}
          onChange={(item) => onChangeIncidentType(item?.id)}
        />
      </PageToolbarActions>
    </PageToolbar>
  );
};

export { IncidentToolbar };
