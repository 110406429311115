import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React from 'react';

import { Icon, IconWrapper } from '../icon';

export type CardTitleProps = {
  label: string;
  iconName: string;
};

const CardHeader = styled.header`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
      align-items: center;
      color: ${colors.primary.default};
      display: flex;
      margin-bottom: ${rem(pxToRem(8))};

      ${IconWrapper} {
        margin-right: ${rem(pxToRem(8))};
      }
    `}
`;

const CardTitleItem = styled.h3`
  ${({
    theme: {
      base: {
        fonts: { getHeadlinesConfig }
      }
    }
  }) => `
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        ${getHeadlinesConfig('S')};
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
    `}
`;

const CardTitle: React.FC<CardTitleProps> = ({
  label,
  iconName
}: CardTitleProps) => {
  return (
    <CardHeader>
      <Icon iconName={iconName} iconSize={32} />
      <CardTitleItem>{label}</CardTitleItem>
    </CardHeader>
  );
};

export { CardTitle, CardHeader };
