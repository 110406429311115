import styled from '@emotion/styled';

const MessageValidation = styled.span`
  ${({
    theme: {
      base: {
        fonts: { getTextsConfig },
        colors
      }
    }
  }) => `
        color: ${colors.system.error};
        ${getTextsConfig('S')};
        text-align: right;

        &:empty {
            padding: 0;
        }
    `}
`;

export { MessageValidation };
