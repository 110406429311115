import {
  Report,
  REPORTPARAM_QUERY_KEY,
  ReportParams,
  REPORTS_QUERY_KEY
} from '@domain/reports';
import {
  getAllReports,
  getParametersReports
} from '@services/reports/reportsService';
import { useQuery, UseQueryResult } from 'react-query';

const useGetAllReports = (): UseQueryResult<Report[], Error> =>
  useQuery<Report[], Error>([REPORTS_QUERY_KEY], getAllReports);

const useGetReportParams = (
  reportId: string | undefined
): UseQueryResult<ReportParams, Error> =>
  useQuery<ReportParams, Error>(
    REPORTPARAM_QUERY_KEY(reportId!),
    () => getParametersReports(reportId!),
    { enabled: !!reportId }
  );

export { useGetAllReports, useGetReportParams };
