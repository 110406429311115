import {
  PanelHeader,
  PanelTitle
} from '@app/shared/components/page/page-header/PanelHeader';
import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonIcon } from '../button-icon';
import { Suspense } from '../suspense';
import { ModalBase } from './ModalBase';

const ModalContentWrapper = styled.section`
  ${({
    theme: {
      base: { colors, layout }
    }
  }) => `
    animation-duration: 0.3s;
    animation-name: translateX;
    background-color: ${colors.neutral.fullLight};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100vh;
    min-width: ${rem(pxToRem(520))};
    overflow: hidden;
    padding: ${rem(pxToRem(32))} ${rem(pxToRem(20))} ${rem(pxToRem(40))} ${rem(
    pxToRem(40)
  )};
    position: fixed;
    right: 0;
    top: ${layout.header.height};

    @keyframes translateX {
      from {
        transform: translateX(100%);
      }
      to {
        transform: translateX(0);
      }
    }
  `}
`;

const ModalHeader = styled(PanelHeader)``;

const ModalHeaderTitle = styled(PanelTitle)``;

const ModalContent = styled.section`
  flex-grow: 1;
`;

interface ModalProps {
  onClose: () => void;
  title?: string;
}

const Modal: React.FC<ModalProps> = ({
  children,
  onClose,
  title
}: PropsWithChildren<ModalProps>) => {
  const { t } = useTranslation();

  return (
    <ModalBase onClose={onClose}>
      <ModalContentWrapper>
        <ModalHeader>
          {title && <ModalHeaderTitle>{title}</ModalHeaderTitle>}
          <ButtonIcon
            className='closePanel'
            iconName='cross_small'
            type='button'
            title={t('generics.close')}
            onClick={onClose}
          />
        </ModalHeader>
        <ModalContent>
          <Suspense>{children}</Suspense>
        </ModalContent>
      </ModalContentWrapper>
    </ModalBase>
  );
};

export { Modal };
