import { DailyBooking } from '@domain/bookings';
import { InfoEntity } from '@domain/floorplans';
import {
  WorkplaceEntity,
  WorkplaceFormModel
} from '@domain/workplaces/models/create/workplaceFormModel';
import {
  WorkplaceDefinition,
  WorkplacePlaceType,
  workplacesDefinition
} from '@domain/workplaces/models/workplace';
import { Colors } from '@styles/theme/models';
import { getHexColorWithOpacity } from '@styles/utils/color';
import {
  Entity,
  Vector3,
  waveCommands,
  waveTools
} from '@wave-engine/wave-viewer';

const OPACITY = 0.8;

const hasEntityDailyBookings = (
  entityId: string,
  dailyBookings: DailyBooking[] = []
): boolean => {
  const workstation = dailyBookings
    .flatMap((b) => b.spaceWorkStations)
    .find((w) => w.waveId === entityId);

  return (workstation?.bookings.length ?? 0) > 0;
};

const hasEntityActiveBookings = (
  entityId: string,
  infoEntities: InfoEntity[] = []
): boolean => {
  const spaceEntity = infoEntities.find((e) => e.waveId === entityId);

  return spaceEntity?.hasActiveBookings ?? false;
};

const getEntityColor = (
  entity: Pick<Entity, 'properties'>,
  colors: Colors,
  hasBookings: boolean
): string => {
  let color = colors.workplaces.free;
  const properties = waveTools.forms.buildFormFromEntityProperies<
    WorkplaceFormModel
  >(entity.properties);

  if (!properties.enabled) {
    color = colors.workplaces.disabled;
  } else if (properties.lockSite) {
    color = colors.workplaces.locked;
  } else if (hasBookings) {
    color = colors.workplaces.reserved;
  }

  return getHexColorWithOpacity(color, OPACITY);
};

const DEFAULT_SCALE: Vector3 = { x: 1, y: 0.1, z: 1 };

const getEntityScale = (
  entity: Pick<Entity, 'properties' | 'scale'>
): Vector3 => {
  const properties = waveTools.forms.buildFormFromEntityProperies<
    WorkplaceFormModel
  >(entity.properties);
  return {
    ...entity.scale,
    y:
      workplacesDefinition[
        (+properties.type ||
          WorkplacePlaceType.Workstation) as WorkplacePlaceType
      ].scaleY
  };
};

const getEntityPushpin = (
  entity: Pick<Entity, 'properties'>
): number | undefined => {
  const properties = waveTools.forms.buildFormFromEntityProperies<
    WorkplaceFormModel
  >(entity.properties);

  const definition = workplacesDefinition[
    (+properties.type || WorkplacePlaceType.Workstation) as WorkplacePlaceType
  ] as WorkplaceDefinition<WorkplaceEntity>;

  const type = definition.subTypes
    ? Object.values(definition.subTypes).find((i) =>
        i.filter(properties as WorkplaceEntity)
      )
    : definition;

  return type?.pushpinId;
};

const updateEntity = (
  entityId: string,
  properties: Partial<{ [key: string]: string }>,
  colors: Colors,
  hasBookings: boolean
): void => {
  waveCommands.state.setPartialEntityProperties(entityId, properties);

  const entity = waveCommands.state.getEntity(entityId);
  if (entity) {
    const color = getEntityColor(entity, colors, hasBookings);
    const pushpinId = getEntityPushpin(entity);
    const scale = getEntityScale(entity);
    if (
      color !== entity.color ||
      pushpinId !== entity.pushpinId ||
      scale?.x !== entity.scale.x ||
      scale?.y !== entity.scale.y ||
      scale?.z !== entity.scale.z
    ) {
      waveCommands.state.setMeshProperties(entity.id, {
        color,
        pushpinId,
        scale
      });
    }
  }
};

export {
  hasEntityDailyBookings,
  hasEntityActiveBookings,
  getEntityColor,
  updateEntity,
  getEntityScale,
  getEntityPushpin,
  DEFAULT_SCALE
};
