import {
  ButtonIcon,
  ButtonPrimary,
  ButtonsContainer,
  ButtonSecondary,
  DetailsFormContent,
  FileBox,
  FormFooter,
  GridTemplate,
  Icon,
  IconWrapper,
  Input,
  Loader,
  Prompt
} from '@app/shared/components';
import {
  FloorplanDetailFormModel,
  floorplanDetailFormSchema,
  FloorplanResponse
} from '@domain/floorplans';
import { FLOORPLAN_FILE_DXF_EXTENSION_ALLOWED } from '@domain/floorplans/models/shared/floorplanConstraints';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers';
import { useLoadBlobFromFile } from '@hooks/useLoadBlobFromFile';
import { useScreenshot } from '@hooks/useScreenshot';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import { downloadDxf, getFilenameExtension } from '@utils/files-utils';
import { useWaveEngineStore, WaveCanvas } from '@wave-engine/wave-viewer';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const WAVE_HEIGHT = 280;
const WAVE_WIDTH = 485;

const FileUploader = styled.div`
  ${({
    theme: {
      base: { colors, radius }
    }
  }) => `
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-bottom: ${rem(pxToRem(12))};

    ${IconWrapper} {
      align-items: center;
      background-color: ${getRgbaStrFromHexColor(colors.primary.default, 0.1)};
      border-radius: ${radius.circle};
      box-sizing: border-box;
      color: ${colors.primary.default};
      display: flex;
      font-size: ${rem(pxToRem(24))};
      height: ${rem(pxToRem(80))};
      justify-content: center;
      margin-right: ${rem(pxToRem(12))};
      padding: ${rem(pxToRem(15))};
      width: ${rem(pxToRem(80))};
    }
  `}
`;

const SummaryContainer = styled.figcaption``;

const SummaryChooseImage = styled.p`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getHeadlinesConfig, regularFont }
      }
    }
  }) => `
    color: ${colors.neutral.dark};
    ${getHeadlinesConfig('XS')};
    font-family: ${regularFont};
  `}
`;

const SummaryConditions = styled.p`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig }
      }
    }
  }) => `
    color: ${colors.neutral.grayDark};
    ${getTextsConfig('S')};
  `}
`;

const FileNameContainer = styled.div`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
    align-items: center;
    border-top: 1px solid ${colors.neutral.grayMedium};
    display: inline-flex;
    justify-content: flex-start;
    padding-top: ${rem(pxToRem(12))};
    width: 100%;

    > span {
      cursor: pointer;
    }
  `}
`;

const FileName = styled.p`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig }
      }
    }
  }) => `
    box-sizing: border-box;
    color: ${colors.primary.darkest};
    cursor: pointer;
    ${getTextsConfig('M')};
    max-width: ${rem(pxToRem(400))};
    overflow: hidden;
    margin-right: ${rem(pxToRem(16))};
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
`;

const ErrorMessage = styled.p`
  ${({
    theme: {
      base: {
        fonts: { getTextsConfig },
        colors
      }
    }
  }) => `
    color: ${colors.system.error};
    ${getTextsConfig('S')};
  `}
`;

const WaveContainer = styled.div`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
    margin-top: ${rem(pxToRem(32))};
    position: relative;
    text-align: center;
    padding: ${rem(pxToRem(10))};
    border: 1px solid ${colors.neutral.gray};
    margin-bottom: ${rem(pxToRem(8))};
    height: ${rem(pxToRem(WAVE_HEIGHT + 20))};
    width:${rem(pxToRem(WAVE_WIDTH + 20))};
  `}
`;

const WavePreviewText = styled.p`
  ${({
    theme: {
      base: {
        fonts: { getTextsConfig },
        colors
      }
    }
  }) => `
    color: ${colors.neutral.grayDark};
    ${getTextsConfig('S')};
  `}
`;

interface FloorplansDetailFormProps {
  initialValues?: FloorplanResponse;
  onSubmit: (floorplan: FloorplanDetailFormModel) => Promise<void>;
  onCancel: () => void;
}

const FloorplansDetailForm: React.FC<FloorplansDetailFormProps> = ({
  initialValues,
  onSubmit,
  onCancel
}: FloorplansDetailFormProps) => {
  const { t } = useTranslation('floorplans');
  const isDXFLoaded = useWaveEngineStore((s) => s.isDXFLoaded);

  const [dxfFile, setDxfFile] = useState<File | null>(null);

  const [loading, blob] = useLoadBlobFromFile(dxfFile);
  const { getScreenshot } = useScreenshot();

  const {
    base: { colors }
  } = useTheme();

  const { register, handleSubmit, errors, formState, setValue } = useForm<
    FloorplanDetailFormModel
  >({
    resolver: yupResolver(floorplanDetailFormSchema),
    mode: 'all',
    defaultValues: {
      ...initialValues,
      dxfFileUrl: initialValues?.planeModelUrl
    }
  });

  const onFormSubmit = async (
    form: FloorplanDetailFormModel
  ): Promise<void> => {
    if (dxfFile) {
      const file = await getScreenshot({
        imageName: `floorplan-${dxfFile?.name}`
      });
      if (file) {
        await onSubmit({ ...form, imageModelFile: file });
      }
    }
  };

  const removeFile = (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event?.stopPropagation();

    setDxfFile(null);
    setValue('dxfFileUrl', undefined, { shouldValidate: true });
  };

  const onUploadFiles = async (files: File[]): Promise<void> => {
    if (files && files.length) {
      const fileExtension = getFilenameExtension(files[0].name);

      if (
        FLOORPLAN_FILE_DXF_EXTENSION_ALLOWED.some(
          (ext) => ext === fileExtension.toLowerCase()
        )
      ) {
        setDxfFile(files[0]);
        setValue('dxfFileUrl', files[0].name, { shouldValidate: true });
      }
    }
  };

  useEffect(() => {
    if (initialValues?.planeModelUrl) {
      const loadDxf = async (): Promise<void> => {
        const file = await downloadDxf({ url: initialValues.planeModelUrl });
        setDxfFile(file);
      };
      loadDxf();
    }
  }, []);

  const showLoader = loading || (!isDXFLoaded && Boolean(blob));

  return (
    <Prompt
      hasChanges={
        formState.isDirty &&
        !formState.isSubmitting &&
        !formState.isSubmitSuccessful
      }>
      <form onSubmit={handleSubmit(onFormSubmit)} autoComplete='off'>
        <input name='dxfFileUrl' type='hidden' ref={register} />
        <DetailsFormContent>
          {showLoader && <Loader />}
          <GridTemplate>
            <Input
              label={t('fieldNames.name')}
              name='name'
              type='text'
              error={errors?.name}
              innerRef={register}
            />
            <FileBox
              name='dxfFile'
              accept={FLOORPLAN_FILE_DXF_EXTENSION_ALLOWED.join(',')}
              innerRef={register}
              onUploadFiles={onUploadFiles}>
              <FileUploader role='button'>
                <Icon iconName='file-dxf' iconSize={18} />
                <SummaryContainer>
                  <SummaryChooseImage>
                    {t('fieldNames.dxfFile')}
                  </SummaryChooseImage>
                  <SummaryConditions>
                    {t('fieldNames.dxfFileConditions')}
                  </SummaryConditions>
                  {errors.dxfFileUrl && (
                    <ErrorMessage>{errors.dxfFileUrl.message}</ErrorMessage>
                  )}
                </SummaryContainer>
              </FileUploader>
              {blob && (
                <>
                  <FileNameContainer>
                    <FileName>{dxfFile?.name}</FileName>
                    <ButtonIcon
                      iconName='trash'
                      iconSize={24}
                      onClick={removeFile}
                    />
                  </FileNameContainer>
                  <WaveContainer>
                    <WaveCanvas
                      width={WAVE_WIDTH}
                      height={WAVE_HEIGHT}
                      dxfModel={blob!}
                      isReadonly={true}
                      style={{
                        backgroundColor: colors.neutral.transparent,
                        manipulatorColor: colors.primary.default,
                        secondaryManipulatorColor: colors.primary.dark,
                        selectionOverlayColor: colors.primary.default,
                        overridedMapColor: colors.neutral.grayDark
                      }}
                    />
                  </WaveContainer>
                  <WavePreviewText>{t('preview')}</WavePreviewText>
                </>
              )}
            </FileBox>
          </GridTemplate>
        </DetailsFormContent>
        <FormFooter>
          <ButtonsContainer>
            <ButtonSecondary
              type='button'
              onClick={onCancel}
              disabled={formState.isSubmitting}
              label={t('shared:generics.cancel')}
            />
            <ButtonPrimary
              type='submit'
              disabled={formState.isSubmitting || !formState.isDirty}
              label={t('shared:generics.confirm')}
            />
          </ButtonsContainer>
        </FormFooter>
      </form>
    </Prompt>
  );
};

export default FloorplansDetailForm;
