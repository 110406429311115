import { useEffect, useState } from 'react';

const useLoadBlobFromFile = (
  file: File | null
): [boolean, Int8Array | null] => {
  const [loading, setLoading] = useState<boolean>(false);
  const [blob, setBlob] = useState<Int8Array | null>(null);

  useEffect(() => {
    const setArrayBuffer = async (file: File): Promise<void> => {
      const buffer = await file.arrayBuffer();
      setBlob(new Int8Array(buffer));
      setLoading(false);
    };

    if (file) {
      setLoading(true);
      setArrayBuffer(file);
    } else {
      setBlob(null);
    }
  }, [file]);

  return [loading, blob];
};

export { useLoadBlobFromFile };
