import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';

const UserListContainer = styled.div`
  margin-top: ${rem(pxToRem(32))};
  margin-bottom: ${rem(pxToRem(32))};
  margin-right: ${rem(pxToRem(20))};
  max-height: calc(
    100vh - ${rem(pxToRem(156))} - ${rem(pxToRem(72))} - ${rem(pxToRem(32))}
  );
  overflow-x: hidden;
  padding: 0 ${rem(pxToRem(20))} ${rem(pxToRem(20))} ${rem(pxToRem(40))};
`;

export { UserListContainer };
