import { ButtonIcon } from '@app/shared/components';
import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import { waveCommands } from '@wave-engine/wave-viewer';
import React, { ReactElement } from 'react';

import { PanelFloat } from './WavePanels';

const PanelFloatWrapper = styled.div`
  left: 50%;
  position: absolute;
  top: ${rem(pxToRem(4))};
  transform: translateX(-50%);
`;

type WaveStateActionsPanelProps = {
  onDelete?: () => void;
};

const WaveStateActionsPanel = ({
  onDelete
}: WaveStateActionsPanelProps): ReactElement => {
  return (
    <PanelFloatWrapper>
      <PanelFloat>
        <ButtonIcon
          iconName='duplicate'
          onClick={() => waveCommands.state.duplicateShape()}
        />
        <ButtonIcon iconName='trash32' onClick={() => onDelete?.()} />
        <ButtonIcon iconName='undo' onClick={() => waveCommands.state.undo()} />
        <ButtonIcon iconName='redo' onClick={() => waveCommands.state.redo()} />
      </PanelFloat>
    </PanelFloatWrapper>
  );
};

export { WaveStateActionsPanel };
