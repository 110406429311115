import {
  ButtonPrimary,
  ButtonsContainer,
  ButtonSecondary,
  DetailsFormContent,
  FileBox,
  FormFooter,
  GridTemplate,
  Input,
  Prompt,
  Select
} from '@app/shared/components';
import {
  Building,
  BuildingDetailFormModel,
  buildingDetailFormSchema,
  UpdateBuilding
} from '@domain/buildings';
import { ACCEPTED_IMAGE_TYPES } from '@domain/buildings/models/shared/buildingConstraints';
import { Venue } from '@domain/venues';
import { yupResolver } from '@hookform/resolvers';
import React, { ReactElement } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BuildingFileUpload } from './BuildingFileUpload';

interface BuildingsDetailFormProps {
  initialValues?: Partial<Building>;
  venues: Venue[];
  onSubmit: (building: UpdateBuilding) => Promise<void>;
  onCancel: () => void;
}

const BuildingsDetailForm = ({
  initialValues,
  onSubmit,
  onCancel,
  venues
}: BuildingsDetailFormProps): ReactElement => {
  const { t } = useTranslation('buildings');

  const { control, register, handleSubmit, errors, formState } = useForm<
    BuildingDetailFormModel
  >({
    resolver: yupResolver(buildingDetailFormSchema),
    mode: 'all',
    defaultValues: initialValues
  });

  return (
    <Prompt
      hasChanges={
        formState.isDirty &&
        !formState.isSubmitting &&
        !formState.isSubmitSuccessful
      }>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
        <input name='imageUrl' type='hidden' ref={register} />
        <DetailsFormContent>
          <GridTemplate>
            <Controller
              render={({ value, onChange }) => (
                <Select
                  label={t('fieldNames.venue')}
                  items={venues ?? []}
                  item={venues?.find((s) => s.id === value)}
                  getItemLabel={(item) =>
                    item ? item?.name : t('fieldNames.venuePlaceholder')
                  }
                  getItemValue={(item) => item?.id}
                  searchable={false}
                  error={errors?.venueId}
                  onChange={(item) => onChange(item?.id)}
                />
              )}
              control={control}
              name='venueId'
              defaultValue={initialValues?.venueId || ''}
            />
            <Input
              label={t('fieldNames.name')}
              placeholder={t('fieldNames.namePlaceholder')}
              name='name'
              type='text'
              error={errors?.name}
              innerRef={register}
            />
            <GridTemplate columns={2}>
              <Input
                label={t('fieldNames.address')}
                placeholder={t('fieldNames.addressPlaceholder')}
                name='address'
                type='text'
                error={errors?.address}
                innerRef={register}
              />
              <Input
                label={t('fieldNames.city')}
                placeholder={t('fieldNames.cityPlaceholder')}
                name='city'
                type='text'
                error={errors?.city}
                innerRef={register}
              />
              <Input
                label={t('fieldNames.capacity')}
                placeholder={t('fieldNames.capacityPlaceholder')}
                name='capacity'
                type='number'
                error={errors?.capacity}
                innerRef={register}
              />
              <Input
                label={t('fieldNames.limitedCapacity')}
                placeholder={t('fieldNames.limitedCapacityPlaceholder')}
                name='limitedCapacity'
                type='number'
                error={errors?.limitedCapacity}
                innerRef={register}
              />
            </GridTemplate>
            <FileBox
              name='image'
              accept={ACCEPTED_IMAGE_TYPES.join(',')}
              innerRef={register}>
              <BuildingFileUpload
                control={control}
                error={errors.image?.message}
              />
            </FileBox>
          </GridTemplate>
        </DetailsFormContent>
        <FormFooter>
          <ButtonsContainer>
            <ButtonSecondary
              type='button'
              onClick={onCancel}
              disabled={formState.isSubmitting}
              label={t('shared:generics.cancel')}
            />
            <ButtonPrimary
              type='submit'
              disabled={formState.isSubmitting || !formState.isDirty}
              label={t('shared:generics.save')}
            />
          </ButtonsContainer>
        </FormFooter>
      </form>
    </Prompt>
  );
};

export default BuildingsDetailForm;
