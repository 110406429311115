const convertHexadecimalToDecimal = (strNumber: string): number =>
  parseInt(strNumber, 16);

const getRedNumbers = (colorNumber: string): string => colorNumber.substr(0, 2);

const getGreenNumbers = (colorNumber: string): string =>
  colorNumber.substr(2, 2);

const getBlueNumbers = (colorNumber: string): string =>
  colorNumber.substr(4, 2);

const checkColorRgb = (color: string): boolean => {
  const colorRgbRegex = new RegExp('^#?([A-Fa-f0-9]{6})$');
  return colorRgbRegex.test(color);
};

const getNumbersFromColor = (color: string): string => color.replace('#', '');

const getRgbaStrFromHexColor = (color: string, opacity: number): string => {
  if (!checkColorRgb(color))
    throw new Error('colors are not in correct format (#546655 | 546655)');

  const colorNumbers = getNumbersFromColor(color);

  const r = convertHexadecimalToDecimal(getRedNumbers(colorNumbers));
  const g = convertHexadecimalToDecimal(getGreenNumbers(colorNumbers));
  const b = convertHexadecimalToDecimal(getBlueNumbers(colorNumbers));

  return `rgba(${r},${g},${b}, ${opacity})`;
};

const getHexColorWithOpacity = (color: string, opacity: number): string => {
  if (!checkColorRgb(color))
    throw new Error('colors are not in correct format (#546655 | 546655)');

  const hexOpacity = Math.floor(opacity * 255).toString(16);
  return `${color}${hexOpacity}`;
};

export { getRgbaStrFromHexColor, getHexColorWithOpacity };
