import { Form } from '@app/shared/components';
import { FloorplanDetailFormModel } from '@domain/floorplans';
import { useCreateFloorplan } from '@hooks/floorplans/useCreateFloorplan';
import React, { ReactElement, useEffect } from 'react';

import FloorplansDetailForm from '../shared/floorplans-detail-form/FloorplansDetailForm';

interface FloorplansCreateFormProps {
  venueId: string;
  buildingId: string;
  onCreateFloorplan: () => void;
  onCancel: () => void;
}

const FloorplansCreateForm = ({
  venueId,
  buildingId,
  onCreateFloorplan,
  onCancel
}: FloorplansCreateFormProps): ReactElement => {
  const { mutateAsync: createFloorplan, isSuccess } = useCreateFloorplan(
    venueId,
    buildingId
  );

  useEffect(() => {
    if (isSuccess) {
      onCreateFloorplan();
    }
  }, [isSuccess]);

  const onSubmit = (floorplant: FloorplanDetailFormModel): Promise<void> =>
    createFloorplan({
      ...floorplant,
      planModelFile: floorplant.dxfFile[0],
      buildingId
    });

  return (
    <Form>
      <FloorplansDetailForm onSubmit={onSubmit} onCancel={onCancel} />
    </Form>
  );
};

export default FloorplansCreateForm;
