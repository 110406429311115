import { Location } from '@app/shared/routes';
import { RouteHelper } from '@utils/helpers/routeHelper';

export interface VenuesLocationParams {
  venueId?: string;
}

const VenuesLocation = new Location<VenuesLocationParams>({
  path: '/facility-management/venues/:venueId?',
  pathParamDefs: {
    venueId: RouteHelper.stringOptionalParamValidation()
  }
});

export { VenuesLocation };
