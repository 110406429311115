export enum EmployeeTypeEnum {
  Internal = 1,
  External
}

export const employeeTypes = Object.keys(EmployeeTypeEnum)
  .filter((v) => isNaN(Number(v)))
  .map((name) => ({
    id: EmployeeTypeEnum[name as keyof typeof EmployeeTypeEnum],
    name
  }));
