import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

const useUpdateEffect = (
  effect: EffectCallback,
  deps: DependencyList = []
): void => {
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    return effect();
  }, deps);
};

export { useUpdateEffect };
