const FLOORPLAN_CONSTRAINTS = {
  NAME: {
    MIN_LENGTH: 1,
    MAX_LENGTH: 50
  }
};

const FLOORPLAN_FILE_DXF_EXTENSION_ALLOWED = ['.dxf'];

const MAX_FILE_DXF_SIZE_BYTES = 12000 * 1024;

export {
  FLOORPLAN_CONSTRAINTS,
  FLOORPLAN_FILE_DXF_EXTENSION_ALLOWED,
  MAX_FILE_DXF_SIZE_BYTES
};
