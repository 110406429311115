import { NotFoundRoute } from '@app/errors';
import React, { ReactElement } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { FacilityManagementLocation } from '.';
import { FloorplansLocation } from './floorplans';
import FloorplanBookings from './floorplans/floorplan-bookings/FloorplanBookings';
import { FloorplanBookingsLocation } from './floorplans/floorplan-bookings/routes/floorplanBookingsLocation';
import FloorplanSettings from './floorplans/floorplan-settings/FloorplanSettings';
import { FloorplanSettingsLocation } from './floorplans/floorplan-settings/routes/floorplanSettingsLocation';
import Floorplans from './floorplans/Floorplans';
import { VenuesLocation } from './venues';
import Venues from './venues/Venues';

const FacilityManagement = (): ReactElement => {
  return (
    <Switch>
      {FacilityManagementLocation.toRoute({
        exact: true,
        render: () => <Redirect to={VenuesLocation.path} />
      })}
      {FloorplanBookingsLocation.toRoute({
        component: FloorplanBookings
      })}
      {FloorplanSettingsLocation.toRoute({
        component: FloorplanSettings
      })}
      {FloorplansLocation.toRoute({
        component: Floorplans
      })}
      {VenuesLocation.toRoute({
        component: Venues
      })}
      <NotFoundRoute />
    </Switch>
  );
};

export default FacilityManagement;
