import { Building, BUILDINGS_QUERY_KEY } from '@domain/buildings';
import { getBuildingsByVenueId } from '@services/buildings/buildingsService';
import { useQuery, UseQueryResult } from 'react-query';

type UseGetBuildingsByVenueIdParams = {
  archived: boolean;
  unpublished: boolean;
  closed: boolean;
};

const useGetBuildingsByVenueId = (
  venueId?: string,
  searchParams: UseGetBuildingsByVenueIdParams = {
    archived: false,
    closed: false,
    unpublished: false
  }
): UseQueryResult<Building[], Error> =>
  useQuery<Building[], Error>(
    [...BUILDINGS_QUERY_KEY(venueId!), searchParams],
    () =>
      getBuildingsByVenueId(
        venueId!,
        searchParams.archived,
        searchParams.unpublished,
        searchParams.closed
      ),
    { enabled: !!venueId }
  );

export { useGetBuildingsByVenueId };
