/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useContext, useState } from 'react';

import { Dialog } from '../Dialog';
import {
  ActionDialogModel,
  DialogModel,
  ShowDialogCallbackType
} from '../shared';

interface DialogContextValue {
  showDialog: ShowDialogCallbackType;
}

interface DialogOptionsState extends DialogModel {}

export const DialogContext = React.createContext<DialogContextValue>({
  showDialog: () => Promise.resolve()
});

export const useDialog = (): DialogContextValue => useContext(DialogContext);

export const DialogProvider: React.FC<unknown> = ({ children }) => {
  const [options, setOptions] = useState<DialogOptionsState | null>(null);

  const hideDialog = () => {
    setOptions(options ? { ...options, open: false } : null);
  };

  const executeCallback = (
    callback: Function | undefined,
    resolve: (value?: void | PromiseLike<void> | undefined) => void
  ) => {
    callback ? resolve(callback()) : resolve();
    return hideDialog();
  };

  const configureAction = (
    action: ActionDialogModel | undefined,
    resolve: (value?: void | PromiseLike<void> | undefined) => void
  ) => {
    return action
      ? {
          callback: () => executeCallback(action.callback, resolve)
        }
      : { callback: hideDialog };
  };

  const showDialog = (options: DialogModel): Promise<void> => {
    return new Promise((resolve) => {
      if (options.open) {
        setOptions({
          ...options,
          onConfirm: configureAction(options.onConfirm, resolve),
          onCancel: configureAction(options.onCancel, resolve)
        });
      } else {
        return executeCallback(options.onConfirm?.callback, resolve);
      }
    });
  };

  const value = { showDialog };

  return (
    <DialogContext.Provider value={value}>
      {children}
      {options && <Dialog {...options} />}
    </DialogContext.Provider>
  );
};
