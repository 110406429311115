import { getLocale } from '@services/i18n/date';
import addMinutes from 'date-fns/addMinutes';
import addWeeks from 'date-fns/addWeeks';
import eachWeekendOfMonth from 'date-fns/eachWeekendOfMonth';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isEqual from 'date-fns/isEqual';
import isToday from 'date-fns/isToday';
import isWeekend from 'date-fns/isWeekend';
import isWithinInterval from 'date-fns/isWithinInterval';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import set from 'date-fns/set';
import startOfWeek from 'date-fns/startOfWeek';

declare global {
  interface Date {
    localeDateString(language?: string): string;
    localeDateLongString(language?: string): string;
    timeString(): string;
    firstDayOfMonth(): Date;
    lastDayOfMonth(): Date;
    withoutTime(): Date;
    isToday(): boolean;
    isBefore(dateCompare: Date | undefined): boolean;
    isAfter(dateCompare: Date | undefined): boolean;
    isEqual(dateCompare: Date): boolean;
    isWeekend(): boolean;
    isWithinInterval(dateCompare1: Date, dateCompare2: Date): boolean;
    addMinutes(minutes: number): Date;
    addWeeks(weeks: number): Date;
    toDateTime(time: string | undefined): Date | undefined;
    eachWeekendOfMonth(): Date[];
    startOfWeek(): Date;
    nextWorkdate(): Date;
    toDateTimezone(): Date;
  }
}

// HACK: toISOString override for nswag (service.ts)
Date.prototype.toISOString = function () {
  const pad = (number: number): unknown => {
    return number < 10 ? '0' + number : number;
  };

  this.setHours(0, 0, 0, 0);
  const newDate = this.toDateTimezone();

  return (
    newDate.getUTCFullYear() +
    '-' +
    pad(newDate.getUTCMonth() + 1) +
    '-' +
    pad(newDate.getUTCDate()) +
    'T' +
    pad(newDate.getUTCHours()) +
    ':' +
    pad(newDate.getUTCMinutes()) +
    ':' +
    pad(newDate.getUTCSeconds()) +
    '.' +
    (newDate.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) +
    'Z'
  );
};

if (!Date.prototype.localeDateString) {
  Date.prototype.localeDateString = function (language?: string) {
    return format(this, 'P', { locale: getLocale(language) });
  };
}

if (!Date.prototype.localeDateLongString) {
  Date.prototype.localeDateLongString = function (language?: string) {
    return format(this, 'PP', { locale: getLocale(language) });
  };
}

if (!Date.prototype.timeString) {
  Date.prototype.timeString = function () {
    return format(this, 'kk:mm');
  };
}

if (!Date.prototype.withoutTime) {
  Date.prototype.withoutTime = function () {
    return set(this, {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0
    });
  };
}

if (!Date.prototype.firstDayOfMonth) {
  Date.prototype.firstDayOfMonth = function () {
    return set(this, { date: 1 });
  };
}

if (!Date.prototype.lastDayOfMonth) {
  Date.prototype.lastDayOfMonth = function () {
    return lastDayOfMonth(this);
  };
}

if (!Date.prototype.isToday) {
  Date.prototype.isToday = function () {
    return isToday(this);
  };
}

if (!Date.prototype.isBefore) {
  Date.prototype.isBefore = function (dateCompare: Date | undefined) {
    return isBefore(this, dateCompare || new Date());
  };
}

if (!Date.prototype.isAfter) {
  Date.prototype.isAfter = function (dateCompare: Date | undefined) {
    return isAfter(this, dateCompare || new Date());
  };
}

if (!Date.prototype.isEqual) {
  Date.prototype.isEqual = function (dateCompare: Date) {
    return isEqual(this, dateCompare);
  };
}

if (!Date.prototype.isWeekend) {
  Date.prototype.isWeekend = function () {
    return isWeekend(this);
  };
}

if (!Date.prototype.isWithinInterval) {
  Date.prototype.isWithinInterval = function (
    dateCompare1: Date,
    dateCompare2: Date
  ) {
    return isWithinInterval(this, { start: dateCompare1, end: dateCompare2 });
  };
}

if (!Date.prototype.addMinutes) {
  Date.prototype.addMinutes = function (minutes: number) {
    return addMinutes(this, minutes);
  };
}

if (!Date.prototype.addWeeks) {
  Date.prototype.addWeeks = function (weeks: number) {
    return addWeeks(this, weeks);
  };
}

if (!Date.prototype.toDateTime) {
  Date.prototype.toDateTime = function (
    time: string | undefined
  ): Date | undefined {
    const splitTime = time && time.split(':');
    return splitTime
      ? set(this, {
          hours: parseInt(splitTime[0]),
          minutes: parseInt(splitTime[1]),
          seconds: 0,
          milliseconds: 0
        })
      : undefined;
  };
}

if (!Date.prototype.eachWeekendOfMonth) {
  Date.prototype.eachWeekendOfMonth = function () {
    return eachWeekendOfMonth(this);
  };
}

if (!Date.prototype.startOfWeek) {
  Date.prototype.startOfWeek = function () {
    return startOfWeek(this, { weekStartsOn: 1 });
  };
}

if (!Date.prototype.nextWorkdate) {
  Date.prototype.nextWorkdate = function () {
    return this.isWeekend()
      ? set(this.addWeeks(1).startOfWeek(), {
          hours: this.getHours(),
          minutes: this.getMinutes(),
          seconds: this.getSeconds(),
          milliseconds: this.getMilliseconds()
        })
      : this;
  };
}

if (!Date.prototype.toDateTimezone) {
  Date.prototype.toDateTimezone = function () {
    return this.addMinutes(-this.getTimezoneOffset());
  };
}

export {};
