import styled from '@emotion/styled';
import React, { ReactElement } from 'react';

import { ButtonBase, ButtonPrimary } from '../../../button/Button';

const ButtonWrapper = styled.div`
  ${ButtonBase} {
    margin: 0 auto;
  }
`;

type ButtonCellProps = {
  label: string | undefined;
  onClick?: () => void;
  disabled?: boolean;
};

const ButtonCell = ({
  label = '',
  onClick,
  disabled = false
}: ButtonCellProps): ReactElement => (
  <ButtonWrapper>
    <ButtonPrimary
      type='button'
      label={label}
      onClick={onClick}
      disabled={disabled}
    />
  </ButtonWrapper>
);

export { ButtonCell };
