import { ButtonPrimary } from '@app/shared/components';
import { PageTitle } from '@app/shared/components/page/page-title/PageTitle';
import {
  PageToolbar,
  PageToolbarActions
} from '@app/shared/components/page/page-toolbar/PageToolbar';
import { Venue } from '@domain/venues';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { CreateBuildingFormLocation } from '../buildings-form/routes/buildingFormLocation';
import Filters from './filters/Filters';
import Venues from './venues/Venues';

interface BuildingsToolbarProps {
  venues: Venue[];
  venueId: string;
}

const BuildingsToolbar = ({
  venues,
  venueId
}: BuildingsToolbarProps): ReactElement => {
  const { t } = useTranslation('buildings');

  const history = useHistory();

  return (
    <PageToolbar>
      <PageTitle label={t(`shared:routes.facilities`)} />
      <Venues venues={venues} venueId={venueId} />
      <PageToolbarActions>
        <ButtonPrimary
          label={t('newBuilding')}
          onClick={() =>
            history.push(CreateBuildingFormLocation.toUrl({ venueId }))
          }
        />
        <Filters />
      </PageToolbarActions>
    </PageToolbar>
  );
};

export default BuildingsToolbar;
