import { CellProps, DateFilter, TableData } from '@app/shared/components';
import {
  ButtonCell,
  DateCell,
  StatusCell,
  UserCell
} from '@app/shared/components/table/cells';
import { IncidentResolveModel, IncidentSearch } from '@domain/care-management';
import i18n from '@services/i18n';
import React from 'react';

interface GetIncidentsTableColumnsProps {
  onResolved: (resolved: boolean, model: IncidentResolveModel) => void;
}

export const getIncidentsTableColumns = ({
  onResolved
}: GetIncidentsTableColumnsProps): TableData<IncidentSearch> => [
  {
    id: 'resolved',
    Header: i18n.t('care:careManagement.incidents.status') as string,
    accessor: 'resolved',
    Cell: StatusCell,
    width: 120,
    disableFilters: true,
    align: 'center'
  },
  {
    id: 'incidentDate',
    Header: i18n.t('care:careManagement.incidents.date') as string,
    accessor: 'registerDate',
    width: 120,
    filterOperator: 'eq',
    Filter: DateFilter,
    Cell: DateCell
  },
  {
    id: 'userName',
    Header: i18n.t('care:careManagement.incidents.name') as string,
    accessor: ({ userName, userImageUrl }) => ({
      userName,
      userImageUrl
    }),
    width: 220,
    Cell: UserCell
  },
  {
    id: 'productName',
    Header: i18n.t('care:careManagement.incidents.product') as string,
    accessor: 'productName'
  },
  {
    id: 'venueName',
    Header: i18n.t('care:careManagement.incidents.venue') as string,
    accessor: 'venueName'
  },
  {
    id: 'buildingName',
    Header: i18n.t('care:careManagement.incidents.building') as string,
    accessor: 'buildingName'
  },
  {
    id: 'floorName',
    Header: i18n.t('care:careManagement.incidents.floor') as string,
    accessor: 'floorName'
  },
  {
    id: 'place',
    Header: i18n.t('care:careManagement.incidents.place') as string,
    accessor: 'place'
  },
  {
    id: '_action',
    Header: '',
    accessor: ({ resolved, reportId, productId }) => ({
      resolved,
      reportId,
      productId
    }),
    Cell: ({
      value: { reportId, productId, resolved }
    }: CellProps<
      IncidentSearch,
      Pick<IncidentSearch, 'reportId' | 'productId' | 'resolved'>
    >) => (
      <ButtonCell
        label={
          resolved
            ? i18n.t('care:careManagement.incidents.action.notResolve')
            : i18n.t('care:careManagement.incidents.action.resolve')
        }
        onClick={() =>
          onResolved(resolved, { incidentId: reportId, productId })
        }
      />
    ),
    disableFilters: true,
    width: 140
  }
];
