import { PageTitle } from '@app/shared/components/page/page-title/PageTitle';
import { PageToolbar } from '@app/shared/components/page/page-toolbar/PageToolbar';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

const UserListToolbar = (): ReactElement => {
  const { t } = useTranslation('users');

  return (
    <PageToolbar>
      <PageTitle label={t('shared:routes.users')} />
    </PageToolbar>
  );
};

export { UserListToolbar };
