import create from 'zustand';

type BuildingsStoreActions = {
  setUnpublish: (isPublish: boolean) => void;
  setArchived: (isArchived: boolean) => void;
  setClosed: (isClosed: boolean) => void;
};

type Filters = {
  unpublish: boolean;
  archived: boolean;
  closed: boolean;
};

type BuildingsStore = {
  filter: Filters;
} & BuildingsStoreActions;

const useBuildings = create<BuildingsStore>((set) => ({
  filter: {
    publish: true,
    unpublish: true,
    archived: false,
    closed: false
  },
  setUnpublish: (isChecked: boolean) =>
    set(({ filter }) => ({
      filter: {
        ...filter,
        unpublish: isChecked
      }
    })),
  setArchived: (isArchived: boolean) =>
    set(({ filter }) => ({
      filter: {
        ...filter,
        archived: isArchived
      }
    })),
  setClosed: (isClosed: boolean) =>
    set(({ filter }) => ({
      filter: {
        ...filter,
        closed: isClosed
      }
    }))
}));

export { useBuildings };
