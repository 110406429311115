import { notifySuccess } from '@app/shared/notifications';
import { PrivacyPolicyModel } from '@domain/privacy-policy/models/create/createPrivacyPolicy';
import { PRIVACY_POLICY_QUERY_KEY } from '@domain/privacy-policy/privacyPolicyKeys';
import { UseMutation, useMutationWrapper } from '@hooks/useMutationWrapper';
import { createPrivacyPolicy } from '@services/privacy-policy/privacyPolicyService';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

const useCreatePrivacyPolicy = (): UseMutation<
  void,
  Error,
  PrivacyPolicyModel,
  unknown
> => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutationWrapper<void, Error, PrivacyPolicyModel>(
    (data) => createPrivacyPolicy(data),
    {
      onSuccess: () => {
        notifySuccess(t('shared:toast.operation-success'));

        queryClient.invalidateQueries(PRIVACY_POLICY_QUERY_KEY);
      }
    }
  );
};

export { useCreatePrivacyPolicy };
