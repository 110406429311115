import { IconWrapper } from '@app/shared/components';
import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';

const PanelHeader = styled.header`
  ${() => `
      align-items: center;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      margin-bottom: ${rem(pxToRem(32))};

      .closePanel {
          position: absolute;
          right: ${rem(pxToRem(8))};
          top: ${rem(pxToRem(8))};

          ${IconWrapper} {
              font-size: ${rem(pxToRem(24))};
          }
      }
  `}
`;

const PanelTitle = styled.h2`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getHeadlinesConfig, semiBoldFont }
      }
    }
  }) => `
    color: ${colors.primary.default};
    ${getHeadlinesConfig('S')};
    font-family: '${semiBoldFont}';
    text-align: center;
    width: 100%;
  `}
`;

const PanelSubTitle = styled.h3`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getHeadlinesConfig }
      }
    }
  }) => `
    color: ${colors.primary.default};
    ${getHeadlinesConfig('XS')};
    margin-bottom: ${rem(pxToRem(12))};
    text-align: left;
    width: 100%;
  `}
`;

export { PanelHeader, PanelTitle, PanelSubTitle };
