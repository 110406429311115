import { FloorplanWorkplace } from '@app/facility-management/floorplans/floorplan-settings/shared/models/floorplanWorkplace';
import {
  ButtonIcon,
  ButtonPrimary,
  ButtonsContainer,
  ButtonSecondary,
  DetailsFormContent,
  FilterButtons,
  FilterButtonsItem,
  FormFooter
} from '@app/shared/components';
import {
  PanelHeader,
  PanelTitle
} from '@app/shared/components/page/page-header/PanelHeader';
import { PanelDock } from '@app/shared/components/page/panel-dock/PanelDock';
import {
  WorkplaceFormModel,
  WorkstationFormModel
} from '@domain/workplaces/models/create/workplaceFormModel';
import { WorkplacePlaceType } from '@domain/workplaces/models/workplace';
import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MeetingRoomsList } from './meeting-rooms-list/MeetingRoomsList';
import { WorkstationsList } from './workstations-list/WorkstationsList';

const TabFilterButtons = styled(FilterButtons)`
  margin-bottom: ${rem(pxToRem(32))};
`;

interface WorkplaceTableProps {
  workplaces?: FloorplanWorkplace;
  selectedWorkplacesIds: string[];
  isUpdating: boolean;
  onSelectionChange: (entityId: string, isSelected: boolean) => void;
  onEdit: (entityId: string) => void;
  onDelete: (entityId: string) => void;
  onClose: () => void;
  onSave: () => void;
  onEditEntitiesProperties: (
    properties: Partial<WorkplaceFormModel>,
    ...entityIds: string[]
  ) => void;
}

type Tab = FilterButtonsItem & {
  id: WorkplacePlaceType;
};

const WorkplacesList = ({
  workplaces,
  selectedWorkplacesIds,
  isUpdating,
  onSelectionChange,
  onEdit,
  onDelete,
  onClose,
  onSave,
  onEditEntitiesProperties
}: WorkplaceTableProps): ReactElement => {
  const {
    t,
    i18n: { language }
  } = useTranslation('workplaces');
  const [selectedTabId, setSelectedTabId] = useState<WorkplacePlaceType>(
    WorkplacePlaceType.Workstation
  );

  const tabs = useMemo(() => {
    const def = [
      {
        id: WorkplacePlaceType.Workstation,
        label: t('workplaceSites.workstation_plural')
      }
      // ,
      // {
      //   id: WorkplacePlaceType.MeetingRoom,
      //   label: t('workplaceSites.meetingRoom_plural')
      // },
      // {
      //   id: WorkplacePlaceType.CommonArea,
      //   label: t('workplaceSites.commonArea_plural')
      // }
    ] as Tab[];

    return def.map((t) =>
      t.id === selectedTabId ? { ...t, active: true } : t
    );
  }, [language, selectedTabId]);

  const tabWorkplaces = useMemo(
    () =>
      workplaces
        ? Object.entries(workplaces).reduce((prevValue, [key, value]) => {
            if (
              (value.model.type ||
                WorkplacePlaceType.Workstation.toString()) ===
              selectedTabId.toString()
            ) {
              prevValue[key] = value;
            }
            return prevValue;
          }, {} as FloorplanWorkplace)
        : {},
    [selectedTabId, workplaces]
  );

  const tabSelectedItems = useMemo(
    () => selectedWorkplacesIds.filter((id) => id in tabWorkplaces),
    [tabWorkplaces, selectedWorkplacesIds]
  );

  return (
    <PanelDock>
      <PanelHeader>
        <PanelTitle>{t('settings.title')}</PanelTitle>
        <ButtonIcon
          className='closePanel'
          iconName='cross_small'
          type='button'
          title={t('shared:generics.close')}
          onClick={onClose}
        />
      </PanelHeader>
      <TabFilterButtons
        buttons={tabs}
        onSelectionChange={(index) => setSelectedTabId(tabs[index]?.id)}
      />
      <DetailsFormContent>
        {workplaces ? (
          selectedTabId === WorkplacePlaceType.Workstation ? (
            <WorkstationsList
              workstations={
                tabWorkplaces as FloorplanWorkplace<WorkstationFormModel>
              }
              selectedWorkplacesIds={tabSelectedItems}
              onSelectionChange={onSelectionChange}
              onDelete={onDelete}
              onEdit={onEdit}
              onEditEntitiesProperties={onEditEntitiesProperties}
            />
          ) : selectedTabId === WorkplacePlaceType.MeetingRoom ? (
            <MeetingRoomsList
              meetingRooms={
                tabWorkplaces as FloorplanWorkplace<WorkplaceFormModel>
              }
              selectedWorkplacesIds={tabSelectedItems}
              onSelectionChange={onSelectionChange}
              onDelete={onDelete}
              onEdit={onEdit}
              onEditEntitiesProperties={onEditEntitiesProperties}
            />
          ) : null
        ) : null}
      </DetailsFormContent>

      <FormFooter>
        <ButtonsContainer>
          <ButtonSecondary
            label={t('shared:generics.cancel')}
            onClick={onClose}
          />
          <ButtonPrimary
            label={t('shared:generics.save')}
            onClick={onSave}
            disabled={isUpdating}
          />
        </ButtonsContainer>
      </FormFooter>
    </PanelDock>
  );
};

export { WorkplacesList };
