import styled from '@emotion/styled';
import { useClickOutsideAction } from '@hooks/useClickOutsideAction';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { useRef } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageSelector } from './language-selector/LanguageSelector';

const UserMenuContainer = styled.div`
  position: relative;
`;

const UserCircle = styled.button`
  ${({
    theme: {
      base: {
        colors,
        radius,
        fonts: { getTextsConfig, boldFont }
      }
    }
  }) => `
        appearance: none;
        background: ${colors.primary.default};
        border-radius: ${radius.circle};
        border: none;
        color: ${colors.neutral.fullLight};
        cursor: pointer;
        display: block;
        ${getTextsConfig('M')};
        font-family: '${boldFont}';
        height: ${rem(pxToRem(40))};
        margin: 0;
        overflow: visible;
        padding: 0;
        text-align: center;
        user-select: none;
        width: ${rem(pxToRem(40))};

        &:focus {
            outline: 0;
        }

        &::-moz-focus-inner {
            border: 0;
            padding: 0;
        }
    `}
`;

const UserMenuWrapper = styled.div`
  ${({
    theme: {
      base: { colors, zindex, shadow }
    }
  }) => `
    background-color: ${colors.neutral.fullLight};
    border-radius: ${rem(pxToRem(4))};
    box-shadow: ${shadow.base(colors)};
    min-width: ${rem(pxToRem(320))};
    overflow: hidden;
    position: absolute;
    right: ${rem(pxToRem(-8))};
    top: ${rem(pxToRem(64))};
    z-index: ${zindex.super};
  `}
`;

const ContentUserMenu = styled.ul`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig }
      }
    }
  }) => `
    color: ${colors.neutral.dark};
    ${getTextsConfig('M')};
    padding: ${rem(pxToRem(24))};
  `}
`;

const ContextUserMenuItem = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: ${rem(pxToRem(16))};
  }
`;

const UserDataWrapper = styled.div`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig, mediumFont }
      }
    }
  }) => `
    align-items: center;
    background-color: ${getRgbaStrFromHexColor(colors.primary.default, 0.1)};
    display: flex;
    height: ${rem(pxToRem(96))};
    padding: ${rem(pxToRem(24))};

    ${UserCircle} {
      flex: 1 0 auto;
      height: ${rem(pxToRem(48))};
      ${getTextsConfig('L')};
      font-family: '${mediumFont}';
      margin-right: ${rem(pxToRem(8))};
      width: ${rem(pxToRem(48))};
    }
  `}
`;

const UserData = styled.ul`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig }
      }
    }
  }) => `
    align-items: flex-start;
    color: ${colors.primary.default};
    display: flex;
    ${getTextsConfig('M')};
    flex-direction: column;
    width: 100%;
  `}
`;

const UserDataItem = styled.li`
  white-space: nowrap;
`;

const UserDataName = styled(UserDataItem)`
  ${({
    theme: {
      base: {
        fonts: { getTextsConfig, boldFont }
      }
    }
  }) => `
    ${getTextsConfig('L')};
    font-family: '${boldFont}';
  `}
`;

const Logout = styled.a`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig }
      }
    }
  }) => `
    color: ${colors.primary.default};
    ${getTextsConfig('L')};
    text-decoration: underline;

    &:hover {
      color: ${colors.primary.dark};
      cursor: pointer;
    }
  `}
`;

interface UserMenuProps {
  username?: string;
  useremail?: string;
  logout?: () => void;
}

const UserMenu: React.FC<UserMenuProps> = ({
  username,
  useremail,
  logout
}: UserMenuProps) => {
  const [isOpenMenu, setOpenMenu] = useState<boolean>();
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('shared');

  useClickOutsideAction(containerRef, () => setOpenMenu(false));

  return (
    <div ref={containerRef}>
      <UserMenuContainer>
        <UserCircle onClick={() => setOpenMenu(!isOpenMenu)}>
          {username?.substring(0, 2).toUpperCase()}
        </UserCircle>
        {isOpenMenu && (
          <UserMenuWrapper>
            <UserDataWrapper>
              <UserCircle>{username?.substring(0, 2).toUpperCase()}</UserCircle>
              <UserData>
                <UserDataName>{username}</UserDataName>
                {useremail && <UserDataItem>{useremail}</UserDataItem>}
              </UserData>
            </UserDataWrapper>
            <ContentUserMenu>
              <ContextUserMenuItem>
                <LanguageSelector />
              </ContextUserMenuItem>
              <ContextUserMenuItem>
                <Logout onClick={logout}>{t('profile.logout')}</Logout>
              </ContextUserMenuItem>
            </ContentUserMenu>
          </UserMenuWrapper>
        )}
      </UserMenuContainer>
    </div>
  );
};

export { UserMenu };
