import styled from '@emotion/styled';
import React, { ReactElement } from 'react';

import { CellProps } from '../../models';

const Text = styled.p`
  ${({
    theme: {
      base: {
        fonts: { getTextsConfig }
      }
    }
  }) => `
    ${getTextsConfig('L')}
  `}
`;

const TextCell = <T extends object>({
  value
}: CellProps<T, string>): ReactElement => <Text>{value}</Text>;

export { TextCell };
