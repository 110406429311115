import { notifyError } from '@app/shared/notifications';
import { ApiException } from '@services/api/apiService';
import i18n from '@services/i18n';
import { QueryClient } from 'react-query';

import { HttpStatusCodes } from './httpStatusCodes';

const createQueryClient = (): QueryClient =>
  new QueryClient({
    defaultOptions: {
      queries: {
        suspense: true,
        refetchOnWindowFocus: false
      },
      mutations: {
        onError: (error): void => {
          if (
            !(error instanceof ApiException) ||
            error.status !== HttpStatusCodes.Unauthorized
          ) {
            notifyError(i18n.t('toast.operation-error'));
          }
        }
      }
    }
  });

export { createQueryClient };
