import { useEffect, useMemo, useRef, useState } from 'react';
import {
  PluginHook,
  TableInstance,
  TableState,
  useAsyncDebounce,
  useFilters,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table';

import { TextCell } from './cells';
import { TextFilter } from './filters';
import {
  ColumnData,
  Filters,
  OnFetchDataCallback,
  PluginsOptions,
  SortBy,
  TableData
} from './models';

const DEBOUNCE_TIME_IN_MILLISECONDS = 1000;
const DEFAULT_ITEMS_PER_PAGE = 10;
export const DEBOUNCE_TIME_FILTER_IN_MILLISECONDS = 500;
export const ROWS_PER_PAGE = [10, 25, 50];

const DEFAULT_CELL_WIDTH = 150;
const DEFAULT_CELL_MIN_WIDTH = 30;
const DEFAULT_CELL_MAX_WIDTH = 200;
const DEFAULT_FILTER_OPERATOR = 'contains';

export type UseTableServiceProps<T extends object> = {
  data: T[] | undefined;
  columns: TableData<T>;
  isSortable?: boolean;
  isResizable?: boolean;
  isFilterable?: boolean;
  isPaginable?: boolean;
  isSelectable?: boolean;
  initialFilters?: Filters<T>;
  initialSortBy?: SortBy<T>;
  noDataMessage?: string;
  isLoading: boolean;
  count: number | undefined;
  onFetchData?: OnFetchDataCallback<T>;
};

const useTableService = <T extends object>(
  props: UseTableServiceProps<T>
): TableInstance<T> => {
  const {
    columns,
    data,
    isResizable,
    isFilterable,
    isSortable,
    isPaginable,
    isSelectable,
    initialFilters,
    initialSortBy,
    count = 0,
    onFetchData
  } = props;
  const [pageCount, setPageCount] = useState(0);
  const onFetchDataDebouncedRef = useRef(onFetchData);

  const { plugins, pluginsConfiguration } = useMemo(() => {
    const plugins = [] as PluginHook<T>[];
    const pluginsConfiguration: PluginsOptions<T> = {
      initialState: {}
    };

    const enableManualHandle = onFetchData != null;

    if (isResizable) {
      plugins.push(useResizeColumns);
    }
    if (isFilterable) {
      plugins.push(useFilters);
      pluginsConfiguration.manualFilters = enableManualHandle;
      pluginsConfiguration.initialState.filters = initialFilters ?? [];
    }
    if (isSortable) {
      plugins.push(useSortBy);
      pluginsConfiguration.manualSortBy = enableManualHandle;
      pluginsConfiguration.initialState.sortBy = initialSortBy ?? [];
    }
    if (isPaginable) {
      plugins.push(usePagination);
      pluginsConfiguration.manualPagination = enableManualHandle;
      pluginsConfiguration.initialState.pageIndex = 0;
      pluginsConfiguration.initialState.pageSize =
        ROWS_PER_PAGE.first() ?? DEFAULT_ITEMS_PER_PAGE;
    }
    if (isSelectable) {
      plugins.push(useRowSelect);
    }
    pluginsConfiguration.initialState.hiddenColumns = columns
      .filter((column) => column.show === false)
      .map((column) => column.id?.toString() || '');

    return { plugins, pluginsConfiguration };
  }, []);

  const onFetchDataDebounced = useAsyncDebounce((params: TableState<T>) => {
    return onFetchDataDebouncedRef.current?.({
      ...params,
      filters: params.filters?.map((f) => ({
        id: f.id as keyof T,
        value: [f.value],
        operator:
          columns.find((c) => c.id === f.id)?.filterOperator ||
          DEFAULT_FILTER_OPERATOR
      }))
    });
  }, DEBOUNCE_TIME_IN_MILLISECONDS);

  const defaultColumn: Partial<ColumnData<T>> = useMemo(
    () => ({
      Cell: TextCell,
      Filter: TextFilter,
      width: DEFAULT_CELL_WIDTH,
      minWidth: DEFAULT_CELL_MIN_WIDTH,
      maxWidth: DEFAULT_CELL_MAX_WIDTH
    }),
    []
  );

  const tableInstance = useTable<T>(
    {
      ...pluginsConfiguration,
      pageCount,
      defaultColumn,
      data: data ?? [],
      columns
    },
    ...plugins
  );

  useEffect(() => {
    onFetchDataDebouncedRef.current = onFetchData;
  }, [onFetchData]);

  useEffect(() => {
    onFetchDataDebounced({
      ...tableInstance.state,
      pageIndex: tableInstance.state.pageIndex + 1
    });
  }, [
    tableInstance.state.pageIndex,
    tableInstance.state.pageSize,
    tableInstance.state.filters,
    tableInstance.state.sortBy
  ]);

  useEffect(() => {
    setPageCount(Math.ceil(count / tableInstance.state.pageSize));
  }, [tableInstance.state.pageSize, count]);

  return { ...tableInstance };
};

export { useTableService };
