import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { PropsWithChildren, ReactElement } from 'react';

import { ButtonBase, ButtonsContainer } from '../button';
import { GridTemplateWrapper } from '../grid-template';
import { InputWrapper } from '.';

const FormTitle = styled.h2`
  ${({
    theme: {
      base: {
        fonts: { getHeadlinesConfig }
      }
    }
  }) => `
        ${getHeadlinesConfig('L')};
        margin-bottom: ${rem(pxToRem(32))};
        text-align: center;
        width: 100%;
    `}
`;

const FormContentSection = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const DetailsFormContent = styled.div`
  ${({
    theme: {
      base: { layout }
    }
  }) => `
    display: flex;
    flex-direction: column;
    height: calc(100vh - ${layout.header.height} - ${rem(pxToRem(72))} - ${rem(
    pxToRem(60)
  )} - ${rem(pxToRem(60))});
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;

    > ${GridTemplateWrapper} {
      margin-right: ${rem(pxToRem(16))};
    }

    ${InputWrapper} {
      width: 100%;
    }
  `}
`;

const DetailsFormContentAlt = styled.div`
  ${() => `
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    gap: ${rem(pxToRem(8))};

    > ${GridTemplateWrapper} {
      margin-right: ${rem(pxToRem(16))};
    }

    ${InputWrapper} {
      width: 100%;
    }
  `}
`;

const FormFooter = styled.footer`
  ${() => `
    margin-top: ${rem(pxToRem(24))};

    ${ButtonsContainer} {
      margin-right: ${rem(pxToRem(16))};

      ${ButtonBase} {
        width: 100%;
      }
    }
  `}
`;

interface FormProps {
  headerTitle?: string;
}

const Form = ({
  headerTitle,
  children
}: PropsWithChildren<FormProps>): ReactElement => {
  return (
    <>
      {headerTitle && <FormTitle>{headerTitle}</FormTitle>}
      <FormContentSection>{children}</FormContentSection>
    </>
  );
};

export { Form, FormFooter, DetailsFormContent, DetailsFormContentAlt };
