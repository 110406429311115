import { FloorplansLocation } from '@app/facility-management/floorplans';
import { FloorplanBookingsLocation } from '@app/facility-management/floorplans/floorplan-bookings/routes/floorplanBookingsLocation';
import { FloorplanSettingsLocation } from '@app/facility-management/floorplans/floorplan-settings/routes/floorplanSettingsLocation';
import { EditFloorplansFormLocation } from '@app/facility-management/floorplans/floorplans-form/routes/floorplansFormLocation';
import { CardDropdown, MenuActionsItem } from '@app/shared/components';
import { useDialog } from '@app/shared/components/dialog';
import { notifySuccess } from '@app/shared/notifications';
import { Floorplan, FloorplansActionsEnum } from '@domain/floorplans';
import { useTheme } from '@emotion/react';
import { useArchiveFloorplan } from '@hooks/floorplans/useArchiveFloorplan';
import { useCloseFloorplan } from '@hooks/floorplans/useCloseFloorplan';
import { useDeleteFloorplan } from '@hooks/floorplans/useDeleteFloorplan';
import { useOpenFloorplan } from '@hooks/floorplans/useOpenFloorplan';
import { usePublishFloorplan } from '@hooks/floorplans/usePublishFloorplan';
import { useUnarchiveFloorplan } from '@hooks/floorplans/useUnarchiveFloorplan';
import { useUnpublishFloorplan } from '@hooks/floorplans/useUnpublishFloorplan';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface FloorplansCardMenuProps {
  venueId: string;
  buildingId: string;
  floorplan: Floorplan;
}

const FloorplansCardMenu = ({
  venueId,
  buildingId,
  floorplan
}: FloorplansCardMenuProps): ReactElement => {
  const {
    base: { colors }
  } = useTheme();

  const { t } = useTranslation('floorplans');

  const { showDialog } = useDialog();

  const history = useHistory();

  const {
    mutateAsync: openFloorplan,
    isSuccess: isOpenFloorplanSuccess
  } = useOpenFloorplan(venueId, buildingId);
  const {
    mutateAsync: closeFloorplan,
    isSuccess: isCloseFloorplanSuccess
  } = useCloseFloorplan(venueId, buildingId);
  const {
    mutateAsync: deleteFloorplan,
    isSuccess: isDeleteFloorplanSuccess
  } = useDeleteFloorplan(venueId, buildingId);
  const {
    mutateAsync: archiveFloorplan,
    isSuccess: isArchiveFloorplanSuccess
  } = useArchiveFloorplan(venueId, buildingId);
  const {
    mutateAsync: unarchiveFloorplan,
    isSuccess: isUnarchiveFloorplanSuccess
  } = useUnarchiveFloorplan(venueId, buildingId);
  const {
    mutateAsync: publishFloorplan,
    isSuccess: isPublishFloorplanSuccess
  } = usePublishFloorplan(venueId, buildingId);
  const {
    mutateAsync: unpublishFloorplan,
    isSuccess: isUnpublishFloorplanSuccess
  } = useUnpublishFloorplan(venueId, buildingId);

  useEffect(() => {
    if (
      isOpenFloorplanSuccess ||
      isCloseFloorplanSuccess ||
      isDeleteFloorplanSuccess ||
      isPublishFloorplanSuccess ||
      isUnpublishFloorplanSuccess ||
      isArchiveFloorplanSuccess ||
      isUnarchiveFloorplanSuccess
    ) {
      notifySuccess(t('shared:toast.operation-success'));
    }
  }, [
    isOpenFloorplanSuccess,
    isCloseFloorplanSuccess,
    isDeleteFloorplanSuccess,
    isPublishFloorplanSuccess,
    isUnpublishFloorplanSuccess,
    isArchiveFloorplanSuccess,
    isUnarchiveFloorplanSuccess
  ]);

  const handleDeleteFloorplan = (floorplanId: string): void => {
    showDialog({
      open: true,
      title: t('delete-prompt.title'),
      subtitle: t('delete-prompt.subtitle'),
      message: t('delete-prompt.body'),
      headerIcon: 'trash',
      headerColor: colors.system.error,
      btnPrimaryText: t('delete-prompt.confirmButtonText'),
      onConfirm: {
        callback: () => deleteFloorplan(floorplanId)
      }
    });
  };

  const handleArchiveFloorplan = (floorplanId: string): void => {
    showDialog({
      open: true,
      title: t('archive-prompt.title'),
      subtitle: t('archive-prompt.subtitle'),
      message: t('archive-prompt.body'),
      headerIcon: 'archive',
      headerColor: colors.system.error,
      btnPrimaryText: t('archive-prompt.confirmButtonText'),
      onConfirm: {
        callback: () => archiveFloorplan(floorplanId)
      }
    });
  };

  const handleUnarchiveFloorplan = (floorplanId: string): void => {
    showDialog({
      open: true,
      title: t('unarchive-prompt.title'),
      subtitle: t('unarchive-prompt.subtitle'),
      message: t('unarchive-prompt.body'),
      headerIcon: 'unarchive',
      headerColor: colors.system.error,
      btnPrimaryText: t('unarchive-prompt.confirmButtonText'),
      onConfirm: {
        callback: () => unarchiveFloorplan(floorplanId)
      }
    });
  };

  const handleOpenFloorplan = (floorplanId: string): void => {
    showDialog({
      open: true,
      title: t('open-prompt.title'),
      subtitle: t('open-prompt.subtitle'),
      message: t('open-prompt.body'),
      headerIcon: 'lock-open-alt',
      headerColor: colors.system.info,
      btnPrimaryText: t('open-prompt.confirmButtonText'),
      onConfirm: {
        callback: () => openFloorplan(floorplanId)
      }
    });
  };

  const handleCloseFloorplan = (floorplanId: string): void => {
    showDialog({
      open: true,
      title: t('close-prompt.title'),
      subtitle: t('close-prompt.subtitle'),
      message: t('close-prompt.body'),
      headerIcon: 'lock-alt',
      headerColor: colors.system.error,
      btnPrimaryText: t('close-prompt.confirmButtonText'),
      onConfirm: {
        callback: () => closeFloorplan(floorplanId)
      }
    });
  };

  const handlePublishFloorplan = (floorplanId: string): void => {
    showDialog({
      open: true,
      title: t('publish-prompt.title'),
      subtitle: t('publish-prompt.subtitle'),
      message: t('publish-prompt.body'),
      headerIcon: 'eye',
      headerColor: colors.system.info,
      btnPrimaryText: t('publish-prompt.confirmButtonText'),
      onConfirm: {
        callback: () => publishFloorplan(floorplanId)
      }
    });
  };

  const handleUnpublishFloorplan = (floorplanId: string): void => {
    showDialog({
      open: true,
      title: t('unpublish-prompt.title'),
      subtitle: t('unpublish-prompt.subtitle'),
      message: t('unpublish-prompt.body'),
      headerIcon: 'eye-slash',
      headerColor: colors.system.info,
      btnPrimaryText: t('unpublish-prompt.confirmButtonText'),
      onConfirm: {
        callback: () => unpublishFloorplan(floorplanId)
      }
    });
  };

  const menuOptions: MenuActionsItem[] = [
    {
      label: t('actions.viewBookings'),
      iconName: 'floormap',
      show: floorplan.activeActions.some(
        (x) => x.identifier === FloorplansActionsEnum.View
      ),
      onClick: () => {
        const baseParams = FloorplansLocation.parseLocationParams(location);
        history.push(
          FloorplanBookingsLocation.toUrl({
            venueId: baseParams.venueId,
            buildingId: baseParams.buildingId,
            floorplanId: floorplan.floorId
          })
        );
      }
    },
    {
      label: t('actions.settings'),
      iconName: 'settings',
      show: floorplan.activeActions.some(
        (x) => x.identifier === FloorplansActionsEnum.Edit
      ),
      onClick: () => {
        const baseParams = FloorplansLocation.parseLocationParams(location);
        history.push(
          FloorplanSettingsLocation.toUrl({
            venueId: baseParams.venueId,
            buildingId: baseParams.buildingId,
            floorplanId: floorplan.floorId
          })
        );
      }
    },
    {
      label: t('actions.edit'),
      iconName: 'edit',
      show: floorplan.activeActions.some(
        (x) => x.identifier === FloorplansActionsEnum.Edit
      ),
      onClick: () => {
        const baseParams = FloorplansLocation.parseLocationParams(location);
        history.push(
          EditFloorplansFormLocation.toUrl({
            ...baseParams,
            floorplanId: floorplan.floorId
          })
        );
      }
    },
    {
      label: t('actions.publish'),
      iconName: 'eye',
      show: floorplan.activeActions.some(
        (x) => x.identifier === FloorplansActionsEnum.Publish
      ),
      onClick: () => {
        handlePublishFloorplan(floorplan.floorId);
      }
    },
    {
      label: t('actions.unpublish'),
      iconName: 'eye-slash',
      show: floorplan.activeActions.some(
        (x) => x.identifier === FloorplansActionsEnum.Unpublish
      ),
      onClick: () => {
        handleUnpublishFloorplan(floorplan.floorId);
      }
    },
    {
      label: t('actions.open'),
      iconName: 'lock-open-alt',
      show: floorplan.activeActions.some(
        (x) => x.identifier === FloorplansActionsEnum.Open
      ),
      onClick: () => {
        handleOpenFloorplan(floorplan.floorId);
      }
    },
    {
      label: t('actions.close'),
      iconName: 'lock-alt',
      show: floorplan.activeActions.some(
        (x) => x.identifier === FloorplansActionsEnum.Close
      ),
      onClick: () => {
        handleCloseFloorplan(floorplan.floorId);
      }
    },
    {
      label: t('actions.archive'),
      iconName: 'archive',
      show: floorplan.activeActions.some(
        (x) => x.identifier === FloorplansActionsEnum.Archive
      ),
      onClick: () => {
        handleArchiveFloorplan(floorplan.floorId);
      }
    },
    {
      label: t('actions.unarchive'),
      iconName: 'unarchive',
      show: floorplan.activeActions.some(
        (x) => x.identifier === FloorplansActionsEnum.Unarchive
      ),
      onClick: () => {
        handleUnarchiveFloorplan(floorplan.floorId);
      }
    },
    {
      label: t('actions.remove'),
      iconName: 'trash',
      show: floorplan.activeActions.some(
        (x) => x.identifier === FloorplansActionsEnum.Remove
      ),
      onClick: () => {
        handleDeleteFloorplan(floorplan.floorId);
      }
    }
  ];

  return <CardDropdown dropdownOptions={menuOptions} />;
};

export default FloorplansCardMenu;
