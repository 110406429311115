import { BUILDINGS_QUERY_KEY } from '@domain/buildings';
import { UseMutation, useMutationWrapper } from '@hooks/useMutationWrapper';
import { openBuilding } from '@services/buildings/buildingsService';
import { useQueryClient } from 'react-query';

const useOpenBuilding = (
  venueId: string
): UseMutation<void, Error, string, unknown> => {
  const queryClient = useQueryClient();

  return useMutationWrapper<void, Error, string>(
    (buildingId) => openBuilding(buildingId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(BUILDINGS_QUERY_KEY(venueId));
      }
    }
  );
};

export { useOpenBuilding };
