import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { PropsWithChildren, ReactElement } from 'react';
import { FieldError } from 'react-hook-form';

import { MessageValidation } from './MessageValidation';

interface LabelProps {
  hasError?: boolean;
  direction?: 'row' | 'column';
}

export const Label = styled.label<{ direction?: 'row' | 'column' }>`
  ${({ direction }) => `
    align-items: ${direction === 'column' ? 'flex-start' : 'center'};
    display: flex;
    flex-direction: ${direction ? direction : 'column'};
    position: relative;
  `}
`;

const LabelText = styled.span<LabelProps>`
  ${({
    theme: {
      base: {
        fonts: { getTextsConfig },
        colors
      }
    },
    hasError,
    direction
  }) => `
        color: ${hasError ? colors.system.error : colors.primary.darkest};
        ${getTextsConfig('S')};
        opacity: 0.8;
        padding: ${
          direction === 'column'
            ? `0 ${rem(pxToRem(4))} ${rem(pxToRem(4))}`
            : `0 ${rem(pxToRem(4))} 0 0`
        };
    `}
`;

export interface FormGroupProps {
  label?: string;
  labelKey?: string;
  error?: FieldError;
}

const FormGroup = ({
  label,
  error,
  direction = 'column',
  children
}: PropsWithChildren<FormGroupProps & LabelProps>): ReactElement => (
  <Label direction={direction}>
    {label && (
      <LabelText hasError={Boolean(error?.message)} direction={direction}>
        {label}
      </LabelText>
    )}
    {children}
    <MessageValidation>{error?.message}</MessageValidation>
  </Label>
);

export { FormGroup };
