import { configuration } from '@configuration';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const {
  debug,
  i18n: { namespaces, defaultNamespace, defaultLanguage, languages }
} = configuration;


const localesPath = configuration.locales.override?
 `/static/locales/${ configuration.locales.namespace }/{{ns}}/{{lng}}.json`:
 '/static/locales/{{ns}}/{{lng}}.json';

i18n
  .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      loadPath: localesPath,
      allowMultiLoading: false,
      queryStringParams: {}
    },
    whitelist: languages,
    fallbackLng: defaultLanguage,
    load: 'currentOnly',
    ns: namespaces,
    defaultNS: defaultNamespace,
    debug,
    interpolation: {
      escapeValue: false, // react already safes from xss
      formatSeparator: ','
    }
  });

export { i18n };
