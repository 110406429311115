import { configuration } from '@configuration';
import { Log, User, UserManager, UserManagerSettings } from 'oidc-client';

import { getAuthorizationHeaderValue } from './authUser';

const { debug } = configuration;

type EventsConfigCallback = (
  onUserLoaded: (user: User) => void,
  onUserUnloaded: () => void,
  onError: (error: Error) => void,
  onAccessTokenExpiring: () => void,
  onAccessTokenExpired: () => void
) => void;

export type AuthService = Readonly<{
  authenticateUser: () => Promise<void>;
  authenticateUserCallback: () => Promise<void>;
  signOutUser: () => Promise<void>;
  signInSilent: () => Promise<void>;
  completeSignInSilent?: () => Promise<void>;
  signInSilentCallback: () => Promise<void>;
  registerEvents: EventsConfigCallback;
  unregisterEvents: EventsConfigCallback;
  tryLoadUser: () => Promise<boolean>;
  revokeAccessToken: () => Promise<void>;
  getAuthorizationHeader: () => Promise<string>;
}>;

const authServiceFactory = (
  userManagerSettings: UserManagerSettings
): AuthService => {
  const userManager = new UserManager(userManagerSettings);

  Log.logger = console;
  Log.level = debug ? Log.INFO : Log.ERROR;

  const signInRedirect = async (): Promise<void> => {
    await userManager.removeUser();
    await userManager.revokeAccessToken();
    await userManager.clearStaleState();
    await userManager.signinRedirect();
  };

  const signInSilent = async (): Promise<void> => {
    await userManager.signinSilent();
  };

  const signInSilentCallback = async (): Promise<void> => {
    await userManager.signinSilentCallback();
  };

  const authenticateUser = async (): Promise<void> => {
    const user = await userManager.getUser();
    if (!user) {
      await signInRedirect();
    } else {
      try {
        await signInSilent();
      } catch (error) {
        await signInRedirect();
      }
    }
  };

  const authenticateUserCallback = async (): Promise<void> => {
    await userManager.signinRedirectCallback();
  };

  const signOutUser = async (): Promise<void> => {
    await userManager.clearStaleState();
    await userManager.signoutRedirect();
  };

  const registerEvents = (
    onUserLoaded: (user: User) => void,
    onUserUnloaded: () => void,
    onError: (error: Error) => void,
    onAccessTokenExpiring: () => void,
    onAccessTokenExpired: () => void
  ): void => {
    userManager.events.addUserLoaded(onUserLoaded);
    userManager.events.addSilentRenewError(onError);
    userManager.events.addUserUnloaded(onUserUnloaded);
    userManager.events.addUserSignedOut(onUserUnloaded);
    userManager.events.addAccessTokenExpiring(onAccessTokenExpiring);
    userManager.events.addAccessTokenExpired(onAccessTokenExpired);
  };

  const unregisterEvents = (
    onUserLoaded: (user: User) => void,
    onUserUnloaded: () => void,
    onError: (error: Error) => void,
    onAccessTokenExpiring: () => void,
    onAccessTokenExpired: () => void
  ): void => {
    userManager.events.removeUserLoaded(onUserLoaded);
    userManager.events.removeSilentRenewError(onError);
    userManager.events.removeUserUnloaded(onUserUnloaded);
    userManager.events.removeUserSignedOut(onUserUnloaded);
    userManager.events.removeAccessTokenExpiring(onAccessTokenExpiring);
    userManager.events.removeAccessTokenExpired(onAccessTokenExpired);
  };

  const tryLoadUser = async (): Promise<boolean> => {
    const user = await userManager.getUser();
    if (user && !user.expired) {
      userManager.events.load(user);
      return true;
    }
    return false;
  };

  const revokeAccessToken = async (): Promise<void> => {
    await userManager.revokeAccessToken();
  };

  const getAuthorizationHeader = async (): Promise<string> => {
    const user = await userManager.getUser();
    return user ? getAuthorizationHeaderValue(user) : '';
  };

  return Object.freeze({
    authenticateUser,
    authenticateUserCallback,
    signOutUser,
    signInSilent,
    signInSilentCallback,
    registerEvents,
    unregisterEvents,
    tryLoadUser,
    revokeAccessToken,
    getAuthorizationHeader
  });
};

let getAuthorizationHeader: (() => Promise<string>) | undefined = undefined;
let authService: AuthService | undefined = undefined;

const createAuthService = (
  userManagerSettings: UserManagerSettings
): AuthService => {
  if (!authService) {
    authService = authServiceFactory(userManagerSettings);
    getAuthorizationHeader = authService.getAuthorizationHeader;
  }
  return authService;
};

export { createAuthService, getAuthorizationHeader };
