import { LoginLocation } from '@app/auth';
import { HttpStatusCodes } from '@services/http';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { getAuthorizationHeader } from '../auth';

const addAuthenticationInterceptor = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const authorizationHeader = getAuthorizationHeader
    ? await getAuthorizationHeader()
    : '';

  if (authorizationHeader) {
    config.headers.Authorization = authorizationHeader;
  }

  return config;
};

const addErrorInterceptor: (value: AxiosError) => AxiosResponse | void = (
  error: AxiosError
) => {
  if (error?.response?.status === HttpStatusCodes.Unauthorized) {
    window.location.href = LoginLocation.path;
  }
  throw error;
};

export { addAuthenticationInterceptor, addErrorInterceptor };
