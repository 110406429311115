export enum FloorplansActionsEnum {
  View = 1,
  Edit,
  Open,
  Close,
  Remove,
  Archive,
  Unarchive,
  Publish,
  Unpublish
}
