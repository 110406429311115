import { BUILDINGS_QUERY_KEY, CreateBuildingModel } from '@domain/buildings';
import { VENUES_QUERY_KEY } from '@domain/venues';
import { UseMutation, useMutationWrapper } from '@hooks/useMutationWrapper';
import { createBuilding } from '@services/buildings/buildingsService';
import { useQueryClient } from 'react-query';

const useCreateBuilding = (
  venueId: string
): UseMutation<void, Error, CreateBuildingModel, unknown> => {
  const queryClient = useQueryClient();

  return useMutationWrapper<void, Error, CreateBuildingModel>(
    (building) => createBuilding(building),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(BUILDINGS_QUERY_KEY(venueId));
        queryClient.invalidateQueries(VENUES_QUERY_KEY);
      }
    }
  );
};

export { useCreateBuilding };
