import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import NavigationPrompt from 'react-router-navigation-prompt';

import { ConfirmModal } from '../modal/ConfirmModal';

type LocationState = { omitNavigationPrompt?: boolean };

type OnClosePrompt = (confirm: boolean) => void;

type PromptProps = { hasChanges: boolean };

const Prompt: React.FC<PropsWithChildren<PromptProps>> = ({
  hasChanges,
  children
}) => {
  const { t } = useTranslation();

  return (
    <>
      <NavigationPrompt
        allowGoBack={false}
        renderIfNotActive={false}
        when={(current, next) =>
          !(next?.state as LocationState)?.omitNavigationPrompt &&
          hasChanges &&
          current.pathname !== next?.pathname
        }>
        {({ isActive, onCancel, onConfirm }) => {
          const onClose: OnClosePrompt = (confirm) =>
            confirm ? onConfirm() : onCancel();

          return (
            isActive && (
              <ConfirmModal
                onClose={onClose}
                title={t('prompt.title')}
                description={t('prompt.body')}
                btnSecondaryText={t('generics.cancel')}
                btnPrimaryText={t('generics.confirm')}
              />
            )
          );
        }}
      </NavigationPrompt>
      {children}
    </>
  );
};

const getStateLocationForOmitNavigationPrompt = (
  locationState?: Record<string, unknown>
): LocationState => ({
  ...locationState,
  omitNavigationPrompt: true
});

export { Prompt, getStateLocationForOmitNavigationPrompt };
