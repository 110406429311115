import { Modal } from '@app/shared/components';
import { ILocationComponentProps } from '@app/shared/routes';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { FloorplansLocation } from '../routes/floorplansLocation';
import FloorplansCreateForm from './floorplans-create-form/FloorplansCreateForm';
import FloorplansEditForm from './floorplans-edit-form/FloorplansEditForm';
import { EditFloorplansFormLocationParams } from './routes/floorplansFormLocation';

interface FloorplansFormProps
  extends ILocationComponentProps<EditFloorplansFormLocationParams> {
  mode: 'create' | 'edit';
}

const FloorplansForm = ({
  match: { params },
  mode
}: FloorplansFormProps): ReactElement => {
  const { t } = useTranslation('floorplans');

  const { venueId, buildingId, floorplanId } = params;

  const history = useHistory();

  const onGoBack = (): void => {
    history.replace(FloorplansLocation.toUrl(params));
  };

  return (
    <Modal
      onClose={onGoBack}
      title={mode === 'create' ? t('creation-title') : t('edition-title')}>
      {mode === 'create' ? (
        <FloorplansCreateForm
          venueId={venueId}
          buildingId={buildingId}
          onCreateFloorplan={onGoBack}
          onCancel={onGoBack}
        />
      ) : (
        <FloorplansEditForm
          venueId={venueId}
          buildingId={buildingId}
          floorplanId={floorplanId || ''}
          onEditFloorplan={onGoBack}
          onCancel={onGoBack}
        />
      )}
    </Modal>
  );
};

export default FloorplansForm;
