import {
  InputCheckItem,
  InputRadio,
  PanelSubTitle
} from '@app/shared/components';
import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const LanSelector = (): ReactElement => {
  const { t } = useTranslation('workplaces');
  const { register, errors } = useFormContext<{ lan: string }>();

  const lanConnection: InputCheckItem[] = [
    {
      id: 'lan',
      label: t('shared:confirmation.yes'),
      value: 1
    },
    {
      id: 'no-lan',
      label: t('shared:confirmation.no'),
      value: 0
    }
  ];

  return (
    <>
      <PanelSubTitle>{t('fieldNames.lan')}</PanelSubTitle>
      <InputRadio
        innerRef={register}
        name='lan'
        options={lanConnection}
        error={errors.lan}
      />
    </>
  );
};

export { LanSelector };
