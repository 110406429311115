import { DAILY_BOOKINGS_QUERY_KEY } from '@domain/bookings';
import { CreateBookingModelForExternalUser } from '@domain/bookings/models/shared/BookingFormModel';
import { FLOORPLANS_KEY } from '@domain/floorplans';
import { UseMutation, useMutationWrapper } from '@hooks/useMutationWrapper';
import { createBookingForExternalUser } from '@services/bookings/bookingsService';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { notifySuccess } from '../../modules/app/shared/notifications';

const useCreateBookingForExternalUser = (
  venueId: string,
  buildingId: string,
  floorId: string,
  selectedDate: Date
): UseMutation<void, Error, CreateBookingModelForExternalUser, unknown> => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutationWrapper<void, Error, CreateBookingModelForExternalUser>(
    (model) => createBookingForExternalUser(model),
    {
      onSuccess: () => {
        notifySuccess(t('shared:toast.operation-success'));

        queryClient.invalidateQueries(
          DAILY_BOOKINGS_QUERY_KEY(floorId, selectedDate?.withoutTime())
        );
        queryClient.invalidateQueries([
          ...FLOORPLANS_KEY(venueId, buildingId),
          {
            archived: true,
            unpublished: true,
            closed: true
          }
        ]);
      }
    }
  );
};

export { useCreateBookingForExternalUser };
