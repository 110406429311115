import { useAuthContext, useAuthContextActions } from '@contexts/auth';
import React, { PropsWithChildren, useEffect } from 'react';

import { Loader } from '../shared/components';

const Authentication: React.FC = ({ children }: PropsWithChildren<unknown>) => {
  const { isLoading } = useAuthContext();
  const { initialize } = useAuthContextActions();

  useEffect(() => {
    initialize();
  }, []);

  return isLoading ? <Loader isFullPage /> : <>{children}</>;
};

export { Authentication };
