import { FLOORPLANS_KEY, UpdateFloorplanModel } from '@domain/floorplans';
import { UseMutation, useMutationWrapper } from '@hooks/useMutationWrapper';
import { updateFloorplan } from '@services/floorplans/floorplansService';
import { useQueryClient } from 'react-query';

const useUpdateFloorplan = (
  venueId: string,
  buildingId: string,
  floorplanId: string
): UseMutation<void, Error, UpdateFloorplanModel, unknown> => {
  const queryClient = useQueryClient();

  return useMutationWrapper<void, Error, UpdateFloorplanModel>(
    (floorplan) => updateFloorplan(floorplanId, floorplan),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FLOORPLANS_KEY(venueId, buildingId));
      }
    }
  );
};

export { useUpdateFloorplan };
