import React, { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { RouteModel } from '../shared/routes/routeModel';
import { PrivateRoute } from './PrivateRoute';

const renderRoutes = (routes: RouteModel[]): ReactElement[] => {
  return routes.map(
    ({ location, exact = true, component, render, isPrivate }) => {
      const RouteComponent = component;

      const renderRouteComponent = (
        props: RouteComponentProps<{}>
      ): React.ReactNode | React.FC =>
        RouteComponent ? <RouteComponent /> : render?.(props);

      return {
        ...location.toRoute({
          exact,
          render: (props) =>
            isPrivate ? (
              <PrivateRoute>{renderRouteComponent(props)}</PrivateRoute>
            ) : (
              renderRouteComponent(props)
            )
        }),
        key: location.path
      };
    }
  );
};

export { renderRoutes };
