import { CardBase } from '@app/shared/components';
import {
  CardEmptyImage,
  CardFooter,
  CardInfoWrapper,
  CardTitle
} from '@app/shared/components/card';
import { CardListData } from '@app/shared/components/card/CardListData';
import {
  CardImage,
  CardPicture
} from '@app/shared/components/card/CardPicture';
import { CardStatusIcon } from '@app/shared/components/card/CardStatusIcon';
import { CardTag } from '@app/shared/components/card/CardTag';
import { Floorplan } from '@domain/floorplans';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { FloorplanBookingsLocation } from '../../floorplan-bookings/routes/floorplanBookingsLocation';
import FloorplansCardMenu from './floorplans-card-menu/FloorplansCardMenu';

const CardImageFloorplan = styled(CardImage)`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
    border: 1px solid ${colors.neutral.gray};
  `}
`;

const CardLastVersion = styled.p`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig }
      }
    }
  }) => `
    color: ${colors.neutral.grayDark};
    ${getTextsConfig('M')};
    margin-bottom: ${rem(pxToRem(8))};
  `}
`;

interface FloorplansCardProps {
  venueId: string;
  buildingId: string;
  floorplan: Floorplan;
}

const FloorplansCard = memo(
  ({ venueId, buildingId, floorplan }: FloorplansCardProps) => {
    const {
      t,
      i18n: { language }
    } = useTranslation('floorplans');
    const history = useHistory();

    const theme = useTheme();

    return (
      <CardBase
        onClick={() =>
          history.push(
            FloorplanBookingsLocation.toUrl({
              venueId: venueId,
              buildingId: buildingId,
              floorplanId: floorplan.floorId
            })
          )
        }
        clickable={true}>
        <CardInfoWrapper
          className={
            floorplan.isClosed || floorplan.isArchived ? 'grayscale' : 'default'
          }>
          <CardTitle label={floorplan.name} iconName='floormap' />
          <CardLastVersion>
            {t('lastVersion')} {floorplan.lastUpdated.toLocaleString(language)}
          </CardLastVersion>
          <CardPicture>
            {floorplan.floorImageUrl ? (
              <CardImageFloorplan
                loading='lazy'
                src={floorplan.floorImageUrl}
              />
            ) : (
              <CardEmptyImage size='default' />
            )}
            {floorplan.isClosed && (
              <CardStatusIcon
                iconName='lock-alt'
                color={theme.base.colors.secondary.first}
              />
            )}
            {floorplan.isArchived && (
              <CardStatusIcon
                iconName='archive'
                color={theme.base.colors.primary.default}
              />
            )}
          </CardPicture>
          <CardListData iconName='desktop' label={floorplan.workPlaces} />
        </CardInfoWrapper>
        <CardFooter>
          <CardTag className={floorplan.isPublished ? 'default' : 'inverse'}>
            {floorplan.isPublished ? t('published') : t('unpublished')}
          </CardTag>
          <FloorplansCardMenu
            venueId={venueId}
            buildingId={buildingId}
            floorplan={floorplan}
          />
        </CardFooter>
      </CardBase>
    );
  }
);

export default FloorplansCard;
