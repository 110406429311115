import {
  INCIDENT_QUERY_KEY,
  IncidentUnresolveModel,
  IncidentUnresolveResponse
} from '@domain/care-management';
import { UseMutation, useMutationWrapper } from '@hooks/useMutationWrapper';
import { careManagementUnresolveIncident } from '@services/care-management/incidents/careManagementIncidentsService';
import { useQueryClient } from 'react-query';

const useUnresolveIncident = (): UseMutation<
  IncidentUnresolveResponse,
  Error,
  IncidentUnresolveModel,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutationWrapper<
    IncidentUnresolveResponse,
    Error,
    IncidentUnresolveModel
  >((model) => careManagementUnresolveIncident(model), {
    onSuccess: () => {
      queryClient.invalidateQueries(INCIDENT_QUERY_KEY);
    }
  });
};

export { useUnresolveIncident };
