import {
  ButtonFeatured,
  ButtonPrimary,
  ButtonsContainer,
  DetailsFormContent,
  GridTemplate,
  Input,
  Select
} from '@app/shared/components';
import {
  WorkplaceFormModel,
  workplaceFormModel
} from '@domain/workplaces/models/create/workplaceFormModel';
import {
  WorkplacePlaceType,
  workplacesDefinition
} from '@domain/workplaces/models/workplace';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers';
import React, { ReactElement, useRef } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MeetingRoomForm } from '../meeting-room-form/MeetingRoomForm';
import { WorkplaceFormRef } from '../shared/workplaceFormRef';
import { WorkstationForm } from '../workstation-form/WorkstationForm';

const WorkplaceFormContent = styled(DetailsFormContent)`
  ${() => `
    height: auto;
    max-height: calc(50vh);
  `}
`;

export interface WorkplaceFormProps {
  initialValues?: WorkplaceFormModel;
  onSave: (form: WorkplaceFormModel) => void;
  onDelete: () => void;
}

type FormModel = WorkplaceFormModel;

const WorkplaceForm = ({
  initialValues,
  onSave,
  onDelete
}: WorkplaceFormProps): ReactElement => {
  const { t } = useTranslation('workplaces');
  const formRef = useRef<WorkplaceFormRef>(null);

  const places = Object.entries(workplacesDefinition).map(([key, def]) => ({
    label: t(def.title),
    value: key.toString()
  }));

  const formMethods = useForm<FormModel>({
    resolver: yupResolver(workplaceFormModel),
    mode: 'all',
    defaultValues: {
      ...initialValues,
      type: initialValues?.type || places.first()?.value || ''
    }
  });

  const { register, handleSubmit, control, errors, formState } = formMethods;

  const type = useWatch<string>({
    control,
    name: 'type'
  });

  const onSaveForm = (model: FormModel): void => {
    onSave(formRef.current?.transformFormModel(model) ?? model);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSaveForm)} autoComplete='off'>
        <WorkplaceFormContent>
          <GridTemplate>
            <Controller
              render={({ value, onChange }) => (
                <Select
                  label={t('fieldNames.place')}
                  items={places ?? []}
                  item={places?.find((s) => s.value == value)}
                  getItemLabel={(item) =>
                    item ? item?.label : t('fieldNames.placePlaceholder')
                  }
                  placeholder={t('fieldNames.placePlaceholder')}
                  getItemValue={(item) => item?.value}
                  searchable={false}
                  error={errors?.type}
                  onChange={(item) => onChange(item?.value)}
                />
              )}
              control={control}
              name='type'
            />
            <Input
              name='label'
              innerRef={register}
              label={t('fieldNames.label')}
              error={errors.label}
            />

            {type === WorkplacePlaceType.Workstation.toString() ? (
              <WorkstationForm ref={formRef} />
            ) : type === WorkplacePlaceType.MeetingRoom.toString() ? (
              <MeetingRoomForm />
            ) : null}
          </GridTemplate>
        </WorkplaceFormContent>

        <ButtonsContainer>
          <ButtonFeatured
            type='button'
            onClick={() => onDelete()}
            disabled={formState.isSubmitting}
            label={t('shared:generics.delete')}
          />
          <ButtonPrimary
            disabled={formState.isSubmitting || !formState.isDirty}
            type='submit'
            label={t('shared:generics.save')}
          />
        </ButtonsContainer>
      </form>
    </FormProvider>
  );
};

export { WorkplaceForm };
