import styled from '@emotion/styled';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement } from 'react';

import { ButtonBase, ButtonPrimary, ButtonSecondary } from '../button';
import { Icon, IconWrapper } from '../icon';
import { ModalBase } from './ModalBase';

const ModalWrapper = styled.div`
  ${({
    theme: {
      base: { colors, modal, shadow }
    }
  }) => `
    background-color: ${colors.neutral.fullLight};
    border-radius: ${rem(pxToRem(2))};
    box-sizing: border-box;
    box-shadow: ${shadow.modal(colors)};
    max-width: ${modal.large};
    overflow: hidden;
    padding: ${rem(pxToRem(40))};
    text-align: center;
    width: ${rem(pxToRem(450))};
  `}
`;

interface ModalHeaderProps {
  color?: string;
}

const ModalHeaderTitle = styled.h2`
  ${({
    theme: {
      base: {
        fonts: { getHeadlinesConfig, mediumFont }
      }
    }
  }) => `
    ${getHeadlinesConfig('S')};
    font-family: '${mediumFont}';
  `}
`;

const ModalHeader = styled.header<ModalHeaderProps>`
  ${({
    theme: {
      base: { colors, radius }
    },
    color
  }) => `
    border-bottom: 1px solid ${getRgbaStrFromHexColor(
      colors.neutral.dark,
      0.2
    )};
    margin-bottom: ${rem(pxToRem(24))};
    padding-bottom: ${rem(pxToRem(16))};

    ${IconWrapper} {
      align-items: center;
      background-color: ${color ? color : colors.neutral.dark};
      border-radius: ${radius.circle};
      color: ${colors.neutral.fullLight};
      display: inline-flex;
      font-size: ${rem(pxToRem(24))};
      height: ${rem(pxToRem(32))};
      justify-content: center;
      margin-bottom: ${rem(pxToRem(8))};
      width: ${rem(pxToRem(32))};
    }

    ${ModalHeaderTitle} {
      color: ${color ? color : colors.neutral.dark};
    }
  `}
`;

const ModalContent = styled.section`
  ${({
    theme: {
      base: {
        fonts: { getTextsConfig }
      }
    }
  }) => `
    align-items: flex-start;
    display: flex;
    ${getTextsConfig('M')};
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;

    ${IconWrapper} {
      margin-right: ${rem(pxToRem(12))};
      margin-top: ${rem(pxToRem(2))};
    }
  `}
`;

const ModalHeaderSubTitle = styled.p`
  ${({
    theme: {
      base: {
        fonts: { semiBoldFont }
      }
    }
  }) => `
    font-family: '${semiBoldFont}';
  `}
`;

const ModalFooter = styled.footer`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
    align-items: center;
    background-color: ${colors.neutral.fullLight};
    display: flex;
    justify-content: center;
    margin-top: ${rem(pxToRem(24))};

    ${ButtonBase} {
      min-width: ${rem(pxToRem(140))};

      &:not(:last-child) {
          margin-right: ${rem(pxToRem(24))};
      }
    }
  `}
`;

const HeaderImageContainer = styled.div`
  margin: 0 auto ${rem(pxToRem(24))};
  max-width: ${rem(pxToRem(165))};
`;

interface ConfirmModalProps {
  headerIcon?: string;
  headerColor?: string;
  headerImage?: React.ReactNode;
  title: string;
  subtitle?: string;
  description: string;
  onClose: (confirm: boolean) => void;
  btnPrimaryText: string;
  btnPrimaryDisabled?: boolean;
  btnSecondaryText?: string;
}

const ActionBaseModal = ({
  onClose,
  headerIcon,
  headerImage,
  headerColor,
  title,
  subtitle,
  description,
  btnPrimaryText,
  btnPrimaryDisabled,
  btnSecondaryText
}: ConfirmModalProps): ReactElement => {
  return (
    <ModalBase onClose={() => onClose(false)}>
      <ModalWrapper>
        <ModalHeader color={headerColor}>
          {headerIcon ? (
            <Icon iconName={headerIcon} />
          ) : headerImage ? (
            <HeaderImageContainer>{headerImage}</HeaderImageContainer>
          ) : null}
          <ModalHeaderTitle>{title}</ModalHeaderTitle>
        </ModalHeader>
        <ModalContent>
          {subtitle && <ModalHeaderSubTitle>{subtitle}</ModalHeaderSubTitle>}
          <p>{description}</p>
        </ModalContent>
        <ModalFooter>
          {btnSecondaryText && (
            <ButtonSecondary
              onClick={() => onClose(false)}
              label={btnSecondaryText}
            />
          )}
          <ButtonPrimary
            onClick={() => onClose(true)}
            disabled={btnPrimaryDisabled}
            label={btnPrimaryText}
          />
        </ModalFooter>
      </ModalWrapper>
    </ModalBase>
  );
};

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  ...props
}: ConfirmModalProps) => {
  return <ActionBaseModal {...props} />;
};

export { ConfirmModal };
