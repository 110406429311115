import styled from '@emotion/styled';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement, useMemo } from 'react';

import { Icon, Label, TableHeaderData } from '..';
import { IconWrapper } from '../icon';
import { TableNoData } from './table-no-data/TableNoData';
import { TableOverlay } from './table-overlay/TableOverlay';
import { TablePagination } from './table-pagination/TablePagination';
import { TableToolbar } from './table-toolbar/TableToolbar';
import { useTableService, UseTableServiceProps } from './table.service';

const TableGridWrapper = styled.div`
  overflow-x: auto;
`;

const TableGrid = styled.table<{
  tableMediumSizeText?: boolean;
}>`
  ${({
    theme: {
      base: {
        fonts: { getTextsConfig }
      }
    },
    tableMediumSizeText
  }) => `
    width: 100%;
    ${tableMediumSizeText ? getTextsConfig('M') : getTextsConfig('S')};
  `}
`;

const TableGridHeader = styled.thead`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
    background-color: ${getRgbaStrFromHexColor(colors.primary.darkest, 0.05)};
    border-radius: ${rem(pxToRem(6))};
    color: ${colors.primary.dark};
    user-select: none;
  `}
`;

const TableGridRow = styled.tr`
  min-width: 100%;
`;

const TableGridHeaderItem = styled.th<{ withFilter?: boolean }>`
  ${({
    theme: {
      base: {
        colors,
        fonts: { semiBoldFont }
      }
    },
    withFilter
  }) => `
    color: ${colors.neutral.grayDark};
    font-family: ${semiBoldFont};
    height: ${withFilter ? rem(pxToRem(72)) : rem(pxToRem(32))};
    vertical-align: middle;
    padding: 0 ${rem(pxToRem(8))};
    position: relative;
    text-align: left;


    &:not(:first-of-type) {
      &:before {
        background-color: ${colors.primary.darkest};
        content: '';
        height: ${withFilter ? rem(pxToRem(52)) : rem(pxToRem(16))};
        left: 0;
        opacity: 0.2;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: ${rem(pxToRem(1))};
      }
    }
  `}
`;

const TableGridItem = styled.td`
  vertical-align: middle;
  padding: 0 ${rem(pxToRem(8))};
`;

const TableGridBody = styled.tbody``;

const TableGridBodyRow = styled(TableGridRow)<{ isSelected?: boolean }>`
  ${({
    theme: {
      base: { colors }
    },
    isSelected
  }) => `
    border-bottom: 1px solid ${getRgbaStrFromHexColor(
      colors.primary.darkest,
      0.2
    )};

    &:hover {
      background-color: ${getRgbaStrFromHexColor(colors.primary.default, 0.08)};
      cursor: pointer;
    }

    ${
      isSelected
        ? `background-color: ${getRgbaStrFromHexColor(
            colors.primary.default,
            0.08
          )}`
        : ''
    };
 `}
`;

const SelectorGridColumn = styled.div`
  align-items: center;
  display: flex;
`;

const LabelHeader = styled.span`
  ${() => `
    width: 100%;

    ${IconWrapper} {
      font-size: ${rem(pxToRem(20))};
    }
 `}
`;

const Filter = styled.div`
  ${() => `
    align-items: center;
    display: flex;
    width: 100%;
 `}

  ${Label} {
    width: 100%;
  }
`;

const DraggableSeparator = styled.div<{ disableResizing?: boolean }>`
  ${({
    theme: {
      base: { zindex }
    },
    disableResizing
  }) => `
    cursor: ${disableResizing ? `col-resize` : `default`};
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    transform: translateX(-50%);
    width: 1px;
    z-index: ${zindex.above};
  `}
`;

export type TableProps<T extends object> = UseTableServiceProps<T>;

const Table = <T extends object>(props: TableProps<T>): ReactElement => {
  const tableInstance = useTableService<T>(props);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    disableResizing,
    page,
    columns
  } = tableInstance;

  const hasAtLeastOneFilter = useMemo(() => {
    return columns.some((x) => x.canFilter);
  }, [columns]);

  return (
    <>
      <TableToolbar total={props.count} instance={tableInstance} />
      <TableGridWrapper>
        <TableGrid {...getTableProps()} tableMediumSizeText>
          <TableGridHeader>
            {headerGroups.map((headerGroup, i) => (
              <TableGridRow
                {...headerGroup.getHeaderGroupProps()}
                key={`tr-${i}`}>
                {(headerGroup.headers as TableHeaderData<T>).map(
                  (column, i) => (
                    <TableGridHeaderItem
                      withFilter={hasAtLeastOneFilter}
                      {...column.getHeaderProps({
                        style: {
                          width: column.width,
                          minWidth: column.minWidth,
                          maxWidth: column.maxWidth
                        }
                      })}
                      key={`th-${i}`}>
                      <LabelHeader {...column.getSortByToggleProps?.()}>
                        {column.render('Header')}
                        {column.canSort && column.isSorted && (
                          <Icon
                            iconName={
                              column.isSortedDesc ? 'arrow-down' : 'arrow-up'
                            }
                          />
                        )}
                      </LabelHeader>
                      {column.canResize && (
                        <DraggableSeparator
                          {...column.getResizerProps?.()}
                          disableResizing={disableResizing}
                        />
                      )}
                      {column.canFilter && (
                        <Filter>{column.render('Filter')}</Filter>
                      )}
                    </TableGridHeaderItem>
                  )
                )}
              </TableGridRow>
            ))}
          </TableGridHeader>
          <TableGridBody {...getTableBodyProps()}>
            <TableNoData
              show={!props.isLoading && !tableInstance.rows.length}
              columns={tableInstance.columns.length}
              message={props.noDataMessage}
            />
            {page.map((row) => {
              prepareRow(row);
              return (
                <TableGridBodyRow {...row.getRowProps()} key={`tr-${row.id}`}>
                  {row.cells.map((cell, ci) => (
                    <TableGridItem
                      {...cell.getCellProps({
                        style: {
                          textAlign: cell.column.align,
                          width: cell.column.width,
                          minWidth: cell.column.minWidth,
                          maxWidth: cell.column.maxWidth
                        }
                      })}
                      key={`td-${ci}`}>
                      {cell.render('Cell')}
                    </TableGridItem>
                  ))}
                </TableGridBodyRow>
              );
            })}
          </TableGridBody>
        </TableGrid>
      </TableGridWrapper>
      <TableOverlay show={props.isLoading} />
      {props.isPaginable && <TablePagination<T> instance={tableInstance} />}
    </>
  );
};

export {
  Table,
  TableGrid,
  TableGridHeader,
  TableGridRow,
  TableGridHeaderItem,
  TableGridItem,
  TableGridBody,
  TableGridBodyRow,
  SelectorGridColumn
};
