import { IconWrapper } from '@app/shared/components';
import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';

const SectionTitle = styled.h2`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getHeadlinesConfig, boldFont }
      }
    }
  }) => `
    color: ${colors.primary.default};
    ${getHeadlinesConfig('XS')};
    font-family: ${boldFont};
    margin-bottom: ${rem(pxToRem(24))};
  `}
`;

const IconItem = styled.div`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
      align-items: center;
      display: flex;

      ${IconWrapper} {
        color: ${colors.neutral.grayDark};
        font-size: ${rem(pxToRem(24))};
        margin-right: ${rem(pxToRem(12))};
      }
   `}
`;

const IconItemLabel = styled.label`
  ${({
    theme: {
      base: {
        fonts: { getTextsConfig }
      }
    }
  }) => `
      ${getTextsConfig('M')};
      line-height: ${rem(pxToRem(24))};
   `}
`;

const EmptyLabel = styled.label`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig }
      }
    }
  }) => `
    color: ${colors.neutral.grayDark};
    ${getTextsConfig('S')};
  `}
`;

export { SectionTitle, IconItem, IconItemLabel, EmptyLabel };
