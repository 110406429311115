import styled from '@emotion/styled';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { forwardRef, Ref } from 'react';

import { Icon } from '../icon';

type ButtonIconStyle = 'inverse' | 'secondary';

type ButtonIconBaseProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const ButtonIconBase = styled.button<ButtonIconBaseProps>`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
        color: ${colors.primary.default};
        display: inline-block;
        line-height: 0;

        &:hover {
          color: ${colors.primary.dark};
          cursor: pointer;
        }

        &:active {
          color: ${colors.primary.default};
        }

        &:disabled {
          color: ${colors.primary.darkest};
          pointer-events: none;
          opacity: 0.3;
        }

        > div {
          display: inherit;
          line-height: 0;
          text-align: center;
        }

        &.inverse {
          color: ${getRgbaStrFromHexColor(colors.primary.darkest, 0.4)};

          &:hover {
            color: ${colors.primary.default};
          }

          &:active {
            color: ${colors.primary.dark};
          }
        }

        &.secondary {
          background-color: ${colors.neutral.fullLight};
          color: ${colors.primary.default};
          border: 2px solid ${colors.neutral.gray};
          border-radius: 50%;
          padding: ${rem(pxToRem(2))};

          &:hover:not(:disabled):not(:active),
          &:focus {
            background-color: ${colors.primary.dark};
            box-shadow: 0 ${rem(pxToRem(-40))} 0 0 ${colors.primary.dark} inset;
            border-color: ${colors.primary.dark};
            color: ${colors.neutral.fullLight};
          }

          &:active {
            background-color: ${colors.primary.default};
            border-color: ${colors.primary.default};
            box-shadow: 0 ${rem(pxToRem(-40))} 0 0 ${
    colors.primary.default
  } inset;
            color: ${colors.neutral.fullLight};
          }

          &:disabled {
            border-color: ${getRgbaStrFromHexColor(colors.neutral.dark, 0.15)};
            color: ${getRgbaStrFromHexColor(colors.primary.darkest, 0.4)};
          }
        }
    `}
`;

interface ButtonIconProps extends ButtonIconBaseProps {
  iconName: string;
  iconSize?: number;
  iconColor?: string;
  btnStyle?: ButtonIconStyle;
}

const ButtonIcon = forwardRef(
  (
    { iconName, iconSize, iconColor, btnStyle, ...restProps }: ButtonIconProps,
    ref: Ref<HTMLButtonElement>
  ) => (
    <ButtonIconBase className={btnStyle} {...restProps} ref={ref}>
      <div data-testid='button-icon'>
        <Icon iconName={iconName} iconSize={iconSize} iconColor={iconColor} />
      </div>
    </ButtonIconBase>
  )
);
ButtonIcon.displayName = 'ButtonIcon';

const ButtonIconInverse = forwardRef(
  (props: ButtonIconProps, ref: Ref<HTMLButtonElement>) => (
    <ButtonIcon btnStyle='inverse' {...props} ref={ref} />
  )
);

const ButtonIconSecondary = forwardRef(
  (props: ButtonIconProps, ref: Ref<HTMLButtonElement>) => (
    <ButtonIcon btnStyle='secondary' {...props} ref={ref} />
  )
);

ButtonIconInverse.displayName = 'ButtonIconInverse';
ButtonIconSecondary.displayName = 'ButtonIconSecondary';

export { ButtonIcon, ButtonIconInverse, ButtonIconSecondary, ButtonIconBase };
