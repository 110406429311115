import { configuration } from '@configuration';
import { axiosClient } from '@services/http';

import { ApiService } from './apiService';

const { apiUrl } = configuration;

const apiService = new ApiService(apiUrl, axiosClient);

export { apiService };
