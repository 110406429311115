import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { PropsWithChildren, ReactElement } from 'react';

export type GridTemplateAlign = 'top' | 'center' | 'bottom';

type GridTemplateWrapperProps = {
  align?: GridTemplateAlign;
  columns?: number;
  gap?: number;
};

const GridTemplateDictionary: { [key: string]: string } = {
  center: 'center',
  bottom: 'flex-end',
  top: 'flex-start'
};

const GridTemplateWrapper = styled.div<GridTemplateWrapperProps>`
  ${({ align, columns, gap }) => `
        align-items: ${GridTemplateDictionary[align ?? 'top']};
        display: grid;
        gap: ${rem(pxToRem(gap ? gap : 24))};
        grid-template-columns: repeat(${columns}, 1fr);
    `}
`;

const GridTemplate = ({
  align,
  columns = 1,
  gap,
  children
}: PropsWithChildren<GridTemplateWrapperProps>): ReactElement => {
  return (
    <GridTemplateWrapper align={align} columns={columns} gap={gap}>
      {children}
    </GridTemplateWrapper>
  );
};

export { GridTemplate, GridTemplateWrapper };
