import { UpdateUserProfile, UserProfile } from '@domain/user-profile';
import {
  getUserProfile,
  setUserProfile
} from '@services/user-profile/userProfileService';
import create from 'zustand';

type UserProfileStoreActions = {
  loadUserProfile: () => Promise<UserProfile>;
  setUserProfile: (newUserProfile: UpdateUserProfile) => Promise<void>;
};

type UserProfileStore = {
  userProfile: UserProfile;
} & UserProfileStoreActions;

const useUserProfile = create<UserProfileStore>((set, get) => ({
  userProfile: {
    id: '',
    userName: '',
    email: '',
    language: '',
    imageUrl: '',
    phone: '',
    isActive: true
  },
  loadUserProfile: async () => {
    let userProfile = get().userProfile;

    if (userProfile.email === '') {
      userProfile = await getUserProfile();
      set({ userProfile });
    }

    return userProfile;
  },
  setUserProfile: async (newUserProfile: UpdateUserProfile) => {
    const userProfile = get().userProfile;
    set({ userProfile: { ...userProfile, ...newUserProfile } });
    await setUserProfile(newUserProfile);
  }
}));

export { useUserProfile };
