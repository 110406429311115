import i18n from '@services/i18n';
import { getFilenameExtension } from '@utils/files-utils';
import * as yup from 'yup';

import {
  FLOORPLAN_CONSTRAINTS,
  FLOORPLAN_FILE_DXF_EXTENSION_ALLOWED,
  MAX_FILE_DXF_SIZE_BYTES
} from './floorplanConstraints';

export interface FloorplanDetailFormModel {
  name: string;
  dxfFile: FileList;
  imageModelFile: File;
  dxfFileUrl: string;
}

const translateDxfFileError = () => (
  params?: Partial<yup.TestMessageParams>
): string =>
  i18n.t('fieldValidations.custom.fileMaxSize', {
    ...params,
    label: i18n.t('floorplans:fieldNames.dxfFile'),
    maxKb: MAX_FILE_DXF_SIZE_BYTES / 1024
  });

const translateDxfFileTypeError = () => (
  params?: Partial<yup.TestMessageParams>
): string =>
  i18n.t('fieldValidations.mixed.notType', {
    ...params,
    label: i18n.t('floorplans:fieldNames.name')
  });

const floorplanDetailFormSchema = yup.object().shape({
  name: yup
    .string()
    .label('floorplans:fieldNames.name')
    .required()
    .min(FLOORPLAN_CONSTRAINTS.NAME.MIN_LENGTH)
    .max(FLOORPLAN_CONSTRAINTS.NAME.MAX_LENGTH),
  dxfFile: yup
    .mixed()
    .nullable()
    .test(
      'validSize',
      translateDxfFileError(),
      (value: FileList): value is FileList =>
        !value || !value.length || value[0].size < MAX_FILE_DXF_SIZE_BYTES
    )
    .test(
      'validType',
      translateDxfFileTypeError(),
      (value: FileList): value is FileList =>
        !value ||
        !value.length ||
        FLOORPLAN_FILE_DXF_EXTENSION_ALLOWED.some(
          (t) => t === getFilenameExtension(value[0].name).toLowerCase()
        )
    ),
  dxfFileUrl: yup.string().required()
});

export { floorplanDetailFormSchema };
