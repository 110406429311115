import styled from '@emotion/styled';

export const FileUploaderSummaryContainer = styled.figcaption``;

export const FileUploaderSummaryChooseImage = styled.p`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getHeadlinesConfig, regularFont }
      }
    }
  }) => `
    color: ${colors.neutral.dark};
    ${getHeadlinesConfig('XS')};
    font-family: ${regularFont};
  `}
`;

export const FileUploaderSummaryConditions = styled.p`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig }
      }
    }
  }) => `
    color: ${colors.neutral.grayDark};
    ${getTextsConfig('S')};
  `}
`;
