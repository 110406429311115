import { InputCheckBox, InputCheckItem } from '@app/shared/components';
import { useBuildings } from '@stores/buildingsStore';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

const Filters = (): ReactElement => {
  const { t } = useTranslation('buildings');

  const { unpublish, archived, closed } = useBuildings((s) => s.filter);
  const setUnpublish = useBuildings((s) => s.setUnpublish);
  const setArchived = useBuildings((s) => s.setArchived);
  const setClosed = useBuildings((s) => s.setClosed);

  const menuCheckbox: InputCheckItem[] = [
    {
      id: 'unPublish',
      label: t('unpublished-buildings'),
      checked: unpublish,
      onChange: (_, { isChecked }) => setUnpublish(isChecked)
    },
    {
      id: 'archived',
      label: t('archived-buildings'),
      checked: archived,
      onChange: (_, { isChecked }) => setArchived(isChecked)
    },
    {
      id: 'closed',
      label: t('closed-buildings'),
      checked: closed,
      onChange: (_, { isChecked }) => setClosed(isChecked)
    }
  ];

  return <InputCheckBox options={menuCheckbox} />;
};

export default Filters;
