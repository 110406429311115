import { EditBuildingFormLocation } from '@app/facility-management/buildings/buildings-form/routes/buildingFormLocation';
import { FloorplansLocation } from '@app/facility-management/floorplans';
import { CardDropdown, MenuActionsItem } from '@app/shared/components';
import { useDialog } from '@app/shared/components/dialog';
import { notifySuccess } from '@app/shared/notifications';
import { Building, BuildingActionsEnum } from '@domain/buildings';
import { useTheme } from '@emotion/react';
import {
  useArchiveBuilding,
  useCloseBuilding,
  useDeleteBuilding,
  useOpenBuilding,
  usePublishBuilding,
  useUnarchiveBuilding,
  useUnpublishBuilding
} from '@hooks/buildings';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface BuildingsCardMenuProps {
  building: Building;
}

const BuildingsCardMenu = ({
  building
}: BuildingsCardMenuProps): ReactElement => {
  const {
    base: { colors }
  } = useTheme();

  const { t } = useTranslation('buildings');

  const { showDialog } = useDialog();

  const history = useHistory();

  const {
    mutateAsync: openBuilding,
    isSuccess: isOpenBuildingSuccess
  } = useOpenBuilding(building.venueId);
  const {
    mutateAsync: closeBuilding,
    isSuccess: isCloseBuildingSuccess
  } = useCloseBuilding(building.venueId);
  const {
    mutateAsync: deleteBuilding,
    isSuccess: isDeleteBuildingSuccess
  } = useDeleteBuilding(building.venueId);
  const {
    mutateAsync: archiveBuilding,
    isSuccess: isArchiveBuildingSuccess
  } = useArchiveBuilding(building.venueId);
  const {
    mutateAsync: unarchiveBuilding,
    isSuccess: isUnarchiveBuildingSuccess
  } = useUnarchiveBuilding(building.venueId);
  const {
    mutateAsync: unpublishBuilding,
    isSuccess: isUnpublishBuildingSuccess
  } = useUnpublishBuilding(building.venueId);
  const {
    mutateAsync: publishBuilding,
    isSuccess: isPublishBuildingSuccess
  } = usePublishBuilding(building.venueId);

  useEffect(() => {
    if (
      isOpenBuildingSuccess ||
      isCloseBuildingSuccess ||
      isDeleteBuildingSuccess ||
      isArchiveBuildingSuccess ||
      isUnarchiveBuildingSuccess ||
      isUnpublishBuildingSuccess ||
      isPublishBuildingSuccess
    ) {
      notifySuccess(t('shared:toast.operation-success'));
    }
  }, [
    isOpenBuildingSuccess,
    isCloseBuildingSuccess,
    isDeleteBuildingSuccess,
    isArchiveBuildingSuccess,
    isUnarchiveBuildingSuccess,
    isUnpublishBuildingSuccess,
    isPublishBuildingSuccess
  ]);

  const handleOpenBuilding = (buildingId: string): void => {
    showDialog({
      open: true,
      title: t('open-prompt.title'),
      subtitle: t('open-prompt.subtitle'),
      message: t('open-prompt.body'),
      headerIcon: 'lock-open-alt',
      headerColor: colors.system.info,
      btnPrimaryText: t('open-prompt.confirmButtonText'),
      onConfirm: {
        callback: () => openBuilding(buildingId)
      }
    });
  };

  const handleCloseBuilding = (buildingId: string): void => {
    showDialog({
      open: true,
      title: t('close-prompt.title'),
      subtitle: t('close-prompt.subtitle'),
      message: t('close-prompt.body'),
      headerIcon: 'lock-alt',
      headerColor: colors.system.error,
      btnPrimaryText: t('close-prompt.confirmButtonText'),
      onConfirm: {
        callback: () => closeBuilding(buildingId)
      }
    });
  };

  const handleDeleteBuilding = (buildingId: string): void => {
    showDialog({
      open: true,
      title: t('delete-prompt.title'),
      subtitle: t('delete-prompt.subtitle'),
      message: t('delete-prompt.body'),
      headerIcon: 'trash',
      headerColor: colors.system.error,
      btnPrimaryText: t('delete-prompt.confirmButtonText'),
      onConfirm: {
        callback: () => deleteBuilding(buildingId)
      }
    });
  };

  const handleArchiveBuilding = (buildingId: string): void => {
    showDialog({
      open: true,
      title: t('archive-prompt.title'),
      subtitle: t('archive-prompt.subtitle'),
      message: t('archive-prompt.body'),
      headerIcon: 'archive',
      headerColor: colors.system.error,
      btnPrimaryText: t('archive-prompt.confirmButtonText'),
      onConfirm: {
        callback: () => archiveBuilding(buildingId)
      }
    });
  };

  const handleUnarchiveBuilding = (buildingId: string): void => {
    showDialog({
      open: true,
      title: t('unarchive-prompt.title'),
      subtitle: t('unarchive-prompt.subtitle'),
      message: t('unarchive-prompt.body'),
      headerIcon: 'unarchive',
      headerColor: colors.system.error,
      btnPrimaryText: t('unarchive-prompt.confirmButtonText'),
      onConfirm: {
        callback: () => unarchiveBuilding(buildingId)
      }
    });
  };

  const handleUnpublishBuilding = (buildingId: string): void => {
    showDialog({
      open: true,
      title: t('unpublish-prompt.title'),
      subtitle: t('unpublish-prompt.subtitle'),
      message: t('unpublish-prompt.body'),
      headerIcon: 'eye-slash',
      headerColor: colors.system.info,
      btnPrimaryText: t('unpublish-prompt.confirmButtonText'),
      onConfirm: {
        callback: () => unpublishBuilding(buildingId)
      }
    });
  };

  const handlePublishBuilding = (buildingId: string): void => {
    showDialog({
      open: true,
      title: t('publish-prompt.title'),
      subtitle: t('publish-prompt.subtitle'),
      message: t('publish-prompt.body'),
      headerIcon: 'eye',
      headerColor: colors.system.info,
      btnPrimaryText: t('publish-prompt.confirmButtonText'),
      onConfirm: {
        callback: () => publishBuilding(buildingId)
      }
    });
  };

  const menuOptions: MenuActionsItem[] = [
    {
      label: t('actions.viewFloorplans'),
      iconName: 'floormap',
      show: building.activeActions.some(
        (x) => x.identifier === BuildingActionsEnum.Viewfloors
      ),
      onClick: () =>
        history.push(
          FloorplansLocation.toUrl({
            venueId: building.venueId,
            buildingId: building.id
          })
        )
    },
    {
      label: t('actions.edit'),
      iconName: 'edit',
      show: building.activeActions.some(
        (x) => x.identifier === BuildingActionsEnum.Edit
      ),
      onClick: () =>
        history.push(
          EditBuildingFormLocation.toUrl({
            buildingId: building.id,
            venueId: building.venueId
          })
        )
    },
    {
      label: t('actions.publish'),
      iconName: 'eye',
      show: building.activeActions.some(
        (x) => x.identifier === BuildingActionsEnum.Publish
      ),
      onClick: () => handlePublishBuilding(building.id)
    },
    {
      label: t('actions.unpublish'),
      iconName: 'eye-slash',
      show: building.activeActions.some(
        (x) => x.identifier === BuildingActionsEnum.Unpublish
      ),
      onClick: () => handleUnpublishBuilding(building.id)
    },
    {
      label: t('actions.open'),
      iconName: 'lock-open-alt',
      show: building.activeActions.some(
        (x) => x.identifier === BuildingActionsEnum.Open
      ),
      onClick: () => handleOpenBuilding(building.id)
    },
    {
      label: t('actions.close'),
      iconName: 'lock-alt',
      show: building.activeActions.some(
        (x) => x.identifier === BuildingActionsEnum.Close
      ),
      onClick: () => handleCloseBuilding(building.id)
    },
    {
      label: t('actions.archive'),
      iconName: 'archive',
      show: building.activeActions.some(
        (x) => x.identifier === BuildingActionsEnum.Archive
      ),
      onClick: () => handleArchiveBuilding(building.id)
    },
    {
      label: t('actions.unarchive'),
      iconName: 'unarchive',
      show: building.activeActions.some(
        (x) => x.identifier === BuildingActionsEnum.Unarchive
      ),
      onClick: () => handleUnarchiveBuilding(building.id)
    },
    {
      label: t('actions.delete'),
      iconName: 'trash',
      show: building.activeActions.some(
        (x) => x.identifier === BuildingActionsEnum.Delete
      ),
      onClick: () => {
        handleDeleteBuilding(building.id);
      }
    }
  ];

  return <CardDropdown dropdownOptions={menuOptions} />;
};

export default BuildingsCardMenu;
