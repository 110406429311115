import React, { ReactElement } from 'react';
import { Route } from 'react-router';

import { NotFound } from '../pages-error';

const NotFoundRoute = (): ReactElement => {
  return <Route component={NotFound} />;
};

export { NotFoundRoute };
