import { Location } from '@app/shared/routes';
import { RouteHelper } from '@utils/helpers/routeHelper';

export interface VenuesListLocationParams {
  venueId: string;
}

const VenuesListLocation = new Location<VenuesListLocationParams>({
  path: '/facility-management/venues/:venueId/buildings/venues',
  pathParamDefs: {
    venueId: RouteHelper.stringOptionalParamValidation()
  }
});

export { VenuesListLocation };
