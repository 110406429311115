import * as yup from 'yup';
import { ObjectSchemaDefinition } from 'yup';

import { WORKPLACE_CONSTRAINTS } from '../shared/workplaceConstraints';
import { WorkplacePlaceType, WorkStationType } from '../workplace';

export interface WorkplaceEntity {
  spaceId: string;
  type: string;
  label: string;
  enabled: string;
  lockSite: string;
  lan: string;
}

export interface WorkstationEntity extends WorkplaceEntity {
  workstationtype: string;
  workplace: string;
  elevatedTable: string;
  wheelchair: string;
  extraSocketStrips: string;
  bookedUserId: string;
  bookedUserName: string;
}

export interface MeetingRoomEntity extends WorkplaceEntity {
  totalOccupancy: string;
  allowedCapacity: string;
  externalVisitors: string;
  vr: string;
  whiteboard: string;
  coffeeCorner: string;
  presentationScreen: string;
  intercom: string;
  audio: string;
}

export type WorkplaceFormModel = {
  [key in keyof WorkplaceEntity]: string;
};

export type WorkstationFormModel = {
  [key in keyof WorkstationEntity]: string;
};

export type MeetingRoomFormModel = {
  [key in keyof MeetingRoomEntity]: string;
};

const booleanTransform = (value: unknown): unknown => {
  let booleanValue = false;
  if (typeof value === 'boolean' || typeof value === 'number') {
    booleanValue = Boolean(value);
  } else {
    booleanValue = value == 'true' || value == '1';
  }
  return booleanValue || false ? '1' : '';
};

const workplaceFieldValidator = (
  type: WorkplacePlaceType,
  fieldSchema: yup.StringSchema
): yup.StringSchema =>
  yup.string().when('type', {
    is: (value) => value === type.toString(),
    then: fieldSchema
  });

const workstationFormModel = {
  workstationtype: workplaceFieldValidator(
    WorkplacePlaceType.Workstation,
    yup.string().label('workplaces:fieldNames.workstationType').required()
  ),
  bookedUserId: workplaceFieldValidator(
    WorkplacePlaceType.Workstation,
    yup.string().when('workstationtype', {
      is: (value) => value == WorkStationType.FixedDesk,
      then: yup.string().label('workplaces:fieldNames.bookedUser').required()
    })
  ),
  workplace: workplaceFieldValidator(
    WorkplacePlaceType.Workstation,
    yup.string().label('workplaces:fieldNames.workstationType').required()
  ),
  elevatedTable: workplaceFieldValidator(
    WorkplacePlaceType.Workstation,
    yup
      .string()
      .transform(booleanTransform)
      .label('workplaces:fieldNames.elevatedTable')
      .optional()
  ),
  wheelchair: workplaceFieldValidator(
    WorkplacePlaceType.Workstation,
    yup
      .string()
      .transform(booleanTransform)
      .label('workplaces:fieldNames.workstation.wheelchair')
      .optional()
  ),
  // extraSocketStrips: workplaceFieldValidator(
  //   WorkplacePlaceType.Workstation,
  //   yup
  //     .string()
  //     .transform(booleanTransform)
  //     .label('workplaces:fieldNames.wheelchair')
  //     .optional()
  // )
} as ObjectSchemaDefinition<Partial<WorkplaceFormModel>>;

const meetingRoomFormModel = {
  totalOccupancy: workplaceFieldValidator(
    WorkplacePlaceType.MeetingRoom,
    yup
      .string()
      .label('workplaces:fieldNames.totalOccupancy')
      .required()
      .min(1)
      .max(3)
  ),
  allowedCapacity: workplaceFieldValidator(
    WorkplacePlaceType.MeetingRoom,
    yup
      .string()
      .label('workplaces:fieldNames.allowedCapacity')
      .required()
      .min(1)
      .max(3)
  ),
  externalVisitors: workplaceFieldValidator(
    WorkplacePlaceType.MeetingRoom,
    yup
      .string()
      .transform(booleanTransform)
      .label('workplaces:fieldNames.externalVisitors')
      .optional()
  ),
  vr: workplaceFieldValidator(
    WorkplacePlaceType.MeetingRoom,
    yup
      .string()
      .transform(booleanTransform)
      .label('workplaces:fieldNames.vr')
      .optional()
  ),
  whiteboard: workplaceFieldValidator(
    WorkplacePlaceType.MeetingRoom,
    yup
      .string()
      .transform(booleanTransform)
      .label('workplaces:fieldNames.whiteboard')
      .optional()
  ),
  coffeeCorner: workplaceFieldValidator(
    WorkplacePlaceType.MeetingRoom,
    yup
      .string()
      .transform(booleanTransform)
      .label('workplaces:fieldNames.coffeeCorner')
      .optional()
  ),
  presentationScreen: workplaceFieldValidator(
    WorkplacePlaceType.MeetingRoom,
    yup
      .string()
      .transform(booleanTransform)
      .label('workplaces:fieldNames.presentationScreen')
      .optional()
  ),
  intercom: workplaceFieldValidator(
    WorkplacePlaceType.MeetingRoom,
    yup
      .string()
      .transform(booleanTransform)
      .label('workplaces:fieldNames.intercom')
      .optional()
  ),
  audio: workplaceFieldValidator(
    WorkplacePlaceType.MeetingRoom,
    yup
      .string()
      .transform(booleanTransform)
      .label('workplaces:fieldNames.audio')
      .optional()
  )
} as ObjectSchemaDefinition<Partial<MeetingRoomFormModel>>;

export const workplaceFormModel = yup.object<WorkplaceFormModel>().shape(
  {
    type: yup
      .string()
      .label('workplaces:fieldNames.place')
      .transform((value) => value || WorkplacePlaceType.Workstation.toString())
      .required(),
    label: yup
      .string()
      .label('workplaces:fieldNames.label')
      .required()
      .min(WORKPLACE_CONSTRAINTS.LABEL.MIN_LENGTH)
      .max(WORKPLACE_CONSTRAINTS.LABEL.MAX_LENGTH),
    enabled: yup
      .string()
      .transform(booleanTransform)
      .label('workplaces:fieldNames.publishSite')
      .optional(),
    lockSite: yup
      .string()
      .transform(booleanTransform)
      .label('workplaces:fieldNames.lockSite')
      .optional(),
    //lan: yup.string().label('workplaces:fieldNames.lan').required(),
    ...workstationFormModel,
    ...meetingRoomFormModel
  },
  [
    ['workstationtype', 'bookedUserId'],
    ...Object.keys(workstationFormModel).map<[string, string]>((key) => [
      'type',
      key
    ]),
    ...Object.keys(meetingRoomFormModel).map<[string, string]>((key) => [
      'type',
      key
    ])
  ]
);
