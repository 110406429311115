import { ButtonIcon } from '@app/shared/components';
import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import { waveCommands } from '@wave-engine/wave-viewer';
import React from 'react';

import { PanelFloat } from './WavePanels';

const PanelFloatWrapper = styled.div`
  left: ${rem(pxToRem(40))};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const WaveCameraActionsPanel: React.FC = () => {
  return (
    <PanelFloatWrapper>
      <PanelFloat column>
        <ButtonIcon
          iconName='plus32'
          onClick={() => waveCommands.camera.zoomInCamera()}
        />
        <ButtonIcon
          iconName='minus'
          onClick={() => waveCommands.camera.zoomOutCamera()}
        />
        <ButtonIcon
          iconName='locate'
          onClick={() => {
            waveCommands.camera.enableOrthographicCamera();
            waveCommands.camera.resetCamera();
          }}
        />
        <ButtonIcon
          iconName='3d'
          onClick={() => {
            waveCommands.camera.resetCamera();
            waveCommands.camera.enablePerspectiveCamera({
              x: 1.06,
              y: -0.37,
              z: 0.0
            });
          }}
        />
      </PanelFloat>
    </PanelFloatWrapper>
  );
};

export { WaveCameraActionsPanel };
