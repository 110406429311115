import { Modal } from '@app/shared/components';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { PrivacyPolicyLocation } from '../routes';
import { PrivacyPolicyCreateForm } from './privacy-policy-create-form/PrivacyPolicyCreateForm';

const PrivacyPolicyForm = (): ReactElement => {
  const { t } = useTranslation('privacy-policy');

  const history = useHistory();

  const onGoBack = (): void => {
    history.replace(PrivacyPolicyLocation.toUrl());
  };

  return (
    <Modal title={t('creation-title')} onClose={onGoBack}>
      <PrivacyPolicyCreateForm
        onCreatePrivacyPolicy={onGoBack}
        onCancel={onGoBack}
      />
    </Modal>
  );
};

export { PrivacyPolicyForm };
