import { BasePlacement, Modifier, State } from '@popperjs/core';
import { useMemo } from 'react';

const rotationDegreesByPosition: { [key in BasePlacement]: string } = {
  top: '0deg',
  right: '90deg',
  bottom: '180deg',
  left: '-90deg'
};

const arrowPositionModifier = {
  name: 'arrowPositionTransform',
  enabled: true,
  phase: 'beforeWrite',
  fn: ({ state }): State | void => {
    const screenPosition = Object.keys(rotationDegreesByPosition).find((key) =>
      state.placement.startsWith(key)
    ) as BasePlacement;

    if (screenPosition) {
      const rotationDegrees = rotationDegreesByPosition[screenPosition];

      const xTransform = Math.round(state.modifiersData.arrow?.x ?? 0);
      const yTransform = Math.round(state.modifiersData.arrow?.y ?? 0);

      const translate = `translate(${xTransform}px, ${yTransform}px)`;

      const transform = `${translate} rotate(${rotationDegrees})`;

      const arrowStyles = { ...state.styles.arrow, transform };

      arrowStyles[screenPosition] = '100%';

      return {
        ...state,
        styles: {
          ...state.styles,
          arrow: arrowStyles
        }
      };
    }

    return state;
  }
} as Modifier<string, unknown>;

type UsePopperArrowModifier = {
  arrowModifier: Modifier<string, unknown>;
};

const usePopperArrowModifier = (): UsePopperArrowModifier =>
  useMemo(
    () => ({
      arrowModifier: arrowPositionModifier
    }),
    []
  );

export { usePopperArrowModifier };
