import { Location } from '@app/shared/routes';
import { RouteHelper } from '@utils/helpers/routeHelper';

export interface FloorplansLocationParams {
  venueId: string;
  buildingId: string;
}

export const FloorplansLocation = new Location<FloorplansLocationParams>({
  path: '/facility-management/venues/:venueId/buildings/:buildingId/floorplans',
  pathParamDefs: {
    venueId: RouteHelper.stringParamValidation(),
    buildingId: RouteHelper.stringParamValidation()
  }
});
