import { configuration } from '@configuration';
import { css, Global } from '@emotion/react';
import { customReset } from '@styles/CustomReset';
import React from 'react';

import { DashboardStyles } from './DashboardStyles';
import { BaleaStyles as EDGEBaleaStyles } from './theme/EDGE/BaleaStyles';
import { BaleaStyles as SCRMBaleaStyles } from './theme/SCRM/BaleaStyles';
import { BaleaStyles as SMOBaleaStyles } from './theme/SMO/BaleaStyles';
import { pxToRem, rem } from './utils/sizes';

const BaleaStylesConfig = {
  SMO: SMOBaleaStyles,
  EDGE: EDGEBaleaStyles,
  SCRM: SCRMBaleaStyles
};

const FontsConfig = {
  SMO: { 
    regular: {
      name:'Montserrat Regular',
      path:'/static/fonts/montserrat/Montserrat-Regular.ttf'
    },
    medium: {
      name:'Montserrat Medium',
      path:'/static/fonts/montserrat/Montserrat-Medium.ttf'
    },
    semibold: {
      name:'Montserrat SemiBold',
      path:'/static/fonts/montserrat/Montserrat-SemiBold.ttf'
    },
    bold: {
      name:'Montserrat Bold',
      path:'/static/fonts/montserrat/Montserrat-Bold.ttf'
    }
  },
  EDGE: 
  { 
    regular: {
      name:'Montserrat Regular',
      path:'/static/fonts/montserrat/Montserrat-Regular.ttf'
    },
    medium: {
      name:'Montserrat Medium',
      path:'/static/fonts/montserrat/Montserrat-Medium.ttf'
    },
    semibold: {
      name:'Montserrat SemiBold',
      path:'/static/fonts/montserrat/Montserrat-SemiBold.ttf'
    },
    bold: {
      name:'Montserrat Bold',
      path:'/static/fonts/montserrat/Montserrat-Bold.ttf'
    }
  },
  SCRM:{ 
    regular: {
      name:'Lidl Font Pro',
      path:'/static/fonts/LidlFontPro/LidlFontPro-Regular.ttf'
    },
    medium: {
      name:'Lidl Font Pro Medium',
      path:'/static/fonts/LidlFontPro/LidlFontPro-Medium.ttf'
    },
    semibold: {
      name:'Lidl Font Pro SemiBold',
      path:'/static/fonts/LidlFontPro/LidlFontPro-Bold.ttf'
    },
    bold: {
      name:'Lidl Font Pro Bold',
      path:'/static/fonts/LidlFontPro/LidlFontPro-Bold.ttf'
    }   
  }
};

const GlobalStyles: React.FC = () => {
  const { theme } = configuration;
  const BaleaStyles = BaleaStylesConfig[theme];
  const FontConfig = FontsConfig[theme];

  return (
    <Global
      styles={({
        base: {
          colors,
          fonts: { regularFont },
          scrollbar
        }
      }) => css`
        ${customReset}

        @font-face {
          font-family: '${FontConfig.regular.name}';
          src: url('${FontConfig.regular.path}') 
          format('truetype');
          font-weight: 400;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: '${FontConfig.medium.name}';
          src: url('${FontConfig.medium.path}') 
          format('truetype');
          font-weight: 500;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: '${FontConfig.semibold.name}';
          src: url('${FontConfig.semibold.path}') 
          format('truetype');
          font-weight: 600;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: '${FontConfig.bold.name}';
          src: url('${FontConfig.bold.path}') 
          format('truetype');
          font-weight: 700;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'smo-icons-font';
          src: url('/static/fonts/iconsFont/smo-icons-font.woff') 
          format('woff');
        }

        body {
          background-color: ${colors.layout.bgApp};
          color: ${colors.primary.darkest};
          font-family: '${regularFont}';
          height: 100%;
          margin: 0;
        }

        #root {
          display: grid;
          grid-auto-rows: auto 1fr;
          min-height: 100vh;
        }

        * {
          &::-webkit-scrollbar {
            background-color: ${scrollbar.bgScrollbar(colors)};
            border-radius: ${rem(pxToRem(scrollbar.radius))};
            height: ${rem(pxToRem(scrollbar.size))};
            width: ${rem(pxToRem(scrollbar.size))};
          }
          &::-webkit-scrollbar-track {
            background-color: ${scrollbar.bgScrollbarTrack(colors)};
            border-radius: ${rem(pxToRem(scrollbar.radius))};
          }
          &::-webkit-scrollbar-thumb {
            background-color: ${scrollbar.bgScrollbarThumb(colors)};
            border-radius: ${rem(pxToRem(scrollbar.radius))};
          }
        }

        ${BaleaStyles}

        ${DashboardStyles}
      `}
    />
  );
};

export { GlobalStyles };
