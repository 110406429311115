import { PrivacyPolicy } from '@domain/privacy-policy';
import { PRIVACY_POLICY_QUERY_KEY } from '@domain/privacy-policy/privacyPolicyKeys';
import { getPrivacyPolicy } from '@services/privacy-policy/privacyPolicyService';
import { useQuery, UseQueryResult } from 'react-query';

const useGetPrivacyPolicy = (): UseQueryResult<
  PrivacyPolicy | undefined,
  Error
> =>
  useQuery<PrivacyPolicy | undefined, Error>(PRIVACY_POLICY_QUERY_KEY, () =>
    getPrivacyPolicy()
  );

export { useGetPrivacyPolicy };
