import { Building, BUILDING_QUERY_KEY } from '@domain/buildings';
import { getBuilding } from '@services/buildings/buildingsService';
import { useQuery, UseQueryResult } from 'react-query';

const useGetBuilding = (
  venueId: string,
  buildingId: string
): UseQueryResult<Building, Error> =>
  useQuery<Building, Error>(BUILDING_QUERY_KEY(venueId, buildingId), () =>
    getBuilding(buildingId)
  );

export { useGetBuilding };
