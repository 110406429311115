/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useTheme } from '@emotion/react';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmModal } from '../modal';
import { DialogModel } from './shared';

interface DialogProps extends DialogModel {}

export const Dialog = ({
  open,
  title,
  subtitle,
  message,
  onConfirm,
  onCancel,
  btnPrimaryText,
  btnSecondaryText,
  hideSecondaryButton = false,
  headerColor,
  headerIcon
}: DialogProps): ReactElement | null => {
  const {
    base: { colors }
  } = useTheme();

  const { t } = useTranslation('shared');

  const handleAction = (callback: Function | undefined) =>
    callback ? callback() : Promise.resolve();

  return open ? (
    <ConfirmModal
      title={title}
      subtitle={subtitle}
      headerIcon={headerIcon || 'info'}
      headerColor={headerColor || colors.system.info}
      description={message}      
      btnSecondaryText={
        !hideSecondaryButton ? btnSecondaryText || t('shared:generics.cancel')  : undefined
      }
      btnPrimaryText={btnPrimaryText || t('shared:generics.confirm')}
      onClose={(confirm: unknown) =>
        confirm
          ? handleAction(onConfirm?.callback)
          : handleAction(onCancel?.callback)
      }
    />
  ) : null;
};
