import {
  ButtonBase,
  ButtonIcon,
  ButtonsContainer,
  PanelHeader,
  PanelTitle,
  Suspense
} from '@app/shared/components';
import { WorkstationFormModel } from '@domain/workplaces/models/create/workplaceFormModel';
import styled from '@emotion/styled';
import { useClickOutsideAction } from '@hooks/useClickOutsideAction';
import { usePopperArrowModifier } from '@hooks/usePopperArrowModifier';
import { useUpdateEffect } from '@hooks/useUpdateEffect';
import { VirtualElement } from '@popperjs/core';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';

import {
  WorkplaceForm,
  WorkplaceFormProps
} from './workplace-form/WorkplaceForm';

const WorkplacePanel = styled.div`
  ${({
    theme: {
      base: { colors, shadow, zindex }
    }
  }) => `
    background-color: ${colors.neutral.fullLight};
    border-radius: ${rem(pxToRem(4))};
    box-shadow: ${shadow.base(colors)};
    padding: ${rem(pxToRem(32))} ${rem(pxToRem(16))} ${rem(pxToRem(32))} ${rem(
    pxToRem(32)
  )};
    width: ${rem(pxToRem(575))};
    z-index: ${zindex.super};

    ${ButtonsContainer} {
      ${ButtonBase} {
        width: 100%;
      }
    }
  `}
`;

const PanelArrow = styled.div`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
        border-color: ${
          colors.neutral.fullLight
        } transparent transparent transparent;
        border-style: solid;
        border-width: ${rem(pxToRem(10))};
    `}
`;

const WorkplaceFormWrapper = styled.div`
  ${() => `
    ${ButtonsContainer} {
      margin-top: ${rem(pxToRem(32))};
    }
  `}
`;

const generateGetBoundingClientRect = (x = 0, y = 0) => () => ({
  width: 0,
  height: 0,
  top: Math.round(y),
  right: Math.round(x),
  bottom: Math.round(y),
  left: Math.round(x)
});

interface WorkplaceModalProps extends WorkplaceFormProps {
  left: number;
  top: number;
  radius: number;
  onClose: () => void;
}

const WorkplaceModal = ({
  left,
  top,
  radius,
  onClose,
  ...restProps
}: WorkplaceModalProps): ReactElement => {
  const { t } = useTranslation('workplaces');

  const virtualElement = useRef<VirtualElement>({
    getBoundingClientRect: generateGetBoundingClientRect(left, top)
  });
  const panelElementRef = useRef<HTMLDivElement>(null);
  const [panelRef, setPanelRef] = useState<HTMLDivElement | null>();

  useClickOutsideAction(panelElementRef, () => onClose());

  const { arrowModifier } = usePopperArrowModifier();
  const { styles, attributes, update } = usePopper(
    virtualElement.current,
    panelRef,
    {
      placement: 'right-start',
      strategy: 'absolute',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, radius + 10]
          }
        },
        arrowModifier
      ]
    }
  );

  useUpdateEffect(() => {
    virtualElement.current.getBoundingClientRect = generateGetBoundingClientRect(
      left,
      top
    );
    update?.();
  }, [left, top]);

  return (
    <WorkplacePanel
      ref={(ref) => {
        setPanelRef(ref);
        (panelElementRef as React.MutableRefObject<HTMLDivElement | null>).current = ref;
      }}
      style={styles.popper}
      {...attributes.popper}>
      <Suspense>
        <WorkplaceFormWrapper>
          <PanelHeader>
            <PanelTitle>{t('workplaceSettings')}</PanelTitle>
            <ButtonIcon
              className='closePanel'
              iconName='cross'
              type='button'
              title={t('shared:generics.close')}
              onClick={onClose}
            />
          </PanelHeader>

          <WorkplaceForm
            {...restProps}
            initialValues={restProps.initialValues as WorkstationFormModel}
          />
        </WorkplaceFormWrapper>
      </Suspense>
      <PanelArrow data-popper-arrow style={styles.arrow} />
    </WorkplacePanel>
  );
};

export { WorkplaceModal };
