import { useAuthContext, useAuthContextActions } from '@contexts/auth';
import React, { PropsWithChildren, useEffect } from 'react';

const PrivateRoute: React.FC = ({ children }: PropsWithChildren<unknown>) => {
  const { user, isLoading } = useAuthContext();
  const { goLoginPage } = useAuthContextActions();

  useEffect(() => {
    if (!isLoading && !user) {
      goLoginPage();
    }
  }, [isLoading, user]);

  return user ? <>{children}</> : null;
};

export { PrivateRoute };
