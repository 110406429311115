import {
  FilterButtons,
  FilterButtonsItem,
  GridTemplate
} from '@app/shared/components';
import { DEFAULT_SCALE } from '@domain/workplaces/models/shared/workplaceEntity';
import {
  WorkplacePlaceType,
  workplacesDefinition
} from '@domain/workplaces/models/workplace';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import { waveCommands } from '@wave-engine/wave-viewer';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

const ActionButton = styled.button`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
    align-items: center;
    border-radius: ${rem(pxToRem(4))};
    border: 1px dashed transparent;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: ${rem(pxToRem(56))};
    justify-content: center;
    width: ${rem(pxToRem(56))};

    &:hover,
    &:focus,
    &:active {
      border-color: ${colors.neutral.grayDark};
      cursor: pointer;
    }
  `}
`;

const ItemElement = styled.span`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
    background-color: ${getRgbaStrFromHexColor(colors.primary.default, 0.1)};
    border: 2px solid ${colors.primary.default};
    display: block;
    height: ${rem(pxToRem(40))};
    width: ${rem(pxToRem(40))};
  `}
`;

const ActionsWrapper = styled.div`
  align-items: center;
  display: flex;

  ${ActionButton} {
    margin-right: ${rem(pxToRem(16))};
  }
`;

const CylinderElement = styled(ItemElement)`
  ${({
    theme: {
      base: { radius }
    }
  }) => `
    border-radius: ${radius.circle};
  `}
`;

const SquareElement = styled(ItemElement)``;

interface WorkstationActionsProps {
  placeType: WorkplacePlaceType;
}

const WorkplacesActions = ({
  placeType
}: WorkstationActionsProps): ReactElement => {
  const { t } = useTranslation('workplaces');

  const theme = useTheme();

  const filterItems: FilterButtonsItem[] = [
    {
      label: t('settings.reservableArea'),
      active: true
    }
  ];

  return (
    <>
      <GridTemplate>
        <FilterButtons buttons={filterItems} noBorder />
        <ActionsWrapper>
          <ActionButton
            onClick={() =>
              waveCommands.shape.createShape(
                'Cylinder',
                {
                  color: theme.base.colors.workplaces.disabled,
                  scale: {
                    ...DEFAULT_SCALE,
                    y: workplacesDefinition[placeType].scaleY
                  },
                  isPushpinVisible: true,
                  pushpinId: placeType
                },
                {
                  type: placeType.toString()
                }
              )
            }>
            <CylinderElement />
          </ActionButton>
          <ActionButton
            onClick={() =>
              waveCommands.shape.createShape(
                'Cube',
                {
                  color: theme.base.colors.workplaces.disabled,
                  scale: {
                    ...DEFAULT_SCALE,
                    y: workplacesDefinition[placeType].scaleY
                  },
                  isPushpinVisible: true,
                  pushpinId: placeType
                },
                {
                  type: placeType.toString()
                }
              )
            }>
            <SquareElement />
          </ActionButton>
        </ActionsWrapper>
      </GridTemplate>
    </>
  );
};

export { WorkplacesActions };
