import { FLOORPLANS_KEY } from '@domain/floorplans';
import { UseMutation, useMutationWrapper } from '@hooks/useMutationWrapper';
import { archiveFloorplan } from '@services/floorplans/floorplansService';
import { useQueryClient } from 'react-query';

const useArchiveFloorplan = (
  venueId: string,
  buildingId: string
): UseMutation<void, Error, string, unknown> => {
  const queryClient = useQueryClient();

  return useMutationWrapper<void, Error, string>(
    (floorplanId) => archiveFloorplan(floorplanId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FLOORPLANS_KEY(venueId, buildingId));
      }
    }
  );
};

export { useArchiveFloorplan };
