import {
  MeetingRoomEntity,
  WorkplaceEntity,
  WorkstationEntity
} from './create/workplaceFormModel';

export enum WorkStationType {
  FlexDesk = 1,
  FixedDesk
}

export enum WorkplaceType {
  PlainWorkplace = 1,
  ComputerWorkplace
}

export enum WorkplacePlaceType {
  Workstation = 1,
  MeetingRoom,
  CommonArea
}

export type WorkplaceFacilityDefinition<T extends WorkplaceEntity> = {
  fieldName: keyof T;
  icon: string;
  pushpinId?: number;
  title: string;
  filter: (item: T) => boolean;
  value: string | number;
};

export type WorkplaceDefinition<T extends WorkplaceEntity> = {
  icon: string;
  title: string;
  description: string;
  subTypes?: {
    [key in WorkStationType]: WorkplaceDefinition<T> & {
      filter: (item: T) => boolean;
    };
  };
  facilities?: WorkplaceFacilityDefinition<T>[];
  scaleY: number;
  pushpinId?: number;
};

const workplacesDefinition = {
  [WorkplacePlaceType.Workstation]: {
    title: 'workplaceSites.workstation',
    icon: '',
    scaleY: 1,
    subTypes: {
      [WorkStationType.FixedDesk]: {
        icon: 'desktop',
        title: 'legend.icons.fixWorkspace.title',
        description: 'legend.icons.fixWorkspace.description',
        pushpinId: 0,
        filter: (item) =>
          item.workstationtype === WorkStationType.FixedDesk.toString()
      },
      [WorkStationType.FlexDesk]: {
        icon: 'laptop',
        title: 'legend.icons.flexWorkspace.title',
        description: 'legend.icons.flexWorkspace.description',
        pushpinId: 1,
        filter: (item) =>
          item.workstationtype === WorkStationType.FlexDesk.toString()
      }
    },
    facilities: [
      {
        fieldName: 'workplace',
        icon: 'desktop',
        title: 'facilities.workstations.computerWorkplace',
        filter: (item) =>
          item.workplace === WorkplaceType.ComputerWorkplace.toString(),
        value: WorkplaceType.ComputerWorkplace
      },
      {
        fieldName: 'workplace',
        icon: 'laptop',
        title: 'facilities.workstations.plainWorkplace',
        filter: (item) =>
          item.workplace === WorkplaceType.PlainWorkplace.toString(),
        value: WorkplaceType.PlainWorkplace
      },
      {
        fieldName: 'elevatedTable',
        icon: 'elevated-table',
        title: 'facilities.workstations.elevatedTable',
        filter: (item) => item.elevatedTable?.toString() === '1',
        value: 1
      },
      {
        fieldName: 'wheelchair',
        icon: 'wheelchair',
        title: 'facilities.workstations.wheelchairAccess',
        filter: (item) => item.wheelchair?.toString() === '1',
        value: 1
      },
      // {
      //   fieldName: 'extraSocketStrips',
      //   icon: 'socket',
      //   title: 'facilities.workstations.extraSocketStrips',
      //   filter: (item) => item.extraSocketStrips?.toString() === '1',
      //   value: 1
      // }
    ]
  } as WorkplaceDefinition<WorkstationEntity>,
  // [WorkplacePlaceType.MeetingRoom]: {
  //   icon: 'meeting',
  //   title: 'legend.icons.meetingRoom.title',
  //   description: 'legend.icons.meetingRoom.description',
  //   scaleY: 1.5,
  //   pushpinId: 2,
  //   facilities: [
  //     {
  //       fieldName: 'vr',
  //       icon: 'vr',
  //       title: 'facilities.meetingRooms.vr',
  //       filter: (item) => item.vr?.toString() === '1',
  //       value: 1
  //     },
  //     {
  //       fieldName: 'whiteboard',
  //       icon: 'chalkboard',
  //       title: 'facilities.meetingRooms.whiteboard',
  //       filter: (item) => item.whiteboard?.toString() === '1',
  //       value: 1
  //     },
  //     {
  //       fieldName: 'coffeeCorner',
  //       icon: 'coffee-corner',
  //       title: 'facilities.meetingRooms.coffeeCorner',
  //       filter: (item) => item.coffeeCorner?.toString() === '1',
  //       value: 1
  //     },
  //     {
  //       fieldName: 'presentationScreen',
  //       icon: 'projector',
  //       title: 'facilities.meetingRooms.presentationScreen',
  //       filter: (item) => item.presentationScreen?.toString() === '1',
  //       value: 1
  //     },
  //     {
  //       fieldName: 'intercom',
  //       icon: 'intercom',
  //       title: 'facilities.meetingRooms.intercom',
  //       filter: (item) => item.intercom?.toString() === '1',
  //       value: 1
  //     },
  //     {
  //       fieldName: 'audio',
  //       icon: 'audio',
  //       title: 'facilities.meetingRooms.audio',
  //       filter: (item) => item.audio?.toString() === '1',
  //       value: 1
  //     }
  //   ]
  // } as WorkplaceDefinition<MeetingRoomEntity>,
  // [WorkplacePlaceType.CommonArea]: {
  //   icon: 'users',
  //   title: 'legend.icons.commonArea.title',
  //   description: 'legend.icons.commonArea.description',
  //   scaleY: 1,
  //   pushpinId: 3
  // }
} as {
  [WorkplacePlaceType.Workstation]: WorkplaceDefinition<WorkstationEntity>;
  [WorkplacePlaceType.MeetingRoom]: WorkplaceDefinition<MeetingRoomEntity>;
  [WorkplacePlaceType.CommonArea]: WorkplaceDefinition<WorkplaceEntity>;
};

export { workplacesDefinition };
