import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';

const CardPicture = styled.picture`
  align-items: center;
  display: flex;
  height: ${rem(pxToRem(117))};
  justify-content: center;
  margin-bottom: ${rem(pxToRem(8))};
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const CardImage = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export { CardPicture, CardImage };
