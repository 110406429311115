import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React from 'react';
import { ReactElement } from 'react';

import { Icon, IconWrapper } from '../icon';

enum ToastTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info'
}

interface ToastBaseProps {
  message: string;
  type: ToastTypes;
}

const ToastWrapper = styled.div`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig }
      }
    }
  }) => `
    .Toastify__toast-container {
      width: ${rem(pxToRem(390))};
    }
    
    .Toastify__toast {
      background-color: ${colors.neutral.fullLight};
      border-radius: 4px;
      box-shadow: 0px 0px 2px rgba(9, 27, 44, 0.06), 0px 32px 60px rgba(9, 27, 44, 0.06), 0px 6px 8px rgba(9, 27, 44, 0.183622);
      color: ${colors.primary.darkest};
      ${getTextsConfig('M')}
      padding: ${rem(pxToRem(16))} ${rem(pxToRem(38))} ${rem(
    pxToRem(16)
  )} ${rem(pxToRem(16))};

      .Toastify__toast-body {
        margin: 0;
      }

      .Toastify__close-button {
        color: ${colors.primary.default};
        line-height: 0;
        opacity: 1;
        padding: ${rem(pxToRem(8))};
        position: absolute;
        right: 0;
        top: 0;

        &:hover {
          color: ${colors.neutral.dark};
        }
      }
    }
  `}
`;

const ToastContent = styled.div<{ type: ToastTypes }>`
  ${({
    theme: {
      base: { colors, radius }
    },
    type
  }) => `
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;

    ${IconWrapper} {
      align-items: center;
      background-color: 
        ${
          type === 'success'
            ? colors.system.success
            : type === 'error'
            ? colors.system.error
            : type === 'warning'
            ? colors.system.warning
            : type === 'info'
            ? colors.system.info
            : colors.neutral.dark
        }
      ;
      border-radius: ${radius.circle};
      color: ${colors.neutral.fullLight};
      display: flex;
      flex-grow: 0;
      font-size: ${rem(pxToRem(24))};
      height: ${rem(pxToRem(28))};
      justify-content: center;
      margin-right: ${rem(pxToRem(16))};
      min-width: ${rem(pxToRem(28))};
      width: ${rem(pxToRem(28))};
    }
  `}
`;

const ToastBase = ({ message, type }: ToastBaseProps): ReactElement => {
  const iconDictionary = {
    [ToastTypes.SUCCESS]: 'tick',
    [ToastTypes.ERROR]: 'cross_small',
    [ToastTypes.WARNING]: 'warning',
    [ToastTypes.INFO]: 'info'
  };
  return (
    <ToastContent type={type}>
      <Icon iconName={iconDictionary[type]} iconSize={16} />
      <p>{message}</p>
    </ToastContent>
  );
};

interface ToastSuccessProps {
  message: string;
}

const ToastSuccess = ({ message }: ToastSuccessProps): ReactElement => {
  return <ToastBase message={message} type={ToastTypes.SUCCESS} />;
};

const ToastError = ({ message }: ToastSuccessProps): ReactElement => {
  return <ToastBase message={message} type={ToastTypes.ERROR} />;
};

const ToastWarning = ({ message }: ToastSuccessProps): ReactElement => {
  return <ToastBase message={message} type={ToastTypes.WARNING} />;
};

const ToastInfo = ({ message }: ToastSuccessProps): ReactElement => {
  return <ToastBase message={message} type={ToastTypes.INFO} />;
};

export { ToastSuccess, ToastError, ToastWarning, ToastInfo, ToastWrapper };
