import {
  UpdateVenueModel,
  UpdateVenueResponse,
  VENUES_QUERY_KEY
} from '@domain/venues';
import { UseMutation, useMutationWrapper } from '@hooks/useMutationWrapper';
import { updateVenue } from '@services/venues/venuesService';
import { useQueryClient } from 'react-query';

const useUpdateVenueId = (
  venueId: string
): UseMutation<UpdateVenueResponse, Error, UpdateVenueModel, unknown> => {
  const queryClient = useQueryClient();

  return useMutationWrapper<UpdateVenueResponse, Error, UpdateVenueModel>(
    (venue) => updateVenue(venueId, venue),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(VENUES_QUERY_KEY);
      }
    }
  );
};

const useUpdateVenue = (): UseMutation<
  UpdateVenueResponse,
  Error,
  { id: string } & UpdateVenueModel,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutationWrapper<
    UpdateVenueResponse,
    Error,
    { id: string } & UpdateVenueModel
  >(({ id, ...venue }) => updateVenue(id, venue), {
    onSuccess: () => {
      queryClient.invalidateQueries(VENUES_QUERY_KEY);
    }
  });
};

export { useUpdateVenueId, useUpdateVenue };
