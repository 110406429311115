import styled from '@emotion/styled';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement, Ref } from 'react';

import { Icon } from '../icon';
import { FormGroup, FormGroupProps } from './FormGroup';

type InputWrapperProps = {
  hasError?: boolean;
  isTypeSearch?: boolean;
} & JSX.IntrinsicElements['input'];

const InputWrapper = styled.input<InputWrapperProps>`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig }
      }
    },
    hasError,
    isTypeSearch
  }) => `
    background: ${colors.neutral.fullLight};
    border-radius: ${rem(pxToRem(4))};
    border: 1px solid ${colors.neutral.grayDark};
    box-sizing: border-box;
    height: ${rem(pxToRem(40))};
    ${getTextsConfig('M')};
    padding: ${
      isTypeSearch
        ? `${rem(pxToRem(10))} ${rem(pxToRem(12))} ${rem(pxToRem(10))} ${rem(
            pxToRem(48)
          )}`
        : `${rem(pxToRem(10))} ${rem(pxToRem(12))}`
    };
    position: relative;
    ${isTypeSearch && `width: ${rem(pxToRem(360))};`};

    ${
      hasError
        ? `background-color: ${getRgbaStrFromHexColor(
            colors.system.error,
            0.1
          )};
          color: ${colors.system.error};
          border-color: ${colors.system.error};`
        : `color: inherit;`
    }

    &::placeholder {
      color: ${
        hasError
          ? getRgbaStrFromHexColor(colors.system.error, 0.4)
          : getRgbaStrFromHexColor(colors.primary.darkest, 0.4)
      };
    }

    &:focus {
      color: ${colors.primary.default};
      border-color: ${colors.primary.default};
    }

    &:disabled {
      background-color: ${getRgbaStrFromHexColor(colors.neutral.gray, 0.3)};
      color: ${getRgbaStrFromHexColor(colors.primary.darkest, 0.4)};
    }
  `}
`;

const SearchIconWrapper = styled.div`
  ${({
    theme: {
      base: { colors, zindex }
    }
  }) => `
    bottom: ${rem(pxToRem(4))};
    color: ${colors.primary.darkest};
    left: ${rem(pxToRem(8))};
    line-height: 0;
    position: absolute;
    opacity: 0.4;
    z-index: ${zindex.XS};
  `}
`;

type InputProps = {
  innerRef?: Ref<HTMLInputElement>;
  direction?: 'row' | 'column';
} & FormGroupProps &
  JSX.IntrinsicElements['input'];

const Input = ({
  label,
  error,
  innerRef,
  isTypeSearch,
  direction,
  placeholder,
  ...props
}: InputProps & InputWrapperProps): ReactElement => (
  <FormGroup label={label} error={error} direction={direction}>
    {isTypeSearch && (
      <SearchIconWrapper>
        <Icon iconName='lens' iconSize={32} />
      </SearchIconWrapper>
    )}
    <InputWrapper
      placeholder={placeholder}
      ref={innerRef}
      isTypeSearch={isTypeSearch}
      hasError={Boolean(error?.message)}
      disabled={props.disabled}
      {...props}
    />
  </FormGroup>
);

export { Input, InputWrapper };
