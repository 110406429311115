import React, { ReactText } from 'react';
import { toast } from 'react-toastify';

import { ToastError, ToastInfo, ToastSuccess, ToastWarning } from './components/toast';

const notifySuccess = (message: string): ReactText =>
  toast(<ToastSuccess message={message} />);

const notifyError = (message: string): ReactText =>
  toast(<ToastError message={message} />);

const notifyWarning = (message: string): ReactText =>
  toast(<ToastWarning message={message} />);

const notifyInfo = (message: string): ReactText =>
  toast(<ToastInfo message={message} />);

export { notifySuccess, notifyError, notifyWarning, notifyInfo };
