import React, { useEffect, useRef } from 'react';

const useClickOutsideAction = (
  ref: React.RefObject<Element>,
  action: () => void
): void => {
  const actionRef = useRef(action);

  useEffect(() => {
    actionRef.current = action;
  }, [action]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        actionRef.current?.();
      }
    };

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
};

export { useClickOutsideAction };
