import { BUILDINGS_QUERY_KEY, UpdateBuilding } from '@domain/buildings';
import { UseMutation, useMutationWrapper } from '@hooks/useMutationWrapper';
import { updateBuilding } from '@services/buildings/buildingsService';
import { useQueryClient } from 'react-query';

const useUpdateBuilding = (
  venueId: string,
  buildingId: string
): UseMutation<void, Error, UpdateBuilding, unknown> => {
  const queryClient = useQueryClient();

  return useMutationWrapper<void, Error, UpdateBuilding>(
    (building) => updateBuilding(buildingId, building),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(BUILDINGS_QUERY_KEY(venueId));
      }
    }
  );
};

export { useUpdateBuilding };
