import { Loader } from '@app/shared/components';
import { useAuthContextActions } from '@contexts/auth';
import React, { ReactElement, useEffect } from 'react';

const Logout = (): ReactElement => {
  const { logout } = useAuthContextActions();

  useEffect(() => {
    logout();
  }, []);

  return <Loader isFullPage />;
};

export default Logout;
