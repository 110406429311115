import React, { ReactElement } from 'react';
import { Router } from 'react-router';
interface AppRouterProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history: any;
  children: React.ReactNode;
}

const AppRouter = ({ children, history }: AppRouterProps): ReactElement => (
  <Router history={history}>{children}</Router>
);

export { AppRouter };
