import { ILocationComponentProps } from '@app/shared/routes';
import { useGetAllVenues } from '@hooks/venues';
import React, { ReactElement, useEffect } from 'react';
import { Switch, useHistory } from 'react-router-dom';

import { BuildingsLocation } from '../buildings';
import Buildings from '../buildings/Buildings';
import { VenuesLocationParams } from './routes/venuesLocation';

interface VenuesProps extends ILocationComponentProps<VenuesLocationParams> {}

const Venues = ({
  match: {
    params: { venueId }
  }
}: VenuesProps): ReactElement => {
  const { data: venues = [], isSuccess } = useGetAllVenues({
    archived: true
  });
  const history = useHistory();

  useEffect(() => {
    if (
      isSuccess &&
      venues &&
      (!venueId || !venues.some((v) => v.id === venueId))
    ) {
      history.replace(
        BuildingsLocation.toUrl({
          venueId: venues?.find((v) => !v.isArchived)?.id ?? 'none'
        })
      );
    }
  }, [isSuccess]);

  return <Switch>{BuildingsLocation.toRoute({ component: Buildings })}</Switch>;
};

export default Venues;
