import * as Yup from 'yup';

export class RouteHelper {
  public static stringParamValidation(): Yup.Schema<string> {
    return Yup.string().required();
  }

  public static stringOptionalParamValidation(): Yup.Schema<
    string | undefined
  > {
    return Yup.string().notRequired();
  }
}
