import { configuration } from '@configuration';
import {
  ApplicationInsights,
  IExceptionTelemetry,
  ITelemetryItem
} from '@microsoft/applicationinsights-web';
import { User } from 'oidc-client';

const {
  debug,
  applicationInsights: { instrumentationKey },
  appVersion
} = configuration;

const appInsightsInstance = instrumentationKey
  ? new ApplicationInsights({
      config: {
        instrumentationKey,
        enableDebug: debug,
        loggingLevelConsole: 2,
        loggingLevelTelemetry: 2,
        enableAutoRouteTracking: true
      }
    })
  : null;

const telemetryInitializer = (envelope: ITelemetryItem): void => {
  if (envelope.tags) {
    envelope.tags['ai.application.ver'] = appVersion;
  }
};

const initializeAppInsights = (): void => {
  if (appInsightsInstance) {
    appInsightsInstance.loadAppInsights();
    appInsightsInstance.addTelemetryInitializer(telemetryInitializer);
  }
};

const setUserContext = (user: User): void => {
  if (appInsightsInstance) {
    appInsightsInstance.setAuthenticatedUserContext(
      user?.profile?.sub,
      user?.profile?.preferred_username,
      true
    );
  }
};

const getTraceId = (): string => {
  return appInsightsInstance
    ? appInsightsInstance.context.telemetryTrace.traceID
    : '';
};

const trackException = (error: IExceptionTelemetry): void => {
  if (appInsightsInstance) {
    appInsightsInstance.trackException(error);
  }
};

const trackAction = (
  severityLevel: string,
  category: string,
  eventName: string,
  action: string,
  msg: string
): void => {
  if (appInsightsInstance) {
    appInsightsInstance.trackTrace({
      properties: {
        severityLevel,
        category,
        action,
        eventName
      },
      message: msg
    });
  }
};

export {
  initializeAppInsights,
  getTraceId,
  trackException,
  setUserContext,
  trackAction
};
