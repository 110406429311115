import { Table, TableData, TableRequestModel } from '@app/shared/components';
import { notifySuccess } from '@app/shared/notifications';
import { IncidentResolveModel, IncidentSearch } from '@domain/care-management';
import { useGetIncidents, useResolveIncident } from '@hooks/care-management';
import { useUnresolveIncident } from '@hooks/care-management/incident/useUnresolveIncident';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getIncidentsTableColumns } from './incident-table-columns/IncidentTableColumns';

interface IncidentTableProps {
  incidentTypeId: string;
}

const IncidentTable = ({
  incidentTypeId
}: IncidentTableProps): ReactElement => {
  const { t } = useTranslation('care');
  const [incidentsRequest, setIncidentsRequest] = useState<
    TableRequestModel<IncidentSearch> | undefined
  >();

  const { data: incidents, isSuccess: isGetIncidentsSuccess } = useGetIncidents(
    incidentsRequest
  );

  const {
    mutateAsync: resolveIncident,
    isLoading: isResolving,
    isSuccess: isResolveSuccess
  } = useResolveIncident();
  const {
    mutateAsync: unresolveIncident,
    isLoading: isUnresolving,
    isSuccess: isUnresolveSuccess
  } = useUnresolveIncident();

  const handleOnResolved = (
    resolved: boolean,
    model: IncidentResolveModel
  ): void => {
    resolved ? unresolveIncident(model) : resolveIncident(model);
  };

  const columns: TableData<IncidentSearch> = useMemo(
    () => getIncidentsTableColumns({ onResolved: handleOnResolved }),
    []
  );

  const handleOnFetchData = (
    model: TableRequestModel<IncidentSearch>
  ): void => {
    setIncidentsRequest({
      ...model,
      filters: [
        ...(model.filters?.filter((x) => x.id !== 'incidentTypeId') ?? []),
        { id: 'incidentTypeId', value: [incidentTypeId], operator: 'eq' }
      ]
    });
  };

  useEffect(() => {
    if (isResolveSuccess) {
      notifySuccess(t('careManagement.incidents.notifications.resolved'));
    }
  }, [isResolveSuccess]);

  useEffect(() => {
    if (isUnresolveSuccess) {
      notifySuccess(t('careManagement.incidents.notifications.notResolved'));
    }
  }, [isUnresolveSuccess]);

  useEffect(() => {
    if (incidentsRequest) {
      handleOnFetchData(incidentsRequest);
    }
  }, [incidentTypeId]);

  const isLoading = !isGetIncidentsSuccess || isResolving || isUnresolving;

  return (
    <Table<IncidentSearch>
      isPaginable
      isSortable
      isFilterable
      data={incidents?.data}
      columns={columns}
      isLoading={isLoading}
      count={incidents?.count}
      onFetchData={handleOnFetchData}
    />
  );
};

export { IncidentTable };
