import { NoResults } from '@app/shared/components';
import { useLoadImage } from '@hooks/useLoadImage';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const NotFound = (): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const { getImage } = useLoadImage();

  return (
    <NoResults
      errorStyle
      title={t('genericError.title')}
      description={t('genericError.description')}
      image={getImage('Error404')}
      btnLabel={t('notFoundError.goBack')}
      btnClick={() => history.goBack()}
    />
  );
};

export { NotFound };
