import { css } from '@emotion/react';

export const BaleaStyles = css`
  balea-admin,
  km-portal {
    // Brand
    --c-primary: #88B8EE; //default
    --c-primary-rgb: 34, 102, 251;
    --c-primary-dark: #0242b5; //dark
    --c-primary-light: #88B8EE; // default
    --c-primary-light-rgb: 34, 102, 251;

    // System
    --c-success: #27c15b;
    --c-warning: #ff8742;
    --c-error: #ff5555;
    --c-error-light: #ff7c7c;
    --c-error-rgb: 202, 66, 66;
    --c-info: #88B8EE; // default

    // Layout colors
    --bg-base: #f0f3f5; // grayLight
    --header-display: none;

    // Fonts
    --ff-base: 'Montserrat Regular', sans-serif;
    --ff-secondary: 'Montserrat SemiBold', sans-serif;
    // - font-size Headlines
    --fs-headlines-l: 18px;
    --fs-headlines-m: 16px;
    --fs-headlines-s: 14px;
    // - line-height Headlines
    --lh-headlines-l: 30px;
    --lh-headlines-m: 28px;
    --lh-headlines-s: 28px;
    // - font-size Bodytext
    --fs-bodytext-l: 16px;
    --fs-bodytext-m: 14px;
    --fs-bodytext-s: 12px;
    // - line-height Bodytext
    --lh-bodytext-l: 28px;
    --lh-bodytext-m: 24px;
    --lh-bodytext-s: 20px;

    // Buttons
    // - font-size Buttons
    --fs-button-l: 16px;
    --fs-button-m: 14px;
    // - line-height Buttons
    --lh-button-l: 28px;
    --lh-button-m: 20px;
    // - general
    --btn-border-radius: 50px;
    --btn-border-width: 2px;
    --btn-font-weight: normal;
    --btn-height: 36px;
    --btn-height-l: 36px;
    --btn-min-width-l: 96px;
    --btn-min-width: 96px;
    --btn-min-width-s: 96px;
    --btn-padding: 0 16px;
    // - primary
    --btn-primary-bg-hover: var(--c-primary-dark);
    --btn-primary-border-color-hover: var(--c-primary-dark);
    --btn-primary-bg-disabled: var(--c-gray-200);
    --btn-primary-border-color-disabled: var(--c-gray-200);
    --btn-primary-color-disabled: var(--c-gray-400);
    // - secondary
    --btn-secondary-bg: transparent;
    --btn-secondary-border-color: rgba(var(--c-gray-500-rgb), 0.2);
    --btn-secondary-color: var(--c-primary);
    --btn-secondary-bg-hover: var(--c-primary-dark);
    --btn-secondary-border-color-hover: var(--c-primary-dark);
    --btn-secondary-color-hover: var(--c-lightest);
    --btn-secondary-bg-disabled: transparent;
    --btn-secondary-border-color-disabled: var(--c-gray-200);
    --btn-secondary-color-disabled: var(--c-gray-300);

    // Icon button
    --btn-icon-height: 36px;
    --btn-icon-size: 24px;
    --btn-icon-width: 36px;
    --btn-icon-color: var(--c-primary);
    --btn-icon-background-hover: rgba(var(--c-primary-rgb), 0.1);
    --btn-icon-border-hover: rgba(var(--c-primary-rgb), 0.1);
    --btn-icon-color-hover: var(--c-primary-dark);
    --btn-icon-background-active: rgba(var(--c-primary-rgb), 0.1);
    --btn-icon-border-active: rgba(var(--c-primary-rgb), 0.1);
    --btn-icon-color-active: var(--c-primary);
    --btn-icon-background-disabled: transparent;
    --btn-icon-border-disabled: transparent;
    --btn-icon-color-disabled: var(--c-gray-300);

    // Inputs
    // - label
    --form-label-color: rgba(var(--c-gray-500-rgb), 0.6);
    --form-label-weight: normal;
    // - input
    --form-input-border-radius: 4px;
    --form-input-height: 40px;
    --form-input-padding: 10px 12px;
    // - textarea
    --form-textarea-border-radius: 4px;
    --form-textarea-min-height: unset;
    // - input search
    --form-input-search-height: 40px;
    --form-input-search-icon-size: 20px;

    km-navbar-item {
      margin-left: 0;

      &:first-child,
      &:last-child {
        display: none;
      }
    }
  }

  balea-admin {
    height: calc(100vh - 72px);
    min-height: calc(100vh - 72px);
  }

  balea-header {
    display: none
  }

  .km-grid-template--small {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export default { BaleaStyles };
