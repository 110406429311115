import styled from '@emotion/styled';

const WaveWrapper = styled.div`
  position: relative;
`;

const WaveContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

export { WaveWrapper, WaveContainer };
