import { BaleaLocation } from '@app/balea';
import { BookingListLocation } from '@app/booking-list';
import { CareManagementLocation } from '@app/care-management';
import { FacilityManagementLocation } from '@app/facility-management';
import { LegalLocation } from '@app/legal';
import { ButtonIcon, ButtonIconBase } from '@app/shared/components';
import { UserListLocation } from '@app/user-list';
import styled from '@emotion/styled';
import { useLoadImage } from '@hooks/useLoadImage';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Navbar, NavbarItemData, NavbarItemLink } from '.';

const SidebarMenuWrapper = styled.div`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig }
      }
    }
  }) => `
    align-items: flex-start;
    background-color: ${colors.neutral.fullLight};
    box-shadow: 0 0 2px rgba(9, 27, 44, 0.183622);
    display: flex;
    ${getTextsConfig('S')};
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding: ${rem(pxToRem(8))} ${rem(pxToRem(12))} ${rem(pxToRem(24))};
    transition: width 0.3s ease-in-out;
    width: ${rem(pxToRem(72))};

    &.isOpen {
      width: ${rem(pxToRem(280))};

      ${NavbarItemLink} {
        max-width: 100%;
      }
    }
  `}
`;

const OpenPanelButton = styled.div`
  ${() => `
    display: flex;
    height: ${rem(pxToRem(56))};
    justify-content: center;
    margin-bottom: ${rem(pxToRem(8))};
    width: ${rem(pxToRem(48))};

    ${ButtonIconBase} {
      height: 100%;
      width: 100%;
    }
  `}
`;

const Isotipo = styled.picture`
  margin-top: auto;
  width: ${rem(pxToRem(48))};

  > img {
    margin: 0 auto;
    width: ${rem(pxToRem(30))};
  }
`;

const SidebarMenu: React.FC = () => {
  const { t } = useTranslation('shared');
  const [showMasterplanParameters, setShowMasterplanParameters] = useState(
    false
  );

  const { getImage } = useLoadImage();

  const navbarLinks: NavbarItemData[] = [
    // {
    //   linkTo: DashboardLocation.path,
    //   iconName: 'chart',
    //   label: t('routes.dashboard')
    // },
    {
      linkTo: FacilityManagementLocation.path,
      iconName: 'building',
      label: t('routes.facilities')
    },
    {
      linkTo: BaleaLocation.path,
      iconName: 'chalkboard',
      label: t('routes.users')
    },
    {
      linkTo: CareManagementLocation.path,
      iconName: 'health',
      label: t('routes.careManagement')
    },
    {
      linkTo: BookingListLocation.path,
      iconName: 'booking',
      label: t('routes.bookingList')
    },
    {
      linkTo: UserListLocation.path,
      iconName: 'users',
      label: t('routes.userList')
    },
    {
      linkTo: LegalLocation.path,
      iconName: 'paperclip',
      label: t('routes.privacyPolicy')
    }
  ];

  return (
    <SidebarMenuWrapper
      className={showMasterplanParameters ? 'isOpen' : 'isClosed'}>
      <OpenPanelButton>
        <ButtonIcon
          iconName={showMasterplanParameters ? 'chevron-left' : 'chevron-right'}
          iconSize={24}
          onClick={() => setShowMasterplanParameters((value) => !value)}
        />
      </OpenPanelButton>
      <Navbar links={navbarLinks} />
      <Isotipo>
        <img src={getImage('Isotipo')} />
      </Isotipo>
    </SidebarMenuWrapper>
  );
};

export { SidebarMenu };
