import { Form } from '@app/shared/components';
import { UpdateBuilding } from '@domain/buildings';
import { Venue } from '@domain/venues';
import { useGetBuilding } from '@hooks/buildings/useGetBuilding';
import { useUpdateBuilding } from '@hooks/buildings/useUpdateBuilding';
import React, { FC, useEffect } from 'react';

import BuildingsDetailForm from '../shared/buildings-detail-form/BuildingsDetailForm';

interface BuildingsEditFormProps {
  venueId: string;
  venues: Venue[];
  buildingId: string;
  onEditBuilding: () => void;
  onCancel: () => void;
}

const BuildingsEditForm: FC<BuildingsEditFormProps> = ({
  venueId,
  venues,
  buildingId,
  onEditBuilding,
  onCancel
}: BuildingsEditFormProps) => {
  const { data: building } = useGetBuilding(venueId, buildingId);
  const { mutateAsync: updateBuilding, isSuccess } = useUpdateBuilding(
    venueId,
    buildingId
  );

  useEffect(() => {
    if (isSuccess) {
      onEditBuilding();
    }
  }, [isSuccess]);

  const onSubmit = (building: UpdateBuilding): Promise<void> =>
    updateBuilding(building);

  return (
    <Form>
      <BuildingsDetailForm
        onSubmit={onSubmit}
        onCancel={onCancel}
        initialValues={building}
        venues={venues ?? []}
      />
    </Form>
  );
};

export default BuildingsEditForm;
