import DefaultBuilding from '@assets/img/SCRM/default-building.svg';
import Error404 from '@assets/img/SCRM/error-404.svg';
import Error500 from '@assets/img/SCRM/error-500.svg';
import Isotipo from '@assets/img/SCRM/isotipo.svg';
import LogoImage from '@assets/img/SCRM/logo.png';
import NoBuildings from '@assets/img/SCRM/no-buildings.svg';
import NoFloorplans from '@assets/img/SCRM/no-floorplans.svg';

import { ImageType } from './load-images.model';

const SCRMImages = {
  DefaultBuilding: DefaultBuilding,
  Error404: Error404,
  Error500: Error500,
  Isotipo: Isotipo,
  Logo: LogoImage,
  NoBuildings: NoBuildings,
  NoFloorplans: NoFloorplans
} as ImageType;

export { SCRMImages };
