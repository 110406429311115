import { PageContainer, PageHeader } from '@app/shared/components';
import React, { ReactElement } from 'react';

import { UserListContainer } from './shared/Layout';
import { UserListTable } from './user-list-table/UserListTable';
import { UserListToolbar } from './user-list-toolbar/UserListToolbar';

const UserList = (): ReactElement => {
  return (
    <PageContainer>
      <PageHeader>
        <UserListToolbar />
      </PageHeader>
      <UserListContainer>
        <UserListTable />
      </UserListContainer>
    </PageContainer>
  );
};

export default UserList;
