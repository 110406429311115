import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import { Icon } from '../icon';

export type CollapsibleType = 'destroy' | 'hide';

type CollapsibleSectionProps = {
  title: string;
  isOpen?: boolean;
  collapType?: CollapsibleType;
  additionalInfoComponent?: React.ReactNode;
};

const CollapseContainer = styled.details`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
    background-color: ${colors.neutral.fullLight};
    border-radius: ${rem(pxToRem(6))};
    border: 1px solid ${getRgbaStrFromHexColor(colors.primary.darkest, 0.15)};
    box-sizing: border-box;
    cursor: pointer;
    height: ${rem(pxToRem(40))};
    padding: ${rem(pxToRem(8))} ${rem(pxToRem(16))};
    position: relative;

    &:focus {
      outline: 0;
    }
  `}
`;

const CollapseTitle = styled.summary`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig, mediumFont }
      }
    }
  }) => `
    align-items: center;
    color: ${colors.primary.default};
    display: flex;
    ${getTextsConfig('M')};
    font-family: ${mediumFont};
    height: 100%;
    justify-content: space-between;
  `}
`;

const CollapseAdditionalInfo = styled.div`
  display: flex;
  gap: ${rem(pxToRem(16))};
`;

const CollapseContent = styled.div<{ collapsed?: boolean }>`
  &:not(:empty) {
    display: ${(props) => (props.collapsed ? 'none' : 'inherit')};
    margin-top: ${rem(pxToRem(12))};
  }
`;

const CollapseWrapper = styled.article``;

const CollapsibleSection: React.FC<PropsWithChildren<
  CollapsibleSectionProps
>> = ({
  title,
  isOpen = true,
  collapType = 'destroy',
  children,
  additionalInfoComponent: AdditionalInfoComponent
}: PropsWithChildren<CollapsibleSectionProps>) => {
  const [collapsed, setCollapsed] = useState(!isOpen);

  useEffect(() => {
    setCollapsed(!isOpen);
  }, [isOpen]);

  const {
    base: { colors }
  } = useTheme();

  return (
    <CollapseWrapper>
      <CollapseContainer onClick={() => setCollapsed((value) => !value)}>
        <CollapseTitle>
          {title}
          <CollapseAdditionalInfo>
            {AdditionalInfoComponent}
            <Icon
              iconName={collapsed ? 'chevron-down' : 'chevron-up'}
              iconColor={colors.primary.default}
              iconSize={24}
            />
          </CollapseAdditionalInfo>
        </CollapseTitle>
      </CollapseContainer>
      {collapType === 'hide' ? (
        <CollapseContent collapsed={collapsed} data-testid='content-hide'>
          {children}
        </CollapseContent>
      ) : (
        <CollapseContent>{!collapsed ? children : null}</CollapseContent>
      )}
    </CollapseWrapper>
  );
};

export { CollapsibleSection, CollapseWrapper, CollapseContent, CollapseTitle };
