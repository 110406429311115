import { VENUES_QUERY_KEY } from '@domain/venues';
import { UseMutation, useMutationWrapper } from '@hooks/useMutationWrapper';
import { deleteVenue } from '@services/venues/venuesService';
import { useQueryClient } from 'react-query';

const useDeleteVenue = (): UseMutation<void, Error, string, unknown> => {
  const queryClient = useQueryClient();

  return useMutationWrapper<void, Error, string>(
    (venueId) => deleteVenue(venueId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(VENUES_QUERY_KEY);
      }
    }
  );
};

export { useDeleteVenue };
