import styled from '@emotion/styled';

const CardFooter = styled.footer`
  ${() => `
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: auto;
    `}
`;

export { CardFooter };
