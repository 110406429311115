import {
  CreateVenueModel,
  CreateVenueResponse,
  UpdateVenueModel,
  UpdateVenueResponse,
  Venue
} from '@domain/venues';
import { apiService } from '@services/api';
import {
  CreateVenueModel as CreateVenueModelServices,
  UpdateVenueModel as UpdateVenueModelServices
} from '@services/api/apiService';

const getAllVenues = async (getArchived: boolean): Promise<Venue[]> => {
  return await apiService.venue_GetAll(getArchived);
};

const createVenue = async (
  venue: CreateVenueModel
): Promise<CreateVenueResponse> => {
  return await apiService.venue_Create(CreateVenueModelServices.fromJS(venue));
};

const updateVenue = async (
  id: string,
  venue: UpdateVenueModel
): Promise<UpdateVenueResponse> => {
  return await apiService.venue_Update(
    id,
    UpdateVenueModelServices.fromJS(venue)
  );
};

const deleteVenue = async (venueId: string): Promise<void> => {
  return await apiService.venue_Delete(venueId);
};

const archiveVenue = async (venueId: string): Promise<void> => {
  return await apiService.venue_Archive(venueId);
};

const unarchiveVenue = async (venueId: string): Promise<void> => {
  return await apiService.venue_Unarchive(venueId);
};

export {
  getAllVenues,
  createVenue,
  updateVenue,
  deleteVenue,
  archiveVenue,
  unarchiveVenue
};
