import { RouteModel } from '@app/shared/routes';
import { lazy } from 'react';

import { LoginLocation } from './loginLocation';
import { LogoutLocation } from './logoutLocation';
import { SigninCallbackLocation } from './signinCallbackLocation';
import { SilentRenewLocation } from './silentRenewLocation';

const SigninCallback: React.LazyExoticComponent<React.FC> = lazy(
  () => import('../SigninCallback')
);

const SilentRenew: React.LazyExoticComponent<React.FC> = lazy(
  () => import('../SilentRenew')
);

const Login: React.LazyExoticComponent<React.FC> = lazy(
  () => import('../Login')
);

const Logout: React.LazyExoticComponent<React.FC> = lazy(
  () => import('../Logout')
);

const authRoutes: RouteModel[] = [
  {
    location: SigninCallbackLocation,
    component: SigninCallback
  },
  {
    location: SilentRenewLocation,
    component: SilentRenew
  },
  {
    location: LoginLocation,
    component: Login
  },
  {
    location: LogoutLocation,
    component: Logout
  }
];

export { authRoutes };
