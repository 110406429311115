import {
  ButtonPrimary,
  ButtonsContainer,
  ButtonSecondary,
  DetailsFormContent,
  FileBox,
  FormFooter,
  GridTemplate,
  Prompt
} from '@app/shared/components';
import {
  TermConditionDetailFormModel,
  termConditionDetailFormSchema
} from '@domain/term-condition/models/shared/termConditionDetailForm';
import { PDF_ALLOWED_EXTENSIONS } from '@domain/term-condition/models/shared/termConditionDetailFormConstraints';
import { yupResolver } from '@hookform/resolvers';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TermConditionFileUpload } from './TermConditionFileUpload';

interface TermConditionDetailFormProps {
  onSubmit: (data: TermConditionDetailFormModel) => Promise<void>;
  onCancel: () => void;
}

export const TermConditionDetailForm = ({
  onSubmit,
  onCancel
}: TermConditionDetailFormProps): ReactElement => {
  const { t } = useTranslation('term-condition');

  const [fileName, setFileName] = React.useState<string>('');

  const { register, handleSubmit, errors, formState } = useForm<
    TermConditionDetailFormModel
  >({
    resolver: yupResolver(termConditionDetailFormSchema),
    mode: 'all'
  });

  const onUploadFiles = (files: File[]): void => {
    if (files && files.length) {
      setFileName(files[0].name);
    }
  };

  return (
    <Prompt
      hasChanges={
        formState.isDirty &&
        !formState.isSubmitting &&
        !formState.isSubmitSuccessful
      }>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
        <DetailsFormContent>
          <GridTemplate>
            <FileBox
              name='pdfFile'
              accept={PDF_ALLOWED_EXTENSIONS.join(',')}
              onUploadFiles={onUploadFiles}
              innerRef={register}>
              <TermConditionFileUpload
                fileName={fileName}
                error={errors?.pdfFile}
              />
            </FileBox>
          </GridTemplate>
        </DetailsFormContent>
        <FormFooter>
          <ButtonsContainer>
            <ButtonSecondary
              type='button'
              onClick={onCancel}
              disabled={formState.isSubmitting}
              label={t('shared:generics.cancel')}
            />
            <ButtonPrimary
              type='submit'
              disabled={formState.isSubmitting || !formState.isDirty}
              label={t('shared:generics.save')}
            />
          </ButtonsContainer>
        </FormFooter>
      </form>
    </Prompt>
  );
};
