import { DependencyList, useEffect, useRef } from 'react';

import { useUpdateEffect } from './useUpdateEffect';

const useDebounce = (
  callback: () => void,
  delay: number,
  deps: DependencyList = []
): void => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useUpdateEffect(() => {
    const handler = setTimeout(() => {
      callbackRef.current();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, ...deps]);
};

export { useDebounce };
