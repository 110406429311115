import { FacilityManagementLocation } from '@app/facility-management';
import { BuildingsLocation } from '@app/facility-management/buildings';
import { FloorplansLocation } from '@app/facility-management/floorplans';
import { ButtonIconSecondary, ButtonPrimary } from '@app/shared/components';
import {
  Breadcrumb,
  BreadcrumbItems
} from '@app/shared/components/breadcrumb/Breadcrumb';
import {
  PageToolbar,
  PageToolbarActions,
  PageToolbarHeader
} from '@app/shared/components/page/page-toolbar/PageToolbar';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface FloorplanSettingsToolbarProps {
  venueId: string;
  venueName: string;
  buildingId: string;
  buildingName: string;
  floorplanName: string;
  onToggleSettings: () => void;
  onToggleLegend: () => void;
}

const FloorplanSettingsToolbar = ({
  venueId,
  venueName,
  buildingId,
  buildingName,
  floorplanName,
  onToggleSettings,
  onToggleLegend
}: FloorplanSettingsToolbarProps): ReactElement => {
  const { t } = useTranslation('workplaces');

  const brItems: BreadcrumbItems[] = [
    {
      label: t(`shared:routes.facilities`),
      linkTo: FacilityManagementLocation.toUrl()
    },
    {
      label: venueName,
      linkTo: BuildingsLocation.toUrl({ venueId })
    },
    {
      label: buildingName,
      linkTo: FloorplansLocation.toUrl({ venueId, buildingId })
    },
    {
      label: floorplanName
    }
  ];

  return (
    <PageToolbar>
      <PageToolbarHeader>
        <Breadcrumb items={brItems} />
      </PageToolbarHeader>
      <PageToolbarActions>
        <ButtonPrimary label={t('settings.title')} onClick={onToggleSettings} />
        <ButtonIconSecondary
          iconName='help'
          iconSize={28}
          onClick={onToggleLegend}
        />
      </PageToolbarActions>
    </PageToolbar>
  );
};

export default FloorplanSettingsToolbar;
