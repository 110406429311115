import styled from '@emotion/styled';
import { useClickOutsideAction } from '@hooks/useClickOutsideAction';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import { ButtonIcon, ButtonIconBase } from '../button-icon';
import {
  MenuActions,
  MenuActionsItem,
  MenuActionsWrapper
} from '../menu-actions';

interface CardDropdownProps {
  dropdownOptions: MenuActionsItem[];
}

const CardDropdownWrapper = styled.div`
  ${({
    theme: {
      base: { zindex }
    }
  }) => `
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;
        width: fit-content;

        ${ButtonIconBase} {
            position: relative;
        }

        ${MenuActionsWrapper} {
            position: absolute;
            right: ${rem(pxToRem(32))};
            top: 50%;
            transform: translateY(-50%);
            z-index: ${zindex.above};
        }
    `}
`;

const CardDropdown = ({ dropdownOptions }: CardDropdownProps): ReactElement => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [dropdownRef, setDropdownRef] = useState<HTMLDivElement | null>(null);
  const [menuRef, setMenuRef] = useState<HTMLDivElement | null>(null);
  const menuElementRef = useRef<HTMLDivElement | null>(null);

  useClickOutsideAction(menuElementRef, () => setOpen(false));

  const toggleDropdownMenu = (): void => {
    setOpen((value) => !value);
  };

  const { styles, attributes, update } = usePopper(dropdownRef, menuRef, {
    placement: 'left',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10]
        }
      }
    ]
  });

  useLayoutEffect(() => {
    if (isOpen) {
      update?.();
    }
  }, [isOpen]);

  return (
    <CardDropdownWrapper
      ref={setDropdownRef}
      onClick={(ev) => ev.stopPropagation()}>
      <ButtonIcon
        iconName='ellipsis-h'
        iconSize={24}
        onClick={toggleDropdownMenu}
      />
      {createPortal(
        <div
          ref={(ref) => {
            setMenuRef(ref);
            menuElementRef.current = ref;
          }}
          style={styles.popper}
          {...attributes.popper}>
          {isOpen && (
            <MenuActions
              options={dropdownOptions}
              onClick={toggleDropdownMenu}
            />
          )}
        </div>,
        document.querySelector('#root')!
      )}
    </CardDropdownWrapper>
  );
};

export { CardDropdown };
