import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React from 'react';

import { Icon, IconWrapper } from '../icon';

export type CardListDataProps = {
  label: string | number;
  iconName: string;
};

const CardListDataWrapper = styled.div`
  ${({
    theme: {
      base: {
        fonts: { getTextsConfig }
      }
    }
  }) => `
    align-items: center;
    display: flex;
    ${getTextsConfig('M')};
    justify-content: flex-start;

    ${IconWrapper} {
      margin-right: ${rem(pxToRem(8))};
    }
  `}
`;

const CardListData: React.FC<CardListDataProps> = ({
  label,
  iconName
}: CardListDataProps) => {
  const {
    base: { colors }
  } = useTheme();

  return (
    <CardListDataWrapper>
      <Icon
        iconName={iconName}
        iconSize={24}
        iconColor={colors.neutral.grayDark}
      />
      {label}
    </CardListDataWrapper>
  );
};

export { CardListData };
