import React, { ReactElement, useState } from 'react';

import { IncidentTable } from './incident-table/IncidentTable';
import { IncidentToolbar } from './incident-toolbar/IncidentToolbar';

const Incident = (): ReactElement => {
  const [incidentTypeId, setIncidentTypeId] = useState<string | undefined>();
  return (
    <>
      <IncidentToolbar
        incidentTypeId={incidentTypeId}
        onChangeIncidentType={setIncidentTypeId}
      />
      {incidentTypeId && <IncidentTable incidentTypeId={incidentTypeId} />}
    </>
  );
};

export default Incident;
