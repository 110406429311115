import '@services/i18n';
import '@utils/prototypes';
import 'react-toastify/dist/ReactToastify.css';

import { Authentication, authRoutes } from '@app/auth';
import { ResetableErrorBoundary } from '@app/errors';
import { Header } from '@app/header';
import { AppRoutes, renderRoutes } from '@app/routes';
import { AppRouter } from '@app/routes/Router';
import { ToastWrapper } from '@app/shared/components';
import { DialogProvider } from '@app/shared/components/dialog';
import { SidebarContainer } from '@app/shared/components/layout';
import { LayoutContainer } from '@app/shared/components/layout/layout-container/LayoutContainer';
import { MainContainer } from '@app/shared/components/layout/main-container/MainContainer';
import { SidebarMenu } from '@app/sidebar-menu';
import { Suspense } from '@components/suspense';
import { configuration } from '@configuration';
import { AuthProvider } from '@contexts/auth';
import UserProfile from '@contexts/user-profile/userProfile';
import { ThemeProvider } from '@emotion/react';
import { authConfiguration, createAuthService } from '@services/auth';
import { createQueryClient } from '@services/http';
import {
  initializeAppInsights,
  setUserContext,
  trackException
} from '@services/logger/applicationInsightsService';
import { GlobalStyles } from '@styles/GlobalStyles';
import { getTheme } from '@styles/themeFactory';
import { createBrowserHistory } from 'history';
import React, { ReactElement } from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const { debug, theme } = configuration;

const history = createBrowserHistory();
const authService = createAuthService(authConfiguration);
const queryClient = createQueryClient();

initializeAppInsights();

const App = (): ReactElement => (
  <ThemeProvider theme={getTheme(theme)}>
    <QueryClientProvider client={queryClient}>
      <ToastWrapper>
        <ToastContainer hideProgressBar={true} />
      </ToastWrapper>
      <DialogProvider>
        <GlobalStyles />
        <AppRouter history={history}>
          <AuthProvider
            authService={authService}
            onError={(error) => trackException({ error })}
            onUserLoaded={setUserContext}>
            <ResetableErrorBoundary>
              <Suspense>
                <Switch>
                  {renderRoutes(authRoutes)}
                  <Route
                    render={() => (
                      <Authentication>
                        <LayoutContainer>
                          <SidebarContainer>
                            <SidebarMenu />
                          </SidebarContainer>
                          <MainContainer>
                            <UserProfile>
                              <Header />
                              <AppRoutes />
                            </UserProfile>
                          </MainContainer>
                        </LayoutContainer>
                      </Authentication>
                    )}
                  />
                </Switch>
              </Suspense>
            </ResetableErrorBoundary>
          </AuthProvider>
        </AppRouter>
      </DialogProvider>
      {debug && <ReactQueryDevtools />}
    </QueryClientProvider>
  </ThemeProvider>
);

export default App;
