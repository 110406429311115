import styled from '@emotion/styled';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement } from 'react';

import { ButtonIcon, ButtonIconBase } from '../button-icon';

const MenuActionsWrapper = styled.ul`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
      align-items: center;
      background-color: ${colors.neutral.fullLight};
      border-radius: ${rem(pxToRem(6))};
      display: inline-flex;
      filter: drop-shadow(0 0 8px rgba(9, 27, 44, 0.183622));
      padding: ${rem(pxToRem(8))};
      position: relative;

      &:after {
        border-color: transparent transparent transparent ${
          colors.neutral.fullLight
        };
        border-style: solid;
        border-width: ${rem(pxToRem(10))} 0 ${rem(pxToRem(10))} ${rem(
    pxToRem(10)
  )};
        content: '';
        position: absolute;
        right: ${rem(pxToRem(-10))};
        top: 50%;
        transform: translateY(-50%);
        z-index: 0;
      }
  `}
`;

const MenuActionsTooltip = styled.span`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig },
        radius
      }
    }
  }) => `
        background-color: ${colors.neutral.fullLight};
        border-radius: ${radius.rounded};
        box-shadow: 0 2px 6px rgba(9, 27, 44, 0.183622);
        color: ${colors.primary.darkest};
        display: block;
        ${getTextsConfig('S')};
        left: 50%;
        opacity: 0;
        padding: 0 ${rem(pxToRem(8))};
        position: absolute;
        top: ${rem(pxToRem(-32))};
        transform: translateX(-50%);
        white-space: nowrap;
        word-break: normal;
    `}
`;

const MenuActionsItem = styled.li`
  ${({
    theme: {
      base: { colors, radius }
    }
  }) => `
    position: relative;

    &:not(:last-of-type) {
      margin-right: ${rem(pxToRem(8))};
    }

    ${ButtonIconBase} {
      align-items: center;
      border-radius: ${radius.circle};
      display: flex;
      height: ${rem(pxToRem(36))};
      justify-content: center;
      width: ${rem(pxToRem(36))};
      
      &:hover {
        background-color: ${getRgbaStrFromHexColor(
          colors.primary.default,
          0.1
        )};

        ~ ${MenuActionsTooltip} {
          opacity: 1;
        }
      }
    }
  `}
`;

export interface MenuActionsItem {
  label?: string;
  iconName: string;
  disabled?: boolean;
  show?: boolean;
  onClick?: () => void;
}

interface MenuActionsProps {
  options: MenuActionsItem[];
  onClick?: () => void;
}

const MenuActions = ({ options, onClick }: MenuActionsProps): ReactElement => {
  return (
    <MenuActionsWrapper data-testid='menu-actions'>
      {options.map(
        ({
          label,
          iconName,
          disabled,
          show = true,
          onClick: onClickItem
        }: MenuActionsItem) =>
          show && (
            <MenuActionsItem key={label}>
              <ButtonIcon
                iconName={iconName}
                iconSize={24}
                onClick={() => {
                  onClick?.();
                  onClickItem?.();
                }}
                disabled={disabled}
              />
              <MenuActionsTooltip>{label}</MenuActionsTooltip>
            </MenuActionsItem>
          )
      )}
    </MenuActionsWrapper>
  );
};

export { MenuActions, MenuActionsWrapper };
