/* eslint-disable */
// @ts-ignore
declare global {
  interface Array<T> {
    intersection(array: T[]): T[];
    includes(searchElement: any, fromIndex?: number): boolean;
    difference(arrayToCompare: T[]): T[];
    symmetricDifference(arrayToCompare: T[]): T[];
    sortBy(property: keyof T): Array<T>;
    sortNumbers(): number[];
    groupBy(f: (arg: T) => any): Array<{ key: any; value: T[] }>;
    isEmpty(): boolean;
    isNotEmpty(): boolean;
    move(fromIndex: number, toIndex: number): T[];
    first(): T | null;
    last(): T | null;
    at(index: number): T | null;
    clear(): void;
    removeDuplicate(): T[];
    toKeyValuePair(): Array<{
      name: string;
      id: number | string | boolean | null;
    }>;
  }
}

if (!Array.prototype.intersection) {
  Array.prototype.intersection = function <T>(array: T[]) {
    return this.filter((x) => array.includes(x));
  };
}

// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, 'includes', {
    // @ts-ignore
    value: function (searchElement, fromIndex) {
      if (this == null) {
        throw new TypeError('"this" es null o no está definido');
      }
      const o = Object(this);
      const len = o.length >>> 0;

      if (len === 0) {
        return false;
      }

      const n = fromIndex | 0;
      let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

      // @ts-ignore
      function sameValueZero(x, y) {
        return (
          x === y ||
          (typeof x === 'number' &&
            typeof y === 'number' &&
            isNaN(x) &&
            isNaN(y))
        );
      }

      while (k < len) {
        if (sameValueZero(o[k], searchElement)) {
          return true;
        }
        k++;
      }

      return false;
    }
  });
}

if (!Array.prototype.difference) {
  Array.prototype.difference = function (arrayToCompare: any[]) {
    return this.filter((x) => !arrayToCompare.includes(x));
  };
}

if (!Array.prototype.symmetricDifference) {
  Array.prototype.symmetricDifference = function (arrayToCompare: any[]) {
    return this.filter((x) => !arrayToCompare.includes(x)).concat(
      arrayToCompare.filter((x) => !this.includes(x))
    );
  };
}

if (!Array.prototype.sortBy) {
  Array.prototype.sortBy = function <T>(property: keyof T) {
    if (this[0] === undefined) {
      return this;
    } else {
      const type = typeof this[0][property] || 'string';
      return this.sort((x, y) =>
        (sortFunctions as any)[type](x[property], y[property])
      );
    }
  };
}

if (!Array.prototype.sortNumbers) {
  Array.prototype.sortNumbers = function () {
    return this.sort(sortFunctions.number);
  };
}

if (!Array.prototype.groupBy) {
  Array.prototype.groupBy = function <T>(f: (arg: T) => any) {
    const groupByObjects = this.reduce(
      (r: any, v: any, i: any, a: any, k = f(v)) => (
        (r[k] || (r[k] = [])).push(v), r
      ),
      {}
    );
    const groupByModels = [];
    for (const obj in groupByObjects) {
      if (!groupByObjects.hasOwnProperty(obj)) {
        continue;
      }
      groupByModels.push({
        key: obj,
        value: groupByObjects[obj]
      });
    }
    return groupByModels;
  };
}

if (!Array.prototype.isEmpty) {
  Array.prototype.isEmpty = function () {
    return this === null || this === undefined || this.length === 0;
  };
}

if (!Array.prototype.isNotEmpty) {
  Array.prototype.isNotEmpty = function () {
    return !this.isEmpty();
  };
}

if (!Array.prototype.move) {
  Array.prototype.move = function (fromIndex: number, toIndex: number) {
    this.splice(toIndex, 0, this.splice(fromIndex, 1)[0]);
    return this;
  };
}

if (!Array.prototype.first) {
  Array.prototype.first = function () {
    return this.length === 0 ? null : this[0];
  };
}

if (!Array.prototype.last) {
  Array.prototype.last = function () {
    return this.length === 0 ? null : this[this.length - 1];
  };
}

if (!Array.prototype.at) {
  Array.prototype.at = function (index: number) {
    return index >= this.length ? null : this[index];
  };
}

if (!Array.prototype.clear) {
  Array.prototype.clear = function () {
    this.splice(0, this.length);
  };
}

if (!Array.prototype.removeDuplicate) {
  Array.prototype.removeDuplicate = function () {
    return this.filter((x, index) => this.indexOf(x) === index);
  };
}

if (!Array.prototype.toKeyValuePair) {
  Array.prototype.toKeyValuePair = function () {
    return this.isNotEmpty() && 'id' in this[0] && 'name' in this[0]
      ? this.map((x) => ({ id: x.id, name: x.name || '' }))
      : [];
  };
}

interface ISortFunctions {
  string: (x: string, y: string) => number;
  number: (x: number, y: number) => number;
}

const sortFunctions: ISortFunctions = {
  string: (x: string, y: string) => (x < y ? -1 : x > y ? 1 : 0),
  number: (x: number, y: number) => x - y
};

export {};
