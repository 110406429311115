import { FLOORPLANS_KEY } from '@domain/floorplans';
import { UseMutation, useMutationWrapper } from '@hooks/useMutationWrapper';
import { setFloorplanMetadata } from '@services/floorplans/floorplansService';
import { SceneState } from '@wave-engine/wave-viewer';
import { useQueryClient } from 'react-query';

const useUpdateFloorplanMetadata = (
  venueId: string,
  buildingId: string,
  floorId: string
): UseMutation<void, Error, SceneState, unknown> => {
  const queryClient = useQueryClient();

  return useMutationWrapper<void, Error, SceneState>(
    (metadataModel) => setFloorplanMetadata(floorId, metadataModel),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FLOORPLANS_KEY(venueId, buildingId));
      }
    }
  );
};

export { useUpdateFloorplanMetadata };
