import icons from '@assets/fonts/smo-icons-font.json';
import styled from '@emotion/styled';
import React, { Ref } from 'react';

interface IconWrapperProps {
  iconBaseName: string;
  iconBaseSize: number;
  iconBaseColor: string;
}

const IconWrapper = styled('span')<IconWrapperProps>`
  ${({ iconBaseName, iconBaseSize, iconBaseColor }) => `
        align-items: center;
        ${iconBaseColor ? `color: ${iconBaseColor};` : ''}
        display: inline-flex;
        font-family: smo-icons-font !important;
        font-size: ${iconBaseSize}px;
        font-style: normal;
        font-variant: normal;
        font-weight: normal !important;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        &:before {
            content: '${iconBaseName}';
            display: block;
            line-height: initial;
        }
    `}
`;

interface IconProps {
  iconName: string;
  iconSize?: number;
  iconColor?: string;
  title?: string;
  ref?: Ref<HTMLSpanElement>;
}

const Icon: React.FC<IconProps> = ({
  iconName,
  iconSize = 16,
  iconColor,
  title,
  ref
}: IconProps) => {
  const iconValue = (icons as never)[iconName];

  return (
    <IconWrapper
      title={title}
      iconBaseName={iconValue ? iconValue : (icons as never)['_default']}
      iconBaseSize={iconSize}
      iconBaseColor={iconColor ? iconColor : ''}
      ref={ref}
    />
  );
};

export { IconWrapper, Icon };
