import styled from '@emotion/styled';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement } from 'react';

import { Icon, IconWrapper } from '../icon';

export type ButtonStyle = 'primary' | 'secondary' | 'featured' | 'basic';

const ButtonBase = styled.button`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig, semiBoldFont }
      }
    }
  }) => `
        align-items: center;
        border-radius: ${rem(pxToRem(50))};
        border: 2px solid transparent;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        ${getTextsConfig('M')};
        font-family: '${semiBoldFont}';
        justify-content: center;
        min-height: ${rem(pxToRem(36))};
        min-width: ${rem(pxToRem(97))};
        padding: 0 ${rem(pxToRem(22))};
        text-align: center;
        transition: all 0.3s ease;

        &:hover {
          cursor: pointer;
        }

        &:focus {
            outline: 0;
        }

        ${IconWrapper} {
            margin-right: ${rem(pxToRem(6))};
        }

        &.primary {
          background-color: ${colors.primary.default};
          color: ${colors.neutral.fullLight};

          &:hover:not(:disabled):not(:active),
          &:focus {
            box-shadow: 0 ${rem(pxToRem(-40))} 0 0 ${colors.primary.dark} inset;
            border-color: ${colors.primary.dark};
          }

          &:active {
            background-color: ${colors.primary.default};
            border-color: ${colors.primary.default};
            box-shadow: 0 ${rem(pxToRem(-40))} 0 0 ${
    colors.primary.default
  } inset;
            color: ${colors.neutral.fullLight};
          }

          &:disabled {
            background-color: ${getRgbaStrFromHexColor(
              colors.primary.darkest,
              0.06
            )};
            border-color: transparent;
            color: ${getRgbaStrFromHexColor(colors.primary.darkest, 0.4)};
          }
        }

        &.secondary {
          background-color: transparent;
          border-color: ${colors.neutral.gray};
          color: ${colors.primary.default};

          &:hover:not(:disabled):not(:active),
          &:focus {
            background-color: ${colors.primary.dark};
            box-shadow: 0 ${rem(pxToRem(-40))} 0 0 ${colors.primary.dark} inset;
            border-color: ${colors.primary.dark};
            color: ${colors.neutral.fullLight};
          }

          &:active {
            background-color: ${colors.primary.default};
            border-color: ${colors.primary.default};
            box-shadow: 0 ${rem(pxToRem(-40))} 0 0 ${
    colors.primary.default
  } inset;
            color: ${colors.neutral.fullLight};
          }

          &:disabled {
            border-color: ${getRgbaStrFromHexColor(colors.neutral.dark, 0.15)};
            color: ${getRgbaStrFromHexColor(colors.primary.darkest, 0.4)};
          }
        }

        &.featured {
          background-color: ${colors.system.error};
          border-color: ${colors.system.error};
          color: ${colors.neutral.fullLight};

          &:hover:not(:disabled):not(:active),
          &:focus {
            box-shadow: 0 ${rem(pxToRem(-40))} 0 0 ${
    colors.secondary.secondDark
  } inset;
            border-color: ${colors.secondary.secondDark};
          }

          &:active {
            background-color: ${colors.system.error};
            border-color: ${colors.system.error};
            box-shadow: 0 ${rem(pxToRem(-40))} 0 0 ${colors.system.error} inset;
            color: ${colors.neutral.fullLight};
          }

          &:disabled {
            background-color: ${getRgbaStrFromHexColor(
              colors.primary.darkest,
              0.06
            )};
            border-color: transparent;
            color: ${getRgbaStrFromHexColor(colors.primary.darkest, 0.4)};
          }
        }

        &.basic {
          background-color: ${getRgbaStrFromHexColor(
            colors.primary.default,
            0.1
          )};
          color: ${colors.primary.default};
          ${getTextsConfig('S')};
          font-family: ${semiBoldFont};
          height: ${rem(pxToRem(32))};
          min-width: ${rem(pxToRem(74))};

          &:hover:not(:disabled):not(:active),
          &:focus {
            box-shadow: 0 ${rem(pxToRem(-40))} 0 0 ${getRgbaStrFromHexColor(
    colors.primary.default,
    0.2
  )} inset;
            border-color: ${getRgbaStrFromHexColor(
              colors.primary.default,
              0.2
            )};
          }

          &:active {
            background-color: ${colors.primary.default};
            border-color: ${colors.primary.default};
            box-shadow: 0 ${rem(pxToRem(-40))} 0 0 ${
    colors.primary.default
  } inset;
            color: ${colors.neutral.fullLight};
          }

          &:disabled {
            background-color: ${getRgbaStrFromHexColor(
              colors.primary.darkest,
              0.06
            )};
            border-color: transparent;
            color: ${getRgbaStrFromHexColor(colors.primary.darkest, 0.4)};
          }
        }

        &:disabled {
          &,
          &:hover {
            cursor: default;
          }
        }

        > div {
          display: inherit;
        }
    `}
`;

export type ButtonProps = {
  label: string;
  iconName?: string;
  btnStyle?: ButtonStyle;
  className?: string;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const Button = ({
  label,
  iconName,
  btnStyle,
  ...restProps
}: ButtonProps): ReactElement => {
  return (
    <ButtonBase type='button' className={btnStyle} {...restProps}>
      {iconName && <Icon iconName={iconName} iconSize={24} />}
      {label}
    </ButtonBase>
  );
};

type ButtonTypeProps = Omit<ButtonProps, 'btnStyle' | 'className'>;

const ButtonPrimary = ({ ...restProps }: ButtonTypeProps): ReactElement => (
  <Button btnStyle='primary' {...restProps} />
);

const ButtonSecondary = ({ ...restProps }: ButtonTypeProps): ReactElement => (
  <Button btnStyle='secondary' {...restProps} />
);

const ButtonFeatured = ({ ...restProps }: ButtonTypeProps): ReactElement => (
  <Button btnStyle='featured' {...restProps} />
);

const ButtonBasic = ({ ...restProps }: ButtonTypeProps): ReactElement => (
  <Button btnStyle='basic' {...restProps} />
);

export {
  ButtonBasic,
  ButtonPrimary,
  ButtonSecondary,
  ButtonFeatured,
  ButtonBase
};
