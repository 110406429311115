import styled from '@emotion/styled';
import { Icon, IconWrapper } from '@modules/app/shared/components/icon';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonIcon, ButtonIconBase } from '../button-icon';

type ImageFileUploadProps = {
  image?: File;
  error?: string;
  imageUrl?: string;
  onRemoveImage: () => void;
};

const Container = styled.figure`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const ActionsWrapper = styled.div`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;

        ${ButtonIconBase} {
            background-color: ${colors.system.error};
            display: inline-block;
            height: 100%;
            line-height: 0;
            width: 100%;

            ${IconWrapper} {
                color: ${colors.neutral.fullLight};
                font-size: ${rem(pxToRem(24))};
            }
        }
    `}
`;

const ProjectImageContainer = styled.div`
  ${({
    theme: {
      base: { colors, radius }
    }
  }) => `
        align-items: center;
        background-color: ${getRgbaStrFromHexColor(
          colors.primary.default,
          0.1
        )};
        border-radius: ${radius.circle};
        border: 1px solid transparent;
        color: ${colors.primary.default};
        display: flex;
        height: ${rem(pxToRem(80))};
        justify-content: center;
        margin-right: ${rem(pxToRem(16))};
        overflow: hidden;
        position: relative;
        width: ${rem(pxToRem(80))};

        &:hover {
            ${ActionsWrapper} {
                opacity: 1;
            }
        }
    `}
`;

const ProjectImageWrapper = styled.div`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
        background-color: ${colors.neutral.fullLight};
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    `}
`;

const ProjectImage = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

const SummaryContainer = styled.figcaption``;

const SummaryChooseImage = styled.p`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getHeadlinesConfig, regularFont }
      }
    }
  }) => `
    color: ${colors.neutral.dark};
    ${getHeadlinesConfig('XS')};
    font-family: ${regularFont};
  `}
`;

const SummaryConditions = styled.p`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig }
      }
    }
  }) => `
    color: ${colors.neutral.grayDark};
    ${getTextsConfig('S')};
  `}
`;

const ErrorMessage = styled.p`
  ${({
    theme: {
      base: {
        fonts: { getTextsConfig },
        colors
      }
    }
  }) => `
        color: ${colors.system.error};
        ${getTextsConfig('S')};
    `}
`;

const ImageUpload: React.FC<ImageFileUploadProps> = ({
  image,
  imageUrl,
  error,
  onRemoveImage
}: ImageFileUploadProps) => {
  const { t } = useTranslation();
  const [imageBlobUrl, setImageBlobUrl] = useState<string>();

  useEffect(() => {
    let url: string;
    if (image) {
      url = URL.createObjectURL(image);
      setImageBlobUrl(url);
    } else {
      setImageBlobUrl(undefined);
    }
    return () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, [image]);

  const removeFile = (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event?.stopPropagation();
    onRemoveImage();
  };

  return (
    <Container>
      <ProjectImageContainer role='img'>
        {(imageBlobUrl || imageUrl) && !error ? (
          <ProjectImageWrapper>
            <ProjectImage src={imageBlobUrl || imageUrl} />
            <ActionsWrapper>
              <ButtonIcon iconName='trash' type='button' onClick={removeFile} />
            </ActionsWrapper>
          </ProjectImageWrapper>
        ) : (
          <Icon iconName='picture' iconSize={24} />
        )}
      </ProjectImageContainer>
      <SummaryContainer>
        <SummaryChooseImage>
          {t('images.upload.chooseImage')}
        </SummaryChooseImage>
        <SummaryConditions>
          {t('images.upload.imageConditions')}
        </SummaryConditions>
        <ErrorMessage>{error}</ErrorMessage>
      </SummaryContainer>
    </Container>
  );
};

export { ImageUpload };
