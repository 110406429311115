import styled from '@emotion/styled';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement } from 'react';

interface FilterButtonProps {
  btnActive?: boolean;
  noBorder?: boolean;
  isDisabled?: boolean;
}

const FilterButton = styled.button<FilterButtonProps>`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig, semiBoldFont }
      }
    },
    btnActive,
    isDisabled
  }) => `
    border-bottom: 2px solid ${
      btnActive ? colors.primary.selectable : 'transparent'
    };
    color: ${
      btnActive
        ? colors.primary.default
        : getRgbaStrFromHexColor(colors.primary.darkest, 0.6)
    };
    ${getTextsConfig('M')};
    font-family: '${semiBoldFont}';
    padding: ${rem(pxToRem(14))} 0 ${rem(pxToRem(10))};
    text-align: center;

    &:hover:not(:disabled) {
      color: ${colors.primary.default};
      cursor: pointer;
    }

    ${isDisabled ? `opacity: 0.6;` : ''}
  `}
`;

const FilterButtonsWrapper = styled.div<{ noBorder?: boolean }>`
  ${({
    theme: {
      base: { colors }
    },
    noBorder
  }) => `
    align-items: flex-end;
    ${
      noBorder
        ? ''
        : `border-bottom: 1px solid ${getRgbaStrFromHexColor(
            colors.neutral.dark,
            0.2
          )};`
    }

    display: flex;
    justify-content: flex-start;

    ${FilterButton} {
      &:not(:last-of-type) {
        margin-right: ${rem(pxToRem(32))};
      }
    }
  `}
`;

export interface FilterButtonsItem {
  label: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

interface FilterButtonsProps extends FilterButtonProps {
  buttons: FilterButtonsItem[];
  className?: string;
  onSelectionChange?: (index: number) => void;
}

const FilterButtons = ({
  noBorder,
  buttons,
  className,
  onSelectionChange
}: FilterButtonsProps): ReactElement => (
  <FilterButtonsWrapper className={className} noBorder={noBorder}>
    {buttons.map((button, index) => (
      <FilterButton
        key={index}
        type='button'
        {...button}
        onClick={() => {
          button.onClick?.();
          onSelectionChange?.(index);
        }}
        btnActive={button.active}
        isDisabled={button.disabled}>
        {button.label}
      </FilterButton>
    ))}
  </FilterButtonsWrapper>
);

export { FilterButtons, FilterButtonsWrapper };
