import { ButtonIconBase, IconWrapper } from '@app/shared/components';
import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';

export type PanelFloatProps = {
  column?: boolean;
};

const PanelFloat = styled.div<PanelFloatProps>`
  ${({
    theme: {
      base: { colors }
    },
    column
  }) => `
        align-items: center;
        background-color: ${colors.neutral.grayAccent};
        border-radius: ${rem(pxToRem(6))};
        display: flex;
        flex-direction: ${column ? 'column' : 'row'};
        padding: ${rem(pxToRem(8))};

        ${ButtonIconBase} {
            margin: ${
              column ? `0 0 ${rem(pxToRem(12))} 0` : `0 ${rem(pxToRem(12))} 0 0`
            };

            &:last-of-type {
                margin: 0;
            }

            ${IconWrapper} {
                font-size: ${rem(pxToRem(32))};
            }
        }
    `}
`;

const ActionButtonMini = styled.button`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: ${rem(pxToRem(12))} ${rem(pxToRem(16))};

  &:hover {
    cursor: pointer;
  }

  &:not(:last-of-type) {
    margin-right: ${rem(pxToRem(8))};
  }
`;

export { PanelFloat, ActionButtonMini };
