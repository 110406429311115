import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type TagProps = {
  status: 'resolved' | 'unsolved';
};

const StatusTagContainer = styled.div`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig }
      }
    }
  }) => `
        border-radius: ${rem(pxToRem(50))};
        box-sizing: border-box;
        color: ${colors.neutral.fullLight};
        display: inline-block;
        ${getTextsConfig('M')};
        min-height: ${rem(pxToRem(26))};
        min-width: ${rem(pxToRem(86))};
        padding: ${rem(pxToRem(2))} ${rem(pxToRem(10))};
        text-align: center;

        &.resolved {
            background-color: ${colors.system.success};
        }

        &.unsolved {
            background-color: ${colors.system.error};
        }
    `}
`;

const StatusTag: React.FC<TagProps> = ({ status }: TagProps) => {
  const { t } = useTranslation();

  return (
    <StatusTagContainer className={status}>
      {t(`status.${status}`)}
    </StatusTagContainer>
  );
};

export { StatusTag };
