import { VenuesWrapper } from '@app/facility-management/buildings/buildings-toolbar/venues/venues-wrapper/VenuesWrapper';
import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';

import { PageTitleElement } from '../page-title/PageTitle';

const PageToolbar = styled.div`
  ${() => `
    width: 100%;

    ${PageTitleElement} {
      margin-bottom: ${rem(pxToRem(12))};
    }

    ${VenuesWrapper} {
      margin-bottom: ${rem(pxToRem(24))};
    }
  `}
`;

const PageToolbarHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${rem(pxToRem(22))};
`;

const PageToolbarActions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export { PageToolbar, PageToolbarActions, PageToolbarHeader };
