import { BookingSpace, DailyBooking } from '@domain/bookings';

export const getBookingsSpace = (
  dailyBookings: DailyBooking[],
  payloadId: string
): BookingSpace[] => {
  return dailyBookings
    .flatMap((d) => d.spaceWorkStations)
    .filter((x) => x.waveId === payloadId)
    .flatMap((x) => x.bookings);
};

export const getSpaceId = (
  dailyBookings: DailyBooking[],
  payloadId: string
): string => {
  const workStation = dailyBookings
    .flatMap((d) => d.spaceWorkStations)
    .find((workStation) => workStation.waveId === payloadId);

  return workStation?.spaceId ?? '';
};
