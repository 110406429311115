import styled from '@emotion/styled';
import { useUpdateEffect } from '@hooks/useUpdateEffect';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import { useCombobox, UseComboboxStateChange } from 'downshift';
import React, {
  ReactElement,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState
} from 'react';
import { createPortal } from 'react-dom';
import { Modifier, usePopper } from 'react-popper';

import { ButtonIcon } from '../button-icon';
import { Loader } from '../loader';
import { FormGroup, FormGroupProps } from './FormGroup';
import { InputWrapper } from './Input';

const DropdownInputWrapper = styled.div<{
  dropdownSize?: number;
}>`
  ${({ dropdownSize }) => `
    position: relative;
    width: ${dropdownSize ? `${rem(pxToRem(dropdownSize))}` : '100%'};
  `}
`;

const DropdownInputButtonIcon = styled(ButtonIcon)<{ hasError?: boolean }>`
  ${({
    theme: {
      base: { colors }
    },
    hasError
  }) => `
      padding: ${rem(pxToRem(12))};
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      ${hasError ? `color: ${colors.system.error}` : ''}
  `}
`;

const DropdownInput = styled(InputWrapper)`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
    padding-right: ${rem(pxToRem(34))};
    width: 100%;

    &:focus {
      ~ ${DropdownInputButtonIcon} {
        color: ${colors.primary.default};
      }
    }
  `}
`;

const DropdownListWrapper = styled.div`
  ${({
    theme: {
      base: { zindex }
    }
  }) => `
      z-index: ${zindex.tooltip};
  `}
`;

const DropdownListContent = styled.div`
  ${({
    theme: {
      base: { colors, shadow }
    }
  }) => `
      background: ${colors.neutral.fullLight};
      border-radius: ${rem(pxToRem(6))};
      box-shadow: ${shadow.default(colors)};
      box-sizing: border-box;
      padding: ${rem(pxToRem(8))} ${rem(pxToRem(4))} ${rem(pxToRem(8))} 0;
      width: 100%;
  `}
`;

const DropdownLoaderWrapper = styled.div`
  min-height: ${rem(pxToRem(40))};
`;

const DropdownList = styled.ul`
  max-height: ${rem(pxToRem(200))};
  overflow-y: auto;
`;

const ListItem = styled.li<{
  active: boolean;
  selected: boolean;
  disabled: boolean;
}>`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig }
      }
    },
    active,
    selected,
    disabled
  }) => `
    align-items: center;
    background-color: ${
      disabled
        ? colors.neutral.gray
        : selected
        ? getRgbaStrFromHexColor(colors.primary.default, 0.2)
        : active
        ? getRgbaStrFromHexColor(colors.primary.default, 0.06)
        : colors.neutral.fullLight
    };
    box-sizing: border-box;
    color: ${
      disabled
        ? colors.neutral.grayDark
        : active
        ? colors.primary.default
        : colors.primary.darkest
    };
    display: flex;
    list-style: none;
    margin: 0 ${rem(pxToRem(4))};
    min-height: ${rem(pxToRem(40))};
    padding: ${rem(pxToRem(8))} ${rem(pxToRem(12))};
    ${getTextsConfig('M')};

    &:hover {
      cursor: ${disabled ? 'default' : selected ? 'default' : 'pointer'};
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid ${colors.neutral.grayLight};
    }
  `}
`;

// eslint-disable-next-line @typescript-eslint/ban-types
const sameWidthModifier: Modifier<string, object> = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${
      (state.elements.reference as HTMLElement).offsetWidth
    }px`;
  }
};

type SelectProps<T> = {
  placeholder?: string;
  items: T[];
  item?: T;
  onChange?: (item?: T | null) => void;
  getItemValue?: (item: T | null) => unknown;
  getItemLabel?: (item: T | null) => string;
  isItemDisabled?: (item: T | null) => boolean;
  disabled?: boolean;
  searchable?: boolean;
  isLoading?: boolean;
  dropdownSize?: number;
  direction?: 'row' | 'column';
  onInputValueChange?: (inputValue?: string) => void;
} & FormGroupProps;

const Select = <T,>({
  placeholder,
  items,
  item,
  getItemValue,
  getItemLabel,
  isItemDisabled,
  onChange,
  dropdownSize,
  direction,
  searchable = true,
  disabled = false,
  isLoading = false,
  onInputValueChange,
  ...formGroupProps
}: SelectProps<T>): ReactElement => {
  const [inputItems, setInputItems] = useState(items);
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);

  useUpdateEffect(() => {
    setInputItems(items);
  }, [items]);

  const modifiers = useMemo(() => [sameWidthModifier], []);

  const { styles, attributes, update } = usePopper(inputRef, tooltipRef, {
    placement: 'bottom',
    strategy: 'fixed',
    modifiers
  });

  const itemValue = useCallback(getItemValue ?? ((item: T | null) => item), [
    getItemValue
  ]);

  const itemToString = useCallback(
    getItemLabel ?? ((item: T | null) => (item as unknown) as string),
    [getItemLabel]
  );

  const onInputChange = ({ inputValue }: UseComboboxStateChange<T>): void => {
    if (onInputValueChange) {
      onInputValueChange(inputValue);
    } else {
      if (!inputValue || !searchable) {
        setInputItems(items);
      } else {
        const filteredItems = items.filter((item) =>
          itemToString(item)?.toLowerCase().includes(inputValue.toLowerCase())
        );
        setInputItems(filteredItems);
      }
    }
  };

  const {
    isOpen,
    getToggleButtonProps,
    selectedItem,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    getInputProps,
    getComboboxProps,
    openMenu
  } = useCombobox({
    items: inputItems,
    itemToString,
    selectedItem: item ?? null,
    onSelectedItemChange: ({ selectedItem: selectedTtemChange }) =>
      onChange?.(selectedTtemChange),
    onInputValueChange: onInputChange
  });

  useLayoutEffect(() => {
    if (isOpen) {
      update?.();
    }
  }, [isOpen]);

  const onClickInput = useCallback(() => {
    if (!isOpen && !searchable) {
      openMenu();
    }
  }, [isOpen, searchable, openMenu]);

  return (
    <FormGroup {...formGroupProps} direction={direction}>
      <DropdownInputWrapper {...getComboboxProps()} dropdownSize={dropdownSize}>
        <DropdownInput
          hasError={Boolean(formGroupProps?.error?.message)}
          {...getInputProps({
            disabled,
            placeholder,
            autoComplete: 'off',
            readOnly: !searchable,
            onClick: onClickInput,
            ref: setInputRef
          })}
        />
        <DropdownInputButtonIcon
          iconName='chevron-down'
          hasError={Boolean(formGroupProps?.error?.message)}
          {...getToggleButtonProps({ type: 'button', disabled })}
        />
      </DropdownInputWrapper>
      {createPortal(
        <DropdownListWrapper
          ref={setTooltipRef}
          style={styles.popper}
          {...attributes.popper}>
          <div {...getMenuProps()}>
            {isOpen && (inputItems?.length || isLoading) ? (
              <DropdownListContent>
                {isLoading ? (
                  <DropdownLoaderWrapper>
                    <Loader />
                  </DropdownLoaderWrapper>
                ) : (
                  <DropdownList>
                    {inputItems.map((item, index) => (
                      <ListItem
                        key={itemValue(item)}
                        active={highlightedIndex === index}
                        selected={selectedItem === item}
                        disabled={isItemDisabled?.(item) ?? false}
                        {...getItemProps({
                          item,
                          index,
                          onMouseDown: (ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                          }
                        })}>
                        {itemToString(item)}
                      </ListItem>
                    ))}
                  </DropdownList>
                )}
              </DropdownListContent>
            ) : null}
          </div>
        </DropdownListWrapper>,
        document.querySelector('#root')!
      )}
    </FormGroup>
  );
};

export { Select };
