import styled from '@emotion/styled';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';

const CardTag = styled.div`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig },
        radius
      }
    }
  }) => `
      background-color: ${getRgbaStrFromHexColor(colors.primary.default, 0.1)};
      border-radius: ${radius.rounded};
      color: ${colors.primary.default};
      ${getTextsConfig('M')};
      padding: ${rem(pxToRem(2))} ${rem(pxToRem(10))};

      &.inverse {
        background-color: ${getRgbaStrFromHexColor(
          colors.primary.darkest,
          0.1
        )};
        color: ${getRgbaStrFromHexColor(colors.primary.darkest, 0.6)};
      }
  `}
`;

export { CardTag };
