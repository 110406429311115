import { DropdownInput, InputCalendar } from '@app/shared/components';
import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement, useState } from 'react';
import { FilterProps, useAsyncDebounce } from 'react-table';

import { DEBOUNCE_TIME_FILTER_IN_MILLISECONDS } from '../../table.service';

const InputCalendarWrapper = styled.div`
  flex: 1;
  ${DropdownInput} {
    height: ${rem(pxToRem(24))};
  }
`;

const DateFilter = <T extends object>({
  column: { filterValue, setFilter }
}: FilterProps<T>): ReactElement => {
  const [date, setDate] = useState<Date | undefined>(
    filterValue ? new Date(filterValue) : undefined
  );

  const onChange = useAsyncDebounce((value: Date | undefined) => {
    setFilter(value || undefined);
  }, DEBOUNCE_TIME_FILTER_IN_MILLISECONDS);

  return (
    <InputCalendarWrapper>
      <InputCalendar value={date} onChange={(d) => (setDate(d), onChange(d))} />
    </InputCalendarWrapper>
  );
};

export { DateFilter };
