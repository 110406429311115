import { notifySuccess } from '@app/shared/notifications';
import { USER_QUERY_KEY } from '@domain/user-profile/userProfileKeys';
import { UseMutation, useMutationWrapper } from '@hooks/useMutationWrapper';
import { updateUserIsActive } from '@services/user-profile/userProfileService';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

type UpdateUserProfileIsActiveModel = {
  id: string;
  isActive: boolean;
};

export type UpdateUserProfileIsActiveMutation = UseMutation<
  void,
  Error,
  UpdateUserProfileIsActiveModel,
  unknown
>;

const useUpdateUserProfileIsActive = (): UpdateUserProfileIsActiveMutation => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutationWrapper<void, Error, UpdateUserProfileIsActiveModel>(
    ({ id, isActive }) => updateUserIsActive(id, { isActive }),
    {
      onSuccess: () => {
        notifySuccess(t('shared:toast.operation-success'));

        queryClient.invalidateQueries(...USER_QUERY_KEY(''));
      }
    }
  );
};

export { useUpdateUserProfileIsActive };
