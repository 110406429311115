import styled from '@emotion/styled';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import React, { ReactElement, ReactNode } from 'react';
import ReactModal from 'react-modal';

import { Suspense } from '../suspense';

const ModalContentStyled = styled(ReactModal)`
  ${({
    theme: {
      base: { zindex }
    }
  }) => `
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    z-index: ${zindex.modalContent} !important;
  `}
`;

interface ChildrenProps {
  closeModal: () => void;
}

export interface ModalProps {
  className?: string;
  onClose: () => void;
  children: ((props: ChildrenProps) => ReactNode) | ReactNode;
}

const CustomModal = ({
  className,
  onClose,
  children
}: ModalProps): ReactElement => {
  return (
    <ModalContentStyled
      portalClassName={className}
      ariaHideApp={false}
      onRequestClose={onClose}
      isOpen
      shouldCloseOnOverlayClick
      shouldCloseOnEsc>
      <Suspense>
        {typeof children === 'function'
          ? children({ closeModal: onClose })
          : children}
      </Suspense>
    </ModalContentStyled>
  );
};

const ModalBase = styled(CustomModal)`
  ${({
    theme: {
      base: { colors, zindex }
    }
  }) => `
        .ReactModal__Overlay {
            align-items: center;
            background-color: transparent !important;
            display: flex;
            justify-content: center;
            z-index: ${zindex.modalOverlay};

            &:after {
              content: '';
              background-color: ${getRgbaStrFromHexColor(
                colors.neutral.dark,
                0.2
              )};
              z-index: ${zindex.modalOverlay};
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              animation-name: overlay;
              animation-duration: 0.3s;

              @keyframes overlay {
                  from {
                      opacity: 0;
                  }
                  to {
                      opacity: 1;
                  }
              }
            }
        }
    `}
`;

export { ModalBase };
