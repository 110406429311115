import { Location } from '@app/shared/routes';
import { RouteHelper } from '@utils/helpers/routeHelper';

export interface CreateFloorplansFormLocationParams {
  venueId: string;
  buildingId: string;
}

export const CreateFloorplansFormLocation = new Location<
  CreateFloorplansFormLocationParams
>({
  path:
    '/facility-management/venues/:venueId/buildings/:buildingId/floorplans/create',
  pathParamDefs: {
    venueId: RouteHelper.stringParamValidation(),
    buildingId: RouteHelper.stringParamValidation()
  }
});

export interface EditFloorplansFormLocationParams {
  venueId: string;
  buildingId: string;
  floorplanId: string;
}

export const EditFloorplansFormLocation = new Location<
  EditFloorplansFormLocationParams
>({
  path:
    '/facility-management/venues/:venueId/buildings/:buildingId/floorplans/:floorplanId/edit',
  pathParamDefs: {
    venueId: RouteHelper.stringParamValidation(),
    buildingId: RouteHelper.stringParamValidation(),
    floorplanId: RouteHelper.stringParamValidation()
  }
});
