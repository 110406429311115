const BUILDING_CONSTRAINTS = {
  NAME: {
    MIN_LENGTH: 1,
    MAX_LENGTH: 50
  },
  ADDRESS: {
    MIN_LENGTH: 1,
    MAX_LENGTH: 250
  },
  CITY: {
    MIN_LENGTH: 1,
    MAX_LENGTH: 50
  },
  LIMITED_CAPACITY: {
    MIN_VALUE: 0,
    MAX_VALUE: 100
  }
};

const MAX_IMAGE_SIZE_BYTES = 800 * 1024;
const ACCEPTED_IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/svg+xml'];

export { BUILDING_CONSTRAINTS, MAX_IMAGE_SIZE_BYTES, ACCEPTED_IMAGE_TYPES };
