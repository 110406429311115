import styled from '@emotion/styled';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';

const CardBase = styled.article<{ clickable?: boolean }>`
  ${({
    theme: {
      base: { colors, shadow }
    },
    clickable
  }) => `
        background-color: ${colors.neutral.fullLight};
        border-radius: ${rem(pxToRem(4))};
        box-shadow: ${shadow.card(colors)};
        box-sizing: border-box;
        cursor: ${clickable ? 'pointer' : 'default'};
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: calc(100% - ${rem(pxToRem(14))});
        justify-content: center;
        margin: ${rem(pxToRem(2))} auto 0;
        padding: ${rem(pxToRem(16))};
        width: calc(100% - ${rem(pxToRem(16))});
        word-break: break-word;

        &:hover {
            box-shadow: ${shadow.cardHover(colors)};
        }
    `}
`;

const CardInfoWrapper = styled.div`
  ${({
    theme: {
      base: { colors }
    }
  }) => `
    &.grayscale {
      * {
        color: ${getRgbaStrFromHexColor(colors.primary.darkest, 0.4)};
      }

      img,
      svg {
        filter: grayscale(1);
        opacity: 0.4;
      }
    }
  `}
`;

export { CardBase, CardInfoWrapper };
