import {
  GridTemplate,
  InputCheckBox,
  InputCheckItem,
  InputRadio
} from '@app/shared/components';
import { UserSearcher } from '@app/shared/components/form/UserSearcher';
import {
  WorkplaceFormModel,
  WorkstationFormModel
} from '@domain/workplaces/models/create/workplaceFormModel';
import {
  WorkplacePlaceType,
  workplacesDefinition,
  WorkStationType
} from '@domain/workplaces/models/workplace';
import React, {
  forwardRef,
  ReactElement,
  Ref,
  useImperativeHandle,
  useMemo
} from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PanelSubTitle } from '../../../../shared/components/page/page-header/PanelHeader';
//import { LanSelector } from '../shared/LanSelector';
import { WorkplaceFormRef } from '../shared/workplaceFormRef';
import { WorkplaceStatusOptions } from '../shared/WorkplaceStatusOptions';

type FormModel = WorkstationFormModel & {
  bookedUser: { id: string; name: string };
};

const WorkstationForm = forwardRef(
  (_, ref: Ref<WorkplaceFormRef>): ReactElement => {
    const { t } = useTranslation('workplaces');

    const { control, register, errors, setValue } = useFormContext<FormModel>();

    const workstationTypeValue = useWatch<string>({
      control,
      name: 'workstationtype'
    });

    useImperativeHandle(
      ref,
      () => ({
        transformFormModel: (model: WorkplaceFormModel): WorkplaceFormModel => {
          const {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            bookedUser,
            ...restModel
          } = model as FormModel;
          return restModel;
        }
      }),
      []
    );

    const workStationType: InputCheckItem[] = [
      {
        id: 'flex-desk',
        label: t('station.flexDesk'),
        value: WorkStationType.FlexDesk
      },
      {
        id: 'fixed-desk',
        label: t('station.fixedDesk'),
        value: WorkStationType.FixedDesk
      }
    ];

    const isFixedDesk = useMemo(
      () =>
        workstationTypeValue ==
        workStationType.find((t) => t.value === WorkStationType.FixedDesk)
          ?.value,
      [workstationTypeValue]
    );

    const facilities: InputCheckItem[] =
      workplacesDefinition[WorkplacePlaceType.Workstation].facilities
        ?.filter((f) => f.fieldName !== 'workplace')
        .map((f) => ({
          id: f.fieldName,
          label: t(f.title),
          name: f.fieldName,
          value: 1
        })) ?? [];

    const workplaces: InputCheckItem[] =
      workplacesDefinition[WorkplacePlaceType.Workstation].facilities
        ?.filter((f) => f.fieldName === 'workplace')
        .map((f) => ({
          id: `${f.fieldName}_${f.icon}`,
          label: t(f.title),
          value: f.value          
        })) ?? [];

    return (
      <>
        <div>
          <WorkplaceStatusOptions />
        </div>
        <GridTemplate columns={2}>
          <div>
            <PanelSubTitle>{t('fieldNames.workstationType')}</PanelSubTitle>
            <InputRadio
              innerRef={register}
              name='workstationtype'
              options={workStationType}
              error={errors.workstationtype}
            />
          </div>
          {/* { <div>
            <LanSelector />
          </div> } */}
        </GridTemplate>
        {isFixedDesk ? (
          <>
            <input type='hidden' name='bookedUserId' ref={register} />
            <input type='hidden' name='bookedUserName' ref={register} />
            <Controller
              render={({ value, onChange }) => (
                <UserSearcher
                  label={t('fieldNames.bookedUser')}
                  placeholder={t('fieldNames.bookedUser')}
                  value={value}
                  onChange={(item) => {
                    setValue('bookedUserId', item?.id);
                    setValue('bookedUserName', item?.name);
                    onChange(item);
                  }}
                  error={errors?.bookedUserId}
                />
              )}
              control={control}
              name='bookedUser'
              defaultValue={
                control.defaultValuesRef.current?.bookedUserId
                  ? {
                      id: control.defaultValuesRef.current?.bookedUserId,
                      name: control.defaultValuesRef.current?.bookedUserName
                    }
                  : {}
              }
            />
          </>
        ) : null}
        <div>
          <PanelSubTitle>{t('fieldNames.facilities')}</PanelSubTitle>
          <GridTemplate>
            <InputRadio
              innerRef={register}
              name='workplace'
              options={workplaces}
              error={errors.workplace}
              columns={2}
            />
            <InputCheckBox
              innerRef={register}
              options={facilities}
              columns={2}
            />
          </GridTemplate>
        </div>
      </>
    );
  }
);

export { WorkstationForm };
