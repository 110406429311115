import {
  MenuActions,
  MenuActionsItem,
  MenuActionsWrapper
} from '@app/shared/components';
import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

const BulkActionsWrapper = styled.div`
  ${() => `
    align-items: center;
    display: inline-flex;
    height: ${rem(pxToRem(36))};
    justify-content: flex-start;

    ${MenuActionsWrapper} {
      filter: none;
      padding: 0;
    }
  `}
`;

const BulkActionsResume = styled.p`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig }
      }
    }
  }) => `
      color: ${colors.primary.darkest};
      ${getTextsConfig('M')};
      margin-right: ${rem(pxToRem(8))};
  `}
`;

interface BulkActionsProps {
  count: number;
  bulkOptions: MenuActionsItem[];
}

const BulkActions = ({
  count,
  bulkOptions
}: BulkActionsProps): ReactElement => {
  const { t } = useTranslation('workplaces');
  return (
    <BulkActionsWrapper>
      <BulkActionsResume>
        {t('shared:selectedItems', { count })}
      </BulkActionsResume>
      <MenuActions options={bulkOptions} />
    </BulkActionsWrapper>
  );
};

export { BulkActions };
