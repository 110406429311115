import * as yup from 'yup';

import { VENUE_CONSTRAINTS } from './venueConstraints';

export interface VenueDetailFormModel {
  name: string;
}

const venueDetailFormSchema = yup.object().shape({
  name: yup
    .string()
    .label('buildings:fieldNames.name')
    .required()
    .min(VENUE_CONSTRAINTS.NAME.MIN_LENGTH)
    .max(VENUE_CONSTRAINTS.NAME.MAX_LENGTH)
});

export { venueDetailFormSchema };
