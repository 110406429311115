import { DAILY_BOOKINGS_QUERY_KEY, DailyBooking } from '@domain/bookings';
import { getDailyBookings } from '@services/bookings/bookingsService';
import { useQuery, UseQueryResult } from 'react-query';

const useGetAllDailyBookings = (
  floorId: string,
  startDate?: Date,
  endDate?: Date
): UseQueryResult<DailyBooking[], Error> =>
  useQuery<DailyBooking[], Error>(
    DAILY_BOOKINGS_QUERY_KEY(floorId, startDate!, endDate),
    () => getDailyBookings(floorId, startDate!, endDate),
    { enabled: !!floorId && !!startDate }
  );

export { useGetAllDailyBookings };
