import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';
import React, { PropsWithChildren, ReactElement } from 'react';
import { FieldError } from 'react-hook-form';

import { MessageValidation } from './MessageValidation';

const FormGroupListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

interface LabelProps {
  hasError?: boolean;
}

const Label = styled.label<LabelProps>`
  ${({
    theme: {
      base: {
        fonts: { getTextsConfig },
        colors
      }
    },
    hasError
  }) => `
        color: ${
          hasError 
            ? colors.system.error
            : colors.primary.darkest 
        };
        ${getTextsConfig('S')};
        opacity: 0.8;
        padding: 0 ${rem(pxToRem(4))} ${rem(pxToRem(4))};
    `}
`;

export interface FormGroupListProps {
  label?: string;
  labelKey?: string;
  error?: FieldError;
}

const FormGroupList = ({
  label,
  error,
  children
}: PropsWithChildren<FormGroupListProps>): ReactElement => (
  <FormGroupListWrapper>
    {label &&
      <Label hasError={Boolean(error?.message)}>
        {label}
      </Label>
    }
    {children}
    <MessageValidation>{error?.message}</MessageValidation>
  </FormGroupListWrapper>
);

export { FormGroupList };
 