import { configuration } from '@configuration';
import { TermCondition } from '@domain/term-condition';
import { apiService } from '@services/api';
import { FileParameter } from '@services/api/apiService';

const createTermCondition = async (
  termConditonFile: FileParameter
): Promise<void> => {
  await apiService.termCondition_Create(termConditonFile);
};

const getTermCondition = async (): Promise<TermCondition | undefined> => {
  const { organizationId } = configuration;

  const result = await apiService.termCondition_Get(organizationId as number);
  return result && result.date ? result : undefined;
};

export { createTermCondition, getTermCondition };
