import { RootLocation } from '@app/routes/rootLocation';
import { useAuthContextActions } from '@contexts/auth';
import styled from '@emotion/styled';
import { useLoadImage } from '@hooks/useLoadImage';
import { useUserProfile } from '@stores/userProfileStore';
import { getRgbaStrFromHexColor } from '@styles/utils/color';
import { pxToRem, rem } from '@styles/utils/sizes';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { UserMenu } from './user-menu/UserMenu';

const HeaderComponent = styled.header`
  ${({
    theme: {
      base: { colors, layout, zindex }
    }
  }) => `
        align-items: center;
        background-color: ${colors.neutral.grayMedium};
        display: flex;
        height: ${layout.header.height};
        justify-content: space-between;
        min-height: ${layout.header.height};
        padding: 0 ${rem(pxToRem(16))} 0 ${rem(pxToRem(40))};
        position: relative;
        z-index: ${zindex.XS};
    `}
`;

const HeaderItem = styled.div`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getTextsConfig }
      }
    }
  }) => `
        &:not(:first-of-type) {
            margin-left: ${rem(pxToRem(40))};
        }

        > a {
            color: ${colors.neutral.dark};
            text-decoration: none;
            ${getTextsConfig('L')};

            &:hover {
                color: ${colors.primary.default};
            }

            &.active {
                &,
                &:hover {
                    color: ${colors.primary.default};
                    cursor: default;
                }
            }
        }
    `}
`;

const MenuContainer = styled.div`
  ${({
    theme: {
      base: { zindex }
    }
  }) => `
        align-items: center;
        display: flex;
        flex-direction: row;
        z-index: ${zindex.default};
    `}
`;

const LogoContainer = styled.div`
  ${({
    theme: {
      base: { colors, zindex }
    }
  }) => `
        align-items: center;
        box-sizing: content-box;
        display: flex;
        margin-right: ${rem(pxToRem(24))};
        padding-right: ${rem(pxToRem(24))};
        position: relative;
        width: ${rem(pxToRem(120))};
        z-index: ${zindex.default};

        &:after {
            background-color: ${getRgbaStrFromHexColor(
              colors.primary.darkest,
              0.2
            )};
            content: '';
            height: ${rem(pxToRem(32))};
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            width: ${rem(pxToRem(2))};
        }

        svg {
            width: ${rem(pxToRem(104))}
        }
    `}
`;

const ClientText = styled.span`
  ${({
    theme: {
      base: {        
        fonts: { getTextsConfig, semiBoldFont }
      }
    }
  }) => `
        ${getTextsConfig('L')};
        font-family: '${semiBoldFont}';
    `}
`;

const UserMenuContainer = styled(MenuContainer)`
  justify-content: flex-end;
`;

const Header: React.FC = () => {
  const { t } = useTranslation('shared');

  const { userProfile } = useUserProfile();
  const { goLogoutPage } = useAuthContextActions();
  const { getImage } = useLoadImage();

  return (
    <HeaderComponent>
      <MenuContainer>
        <LogoContainer role='logo'>
          {RootLocation.toLink(<img src={getImage('Logo')} />)}
        </LogoContainer>
        <ClientText>{t('poweredBy')}</ClientText>
      </MenuContainer>
      {userProfile.userName && (
        <UserMenuContainer data-testid='username-menu'>
          <HeaderItem>
            <UserMenu
              username={userProfile.userName}
              useremail={userProfile.email}
              logout={goLogoutPage}
            />
          </HeaderItem>
        </UserMenuContainer>
      )}
    </HeaderComponent>
  );
};

export { Header };
