import { Loader } from '@app/shared/components';
import { useAuthContextActions } from '@contexts/auth';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const SigninCallback: React.FC = () => {
  const { completeLogin } = useAuthContextActions();

  const history = useHistory();

  useEffect(() => {
    const redirectLogin = async (): Promise<void> => {
      try {
        await completeLogin();
      } catch (ex) {
        history.push('/');
      }
    };

    redirectLogin();
  }, []);

  return <Loader isFullPage />;
};

export default SigninCallback;
