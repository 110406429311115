import { SHIFT_QUERY_KEY, ShiftType } from '@domain/buildings';
import { getShift } from '@services/buildings/buildingsService';
import { useQuery, UseQueryResult } from 'react-query';

type Props = {
  buildingId: string;
  suspense?: boolean;
};

const useGetShift = ({
  buildingId,
  suspense
}: Props): UseQueryResult<ShiftType[], Error> =>
  useQuery<ShiftType[], Error>(
    SHIFT_QUERY_KEY(buildingId),
    () => getShift(buildingId),
    { suspense: !!suspense }
  );

export { useGetShift };
