import { FloorplanWorkplace } from '@app/facility-management/floorplans/floorplan-settings/shared/models/floorplanWorkplace';
import { CollapsibleSection, GridTemplate } from '@app/shared/components';
import { WorkplaceFormModel } from '@domain/workplaces/models/create/workplaceFormModel';
import { WorkplacePlaceType } from '@domain/workplaces/models/workplace';
import styled from '@emotion/styled';
import React, { ReactNode, useMemo } from 'react';
import { ReactElement } from 'react';

import { BulkActions } from '../../workplaces-form/BulkActions';
import { useWorkplaceTypeActions } from './useWorkplaceTypeActions';
import { WorkplacesActions } from './WorkplacesActions';
import { WorkplaceTable } from './WorplaceTable';

const WorkplacesTitle = styled.h2`
  ${({
    theme: {
      base: {
        colors,
        fonts: { getHeadlinesConfig }
      }
    }
  }) => `
    color: ${colors.primary.default};
    ${getHeadlinesConfig('XS')};
  `}
`;

export type Section = {
  title: string;
  filter: (item: WorkplaceFormModel) => boolean;
};

export type Column = {
  header: string;
  value: (item: WorkplaceFormModel) => ReactNode | string;
  show?: (item: WorkplaceFormModel) => boolean;
};

interface WorkplaceTypeListProps {
  placeType: WorkplacePlaceType;
  title: string;
  workplaces: FloorplanWorkplace;
  selectedWorkplacesIds: string[];

  columns: Column[];
  sections?: Section[];

  onSelectionChange: (entityId: string, isSelected: boolean) => void;
  onEdit: (entityId: string) => void;
  onDelete: (...entityId: string[]) => void;
  onEditEntitiesProperties: (
    properties: Partial<WorkplaceFormModel>,
    ...entityIds: string[]
  ) => void;
}

const WorkplaceTypeList = ({
  placeType,
  title,
  workplaces,
  selectedWorkplacesIds,
  columns,
  sections,
  onSelectionChange,
  onEdit,
  onDelete,
  onEditEntitiesProperties
}: WorkplaceTypeListProps): ReactElement => {
  const { bulkActionsItems, getItemActions } = useWorkplaceTypeActions({
    workplaces,
    selectedWorkplacesIds,
    onEdit,
    onDelete,
    onEditEntitiesProperties
  });

  const workplaceSections = useMemo(
    () =>
      sections
        ?.map((s) => ({
          title: s.title,
          workplaces: Object.entries(workplaces)
            .filter(([, value]) => s.filter(value.model))
            .reduce((prevValue, [key, value]) => {
              prevValue[key] = value;
              return prevValue;
            }, {} as FloorplanWorkplace)
        }))
        ?.filter((s) => Object.keys(s.workplaces).isNotEmpty()),
    [sections, workplaces]
  );

  return (
    <GridTemplate>
      <WorkplacesActions placeType={placeType} />
      <WorkplacesTitle>
        {title} ({Object.keys(workplaces).length})
      </WorkplacesTitle>
      <BulkActions
        bulkOptions={bulkActionsItems}
        count={selectedWorkplacesIds.length}
      />

      {workplaceSections?.isNotEmpty() ? (
        workplaceSections.map(
          ({ title, workplaces: sectionWorkplaces }, index) => (
            <CollapsibleSection
              key={index}
              title={`${title} (${Object.keys(sectionWorkplaces)?.length})`}>
              <WorkplaceTable
                workplaces={sectionWorkplaces}
                selectedWorkplacesIds={selectedWorkplacesIds}
                columns={columns}
                onSelectionChange={onSelectionChange}
                getItemActions={getItemActions}
              />
            </CollapsibleSection>
          )
        )
      ) : Object.keys(workplaces).isNotEmpty() ? (
        <WorkplaceTable
          workplaces={workplaces}
          selectedWorkplacesIds={selectedWorkplacesIds}
          columns={columns}
          onSelectionChange={onSelectionChange}
          getItemActions={getItemActions}
        />
      ) : null}
    </GridTemplate>
  );
};

export { WorkplaceTypeList, WorkplacesTitle };
