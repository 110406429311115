import styled from '@emotion/styled';
import { pxToRem, rem } from '@styles/utils/sizes';

const BookingListContainer = styled.div`
  margin-bottom: ${rem(pxToRem(32))};
  margin-right: ${rem(pxToRem(20))};
  max-height: calc(
    100vh - ${rem(pxToRem(156))} - ${rem(pxToRem(72))} - ${rem(pxToRem(32))}
  );
  overflow-x: hidden;
  padding: 0 ${rem(pxToRem(20))} ${rem(pxToRem(20))} ${rem(pxToRem(40))};
`;

const BookintListBarActions = styled.div`
  align-items: center;
  display: flex;

  > * {
    margin-right: ${rem(pxToRem(24))};
  }
`;

export { BookingListContainer, BookintListBarActions };
