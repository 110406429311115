import React, { memo, ReactNode } from 'react';
import { areEqual, GridChildComponentProps } from 'react-window';

type GridItemProps = {
  columnCount: number;
  itemTemplate: (item: unknown) => ReactNode;
} & GridChildComponentProps;

const GridItem = memo(
  ({
    style,
    data,
    rowIndex,
    columnCount,
    columnIndex,
    itemTemplate
  }: GridItemProps) => {
    const item = data[rowIndex * columnCount + columnIndex];
    return item ? <li style={style}>{itemTemplate(item)}</li> : null;
  },
  areEqual
);
GridItem.displayName = 'GridItem';

export { GridItem };
